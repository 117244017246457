import "./quaymanual.css"

import React, { useState } from "react";
import PanelBar from "../../components/layout/PanelBar";
import QuayObservation from "./QuayObservation";
import QuayDocumentCard from "./components/QuayDocumentCard";
import Slide from "../../components/layout/Slide";
import QuayPDF from "./components/QuayPDF";
import Spacer from "../../components/layout/Spacer";

const SearchedQuayLocation = ({ searchedLocation }) => {
    const [showPdf, setShowPdf] = useState(false);

    return (
        <>        
            {searchedLocation &&
                <PanelBar key={searchedLocation.locationId} title={searchedLocation.name} className="quaymanual-panelbar">
                    <div className="quaymanual-blinds-content">
                    {searchedLocation.documents && searchedLocation.documents.map((doc) => (
                        <PanelBar key={doc.binaryContentId} title={doc.name} className="sub-expansion-panel">
                            <QuayDocumentCard
                                onClick={() => setShowPdf(!showPdf)}
                                doc={doc} />
                            <Slide show={showPdf}>
                                <QuayPDF documentId={showPdf ? doc.binaryContentId : 0} />
                            </Slide>
                            <Spacer height={3} />
                            <QuayObservation doc={doc} />
                        </PanelBar>
                        ))}
                    </div>
                </PanelBar>
            }
        </>
    );
};

export default SearchedQuayLocation;