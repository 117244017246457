import './styles/worktime.css';

import React, { useReducer, useState } from 'react';
import {
    PageStates,
    WorkTimePeriodStates,
    WorkTimeStampTypes,
    IconColors,
} from '../../services/SystemNames';

import { useEffectOnce } from '../../components/useEffectOnce';
import { format } from 'date-fns';

import StartAndStop from './components/StartAndStop';
import SelfDefined from './components/SelfDefined';
import DeviationSelector from './components/DeviationSelector';
import OptimizableWorkTime from './components/OptimizableWorkTime';
import { Button } from "@progress/kendo-react-buttons";
import parse from 'html-react-parser';
import Spacer from '../../components/layout/Spacer';
import WorkTimeApiRepository from '../../repositories/api/WorkTimeApiRepository';
import Moment from 'moment';
import ContentGrid from '../../components/layout/ContentGrid';
import Icons, { CloseIcon, OkIcon } from '../../components/layout/icons/Icons';
import Overlay from '../../components/layout/overlay/Overlay';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import SourceApiRepository from '../../repositories/api/SourceApiRepository';
import ApplicationInsightsService from '../../services/AppInsightsService';
import WorkTimeBottomNavigation from './components/WorkTimeBottomNavigation';
import { isNullOrEmpty, removeQuotesFromString } from '../../components/helpers/ObjectHelpers';
import { publishErrorNotificationTopicWithFadeout, publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { RegisterLocationStopDialog } from './components/RegisterLocationStopDialog';

const WorkTimeActions = {
    // Events
    ActivateSelfDefined: 'ActivateSelfDefined',
    DeactivateSelfDefined: 'DeactivateSelfDefined',
    DeletePeriod: 'DeletePeriod',
    ActivateOngoingWorkTime: 'ActivateOngoingWorkTime',
    Reset: 'Reset',
    StartEdit: 'StartEdit',
    ShowError: 'ShowError',

    // Properties
    WorkTimePeriodState: 'WorkTimePeriodState',
    IsNavigatingToSelfDefined: 'IsNavigatingToSelfDefined',
    HasOngoingWorkTime: 'HasOngoingWorkTime',
    OngoingWorkTimeHasDeviation: 'OngoingWorkTimeHasDeviation',
    IsStopping: 'IsStopping',
    StopWithDeviation: 'StopWithDeviation',
    SelfDefinedStartStopText: 'SelfDefinedStartStopText',
    InstructionText: 'InstructionText',
    SelfDefinedTime: 'SelfDefinedTime',
    EditWorkTimeInstructionText: 'EditWorkTimeInstructionText'
}

function Register() {

    const workTypeId = 1;
    const restingTypeId = 2;
    const [clickedPeriod, setClickedPeriod] = useState(true);
    const [pageState, setPageState] = useState(PageStates.Busy);
    const [httpStatusCode, setHttpStatusCode] = useState(0);
    const [workTimePeriods, setWorkTimePeriods] = useState([]);
    const [restingPeriods, setRestingPeriods] = useState([]);
    const [shouldShowLocationDialog, setShouldShowLocationDialog] = useState(false);
    const [latestWorkTime, setLatestWorkTime] = useState({});
    const [optimizedWorkTime, setOptimizedWorkTime] = useState({ description: '', isWorktTimeOptimizable: false, header: '' });
    const [workTimeState, workTimeDispatcher] = useReducer(
        workTimeReducer,
        {
            ongoingWorkTime: {},

            isNavigatingToSelfDefined: false,
            hasOngoingWorkTime: false,
            ongoingWorkTimeHasDeviation: false,
            isStopping: false,
            stopWithDeviation: false,
            isUpdatingRestingTime: false,
            IsUpdatingWorkTime: false,

            workTimePeriodState: WorkTimePeriodStates.Default,

            selfDefinedStartStopText: "Egendefinert start",
            instructionText: "Start ved avreise fra hvilested",
            editWorkTimeInstructionText: "Start ved avreise fra hvilested",
            selfDefinedTime: null
        });

    // Functions for processing data
    const initializeAsync = async () => {
        const response = await WorkTimeApiRepository.getRecentAsync();
        if (response.ok === true) {
            executeWorkTimeDispatcher(WorkTimeActions.SelfDefinedTime, null);
            const data = await response.json();
            const orderedPeriods = data.sort(orderDateByDecending);
            const ongoingWorkTime = orderedPeriods.find(e => checkForDefaultDate(e.stopTime) && e.workTimePeriodType.workTimePeriodTypeId === workTypeId);
            const latestWorkTime = orderedPeriods.find(e => !checkForDefaultDate(e.stopTime) && e.workTimePeriodType.workTimePeriodTypeId === workTypeId && ongoingWorkTime === undefined);
            if (ongoingWorkTime !== undefined) {
                executeWorkTimeDispatcher(WorkTimeActions.ActivateOngoingWorkTime, ongoingWorkTime);
            }
            setLatestWorkTime(latestWorkTime);
            setWorkTimePeriods(orderedPeriods.filter(p => p.workTimePeriodType.workTimePeriodTypeId === workTypeId));
            setRestingPeriods(orderedPeriods.filter(p => p.workTimePeriodType.workTimePeriodTypeId === restingTypeId));
            setPageState(PageStates.Default);
            getOptimizedWorkTimeAsync(new Date());

        } else {
            handleError(response);
        }
    }

    const saveAsync = async (url, workTimeQuery) => {
        setPageState(PageStates.Busy);
        const response = await WorkTimeApiRepository.setAsync(url, workTimeQuery);
        if (response.ok === true) {
            if (url.startsWith('stop')) {
                getPilotPosition();
            }
            executeWorkTimeDispatcher(WorkTimeActions.Reset);
            initializeAsync();
        } else {
            const message = await response.text();
            if (isNullOrEmpty(message)) {
                executeWorkTimeDispatcher(WorkTimeActions.Reset);
                handleError(response);
            } else {
                setPageState(PageStates.Default);
                initializeAsync();
                publishWarningNotificationTopic(removeQuotesFromString(message));
                executeWorkTimeDispatcher(WorkTimeActions.ShowError);
            }
        }
    }

    // Initialize function
    useEffectOnce(() => {
        publishHeaderTopic("Arbeidstid");
        initializeAsync();
    }, []);

    // Functions for rendering page
    const renderStartStop = () => {
        return (
            <>
                <div className="worktime-subtitle">
                    <center>
                        {parse(workTimeState.instructionText)}
                    </center>
                </div>
                <Spacer classname="worktime-spacer-high" />
                <div>
                    <center>
                        <StartAndStop
                            hasOngoingWorkTime={workTimeState.hasOngoingWorkTime}
                            handleWorkTime={handleWorkTime}
                        />
                    </center>
                </div>
                <Spacer classname="worktime-spacer-high" />
            </>
        );
    }

    const renderSelfDefined = () => {
        if (workTimeState.isNavigatingToSelfDefined === false) {
            return (
                <React.Fragment>
                    <div className="self-defined-subtitle">
                        <Button
                            size="large"
                            themeColor="primary"
                            style={{
                                width: 250
                            }}
                            onClick={onSelfDefinedClick}>{workTimeState.selfDefinedStartStopText}</Button>
                    </div>
                    <Spacer classname="worktime-spacer-medium" />
                </React.Fragment>
            );
        } else {
            return (
                <SelfDefined
                    title={workTimeState.selfDefinedStartStopText}
                    onChange={handleOnChange}
                    dateToShow={workTimeState.selfDefinedTime}
                    onSave={onSaveSelfDefined}
                    cancel={() => handleCancelSelfDefined()}
                />
            );
        }
    }

    const renderEditWorkTimePeriod = (period) => {
        return (
            <React.Fragment>
                <Spacer classname="worktime-spacer-low" />
                <SelfDefined
                    title={parse(workTimeState.editWorkTimeInstructionText)}
                    onChange={handleOnChange}
                    onSave={onUpdatePeriod}
                    dateToShow={workTimeState.selfDefinedTime}
                    cancel={() => executeWorkTimeDispatcher(WorkTimeActions.WorkTimePeriodState, WorkTimePeriodStates.Default)}
                />
            </React.Fragment>
        );
    }

    const renderDeleteWorkTimePeriod = (period) => {
        return (
            <React.Fragment>
                <Spacer classname="worktime-spacer-low" />
                <div className="workTime-delete">
                    <div>
                        <Button
                            svgIcon={CloseIcon}
                            onClick={() => executeWorkTimeDispatcher(WorkTimeActions.WorkTimePeriodState, WorkTimePeriodStates.Default)}
                        >Avbryt</Button>
                    </div>
                    <div>
                        <Button
                            svgIcon={OkIcon}
                            themeColor="primary"
                            onClick={() => onDelete(period)}
                        >Slett</Button>
                    </div>
                </div>
                <Spacer classname="worktime-spacer-medium" />
            </React.Fragment>
        );
    }

    const renderWorkTimePeriodAction = (period, index) => {
        if (index !== 0) return null;
        switch (workTimeState.workTimePeriodState) {
            case WorkTimePeriodStates.Edit:
                if (clickedPeriod) {
                    workTimeState.isUpdatingWorkTime = true;
                    workTimeState.isUpdatingRestingTime = false;
                    return renderEditWorkTimePeriod(period);
                }
                workTimeState.isUpdatingWorkTime = false;
                workTimeState.isUpdatingRestingTime = true;
                return renderEditWorkTimePeriod(restingPeriods[0]);
            case WorkTimePeriodStates.Delete:
                return renderDeleteWorkTimePeriod(period);

            default:
                return null;
        }
    }

    const renderWorkTimePeriod = (period, index) => {

        const selectedStyle = (index === 0 && workTimeState.hasOngoingWorkTime) ? "selected-workTime" : "";
        const pointerStyle = (index === 0) ? "cursor-pointer" : "";
        return (
            <div key={period.workTimeId}>
                <div className={`workTime-list-item ${selectedStyle}`}>
                    <div className={`workTime-list-item-date ${pointerStyle}`}>
                        <div onClick={() => onEditPeriodClick(period, index, true)}>
                            <div>
                                <div className="workTime-time-text">Start tid:</div>
                                <div className="workTime-list-item-date-display">
                                    <Icons color={IconColors.Primary} iconName="calendar2" dimensions="16" className="workTime-time-img" />
                                    {getDateStampFromDate(period.periodStartTime)}
                                    <Icons color={IconColors.Primary} iconName="clock" dimensions="16" className="workTime-time-img m-l-5" />
                                    {getTimeStampFromDate(period.periodStartTime)}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`workTime-list-item-date ${pointerStyle}`}>

                        <div onClick={() => onEditPeriodClick(period, index, false)}>
                            <div>
                                <div className="workTime-time-text">Stopp tid:</div>
                                {
                                    isWorktTimeOngoing(period.stopTime) ?
                                        <div>{getWorktimeOngoingText()}</div>
                                        :
                                        <div className="workTime-list-item-date-display">
                                            <Icons color={IconColors.Primary} iconName="calendar2" dimensions="16" className="workTime-time-img" />
                                            {getDateStampFromDate(period.stopTime)}
                                            <Icons color={IconColors.Primary} iconName="clock" dimensions="16" className="workTime-time-img m-l-5" />
                                            {getTimeStampFromDate(period.stopTime)}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        index === 0 ?
                            <div className="workTime-list-item-delete"><Icons color={IconColors.Primary} iconName="trash" dimensions="16"
                                onClick={onDeletePeriodClick} pointer={true} /></div>
                            :
                            null
                    }
                    <div className="workTime-list-item-clock-img"><Icons color={IconColors.Primary} iconName="clock" dimensions="20" />
                    </div>

                </div>
                {renderWorkTimePeriodAction(period, index)}
            </div>
        );
    }

    const renderWorkTimePeriods = () => {
        return (
            workTimePeriods.map((period, index) => (
                renderWorkTimePeriod(period, index)
            ))
        );
    }

    const renderPageStateDefault = () => {
        return (
            <ContentGrid title="Arbeidstid" titleIcon="clock" fullSize={false}>
                {renderWorkTimeOptimization()}
                {renderStartStop()}
                {renderSelfDefined()}
                {renderWorkTimePeriods()}
            </ContentGrid>
        );
    }

    const renderPageStateDeviation = () => {
        return (
            <ContentGrid title="Arbeidstid" titleIcon="clock" fullSize={false}>
                <div>
                    <DeviationSelector
                        handleSelect={handleSelectDeviation}
                        cancel={handleCancelDeviation}
                    />
                </div>
            </ContentGrid>
        );
    }

    const renderWorkTimeOptimization = () => {
        return (
            <OptimizableWorkTime
                description={optimizedWorkTime.description}
                workTimeIsOptimizable={optimizedWorkTime.isWorktTimeOptimizable}
                header={optimizedWorkTime.header}
            />
        );
    }

    return (
        <>
            {(() => {
                switch (pageState) {
                    case PageStates.Default:
                        return renderPageStateDefault();
                    case PageStates.Deviation:
                        return renderPageStateDeviation();
                    default:
                        return null;
                }
            })()}
            {(shouldShowLocationDialog) &&
                <RegisterLocationStopDialog
                    onClose={() => setShouldShowLocationDialog(false)}
                    onSave={onSavePilotPositionAsync}
                />
            }

            <WorkTimeBottomNavigation id="register" />

            <Overlay
                isBusy={pageState === PageStates.Busy}
                hasErrors={pageState === PageStates.Error}
                onReloadClick={onReloadClick}
                statusCode={httpStatusCode} />
        </>
    );

    function workTimeReducer(state, action,) {
        switch (action.type) {
            // Events
            case WorkTimeActions.ActivateSelfDefined:
                return {
                    ...state,
                    isNavigatingToSelfDefined: true,
                    workTimePeriodState: WorkTimePeriodStates.Default,
                    isUpdatingRestingTime: action.payload.hasOngoingWorkTime === false,
                    isUpdatingWorkTime: action.payload.hasOngoingWorkTime === true
                };
            case WorkTimeActions.DeactivateSelfDefined:
                return {
                    ...state,
                    isNavigatingToSelfDefined: false
                };
            case WorkTimeActions.DeletePeriod:
                return {
                    ...state,
                    workTimePeriodState: WorkTimePeriodStates.Delete,
                    isNavigatingToSelfDefined: false,
                };
            case WorkTimeActions.ActivateOngoingWorkTime:
                return {
                    ...state,
                    ongoingWorkTime: action.payload,
                    hasOngoingWorkTime: true,
                    instructionText: "Stopp n&aring;r du er framme p&aring; egnet hvilested",
                    selfDefinedStartStopText: "Egendefinert stopp",
                    isStopping: true,
                    ongoingWorkTimeHasDeviation: (Date.now() - (new Date(action.payload.periodStartTime))) / (1000 * 60 * 60) > 12,
                }
            case WorkTimeActions.Reset:
                return {
                    ...state,
                    selfDefinedStartStopText: "Egendefinert start",
                    isDeleting: false,
                    isNavigatingToSelfDefined: false,
                    isUpdatingRestingTime: false,
                    isUpdatingWorkTime: false,
                    isStopping: false,
                    hasOngoingWorkTime: false,
                    ongoingWorkTimeHasDeviation: false,
                    instructionText: "Start ved avreise fra hvilested",
                    stopWithDeviation: false,
                    workTimePeriodState: WorkTimePeriodStates.Default,
                }
            case WorkTimeActions.StartEdit:
                return {
                    ...state,
                    workTimePeriodState: WorkTimePeriodStates.Edit,
                    isUpdatingRestingTime: action.payload.hasOngoingWorkTime === false,
                    isUpdatingWorkTime: action.payload.hasOngoingWorkTime === true,
                    editWorkTimeInstructionText: `Oppdater arbeidsperiodens ${(action.payload.periodClickedIndicator) ? 'start' : 'stopp'} tid`
                }
            case WorkTimeActions.ShowError:
                return {
                    ...state,
                    workTimePeriodState: WorkTimePeriodStates.Default,
                }

            // Properties
            case WorkTimeActions.WorkTimePeriodState:
                return { ...state, workTimePeriodState: action.payload };
            case WorkTimeActions.IsNavigatingToSelfDefined:
                return { ...state, isNavigatingToSelfDefined: action.payload };
            case WorkTimeActions.HasOngoingWorkTime:
                return { ...state, hasOngoingWorkTime: action.payload };
            case WorkTimeActions.OngoingWorkTimeHasDeviation:
                return { ...state, ongoingWorkTimeHasDeviation: action.payload };
            case WorkTimeActions.IsStopping:
                return { ...state, isStopping: action.payload };
            case WorkTimeActions.StopWithDeviation:
                return { ...state, stopWithDeviation: action.payload };
            case WorkTimeActions.SelfDefinedStartStopText:
                return { ...state, selfDefinedStartStopText: action.payload };
            case WorkTimeActions.InstructionText:
                return { ...state, instructionText: action.payload };
            case WorkTimeActions.SelfDefinedTime:
                return { ...state, selfDefinedTime: action.payload };
            case WorkTimeActions.EditWorkTimeInstructionText:
                return { ...state, editWorkTimeInstructionText: action.payload };
            default:
                throw new Error();
        }
    }

    function executeWorkTimeDispatcher(type, payload = null) {
        workTimeDispatcher({ type: type, payload: payload })
    }

    function onReloadClick() {
        setPageState(PageStates.Busy);
        initializeAsync();
    }

    function handleError(response) {
        setHttpStatusCode(response.status);
        setPageState(PageStates.Error);
    }

    function orderDateByDecending(a, b) {
        return new Date(b.periodStartTime).getTime() - new Date(a.periodStartTime).getTime();
    }

    function checkForDefaultDate(d) {
        var date = new Date(d);
        return date.getFullYear() === 1;
    }

    function getWorktimeOngoingText() {
        var text = 'P&aring;g&aring;ende';
        return parse(text);
    }

    function isWorktTimeOngoing(d) {
        var date = new Date(d)

        return date.getFullYear() === 1;
    }

    function getDateStampFromDate(d) {
        var date = new Date(d);
        return format(date, 'dd.MM');
    }

    function getTimeStampFromDate(d) {
        var date = new Date(d);

        return format(date, 'HH:mm');
    }

    async function getPilotPosition() {
        var gotPosition = false;

        const registerPosition = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    gotPosition = true;

                    if (position === null || position === undefined) {
                        informOfNoLocationPermission();
                        return;
                    }
                    
                    const registerPositionResponse = await PilotApiRepository.registerPilotPosition(position.coords.longitude, position.coords.latitude);
                    if (registerPositionResponse.ok) {
                        await getPositionNameAndNotifyAsync(position);
                    } else {
                        ApplicationInsightsService.trackTrace("Error registering pilot position");
                    }
                },
                (e) => {
                    gotPosition = true;
                    ApplicationInsightsService.trackException(e);
                    informOfNoLocationPermission();
                }
            )
        });

        await registerPosition.then(() => {
            if (!gotPosition) {                
                informOfNoLocationPermission();
            }
        });
    }

    function informOfNoLocationPermission() {
        setShouldShowLocationDialog(true);
        publishErrorNotificationTopicWithFadeout("Du har ikke tillatt deling av lokasjon");
    }

    async function getPositionNameAndNotifyAsync(position) {
        const positionResponse = await SourceApiRepository.getPositionAsync({ lng: position.coords.longitude, lat: position.coords.latitude });
        if (positionResponse.ok) {
            const name = await positionResponse.json();
            publishSuccessNotificationTopic(`Lokasjon ${name} er registrert`);
        }
    }

    function onEditPeriodClick(period, index, periodClickedIndicator) {
        if (index !== 0 || (!periodClickedIndicator && workTimeState.hasOngoingWorkTime)) return;
        workTimeState.isNavigatingToSelfDefined = false;
        setClickedPeriod(periodClickedIndicator);
        if (periodClickedIndicator) {
            workTimeState.selfDefinedTime = new Date(period.periodStartTime);
        } else {
            workTimeState.selfDefinedTime = new Date(restingPeriods[0].periodStartTime);
        }

        executeWorkTimeDispatcher(WorkTimeActions.StartEdit,
            {
                period: period,
                index: index,
                hasOngoingWorkTime: workTimeState.hasOngoingWorkTime,
                periodClickedIndicator
            }
        );
    }

    function onDeletePeriodClick() {
        executeWorkTimeDispatcher(WorkTimeActions.DeletePeriod);
    }

    function onSelfDefinedClick() {
        workTimeState.selfDefinedTime = new Date();
        setOptimizedWorkTime({
            ...optimizedWorkTime,
            description: "",
            isWorktTimeOptimizable: false
        });

        executeWorkTimeDispatcher(WorkTimeActions.ActivateSelfDefined, workTimeState.hasOngoingWorkTime);
    }

    function onUpdatePeriod() {
        if (workTimeState.selfDefinedTime === null) return;
        const workTimeToUpdate = workTimeState.isUpdatingRestingTime === false ?
            getUpdateWorkTime() : getUpdateRestingTime();
        const workTimeUpdateQuery = {
            periodStartTime: workTimeState.selfDefinedTime,
            workTimePeriodID: workTimeToUpdate.workTimeId
        };

        saveAsync('update', workTimeUpdateQuery);
    }

    function onSaveSelfDefined() {
        let time = workTimeState.selfDefinedTime;
        if (time === null) {
            time = new Date();
        }

        const workTimeQuery = {
            periodStartTime: workTimeState.selfDefinedTime === null ? new Date() : workTimeState.selfDefinedTime,
            stampType: WorkTimeStampTypes.MANUAL_OVERRIDDEN,
            workTimeStampReason: ""
        }
        if (workTimeState.isStopping) {
            const selfDefinedStopHasDeviation = (time - new Date(workTimeState.ongoingWorkTime.periodStartTime)) / (1000 * 60 * 60) > 12;
            if (workTimeState.ongoingWorkTimeHasDeviation && selfDefinedStopHasDeviation) {
                executeWorkTimeDispatcher(WorkTimeActions.StopWithDeviation, workTimeState.ongoingWorkTimeHasDeviation);
                setPageState(PageStates.Deviation);
            } else {
                saveAsync('stop', workTimeQuery);
            }
        } else {
            saveAsync('start', workTimeQuery);
        }
    }

    function handleWorkTime() {
        const workTimeQuery = {
            periodStartTime: new Date(),
            stampType: WorkTimeStampTypes.REALTIME,
            workTimeStampReason: ""
        }

        if (workTimeState.hasOngoingWorkTime && !workTimeState.ongoingWorkTimeHasDeviation) {
            saveAsync('stop', workTimeQuery);
        } else if (!workTimeState.hasOngoingWorkTime) {
            saveAsync('start', workTimeQuery);
        } else {
            executeWorkTimeDispatcher(WorkTimeActions.StopWithDeviation, workTimeState.ongoingWorkTimeHasDeviation);
            setPageState(PageStates.Deviation);
        }
    }

    function handleCancelDeviation() {
        executeWorkTimeDispatcher(WorkTimeActions.StopWithDeviation, false);
        setPageState(PageStates.Default);
    }

    function handleSelectDeviation(action) {
        const workTimeQuery = {
            periodStartTime: workTimeState.selfDefinedTime !== null ? workTimeState.selfDefinedTime: new Date(),
            stampType: workTimeState.selfDefinedTime != null ? WorkTimeStampTypes.MANUAL_OVERRIDDEN : WorkTimeStampTypes.REALTIME,
            workTimeStampReason: ""
        }

        switch (action) {
            case 1:
                saveAsync('stop/deviation', workTimeQuery);
                break;
            case 2:
                saveAsync('stop/deviation-travel', workTimeQuery);
                break;
            case 3:
                saveAsync('stop/deviation-travel-without-overtime', workTimeQuery);
                break;
            default:
                break;
        }
    }

    function handleCancelSelfDefined() {
        executeWorkTimeDispatcher(WorkTimeActions.IsNavigatingToSelfDefined, false);
        getOptimizedWorkTimeAsync(new Date());
    }

    function onDelete(period) {
        saveAsync('delete', period);
    }

    function handleOnChange(e) {
        if (isNullOrEmpty(e.value)) return;
        workTimePeriods.selfDefinedTime = new Date(e.value);
        executeWorkTimeDispatcher(WorkTimeActions.SelfDefinedTime, new Date(e.value));
    }

    async function getOptimizedWorkTimeAsync(date) {
        const optimizationResponse = await WorkTimeApiRepository.getOptimizationAsync(Moment(date))
        var optimization = await optimizationResponse.json();

        setOptimizedWorkTime({
            ...optimizedWorkTime,
            description: optimization.description.substring(optimization.description.indexOf(':')),
            isWorktTimeOptimizable: optimization.isWorkTimeValid,
            header: optimization.description.substring(0, optimization.description.indexOf(':'))
        });
    }

    function getUpdateWorkTime() {
        var workTimeToUpdate = workTimePeriods.sort(orderDateByDecending).filter(e => e.workTimePeriodType.workTimePeriodTypeId === 1)[0];
        workTimeToUpdate.stampType.stampType = WorkTimeStampTypes.MANUAL_OVERRIDDEN;
        workTimeToUpdate.periodStartTime = workTimeState.selfDefinedTime;

        return workTimeToUpdate;
    }

    function getUpdateRestingTime() {
        var restingTimeToUpdate = restingPeriods[0];
        restingTimeToUpdate.stampType.stampType = WorkTimeStampTypes.MANUAL_OVERRIDDEN;
        restingTimeToUpdate.periodStartTime = workTimeState.selfDefinedTime;

        return restingTimeToUpdate;
    }

    async function onSavePilotPositionAsync(position, positionName) {
        setShouldShowLocationDialog(false);
        const response = await PilotApiRepository.registerPilotPosition(position.lng, position.lat);
        if (response.ok) {
            publishSuccessNotificationTopic(`Lokasjon ${positionName} er registrert`);
        }
    }
}

export default Register;