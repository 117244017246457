import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TimeArrivalCard } from './TimeArrivalCard';
import { TimeDepartureReturnCard } from './TimeDepartureReturnCard';
import { TimeWaitingHoursCard } from './TimeWaitingHoursCard';
import { PilotAssignmentCommands, PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import Slide from '../../../../components/layout/Slide';
import { errorsContainsFromObject } from '../../../../components/helpers/ObjectHelpers';
import { hasCommandType } from '../helpers/PilotAssignmentCommandHelpers';

export const TimeCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    const timeArrivalRef = useRef();
    const timeDepartureReturnRef = useRef();
    const timeWaitingHoursRef = useRef();

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeIsDirtyAsync();

            timeArrivalRef.current.onPilotageChanged();
            timeDepartureReturnRef.current.onPilotageChanged();
            timeWaitingHoursRef.current.onPilotageChanged();
        },
        onLocationChanged() {
            initializeAsync();
            timeWaitingHoursRef.current.onLocationChanged();
        },
        onValidationChanged(codeStrings) {
            const errorCodes = {
                PilotArrivalTimeIsMissing: PilotAssignmentErrorCodes.PilotArrivalTimeIsMissing,
                PilotDepartureTimeIsMissing: PilotAssignmentErrorCodes.PilotDepartureTimeIsMissing,
                ReturnTimeBeforeDepartureTime: PilotAssignmentErrorCodes.ReturnTimeBeforeDepartureTime,
                PilotStartBeforePilotArrivalTime: PilotAssignmentErrorCodes.PilotStartBeforePilotArrivalTime,
                PilotReturnTimeIsMissing: PilotAssignmentErrorCodes.PilotReturnTimeIsMissing,
                WaitingHourReasonIsMissing: PilotAssignmentErrorCodes.WaitingHourReasonIsMissing,
                WaitingHourReasonRemarkIsEmpty: PilotAssignmentErrorCodes.WaitingHourReasonRemarkIsEmpty
            };

            setHasValidationError(errorsContainsFromObject(codeStrings, errorCodes));

            timeArrivalRef.current.onValidationChanged(codeStrings)
            timeDepartureReturnRef.current.onValidationChanged(codeStrings)
        }
    }));

    const [isWastedTrip, setIsWastedTrip] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            properties={{
                ...CardProperties,
                title: "Tidspunkt",
                hasValidationError: hasValidationError,
                isDirty: isDirty
            }}>

            <TimeArrivalCard
                ref={timeArrivalRef}
                pilotAssignmentId={pilotAssignmentId}
                initializeIsDirty={initializeIsDirtyAsync}
                onArrivalTimeChanged={onArrivalTimeChanged}
                onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
            />

            <Slide show={isWastedTrip}>
                <TimeDepartureReturnCard
                    ref={timeDepartureReturnRef}
                    pilotAssignmentId={pilotAssignmentId}
                    initializeIsDirty={initializeIsDirtyAsync}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />
            </Slide>

            <TimeWaitingHoursCard
                ref={timeWaitingHoursRef}
                pilotAssignmentId={pilotAssignmentId}
                initializeIsDirty={initializeIsDirtyAsync}
                onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
            />
        </Card>
    )

    function onArrivalTimeChanged() {
        timeWaitingHoursRef.current.onArrivalTimeChanged();
    }

    async function getPilotAssignmentAsync() {
        return await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
    }

    async function initializeAsync() {
        const pilotAssignment = await getPilotAssignmentAsync();

        setIsWastedTrip(pilotAssignment.isWastedTrip);

        initializeIsDirtyAsync();
    }

    async function initializeIsDirtyAsync() {

        const pilotAssignment = await getPilotAssignmentAsync();
        
        const hasArrivalTimeCommand = hasCommandType(pilotAssignment, PilotAssignmentCommands.PilotArrivalTime);
        const hasDepartureTimeCommand = hasCommandType(pilotAssignment, PilotAssignmentCommands.PilotDepartureTime);
        const hasReturnTimeCommand = hasCommandType(pilotAssignment, PilotAssignmentCommands.PilotReturnTime);

        if (pilotAssignment.isWastedTrip) {
            setIsDirty(hasArrivalTimeCommand || hasDepartureTimeCommand || hasReturnTimeCommand);
        } else {
            setIsDirty(hasArrivalTimeCommand);
        }
    }
})
