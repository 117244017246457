import { hideOnImageError } from "../helpers/ElementHelpers";
import { isNullOrEmpty } from "../helpers/ObjectHelpers";
import Spacer from "./Spacer";


const ShipImage = ({
    imoNo = null,
    description = "",
    spaceTop = 0,
    spaceBottom = 0
}) => {

    if (isNullOrEmpty(imoNo)) return;
    if (imoNo <= 0) return;

    return (
        <>
            <Spacer height={spaceTop} />
            <div>
                <img
                    src={`https://www.ship-info.com/vessels/${imoNo}.jpg`}
                    alt={description}
                    style={{
                        width: "100%"
                    }}
                    onError={({ currentTarget }) => {
                        hideOnImageError(currentTarget);
                    }}
                />
            </div>
            <Spacer height={spaceBottom} />
        </>
    )
}

export default ShipImage;
