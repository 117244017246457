import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import TitleContainer from '../../../components/layout/TitleContainer';
import { CardList } from '../../../components/layout/card/CardList';
import { CardModes, CardProperties } from '../../../components/layout/card/components/CardProperties';
import Spacer from '../../../components/layout/Spacer';
import { formatStringToList, isNullOrEmpty, isObjectNull, removeQuotesFromString } from '../../../components/helpers/ObjectHelpers';
import Moment from 'moment';
import CompensationApiRepository from '../../../repositories/api/CompensationApiRepository';
import { PilotageHourCompensationDialog } from './dialogs/PilotageHourCompensationDialog';
import uuid from 'react-uuid';
import { PilotAssignmentCommands } from '../../pilot/services/DispatcherActions';
import { formatMomentToDefault } from '../../../components/helpers/DateTimeHelpers';
import { toggleSelectedItem } from '../../pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
import { publishErrorNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';

export const PilotageHourCompensationCard = ({ pilotageCompensations, canEdit = false, pilotageCompensationId, updatePilotageCompensation, refresh }) => {

    const [compensation, setCompensation] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [toggleActivateSelect, setToggleActivateSelect] = useState(false);
    const [selectedCompensations, setSelectedCompensations] = useState([]);
    const [cards, setCards] = useState([]);

    useEffect(() => {
        initializeAsync();
    }, [pilotageCompensations]);

    return (
        <>
            <TitleContainer
                title="C-tillegg"
                error={toggleActivateSelect && selectedCompensations.some(c => c.selected)}
                actions={getTitleActions()}
            />

            <CardList
                mode={CardModes.Multible}
                cards={cards}
                toggleActivateSelect={setToggleActivateSelect}
                toggleSelectItem={toggleSelectItem}
            />

            {
                showDialog &&
                <PilotageHourCompensationDialog
                    onClose={() => setShowDialog(false)}
                    compensation={compensation}
                    canEdit={canEdit}
                    callBack={onCallbackAsync}
                />
            }

            {
                showConfirmationDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Slett C-tillegg",
                        text: "&Oslash;nsker du &aring; slette C-tillegg?",
                        size: "small",
                        onClose: () => setShowConfirmationDialog(false),
                        onClick: onConfirmCallbackAsync
                    }}

                />
            }
        </>
    )

    function getTitleActions() {
        const actions = [];

        if (canEdit) {
            if (toggleActivateSelect) {
                actions.push(
                    {
                        icon: "trash",
                        onClick: onDeleteSelectedClick
                    });
            } else {
                actions.push(
                    {
                        icon: "plus",
                        onClick: () => {
                            setCompensation(null);
                            setShowDialog(true);
                        }
                    });
            }
        }
        return actions;
    }

    async function initializeAsync() {
        const result = [];
        if (!isNullOrEmpty(pilotageCompensations.hourCompensations)) {
            const pilotageHourCompensations = pilotageCompensations.hourCompensations;
            pilotageHourCompensations.map((compensation) =>
                result.push({
                    guid: uuid(),
                    properties: {
                        ...CardProperties,
                        id: compensation.id,
                        sourceId: compensation.id,
                        title: getCompensationTimeDescription(compensation),
                        titleClassName: "card-item-content-header-title-small",
                        subTitle: compensation.isAttentionRequired ? `Korrigeringskrav` : "",
                        subTitleClassName: "semi-bold",
                        isSwipeEnabled: canEdit,
                        mode: CardModes.Multible,
                        borderColor: "var(--card-swipe-border)",
                        backgroundColor: "var(--card-swipe-bg)",        
                        onDeleteClick: canEdit ? () => onDeleteClick(compensation) : null,
                        onClick: canEdit ? () => {
                            setCompensation(compensation);
                            setShowDialog(true);
                        } : null       
                    },
                    body: <HourCompensationContent compensation={compensation} />
                }));
            }
        setCards(result);
    }

    async function onCallbackAsync(obj) {
        switch (obj.command) {

            case PilotAssignmentCommands.AddPilotHourCompensation:
            case PilotAssignmentCommands.UpdatePilotHourCompensation:
                await onPilotHourCompensationAsync(obj);
                break;

            case PilotAssignmentCommands.DeletePilotHourCompensation:
                onDeletePilotageHourCompensation(obj);
                break;

            default:
                return;
        }
    }

    function onDeleteClick(compensation) {
        setCompensation(compensation);
        setShowConfirmationDialog(true);
    }

    function toggleSelectItem(id, selected) {
        const result = toggleSelectedItem(selectedCompensations, id, selected);
        setSelectedCompensations(result);
    }

    function onDeleteSelectedClick() {
        setCompensation(null);
        setShowConfirmationDialog(true);
    }

    async function onConfirmCallbackAsync() {
        if (isNullOrEmpty(compensation)) {
            onDeleteMultipleSelectedAsync();
        } else {
            onDeletePilotageHourCompensation(compensation);
        }

        setShowConfirmationDialog(false);
        setSelectedCompensations([]);
        setToggleActivateSelect(false);
    }

    async function onDeletePilotageHourCompensation(obj) {
        if (isNullOrEmpty(pilotageCompensations.hourCompensations)) return;
        const index = pilotageCompensations.hourCompensations.findIndex(x => x.id === obj.id);
        const response = await CompensationApiRepository.deletePilotageHourCompensation(obj.id);
        if (response.ok === true) {
            pilotageCompensations.hourCompensations.splice(index, 1);
            updatePilotageCompensation(pilotageCompensations);
            refresh();
        } else {
            publishErrorNotificationTopic("Noe gikk galt under slettingen");
        }  
    }

    async function onDeleteMultipleSelectedAsync() {
        for (let i = 0; i < selectedCompensations.length; i++) {
            const selectedCompensation = selectedCompensations[i];
            const response = await CompensationApiRepository.deletePilotageHourCompensation(selectedCompensation.id);
            if (response.ok !== true) {
                publishErrorNotificationTopic("Noe gikk galt under slettingen");
            } else {
                const index = pilotageCompensations.overtimes.findIndex(x => x.id === selectedCompensation.id);
                pilotageCompensations.hourCompensations.splice(index, 1);
            }
        }
        refresh();
    }

    function getCompensationTimeDescription(compensation) {
        const toTime = isNullOrEmpty(compensation.toTime) ?
            Moment(compensation.fromTime).add(compensation.number, "hours") : Moment(compensation.toTime);
        return `${formatMomentToDefault(compensation.fromTime)} &rarr; ${toTime.format('DD.MM HH:mm')}`;
    }

    async function onPilotHourCompensationAsync(obj) {
        if (isObjectNull(pilotageCompensations.hourCompensations)) {
            pilotageCompensations.hourCompensations = [];
        }

        switch (obj.command) {
            case PilotAssignmentCommands.AddPilotHourCompensation:
                obj.pilotageCompensationId = pilotageCompensationId;
                const response = await CompensationApiRepository.addPilotageHourCompensation(obj);
                if (response.ok) {
                    pilotageCompensations.hourCompensations.push(obj);
                    refresh();
                } else {
                    const errorMessage = await response.text();
                    publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
                }
                break;
            default:
                const index = pilotageCompensations.hourCompensations.findIndex(c => c.id === obj.id);
                const updateResponse = await CompensationApiRepository.updatePilotageHourCompensation(obj);
                if (updateResponse.ok) {
                    pilotageCompensations.hourCompensations[index] = obj;
                    updatePilotageCompensation(pilotageCompensations);
                    initializeAsync();
                } else {
                    const errorMessage = await updateResponse.text();
                    publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
                }
                break;
        }
    }
}

const HourCompensationContent = ({ compensation }) => {
    return (
        <>
            <div className="align-items-center">
                <div className="col">
                    {compensation.number} timer
                </div>
            </div>
            <div className="align-items-center">
                <div className="col">
                    {compensation.pilotagePilotHourCompensationName}
                </div>
            </div>
            <Spacer height={5} />
            <div className="row align-items-center">
                <div className="col">
                    <i>{formatStringToList(compensation.description)}</i>
                </div>
            </div>
        </>
    );
};