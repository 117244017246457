import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconColors } from "../../../../../services/SystemNames"
import { getElementById, getElementsByClassName } from "../../../../helpers/ElementHelpers";
import { isArrayEmpty, isBoolean } from "../../../../helpers/ObjectHelpers";
import { CornersOut, CornersIn } from "phosphor-react-sc";
import Icons from "../../../icons/Icons"
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { isMobile } from "../../../../helpers/DeviceHelpers";
import { hideMapControl, markersVisibleAtZoom, showMapControl } from "../../GoogleMapHelpers";
import { PanelsState } from "../MapPanels";


export const MapControlsTop = forwardRef(({
    mapId,
    properties,
    isLocationsActive,
    onShowPanel
}, ref) => {

    useImperativeHandle(ref, () => ({
        onZoomChanged() {
            onToggleInfo();
        }
    }));

    const map = useMap();

    const [isAdded, setIsAdded] = useState(false);
    const [isFullScreen, setIsFulllScreen] = useState(false);

    const settingsElemId = `googleMapControlSettings-${mapId}`;
    const fullScreenElemId = `googleMapControlFullScreen-${mapId}`;
    const infoElemId = `googleMapControlMarkerInformation-${mapId}`;
    const searchElemId = `googleMapControlSearch-${mapId}`;

    useEffect(() => {

        if (!map || isAdded) return;

        if (properties.showSettings) {
            const settingsElem = getElementById(settingsElemId);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(settingsElem);

            const infoElem = getElementById(infoElemId);
            if (!properties.isAisActive) {
                infoElem.classList.remove("google-map-control-active");
            }

            map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(infoElem);
        }

        if (properties.showSearch) {
            const searchElem = getElementById(searchElemId);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(searchElem);
        }

        const fullScreenElem = getElementById(fullScreenElemId);
        if (!isMobile()) {
            map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(fullScreenElem);
        } else {
            fullScreenElem.classList.add("display-none");
        }

        setIsAdded(true);

        if (properties.pilotageId === 0 && properties.shipId === 0) return;

        const zoom = map.getZoom();
        if (zoom > markersVisibleAtZoom) return;

        showMapControl(infoElemId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties]);

    useEffect(() => {
        if (properties.pilotageId > 0 || properties.shipId > 0) return;
        if (!isBoolean(isLocationsActive)) return;

        onToggleInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocationsActive]);

    return (
        <>
            <div id={settingsElemId} className="google-map-control google-map-control-button google-map-settings-gear" onClick={onShowSettings}>
                <Icons iconName="settings" dimensions={20} color={IconColors.Primary} />
            </div>

            <div id={searchElemId} className="google-map-control google-map-control-button google-map-search-magnify" onClick={onShowSearch}>
                <Icons iconName="search" dimensions={20} color={IconColors.Primary} />
            </div>

            <div id={infoElemId} className="google-map-control google-map-control-zoom-info">
                <div className="text-truncate">Zoom inn for &aring; se elementer i kartet</div>
            </div>

            <div id={fullScreenElemId} onClick={onToggleFullScreen} className="google-map-control-button google-map-control-fullscreen google-map-control">
                {
                    isFullScreen ?
                        <CornersIn size={20} color={IconColors.Primary} />
                        :
                        <CornersOut size={20} color={IconColors.Primary} />
                }
            </div>
        </>
    )

    function onShowSettings() {
        onShowPanel(PanelsState.Settings);
    }

    function onShowSearch() {
        onShowPanel(PanelsState.Search);
    }

    function onToggleFullScreen() {
        const elems = getElementsByClassName("gm-fullscreen-control");
        if (isArrayEmpty(elems)) return;
        elems[0].click();
        setIsFulllScreen(!isFullScreen);
    }

    function onToggleInfo() {
        const zoom = map.getZoom();

        if (!properties.isAisActive && !isLocationsActive) {
            hideMapControl(infoElemId);
        } else {
            if (zoom >= markersVisibleAtZoom) {
                hideMapControl(infoElemId);
            } else {
                showMapControl(infoElemId);
            }
        }
    }
})
