import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Icons from '../../icons/Icons';
import { IconColors, NavItemTypes } from '../../../../services/SystemNames';
import Moment from 'moment';
import { isArrayEmpty } from '../../../helpers/ObjectHelpers';
import { shouldShowNavItem } from '../../../helpers/NavHelpers';

export default function NavigationBurgerMenuContent({ toggleItems, setToggleItems, navItems = [], onNavItemClick, newVersionAvailable = false }) {
    
    return (
        getNavItems().map((navItem, index) =>
            <ParentMenuItem
                key={index}
                newVersionAvailable={newVersionAvailable}
                toggleItems={toggleItems}
                setToggleItems={setToggleItems}
                navItem={navItem}
                onNavItemClick={onNavItemClick} />
        )
    );

    function getNavItems() {
        const items = [];

        for (let i = 0; i < navItems.length; i++) {
            const navItem = navItems[i];

            if (shouldShowNavItem(navItem)) {
                items.push(navItem);
            }
        }

        return items;
    }
}

const ParentMenuItem = ({ toggleItems, setToggleItems, navItem, onNavItemClick, newVersionAvailable = false }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const hasChildren = navItem.children.length > 0;
    const [toggleChildren, setToggleChildren] = useState(Moment().valueOf());

    useEffect(() => {
        if (isExpanded) {
            setIsExpanded(false);
            setToggleChildren(Moment().valueOf());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleItems]);

    return (
        <>
            <div className={`bm-menu-tm ${isExpanded ? 'bm-menu-tm-active' : ''}`} onClick={onClick}>
                <div>
                    <div>
                        <Icons color={IconColors.Primary} iconName={navItem.icon} className="pointer" dimensions={24} />
                    </div>
                    <div className="text-truncate">{parse(navItem.title)}</div>
                    {
                        hasChildren && <div><ChevronMenuItem isExpanded={isExpanded}/></div>
                    }
                </div>
            </div>
            {
                hasChildren && 
                <div className={`bm-menu-sub ${isExpanded ? 'bm-menu-sub-active' : ''}`}>
                    <div className="bm-menu-sub-inner">
                    {
                        navItem.children.map((child, index) =>
                            <ChildMenuItem
                                key={index}
                                newVersionAvailable={newVersionAvailable}
                                setToggleItems={setToggleItems}
                                toggleChildren={toggleChildren}
                                navItem={child}
                                onNavItemClick={onNavItemClick}
                                level={1}
                            />
                        )
                    }
                    </div>
                </div>
            }
        </>
    );

    function onClick() {
        if (!hasChildren) {
            onNavItemClick(navItem);
            setToggleItems();
        } else {
            setToggleItems();
            setTimeout(() => {
                setIsExpanded(!isExpanded);
            }, 50);
        }
    }
}



const ChildMenuItem = ({ toggleChildren, navItem, onNavItemClick, setToggleItems, level, newVersionAvailable = false }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const hasChildren = !isArrayEmpty(navItem.children);

    useEffect(() => {
        if (isExpanded) {
            setIsExpanded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleChildren]);

    return (
        <>
            <div className={`bm-sub-menu-item ${isNewVersionElem() ? 'sm-lnk-itm-new-version' : ''}`} onClick={onClick}
                style={{
                    paddingLeft: getPaddingLeft(),
                    display: (isNewVersionElem() && !newVersionAvailable)? "none" : "grid"
                } }
            >
                <div>{parse(navItem.title)}</div>    
                {
                    hasChildren && 
                    <div>
                        <ChevronMenuItem isExpanded={isExpanded}/>
                    </div>
                }
            </div>
            
            {
                hasChildren && 
                <div className={`bm-menu-sub ${isExpanded ? 'bm-menu-sub-active' : ''}`}>
                    <div className="bm-menu-sub-inner">
                        {
                            navItem.children.map((child, index) =>
                                <ChildMenuItem
                                    key={index}
                                    navItem={child}
                                    setToggleItems={setToggleItems}
                                    onNavItemClick={onNavItemClick}
                                    level={level + 1}
                                />)
                        }
                    </div>
                </div>
            }
            
        </>
    )

    function onClick() {
        if (!hasChildren) {
            setToggleItems();
            onNavItemClick(navItem);
        } else {
            setIsExpanded(!isExpanded);
        }
    }

    function getPaddingLeft() {
        if (level === 1) return 75;
        return 75 + ((level - 1) * 23);
    }

    function isNewVersionElem() {
        return navItem.type === NavItemTypes.NewVersion;
    }
}

const ChevronMenuItem = ({ isExpanded }) => {
    return (
        <Icons color={IconColors.Primary} iconName={isExpanded ? "chevronUp" : "chevronDown"} dimensions={24} />
    )
}
