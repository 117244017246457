import './dropdownlist.css'

import React from 'react';
import {
    DropDownList as KendoDropDownList,
} from "@progress/kendo-react-dropdowns";
import { onToggleComponent } from '../helpers/ComponentHelpers';
import { ChevronDownIcon } from './icons/Icons';

export default function DropDownList(
    {
        data,
        value,
        textField = "name",
        onChange,
        adaptive = false,
        adaptiveTitle = "Velg alternativ",
        disabled = false,
        hasValidationError = false,
        defaultText = "Ikke oppgitt"
    }
) {
    
    return (
        <KendoDropDownList
            data={data}
            value={value}
            textField={textField}
            onChange={handleOnChange}
            adaptive={adaptive}
            adaptiveTitle={adaptiveTitle}
            onOpen={onOpen}
            onClose={onClose}
            fillMode="solid"
            disabled={disabled}
            themeColor={hasValidationError ? "error" : "base"}
            className="p-dropdown-list"
            svgIcon={ChevronDownIcon}
            defaultItem={{ [textField]: defaultText, notSelectable: true }}
        />
    );

    function onOpen() {
        if (!adaptive) return;
        onToggleComponent(true);
    }

    function onClose() {
        if (!adaptive) return;
        onToggleComponent(false);
    }

    function handleOnChange(e) {
        if (e.value.hasOwnProperty("notSelectable")) return;
        onChange(e.value);
    }
}
