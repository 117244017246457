import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import PcsApiRepository from '../../../../repositories/api/PcsApiRepository';
import { isObjectNull } from '../../../helpers/ObjectHelpers';
import { defaultFillColor, defaultOpacity, defaultStrokeColor, mouseOverStrokeColor, pcsGroupFeatureType, selectedStrokeColor, setMapTitle, setTilesLoadedTimer, unselectFeatures, updateZoomByUrlParameter } from '../GoogleMapHelpers';

export const MapPilotCoastalSegmentGroups = forwardRef(({
    mapId,
    properties,
}, ref) => {

    const map = useMap();

    useImperativeHandle(ref, () => ({
        onReset() {

            localRef.initialized = false;

            clearAllFeatures();
            initializeAsync();

            localRef.initialized = true;

        },
        onBoundsChanged() {
            if (!properties.showPcsGroups)
                return;

            initializeAsync();

            localRef.initialized = true;
        },
        onSelected() {
            if (!map) return;

            if (!properties.showPcsGroups)
                return;

            unselectFeatures(map, pcsGroupFeatureType);
        }
    }));

    const componentRef = useRef({
        initialized: false,
        mouseOverListener: null,
        mouseOutListener: null,
        mouseMoveListener: null,
        clickListener: null
    });
    const { current: localRef } = componentRef;
    
    useEffect(() => {

        if (!map) return;

        if (properties.showPcsGroups) return;

        clearAllFeatures();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties.showPcsGroups]);

    async function initializeAsync() {

        if (localRef.initialized) {
            return;
        }

        const response = await PcsApiRepository.getSummaryAsync();
        if (response.ok) {
            const data = await response.json();

            for (let feature of data.groupsGeoJson.geoJson.features) {
                feature.properties.featureType = pcsGroupFeatureType;
            }

            map.data.addGeoJson(data.groupsGeoJson.geoJson);

            map.data.setStyle(function (feature) {
                const featureType = feature.getProperty("featureType");
                if (featureType === pcsGroupFeatureType) {
                    const isSelected = feature.getProperty("isSelected");
                    return {
                        fillColor: defaultFillColor,
                        fillOpacity: defaultOpacity,
                        strokeWeight: 2,
                        strokeColor: isSelected ? selectedStrokeColor : defaultStrokeColor
                    };
                }
            });

            localRef.mouseOverListener = map.data.addListener("mouseover", (event) => {

                showFeatureName(event.feature.getProperty("segmentName"));
                map.data.overrideStyle(event.feature,
                    {
                        strokeColor: mouseOverStrokeColor,
                        fillColor: mouseOverStrokeColor,
                        strokeWeight: 4,
                        zIndex: 1000
                    }
                );

                const groupId = event.feature.getProperty("segmentGroupId");
                properties.handleOnFeatureMouseOver(groupId);
            });

            localRef.mouseOutListener = map.data.addListener("mouseout", (event) => {

                const isSelected = event.feature.getProperty("isSelected");
                map.data.overrideStyle(event.feature,
                    {
                        fillColor: defaultFillColor,
                        fillOpacity: defaultOpacity,
                        strokeWeight: 2,
                        strokeColor: isSelected ? selectedStrokeColor : defaultStrokeColor
                    }
                );

                let featureName = "";

                map.data.forEach(function (feature) {
                    const isSelected = feature.getProperty("isSelected");
                    if (isSelected) {
                        featureName = feature.getProperty("segmentName");
                    }
                });

                showFeatureName(featureName);

                properties.handleOnFeatureMouseOut();
            });

            localRef.clickListener = map.data.addListener("click", (event) => {

                const groupId = event.feature.getProperty("segmentGroupId");
                event.feature.setProperty("isSelected", true);
                properties.handleOnFeatureClick(groupId);
            });

            setTilesLoadedTimer(properties, map);
        }

        updateZoomByUrlParameter(map);
    }

    function clearAllFeatures() {
        map.data.forEach(function (feature) {
            const featureType = feature.getProperty("featureType");
            if (featureType === pcsGroupFeatureType) {
                map.data.remove(feature);
            }
        });

        if (!isObjectNull(localRef.mouseOverListener)) {
            window.google.maps.event.removeListener(localRef.mouseOverListener);
        }
        if (!isObjectNull(localRef.mouseMoveListener)) {
            window.google.maps.event.removeListener(localRef.mouseMoveListener);
        }
        if (!isObjectNull(localRef.mouseOutListener)) {
            window.google.maps.event.removeListener(localRef.mouseOutListener);
        }
        if (!isObjectNull(localRef.clickListener)) {
            window.google.maps.event.removeListener(localRef.clickListener);
        }

        localRef.mouseOverListener = null;
        localRef.mouseMoveListener = null;
        localRef.mouseOutListener = null;
        localRef.clickListener = null;
        localRef.initialized = false;
    }

    function showFeatureName(name) {
        setMapTitle(mapId, name);
    }
})
