import React, { useEffect, useState } from 'react';
import { PilotagePilotVariableCompensationTypeEnums } from '../../../services/SystemNames';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import { VariableCompensationItem } from './VariableCompensationCard';
import { VariableCompensationBoardingDialog } from './dialogs/VariableCompensationBoardingDialog';
import CompensationApiRepository from '../../../repositories/api/CompensationApiRepository';
import { deepCopyObject, isArrayEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishErrorNotificationTopic } from '../../../components/helpers/PubSubHelpers';

export const VariableCompensationBoardingCard = ({ pilotageCompensations, handleUpdate }) => {
    const [hasBoardingVesselValidationError, setBoardingVesselHasValidationError] = useState(false);
    const [hasHelicopterValidationError, setHelicopterHasValidationError] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [boardingVessel, setBoardingVessel] = useState({});
    const [boardingHelicopter, setBoardingHelicopter] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [currentCompensation, setCurrentCompensation] = useState(null);
    const [isAttentionRequired, setIsAttentionRequired] = useState(false);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <>
            <Card
                properties={{
                    ...CardProperties,
                    title: "Bording / kvitting tillegg",
                    subTitle: isAttentionRequired ? "Korrigeringskrav" : "",
                    subTitleClassName: "semi-bold",
                    hasValidationError: (hasBoardingVesselValidationError || hasHelicopterValidationError)
                }}>
                <div className="row">
                    <VariableCompensationItem
                        title="Losb&aring;t"
                        hasValidationError={hasBoardingVesselValidationError}
                        isEditable={isEditable}
                        compensation={boardingVessel}
                        onEdit={onEditBoarding}
                        onDelete={onDeleteBoarding}
                        decimals={0}
                    />
                    <VariableCompensationItem
                        title="Helikopter"
                        hasValidationError={hasHelicopterValidationError}
                        isEditable={isEditable}
                        compensation={boardingHelicopter}
                        onEdit={onEditBoarding}
                        onDelete={onDeleteBoarding}
                        decimals={0}
                    />
                </div>
            </Card>
            {
                showDialog &&
                <VariableCompensationBoardingDialog
                    onClose={() => setShowDialog(false)}
                    compensation={currentCompensation}
                    callback={onBoardingCallback}
                />
            }
        </>
    )

    async function initializeAsync() {
        setIsEditable(true);

        const calculated = getCalculatedBoardingTypes();

        setCompensation(pilotageCompensations.variableCompensations, setBoardingVessel, PilotagePilotVariableCompensationTypeEnums.BoardingVessel, calculated.calculatedPilotBoat);
        setCompensation(pilotageCompensations.variableCompensations, setBoardingHelicopter, PilotagePilotVariableCompensationTypeEnums.BoardingHelicopter, calculated.calculatedHelicopter);
    }

    function setCompensation(variableCompensations, set, systemName) {
        const compensations = variableCompensations;
        if (isArrayEmpty(compensations)) return;

        const compensation = compensations.find(c => c.type.systemName === systemName);

        if (isObjectNull(compensation)) return;
        compensation.isAttentionRequired && setIsAttentionRequired(true);

        set(compensation);
    }
    function onEditBoarding(compensation) {
        if (!isEditable) return;

        setCurrentCompensation(compensation);
        setShowDialog(true);
    }

    async function onDeleteBoarding(compensation) {
        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.overrideReasonRemark = null;
        compensationCopy.overriddenNumber = null;
        compensationCopy.overrideReasonType = null;

        const response = await CompensationApiRepository.updatePilotageVariableCompensation(compensationCopy);
        if (response.ok) {
            onBoardingCallback(compensationCopy);
        } else {
            publishErrorNotificationTopic("Noe gikk galt");
        }
    }

    function onBoardingCallback(compensation) {
        if (compensation.type.systemName === PilotagePilotVariableCompensationTypeEnums.BoardingVessel) {
            setBoardingVessel(compensation);
        } else {
            setBoardingHelicopter(compensation);
        }

        onUpdateVariableCompensationOverriddenNumberAsync(compensation);
    }

    async function onUpdateVariableCompensationOverriddenNumberAsync(obj) {

        const compensations = pilotageCompensations.variableCompensations;
        const compensation = compensations.find(c => c.type.systemName === obj.type.systemName);
        const index = compensations.indexOf(compensation);

        compensations[index] = obj;
        handleUpdate(compensations);
    }

    function getCalculatedBoardingTypes() {
        return {
            calculatedPilotBoat: getCalculatedByBoardingType(PilotagePilotVariableCompensationTypeEnums.BoardingVessel),
            calculatedHelicopter: getCalculatedByBoardingType(PilotagePilotVariableCompensationTypeEnums.BoardingHelicopter)
        }
    }

    function getCalculatedByBoardingType(pilotBoardingType) {

        const onBoarding = pilotageCompensations.variableCompensations.filter(v =>
            v.type.systemName === pilotBoardingType);

        let offBoarding = pilotageCompensations.variableCompensations.filter(v =>
            v.type.systemName === pilotBoardingType);

        return onBoarding + offBoarding;

    }
}