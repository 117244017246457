import { useRef } from "react";
import { useState } from "react";
import { GroupListCards } from "./components/GroupListCards";
import { PcsBottomMenu } from "./components/PcsBottomMenu";
import { PcsNoEdit } from "./components/PcsNoEdit";
import Spacer from "../../components/layout/Spacer";
import TitleContainer from "../../components/layout/TitleContainer";
import { GoogleMapCard } from "../../components/layout/map/GoogleMapCard";
import { GoogleMapProperties } from "../../components/layout/map/GoogleMapProperties";
import { PageStates } from "../../services/SystemNames";
import FloatingButtonContainer from "../../components/layout/FloatingButtonContainer";
import FloatingActionButton from "../../components/layout/FloatingActionButton";
import { PlusIcon } from "../../components/layout/icons/Icons";
import { GroupCard } from "./components/GroupCard";
import { useEffect } from "react";
import { publishHeaderTopic } from "../../components/helpers/PubSubHelpers";

export default function Groups() {

    const [hasBottomNavigation, setHasBottomNavigation] = useState(false);
    const [mapChanges, setMapChanges] = useState(null);
    const [pageState, setPageState] = useState(PageStates.Default);
    const [groupId, setGroupId] = useState(0);
    const [showPcsGroups, setShowPcsGroups] = useState(true);

    const mapRef = useRef();
    const listRef = useRef();
    const groupRef = useRef();

    useEffect(() => {
        publishHeaderTopic("Sertifikatomr&aring;der");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={`pcs-container ${hasBottomNavigation ? "pcs-container-mnu" : "pcs-container-no-mnu"}`}>
                <div>
                    <div>
                        <Spacer height={10} />
                        <div className="container">
                            <div className="row">
                                <TitleContainer
                                    title={getTitle()}
                                    icon={getTitleIcon()}
                                    iconDimensions={32}
                                    actions={getTitleActions()}
                                />
                            </div>
                        </div>
                        <Spacer height={10} />
                    </div>
                    {
                        (pageState === PageStates.Default) ?
                            <GroupListCards
                                ref={listRef}
                                onClick={onGroupClick}
                                onMouseOver={onGroupListMouseOver}
                                onMouseOut={onGroupListMouseOut}
                                mapChanges={mapChanges}
                                addSpacer={true}
                            />
                            :
                            <GroupCard
                                ref={groupRef}
                                groupId={groupId}
                                onClose={onCloseEdit}
                                mapChanges={mapChanges}
                                onUpdatePcsTitle={onUpdatePcsTitle}
                            />
                    }
                </div>

                <GoogleMapCard
                    ref={mapRef}
                    properties={{
                        ...GoogleMapProperties,
                        pcsGroupId: groupId,
                        showPcsGroups: showPcsGroups,
                        showLocations: true,
                        multiSelectControl: (pageState !== PageStates.Default),
                        showPcs: !showPcsGroups,
                        isInline: false,
                        onDragEnd: onMapChange,
                        onZoomChanged: onMapChange,
                        onTilesLoaded: onMapChange,
                        onResize: onMapChange,
                        onGeoJsonLoaded: onMapChange,
                        onFeatureClick: onMapFeatureClick,
                        onFeatureMouseOver: onMapFeatureMouseOver,
                        onFeatureMouseOut: onMapFeatureMouseOut
                    }}
                />

                <PcsNoEdit />
            </div>

            <PcsBottomMenu callback={onBottomMenuCallback} />


            {
                (pageState === PageStates.Default) &&
                <FloatingButtonContainer position="left" className="pcs-add-button" hasBottomNavigation={hasBottomNavigation}>
                    <FloatingActionButton
                        themeColor="primary"
                        svgIcon={PlusIcon}
                        onClick={onAddClick}
                    />
                </FloatingButtonContainer>
            }
        </>
    )
    function onGroupClick(groupId) {
        getGroup(groupId);
    }

    function onGroupListMouseOver(groupId) {
        mapRef.current.onFeatureMouseOver({
            property: "segmentGroupId",
            value: groupId
        });
    }

    function onGroupListMouseOut() {
        mapRef.current.onFeatureMouseOut();
    }

    function onMapFeatureMouseOver(e) {
        listRef.current.onGroupMouseOver(e);
    }

    function onMapFeatureMouseOut() {
        listRef.current.onGroupMouseOut();
    }

    function onMapFeatureClick(e) {
        if (pageState === PageStates.Default) {
            getGroup(e);
        } else {
            setMapChanges(e);
        }
    }

    function onMapChange(e) {
        setMapChanges(e);
    }

    function onBottomMenuCallback(e) {
        setHasBottomNavigation(e);
    }

    function onAddClick() {
        setGroupId(0);
        setPageState(PageStates.Edit);
        getGroup();
    }

    function getGroup(groupId = 0) {
        setGroupId(groupId);
        setShowPcsGroups(false);
        setPageState(PageStates.Edit);
    }

    function getTitle() {
        if (pageState === PageStates.Default) return "Sertifikatomr&aring;der";

        return "Rediger";
    }

    function getTitleIcon() {
        if (pageState === PageStates.Default) return "bulletList";

        return "edit";
    }

    function getTitleActions() {
        if (pageState === PageStates.Default || groupId === 0) return [];

        return [
            {
                icon: "trash",
                onClick: () => groupRef.current.onDelete()
            }
        ];
    }

    function onCloseEdit() {
        mapRef.current.onCancelMultiSelect();
        mapRef.current.onResetPilotCoastalSegmentGroups();
        setGroupId(0);
        setShowPcsGroups(true);
        setPageState(PageStates.Default);
    }

    function onUpdatePcsTitle(title) {
        mapRef.current.onUpdatePcsTitle(title);
    }
}
