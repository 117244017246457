import './home.css';

import React, { useEffect, useState } from 'react';
import {
    PageStates,
    PersonTypes,
} from '../../services/SystemNames';
import MetaIdbRepository from '../../repositories/idb/MetaIdbRepository';
import PubSub from 'pubsub-js';
import Overlay from '../../components/layout/overlay/Overlay';
import { isObjectNull, isNullOrEmpty } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic, PubSubTopics } from '../../components/helpers/PubSubHelpers';
import { hasPersonType, isLoggedIn } from '../../components/helpers/AuthHelpers';
import PushPermissionModal from '../pilot/pushNotifications/PushPermissionModal';
import { refreshDeviceKey, updateSubscriptionToken } from '../pilot/pushNotifications/PushHelpers';
import { useGetApplicationRightsQuery, useGetSourceConfigQuery } from '../../reducers/slices/api.slice';
import ContentGrid from '../../components/layout/ContentGrid';
import { HomePilotagesCard } from './components/HomePilotagesCard';
import { HomeWorkTimeCard } from './components/HomeWorkTimeCard';
import { HomeGuestDepartment } from './components/HomeGuestDepartment';
import { HomePilotStationCard } from './components/HomePilotStationCard';
import { HomeAllPilotagesCard } from './components/HomeAllPilotagesCard';
import { HomeCoastalSegmentsCard, HomeCoastalSegmentsEvaluationsCard, HomeCoastalSegmentsLocationsCard } from './components/HomeCoastalSegmentsCards';

const initialState = {
    pageState: PageStates.Default,
    isBusy: true,
    hasErrors: false,
    name: "",
    isPilot: false,
    showPushPermissionModal: false
};

const busyTimeout = 500;

export const Index = () => {

    const [
        {
            isBusy,
            hasErrors,
            showPushPermissionModal
        }, setState] = useState(initialState);
    const isPilot = hasPersonType(PersonTypes.Pilot);

    const {
        data: applicationRights,
        isSuccess: isApplicationRightsSuccess
    } = useGetApplicationRightsQuery();

    useEffect(() => {
        if (isNullOrEmpty(isApplicationRightsSuccess)) return;
        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApplicationRightsSuccess])


    // Initialize function
    useEffect(() => {
        publishHeaderTopic("NjordPilotWeb", "", false);
        PubSub.subscribe(PubSubTopics.Logout, handlePubSubTopic);

        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }

        return () => {
            PubSub.unsubscribe(PubSubTopics.Logout);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        isApplicationRightsSuccess &&
        <>
            {showPushPermissionModal && <PushPermissionModal />}
            <HomeContentGrid>
                <div className="home-grid k-gap-2">
                    <HomeGuestDepartment/>
                    <HomePilotagesCard
                        busyTimeout={busyTimeout}
                        isPilot={isPilot}
                    />
                    <HomeAllPilotagesCard
                        canShow={isPilot}
                        isPilot={isPilot}
                    />
                    <HomeWorkTimeCard
                        busyTimeout={busyTimeout}
                        isPilot={isPilot}
                        
                    />
                    <HomeCoastalSegmentsCard
                        isPilot={isPilot}
                        applicationRights={applicationRights}
                        
                    />
                    <HomeCoastalSegmentsEvaluationsCard
                        isPilot={isPilot}
                        applicationRights={applicationRights}                        
                    />
                    <HomeCoastalSegmentsLocationsCard
                        isPilot={isPilot}
                        applicationRights={applicationRights}
                    />
                    <HomePilotStationCard
                        isPilot={isPilot}
                    />
                    <HomeAllPilotagesCard
                        canShow={!isPilot}
                        isPilot={isPilot}
                    />
                </div>
            </HomeContentGrid>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />

        </>
    );

    function shouldShowPushPermissionModal() {
        if (isLoggedIn() && !localStorage.getItem("shownAlready")) {
            try {
                if (Notification?.permission === "default" && !localStorage.getItem("shownAlready")) {
                    setState((prev) => ({
                        ...prev,
                        showPushPermissionModal: true
                    }));                    
                }                
            } catch (error) {
                console.error(error);            
            }
        }
    }

    function onReloadClick() {
        setState((prev) => ({
            ...prev,
            hasErrors: false,
            isBusy: true
        }));

        initializeOnlineAsync();
    }

    function handlePubSubTopic() {
        // kommer man hit når man logger ut mens man er på forsiden?
        publishHeaderTopic("");

        setState((prev) => ({
            ...prev,
            name: "",
            modules: []
        }));

        setTimeout(() =>
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default
            })), 250);
    }

    async function initializeOnlineAsync() {

        if (!isLoggedIn()) {
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default,
                isBusy: false
            }));
        } else {          
            shouldShowPushPermissionModal();

            const deviceId = localStorage.getItem("deviceId");
            if (deviceId) {
                refreshDeviceKey(deviceId);
                updateSubscriptionToken().then(() => localStorage.setItem("refreshed", true));
            }
                
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Welcome,
                isPilot: hasPersonType(PersonTypes.Pilot),
                isBusy: false
            }));
        }
    }

    async function initializeOfflineAsync() {

        if (isLoggedIn() === false) {
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default,
                isBusy: false
            }));

            //for push notifications
            localStorage.removeItem("refreshed")
            if (window?.Notification?.permission !== "granted") {
                localStorage.removeItem("shownAlready")
            }

        } else {
            const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
            let firstName = "";

            if (!isObjectNull(whoAmI)) {
                firstName = whoAmI.firstName;
            } else {
                publishWarningNotificationTopic("Kunne ikke laste inn person informasjon");
            }
            setState((prev) => ({
                ...prev,
                isPilot: true,
                pageState: PageStates.Welcome,
                name: firstName,
                isBusy: false,
                hasErrors: false
            }));
        }
    }
}

const HomeContentGrid = ({ children }) => {

    const [name, setName] = useState("");
    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess) || !isSourceConfigSuccess) return;
        if (isNullOrEmpty(sourceConfig.environment)) return;
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess]);

    return (
        (isNullOrEmpty(name)) ?
            <ContentGrid>
                <div className="home-content-grid">
                    {children}
                </div>
            </ContentGrid>
            :
            <ContentGrid
                title="Velkommen"
                subTitle={name}
                titleIcon="lighthouse"
                autoHideTitle={false}>
                {children}
            </ContentGrid>
    )

    async function initializeAsync() {
        const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
        setName(whoAmI.firstName);
    }
}




