import './pilotassignment.css';

import React from 'react';
import { GoogleMapCard } from '../../../components/layout/map/GoogleMapCard';
import { GoogleMapProperties } from '../../../components/layout/map/GoogleMapProperties';

export default function PilotAssignmentMap({ pilotageId, shipId }) {

    return (
        <GoogleMapCard
            properties={{
                ...GoogleMapProperties,
                pilotageId: Number(pilotageId),
                shipId: Number(shipId),
                minZoom: 5,
                isAisActive: true,
                showSettings: true,
                showSearch: true,
                isInline: false
            }} 
        />
    )
}
