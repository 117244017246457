import './home.css';

import React, { useEffect } from 'react';
import {
    Tokens,
} from '../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import Overlay from '../../components/layout/overlay/Overlay';
import { handleLogout } from '../../components/helpers/NavHelpers';

function Logout() {

    const navigate = useNavigate();
    
    useEffect(() => {
        localStorage.removeItem(Tokens.Token);
        handleLogout(navigate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Overlay isBusy={true}/>
    );

    
}

export default Logout;
