import './timeline.css';

import React from 'react';
import { TimelineViewDays } from './components/TimelineViewDays';
import { TimelineViewType } from './services/Types';
import TimelineViewContext from './components/TimelineViewContext';

export function TimelineView({ children, timelineViewType = TimelineViewType }) {
    return (
        <TimelineViewContext.Provider value={{ timelineViewType }}>
            <div className="timeline">
                <TimelineViewDays />
                {children}
            </div>
        </TimelineViewContext.Provider>
    )
}
