
export const DialogForm = ({ children }) => {

    return (
        <div className="dialog-form">
            <select style={{ display: 'none'}} />
            {children}
        </div>
    )
}
