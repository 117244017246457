import React from 'react';
import { isNullOrEmpty } from '../helpers/ObjectHelpers';
import ContentGrid from './ContentGrid';

export default function AppContent(
    {
        children,
        title = "",
        subTitle = ""
    }
) {
    return (
        <div className="appContent" id="appContent">
            <main>
                {
                    isNullOrEmpty(title) ? children
                    :
                    <ContentGrid title={title} subTitle={subTitle}>
                        {children}
                    </ContentGrid>
                }
            </main>
        </div>
    );
}
