import moment from 'moment';
import { AppointmentTypeEnum, WorkTimeTypeEnum } from './SystemNames';
import uuid from 'react-uuid';
import { isNullOrEmpty } from '../../../helpers/ObjectHelpers';
import { getElementById } from '../../../helpers/ElementHelpers';
import { formatMoment } from '../../../helpers/DateTimeHelpers';

const minuteHeight = 0.5;

const TimelineViewService = {

    calcHeight(fromTime, toTime) {
        if (fromTime === null || fromTime === undefined || toTime === null || toTime === undefined) return 0;
        const duration = moment.duration(toTime.diff(fromTime));
        return duration.asMinutes() * minuteHeight;
    },

    getDurationDescription(start, stop) {
        const duration = moment.duration(stop.diff(start));

        const hours = Math.floor(duration.asHours());
        const minutes = duration.asMinutes() - (hours * 60);

        let description = '';

        if (hours > 0 && hours !== undefined) {
            description = `${hours}t`;
        }
        if (hours > 0 && minutes > 0) {
            description += ' ';
        }
        if (minutes > 0) {
            description += `${minutes}min`;
        }

        return description;
    },

    hoursToHeight(hours) {
        return (hours * 60) * minuteHeight;
    },

    scrollToNow() {
        setTimeout(() => {
            const element = getElementById("timeLineNowAnchor");
            if (element !== null) {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        }, 250);
    },

    setSimulationPeriodsProperties(periods) {

        for (let i = 0; i < periods.length; i++) {
            const period = periods[i];

            if (isNullOrEmpty(period.title)) {
                let title = "";
                switch (period.type) {
                    case WorkTimeTypeEnum.WorkingTime:
                        title = "Arbeid"
                        break;
                    case WorkTimeTypeEnum.RestingTime:
                        title = "Hvile";
                        break;
                    default:
                        break;
                }

                if (period.isFuture) {
                    if (period.type === WorkTimeTypeEnum.WorkingTime) {
                        title = `Rest`;
                    } else {
                        title = `Pålagt`;
                    }
                }

                period.title = `${title}, ${this.getDurationDescription(moment(period.originalStart), moment(period.stop))}`;
            }
            period.showTitle = true;
        }

        return periods;
    },

    createPilotageAppointments(pilotages) {
        const result = [];
        
        for (let i = 0; i < pilotages.length; i++) {
            const pilotage = pilotages[i];
            result.push(
                {
                    id: uuid(),
                    sequenceNo: 0,
                    pilotageId: pilotage.id,
                    type: AppointmentTypeEnum.Pilotage,
                    start: pilotage.start,
                    stop: pilotage.end,
                    startToDate: moment(pilotage.start).toDate(),
                    stopToDate: moment(pilotage.end).toDate(),
                    title: pilotage.ship,
                    attributes: {
                        pilotageNo: pilotage.pilotageNo,
                        status: pilotage.pilotagePilotStatus,
                        from: pilotage.from,
                        to: pilotage.to,
                        modifiedDate: pilotage.modifiedDate
                    }
                }
            );
        }
        return result;
    },

    createAppointments(appointments) {
        const result = [];

        for (let i = 0; i < appointments.length; i++) {
            const appointment = appointments[i];
            result.push(
                {
                    id: uuid(),
                    sequenceNo: 0,
                    type: AppointmentTypeEnum.Calendar,
                    start: appointment.start,
                    stop: appointment.end,
                    startToDate: moment(appointment.start).toDate(),
                    stopToDate: moment(appointment.end).toDate(),
                    title: appointment.title,
                    attributes: {
                        isTentative: appointment.isTentative,
                        remark: appointment.remark
                    }
                }
            );
        }
        return result;
    },

    adjustAppointments(timelineViewType) {

        const fromTime = timelineViewType.fromTime;
        const toTime = timelineViewType.toTime;
        const sourceAppointments = timelineViewType.appointments;
        let appointments = [];

        // add only appointments within view
        for (let i = 0; i < sourceAppointments.length; i++) {
            const appointment = sourceAppointments[i];

            if (appointment.startToDate < fromTime && appointment.stopToDate < fromTime) continue;

            appointments.push(appointment);
        }

        // update stop if exceeds toTime
        for (let i = 0; i < appointments.length; i++) {
            const appointment = appointments[i];
            
            if (appointment.stopToDate > toTime) {
                appointment.stop = formatMoment(toTime);
                appointment.stopToDate = moment(appointment.stop).toDate();
                appointment.exceeds = true;
            } else {
                appointment.exceeds = false;
            }
            //appointment.isAdjusted = false;
        }

        appointments = [...appointments].sort((a, b) => {
            return moment(a.start) < moment(b.start) ? -1 : 1;
        });

        const result = [];

        
        for (let i = 0; i < appointments.length; i++) {
            const appointment = appointments[i];
            appointment.pilotageId = appointment.pilotageId;
            appointment.sequenceNo = this.getAppointmentSequenceNo(appointment, result);
            result.push(appointment);
        }
        
        return result;
    },

    getAppointmentSequenceNo(appointment, result) {
        let sequenceNo = 0;
        for (let i = 0; i < result.length; i++) {
            const appointmentsOnSequenceNo = result
                .filter(a => a.sequenceNo === sequenceNo)
                .filter(a => this.isOverlapping(appointment, a));
            const overlapping = appointmentsOnSequenceNo.length > 0;

            if (!overlapping) return sequenceNo;
            sequenceNo += 1;
        }

        return sequenceNo;
    },
    
    isOverlapping(appointment1, appointment2) {
        return (appointment1.startToDate < appointment2.stopToDate && appointment2.startToDate < appointment1.stopToDate)
            || (appointment2.startToDate > appointment1.startToDate && appointment1.stopToDate < appointment2.stopToDate)
    }

};

export default TimelineViewService;