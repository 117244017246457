import './pilotassignment.css';

import React, { useEffect, useReducer, useRef } from 'react';
import { PageStates } from '../../../services/SystemNames';
import { DispatcherActions, PilotAssignmentCommands } from '../services/DispatcherActions';
import { PilotAssignmentCard } from './components/PilotAssignmentCard';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import { ReceiptDialog } from './components/dialogs/ReceiptDialog';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import Overlay from '../../../components/layout/overlay/Overlay';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { getFormattedInfoString, isArrayEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishReloadAlertsTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic, PubSubTopics } from '../../../components/helpers/PubSubHelpers';
import { getCompletePilotageReceiptCommand, hasCommandType, hasLocalChanges } from './helpers/PilotAssignmentCommandHelpers';
import {
    convertPilotAssignmentApiDtoToIdb,
    hasPilotAssignmentInvoice,
    isPilotAssignmentCompleted,
    isPilotAssignmentConfirmed
} from './helpers/PilotAssignmentHelpers';

export default function PilotAssignment({ pilotageId }) {

    const navigate = useNavigate();

    const pilotAssignmentRef = useRef();

    const initialState = {
        hasErrors: false,
        isBusy: false,
        pilotAssignment: null,
        httpStatusCode: 0,
        pilotage: {},
        isConfirmed: false,
        locationBefore: null,
        isOnline: navigator.onLine,
        showCompleteDialog: false,
        showReceiptDialog: false,
        isBothPilotAndExaminer: false,
        convertingToPilotIncl: false
    };


    const reducer = (state = initialState, action) => {
        switch (action.type) {

            case DispatcherActions.IsOnline:
                return Object.assign({}, state, {
                    isOnline: action.payload
                });

            case DispatcherActions.IsBusy:
                return Object.assign({}, state, {
                    isBusy: action.payload,
                    hasErrors: false
                });

            case DispatcherActions.PilotAssignment:
                return Object.assign({}, state, {
                    pilotAssignment: action.payload,
                });

            case DispatcherActions.Initialized:
                return Object.assign({}, state, {
                    pilotAssignment: action.payload,
                    isConfirmed: isPilotAssignmentConfirmed(action.payload),
                    pageState: PageStates.Default,
                    hasErrors: false,
                    isBusy: false
                });

            case DispatcherActions.Completing:
                return Object.assign({}, state, {
                    showCompleteDialog: action.payload.showCompleteDialog,
                    isBothPilotAndExaminer: action.payload.isBothPilotAndExaminer,
                    convertingToPilotIncl: action.payload.convertingToPilotIncl,
                    showReceiptDialog: false
                });

            case DispatcherActions.Receipt:
                return Object.assign({}, state, {
                    showReceiptDialog: action.payload,
                    showCompleteDialog: false
                });

            default:
                return state;
        }
    };

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    }

    useEffect(() => {

        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        handleOnOffline();

        initializeAsync();

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                !isObjectNull(state.pilotAssignment) &&
                    <PilotAssignmentCard
                        ref={pilotAssignmentRef}
                        setIsBusy={setIsBusy}
                        pilotAssignment={state.pilotAssignment}
                        isConfirmed={state.isConfirmed}
                        onShowModalMenu={onShowModalMenu}
                        onSaveAsync={onSaveAsync}
                        />

            }

            {
                state.showCompleteDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        title: "Send skipperbeviset",
                            onClose: () => dispatch(DispatcherActions.Completing,
                                {
                                    showCompleteDialog: false,
                                    isBothPilotAndExaminer: false,
                                    convertingToPilotIncl: false
                                }),
                        actions: [
                            {
                                onClick: () => dispatch(DispatcherActions.Completing,
                                    {
                                        showCompleteDialog: false,
                                        isBothPilotAndExaminer: false,
                                        convertingToPilotIncl: false
                                    }),
                                icon: "close",
                                text: "Avbryt"
                            },
                            {
                                icon: "ok",
                                onClick: () => {
                                    dispatch(DispatcherActions.Completing,
                                        {
                                            showCompleteDialog: false,
                                            isBothPilotAndExaminer: false,
                                            convertingToPilotIncl: false
                                        }
                                    );
                                        thenSaveAsync();
                                    },
                                themeColor: "primary",
                                text: "Lagre"
                            }
                        ]
                        }}>
                        <p>
                            Ved &aring; sende inn Utf&oslash;rt skipperbevis, bekrefter jeg at alle tillegg for overf&oslash;ring til l&oslash;nn er kontrollert og korrekt
                        </p>
                        {
                            state.isBothPilotAndExaminer &&
                                <p>
                                    <b>NB: Du har valgt &aring; ta rollen som b&aring;de los og sensor p&aring; oppdraget.</b>
                                </p>
                        }
                        {
                            state.convertingToPilotIncl &&
                                <p>
                                    <b>NB: Du har valgt &aring; gj&oslash;re oppdraget om til et losoppdrag slik at losingstimene kan faktureres.</b>
                                </p>
                        }
                    </Dialog>
            }

            {
                state.showReceiptDialog && 
                <ReceiptDialog
                    onClose={() => {
                        dispatch(DispatcherActions.Receipt, false);
                        initializeAsync();
                    }}
                    onGoBack={() => navigate("/pilot/pilotages/assigned") }
                    pilotageId={pilotageId}
                />
            }
            
            <Overlay isBusy={state.isBusy} hasErrors={state.hasErrors} onReloadClick={onReloadClick} />
        </>
    )

    async function initializeAsync() {

        const data = await PilotAssignmentIdbRepository.getByPilotageIdAsync(Number(pilotageId));
        
        if (!isObjectNull(data)) {
            dispatch(DispatcherActions.Initialized, data);
        } else {
            publishWarningNotificationTopic("Losoppdraget ligger ikke lokalt.");
            navigate("/pilot/pilotages/assigned");
        }
        
    }

    function setIsBusy(busy) {
        dispatch(DispatcherActions.IsBusy, busy);
    }

    function handleOnOffline() {
        dispatch(DispatcherActions.IsOnline, navigator.onLine);
    }

    function onShowModalMenu(modalMenuState, modalMenuSize, onCallback, selectedId = 0, obj = null) {
        if (!state.isConfirmed) return;
        
        dispatch(DispatcherActions.ShowModalMenu,
            {
                modalMenuState: modalMenuState,
                modalMenuSize: modalMenuSize,
                modalMenuCallback: onCallback,
                modalSelectedId: selectedId,
                modalObj: obj
            });
    }

    function onReloadClick() {
        initializeAsync();
    }
    
    async function onSaveAsync() {
        if (!navigator.onLine) return;

        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(state.pilotAssignment.pilotagePilotId);
        if (!hasLocalChanges(pilotAssignment)) return;

        const command = getCompletePilotageReceiptCommand(pilotAssignment);
        const isCompleting = !isObjectNull(command) && command.BoolValueLevel1;
        const isBothPilotAndExaminer = hasCommandType(pilotAssignment, PilotAssignmentCommands.BothPilotAndExaminer);
        const convertingToPilotIncl = hasCommandType(pilotAssignment, PilotAssignmentCommands.ConvertToPilotageIncl);

        if (isCompleting) {
            dispatch(DispatcherActions.Completing, {
                showCompleteDialog: true,
                isBothPilotAndExaminer: isBothPilotAndExaminer,
                convertingToPilotIncl: convertingToPilotIncl
            });
        } else {
            thenSaveAsync();
        }
    }

    async function thenSaveAsync() {
        setIsBusy(true);

        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(state.pilotAssignment.pilotagePilotId);
        const command = getCompletePilotageReceiptCommand(pilotAssignment);
        const hasCompleteCommand = !isObjectNull(command);
        const shouldReload = hasCompleteCommand && (isPilotAssignmentCompleted(state.pilotAssignment) !== command.BoolValueLevel2);

        const response = await PilotApiRepository.savePilotageReceiptAsync(pilotAssignment);
        const pilotageNo = state.pilotAssignment.pilotage.pilotageNo;

        if (response.ok) {
            const data = await response.json();
            
            const pilotAssignment = convertPilotAssignmentApiDtoToIdb(data);

            await PilotAssignmentIdbRepository.deleteAsync(pilotAssignment);
            await PilotAssignmentIdbRepository.setAsync(pilotAssignment);

            publishSuccessNotificationTopic("Skipperbeviset ble oppdatert!");

            if (shouldReload) {

                dispatch(DispatcherActions.PilotAssignment, null);
                initializeAsync();

                if (isPilotAssignmentCompleted(pilotAssignment)) {
                    dispatch(DispatcherActions.Receipt, true);
                }

            } else {
                pilotAssignmentRef.current.onValidationChanged([]);

                if (isPilotAssignmentCompleted(pilotAssignment)) {
                    publishReloadAlertsTopic();
                    dispatch(DispatcherActions.Receipt, true);
                }

                setIsBusy(false);
            }
                
            pilotAssignmentRef.current.onPilotageChanged();
        } else {
            const errorMessage = await response.json();

            if (!isArrayEmpty(errorMessage.infoStrings)) {
                let warning = `Kan ikke lagre skipperbeviset for losoppdrag ${pilotageNo}:${getFormattedInfoString(errorMessage.infoStrings)}`;
                publishWarningNotificationTopic(warning);
            } else {
                publishWarningNotificationTopic(`Kan ikke lagre skipperbeviset for losoppdrag ${pilotageNo}:`);
            }

            if (hasCompleteCommand && !isArrayEmpty(errorMessage.codeStrings)) {
                pilotAssignmentRef.current.onValidationChanged(errorMessage.codeStrings);
            }

            setIsBusy(false);
        }
    }
}
