import { getFormattedDateTime } from "../../../../components/helpers/DateTimeHelpers";
import { isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from "../../../../components/helpers/ObjectHelpers";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";

function createEventId(commandType, obj) {
	switch (commandType) {

		case PilotAssignmentCommands.AddLocation:
		case PilotAssignmentCommands.DeleteLocation:
			return `${commandType}-LocationId-${obj.locationId}-SequenceNo-${obj.sequenceNo}`;

		case PilotAssignmentCommands.UpdateLocationDepartureTime:
		case PilotAssignmentCommands.UpdateLocationArrivalTime:
		case PilotAssignmentCommands.UpdateLocationPilotType:
		case PilotAssignmentCommands.UpdateLocationBoardingType:
			return `${commandType}-LocationId-${obj.location.locationId}-SequenceNo-${obj.location.sequenceNo}`;

		case PilotAssignmentCommands.UpdateVariableCompensationOverriddenNumber:
			return `${commandType}-${obj.pilotagePilotVariableCompensationSystemName}`;

		case PilotAssignmentCommands.AddPilotAssignmentOvertime:
		case PilotAssignmentCommands.AddPilotHourCompensation:
			return `${commandType}-${obj.guid}`;

		case PilotAssignmentCommands.UpdatePilotAssignmentOvertime:
		case PilotAssignmentCommands.DeletePilotAssignmentOvertime:
			return `${commandType}-${obj.pilotagePilotOvertimeId}`;

		case PilotAssignmentCommands.UpdatePilotHourCompensation:
		case PilotAssignmentCommands.DeletePilotHourCompensation:
			return `${commandType}-${obj.pilotagePilotHourCompensationId}`;

		default:
			return `${commandType}`;
	}
}

function getExistingCommand(eventId, pilotAssignment) {
	if (isArrayEmpty(pilotAssignment.commands)) return null;
	return pilotAssignment.commands.find(c => c.eventId === eventId);
}

function getExistingAddLocationCommand(pilotAssignment, obj) {
	const commandType = PilotAssignmentCommands.AddLocation;
	const eventId = createEventId(commandType, obj);
	return getExistingCommand(eventId, pilotAssignment);
}

function createBaseCommand(eventId, pilotAssignment, commandType) {
	const existingCommand = getExistingCommand(eventId, pilotAssignment);
	if (!isObjectNull(existingCommand)) return existingCommand;

	return {
		guid: "",
		eventId: eventId,
		typeValue: commandType,
		pilotageId: pilotAssignment.pilotage.pilotageId,
		pilotAssignmentId: pilotAssignment.pilotagePilotId
	};
}

function createCommandFromPilotAssignment(pilotAssignment, commandType) {
	const eventId = createEventId(commandType);
	return createBaseCommand(eventId, pilotAssignment, commandType);
}

function createCommandFromLocation(obj, pilotAssignment, commandType) {
	const eventId = createEventId(commandType, obj);
	return createBaseCommand(eventId, pilotAssignment, commandType);
}

function pilotAssignmentWithFilteredCommands(pilotAssignment, command, addCommand = true) {
	if (isObjectNull(pilotAssignment.commands)) {
		pilotAssignment.commands = [];
	}

	const filteredCommands = pilotAssignment.commands.filter(c => c.eventId !== command.eventId);

	if (addCommand) {
		filteredCommands.push(command);
	}

	pilotAssignment.commands = filteredCommands;

	return pilotAssignment;
}

function addAddLocationCommand(pilotAssignment, obj, boardingType, pilotType) {
	const command = createCommandFromLocation(obj, pilotAssignment, PilotAssignmentCommands.AddLocation);

	command.idLevel1 = obj.locationId;
	command.intValue = obj.sequenceNo;
	command.systemNameLevel1 = pilotType;
	command.systemNameLevel2 = boardingType;
	command.dateTimeLevel2 = null;
	command.dateTimeLevel1 = null;

	moveLocationCommands(pilotAssignment, obj.sequenceNo);

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addDeleteLocationCommand(pilotAssignment, obj) {
	let command = getExistingAddLocationCommand(pilotAssignment, obj);

	if (!isObjectNull(command)) {
		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command, false);
	} else {

		command = createCommandFromLocation(obj, pilotAssignment, PilotAssignmentCommands.DeleteLocation);

		command.idLevel1 = obj.pilotagePilotLocationId;

		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command);
	}

	moveLocationCommands(pilotAssignment, obj.sequenceNo, "up");

	return pilotAssignment;
}

function addUpdateLocationDepartureTimeCommand(pilotAssignment, obj) {
	let command = getExistingAddLocationCommand(pilotAssignment, obj.location);

	if (isObjectNull(command)) {
		command = createCommandFromLocation(obj, pilotAssignment, PilotAssignmentCommands.UpdateLocationDepartureTime);

		command.idLevel1 = obj.location.pilotagePilotLocationId;
	}

	command.dateTimeLevel1 = getFormattedDateTime(obj.departureTime);
	command.intValue = obj.location.sequenceNo;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addUpdateLocationArrivalTimeCommand(pilotAssignment, obj) {
	let command = getExistingAddLocationCommand(pilotAssignment, obj.location);

	if (isObjectNull(command)) {
		command = createCommandFromLocation(obj, pilotAssignment, PilotAssignmentCommands.UpdateLocationArrivalTime);

		command.idLevel1 = obj.location.pilotagePilotLocationId;
	}

	command.dateTimeLevel2 = getFormattedDateTime(obj.arrivalTime);

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addUpdateLocationPilotTypeCommand(pilotAssignment, obj) {
	let command = getExistingAddLocationCommand(pilotAssignment, obj.location);

	if (isObjectNull(command)) {
		command = createCommandFromLocation(obj, pilotAssignment, PilotAssignmentCommands.UpdateLocationPilotType);

		command.idLevel1 = obj.location.pilotagePilotLocationId;
	}

	command.systemNameLevel1 = obj.pilotType.systemName;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addUpdateLocationBoardingTypeCommand(pilotAssignment, obj) {
	let command = getExistingAddLocationCommand(pilotAssignment, obj.location);

	if (isObjectNull(command)) {
		const commandType = PilotAssignmentCommands.UpdateLocationBoardingType;
		const eventId = createEventId(commandType, obj);

		command = getExistingCommand(eventId, pilotAssignment);

		if (isObjectNull(command)) {
			command = createBaseCommand(eventId, pilotAssignment, commandType);
			command.idLevel1 = obj.location.pilotagePilotLocationId;
		}
	}

	command.systemNameLevel2 = obj.boardingType.systemName;
	command.boolValueLevel1 = obj.boardingType.isPilotBoardingTypeOffBoarding;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addIsWastedTripCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.IsWastedTrip);

	command.boolValueLevel1 = obj;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addPilotRemarkCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.PilotRemark);

	command.textLevel1 = obj;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addPilotArrivalTimeCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.PilotArrivalTime);

	command.dateTimeLevel1 = getFormattedDateTime(obj);

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addPilotDepartureTimeCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.PilotDepartureTime);

	command.dateTimeLevel1 = getFormattedDateTime(obj);

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addPilotReturnTimeCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.PilotReturnTime);

	command.dateTimeLevel1 = getFormattedDateTime(obj);

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);

}

function addAddTowingTonnageCommand(pilotAssignment, obj) {
    const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.AddTowingTonnage);

    command.intValue = obj;

    return pilotAssignmentWithFilteredCommands(pilotAssignment, command);

}

function addManeuverResponsibleTypeCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.ManueverResponsibleType);

	command.systemNameLevel1 = obj.systemName;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);

}

function addWaitingHourReasonCommand(pilotAssignment, obj, remark) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.WaitingHourReason);

	command.systemNameLevel1 = obj.systemName;
	command.textLevel1 = remark;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addWaitingHourReasonRemarkCommand(pilotAssignment, obj, systemName) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.WaitingHourReason);

	command.textLevel1 = obj;
	command.systemNameLevel1 = systemName;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addUpdateVariableCompensationOverriddenNumberCommand(pilotAssignment, obj) {
	const commandType = PilotAssignmentCommands.UpdateVariableCompensationOverriddenNumber;
	const eventId = createEventId(commandType, obj);
	const command = createBaseCommand(eventId, pilotAssignment, commandType);

	command.idLevel1 = obj.pilotagePilotCompensationId;
	command.idLevel2 = obj.pilotagePilotVariableCompensationId;
	command.systemNameLevel1 = obj.pilotagePilotVariableCompensationOverrideReasonType;
	command.textLevel1 = obj.pilotagePilotVariableCompensationOverrideReasonRemark;
	command.floatValue2 = obj.overridenNumber;
	command.systemNameLevel2 = obj.pilotagePilotVariableCompensationSystemName;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addPilotAssignmentOvertimeCommand(pilotAssignment, obj, pilotagePilotOvertimeId = 0) {
	const commandType = obj.command;
	const eventId = createEventId(commandType, obj);
	const command = createBaseCommand(eventId, pilotAssignment, commandType);
	const defaultType = "OVERTIME_100_PERCENT";

	command.guid = obj.guid;
	command.idLevel1 = pilotAssignment.pilotagePilotCompensation.pilotagePilotCompensationId;
	command.idLevel2 = pilotagePilotOvertimeId;
	command.dateTimeLevel1 = obj.fromTime;
	command.dateTimeLevel2 = obj.toTime;
	command.boolValueLevel1 = obj.isMinimum;
	command.boolValueLevel2 = obj.isTwoThird;
	command.systemNameLevel1 = defaultType;
	command.textLevel1 = obj.description;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addDeletePilotAssignmentOvertimeCommand(pilotAssignment, obj) {

	const commandType = PilotAssignmentCommands.DeletePilotAssignmentOvertime;
	const eventId = createEventId(commandType, obj);
	const command = createBaseCommand(eventId, pilotAssignment, commandType);
	let addCommand = false;

	command.idLevel1 = obj.pilotagePilotCompensationId;
	command.idLevel2 = obj.pilotagePilotOvertimeId;

	if (isNumeric(obj.pilotagePilotOvertimeId)) {
		addCommand = obj.pilotagePilotOvertimeId > 0;
	}

	pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command, addCommand);
	let existingEventId = null;

	if (!addCommand) {
		existingEventId = createEventId(PilotAssignmentCommands.AddPilotAssignmentOvertime, obj);
	} else {
		existingEventId = createEventId(PilotAssignmentCommands.UpdatePilotAssignmentOvertime, obj);
	}

	const existingCommand = getExistingCommand(existingEventId, pilotAssignment);

	if (!isObjectNull(existingCommand)) {
		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, existingCommand, false);
	}

	return pilotAssignment;
}

function addPilotHourCompensationCommand(pilotAssignment, obj, pilotagePilotHourCompensationId = 0) {
	const commandType = obj.command;
	const eventId = createEventId(commandType, obj);
	const command = createBaseCommand(eventId, pilotAssignment, commandType);

	command.guid = obj.guid;
	command.idLevel1 = pilotAssignment.pilotagePilotCompensation.pilotagePilotCompensationId;
	command.idLevel2 = pilotagePilotHourCompensationId;
	command.systemNameLevel1 = obj.pilotagePilotHourCompensationSystemName;
	command.dateTimeLevel1 = obj.fromTime;
	command.dateTimeLevel2 = obj.toTime;
	command.textLevel1 = obj.description;
	command.floatValue1 = obj.number;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addDeletePilotHourCompensationCommand(pilotAssignment, obj) {
	const commandType = PilotAssignmentCommands.DeletePilotHourCompensation;
	const eventId = createEventId(commandType, obj);
	const command = createBaseCommand(eventId, pilotAssignment, commandType);
	let addCommand = false;

	command.idLevel1 = obj.pilotagePilotCompensationId;
	command.idLevel2 = obj.pilotagePilotHourCompensationId;

	if (isNumeric(obj.pilotagePilotCompensationId)) {
		addCommand = obj.pilotagePilotHourCompensationId > 0;
	}

	pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command, addCommand);
	let existingEventId = null;

	if (!addCommand) {
		existingEventId = createEventId(PilotAssignmentCommands.AddPilotHourCompensation, obj);
	} else {
		existingEventId = createEventId(PilotAssignmentCommands.UpdatePilotHourCompensation, obj);
	}

	const existingCommand = getExistingCommand(existingEventId, pilotAssignment);

	if (!isObjectNull(existingCommand)) {
		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, existingCommand, false);
	}

	return pilotAssignment;
}

function addUpdateReimbursmentStatusCommand(pilotAssignment, obj) {
	const command = createCommandFromPilotAssignment(pilotAssignment, PilotAssignmentCommands.UpdateReimbursmentStatus);

	command.idLevel1 = pilotAssignment.pilotagePilotCompensation.pilotagePilotCompensationId;
	command.boolValueLevel1 = obj;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function addConvertToPilotageInclCommand(pilotAssignment, convertToPilotageIncl) {
	const commandType = PilotAssignmentCommands.ConvertToPilotageIncl;
	const eventId = createEventId(commandType, convertToPilotageIncl);

	if (convertToPilotageIncl) {
		const command = createBaseCommand(eventId, pilotAssignment, commandType);
		command.IdLevel1 = pilotAssignment.pilotage.pilotAssignmentCompensationId;
		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command);

	} else {
		const existingCommand = getExistingCommand(eventId, pilotAssignment);
		if (!isObjectNull(existingCommand)) {
			pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, existingCommand, false);
        }
	}

	return pilotAssignment;
}

function addBothPilotAndExaminerCommand(pilotAssignment, bothPilotAndExaminer) {
	const commandType = PilotAssignmentCommands.BothPilotAndExaminer;
	const eventId = createEventId(commandType, bothPilotAndExaminer);

	if (bothPilotAndExaminer) {
		const command = createBaseCommand(eventId, pilotAssignment, commandType);
		command.IdLevel1 = pilotAssignment.pilotagePilotCompensation.pilotagePilotCompensationId;
		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command);

	} else {
		const existingCommand = getExistingCommand(eventId, pilotAssignment);
		if (!isObjectNull(existingCommand)) {
			pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, existingCommand, false);
		}
	}

	return pilotAssignment;
}

function addCompletePilotageReceiptCommand(pilotAssignment, isComplete) {
	const commandType = PilotAssignmentCommands.CompletePilotageReceipt;
	const eventId = createEventId(commandType, isComplete);
	const command = createBaseCommand(eventId, pilotAssignment, commandType);

	command.IdLevel1 = pilotAssignment.pilotagePilotCompensation.pilotagePilotCompensationId;
	command.BoolValueLevel1 = isComplete;
	command.BoolValueLevel2 = pilotAssignment.isConfirmedByPilotDispatcher;

	return pilotAssignmentWithFilteredCommands(pilotAssignment, command);
}

function removeCompletePilotageReceiptCommand(pilotAssignment) {
	const commandType = PilotAssignmentCommands.CompletePilotageReceipt;
	const eventId = createEventId(commandType);
	const command = getExistingCommand(eventId, pilotAssignment);

	if (!isObjectNull(command)) {
		pilotAssignment = pilotAssignmentWithFilteredCommands(pilotAssignment, command, false);
	}
	return pilotAssignment;
}

function getCompletePilotageReceiptCommand(pilotAssignment) {
	const commandType = PilotAssignmentCommands.CompletePilotageReceipt;
	const eventId = createEventId(commandType);
	const command = getExistingCommand(eventId, pilotAssignment);

	return command;
}

function filterCommandsByTypeValue(pilotAssignment, typeValues) {

	if (!hasLocalChanges(pilotAssignment)) return pilotAssignment;

	const filteredCommands = pilotAssignment.commands
		.filter(c => !typeValues.includes(c.typeValue));

	pilotAssignment.commands = filteredCommands;
	pilotAssignment.hasLocalChanges = hasLocalChanges(pilotAssignment);

	return pilotAssignment;
}

function hasLocationCommand(pilotAssignment, location, type) {
	const eventId = createEventId(type, { location: location });
	const hasCommands = pilotAssignment.commands
		.some(c =>
			c.eventId === eventId);
	return hasCommands;
}

function hasAddLocationCommand(pilotAssignment, location) {
	const command = getExistingAddLocationCommand(pilotAssignment, location);
	return !isObjectNull(command);
}

function hasCommandType(pilotAssignment, typeValue) {
	const hasCommands = pilotAssignment.commands
		.some(c => c.typeValue === typeValue);

	return hasCommands;
}

function hasExistingCommand(copy, eventId) {
	if (isNullOrEmpty(copy.commands)) return false;
	return !isObjectNull(copy.commands.find(c => c.eventId === eventId));
}

function hasLocalChanges(pilotAssignment) {
	if (isObjectNull(pilotAssignment)) return false;

	if (isObjectNull(pilotAssignment.commands)) return false;

	return !isArrayEmpty(pilotAssignment.commands);
}

function moveLocationCommands(pilotAssignment, sequenceNo, direction = "down") {
	for (const existingCommand of pilotAssignment.commands) {
		if (!existingCommand.eventId.includes("SequenceNo")) continue;

		const eventIdArr = existingCommand.eventId.split('-');
		const currentSequenceNo = Number(eventIdArr[eventIdArr.length - 1]);

		if (currentSequenceNo < sequenceNo) continue;

		const newSequenceNo = direction === "down" ? currentSequenceNo + 1 : currentSequenceNo - 1;

		const eventId = existingCommand.eventId.replace(`SequenceNo-${currentSequenceNo}`, `SequenceNo-${newSequenceNo}`)

		existingCommand.eventId = eventId;
	}
}

export {
    filterCommandsByTypeValue,

    hasLocalChanges,
    hasCommandType,
    hasAddLocationCommand,
    hasLocationCommand,
    hasExistingCommand,

    getCompletePilotageReceiptCommand,

    addBothPilotAndExaminerCommand,
    addCompletePilotageReceiptCommand,
    addConvertToPilotageInclCommand,
    addPilotHourCompensationCommand,
    addAddTowingTonnageCommand,
    addManeuverResponsibleTypeCommand,
    addPilotAssignmentOvertimeCommand,
    addPilotRemarkCommand,
    addAddLocationCommand,
    addUpdateLocationPilotTypeCommand,
    addPilotArrivalTimeCommand,
    addPilotDepartureTimeCommand,
    addPilotReturnTimeCommand,
    addWaitingHourReasonCommand,
    addWaitingHourReasonRemarkCommand,
    addIsWastedTripCommand,

    addUpdateReimbursmentStatusCommand,
    addUpdateLocationDepartureTimeCommand,
    addUpdateLocationBoardingTypeCommand,
    addUpdateLocationArrivalTimeCommand,
    addUpdateVariableCompensationOverriddenNumberCommand,

    addDeletePilotHourCompensationCommand,
    addDeletePilotAssignmentOvertimeCommand,
    addDeleteLocationCommand,

    removeCompletePilotageReceiptCommand,
}
