import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import { NumericTextBox } from '../../../../components/layout/NumericTextBox';
import Spacer from '../../../../components/layout/Spacer';
import Overlay from '../../../../components/layout/overlay/Overlay';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import {
    TextBox,
} from "@progress/kendo-react-inputs";
import CompensationApiRepository from '../../../../repositories/api/CompensationApiRepository';
import { deepCopyObject, isNullOrEmpty, isNumeric, isObjectNull, removeQuotesFromString } from '../../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../../components/helpers/PubSubHelpers';
import TextAreaFieldWrapper from '../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { DialogForm } from '../../../../components/layout/DialogForm';

export const VariableCompensationBridgeDialog = ({ onClose,
    compensation = null,
    callback }) => {

    const [canSave, setCanSave] = useState(false);
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [overriddenNumber, setOverriddenNumber] = useState(compensation.overriddenNumber);
    const [remark, setRemark] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <>
            <Dialog
                properties={{
                    ...DialogProperties,
                    title: compensation.type.nameNor,
                    onClose: onClose,
                    className: "dialog-std",
                    actions: [
                        {
                            onClick: onClose,
                            icon: "close",
                            text: "Avbryt"
                        },
                        {
                            onClick: onSaveClickAsync,
                            disabled: !canSave,
                            icon: "ok",
                            themeColor: "primary",
                            text: "Ok"
                        }
                    ]
                }}>
                <DialogForm>

                    <FieldWrapper>
                        <Label className="k-form-label">
                            Beregnet antall:
                        </Label>
                        <div className="k-form-field-wrap">
                            <TextBox value={getCalculatedNumber()} disabled={true} readOnly={true} />
                        </div>
                    </FieldWrapper>

                    <Spacer height={20} />

                    <FieldWrapper>
                        <Label className="k-form-label">
                            Overstyrt antall:
                        </Label>
                        <div className="k-form-field-wrap">
                            <div className="dialog-hours-minutes">
                                <div><NumericTextBox value={hours} onChange={onHoursChange} /></div>
                                <div>timer</div>
                                <div><NumericTextBox value={minutes} onChange={onMinutesChange} /></div>
                                <div>minutter</div>
                            </div>
                        </div>
                    </FieldWrapper>

                    <Spacer height={20} />
                    <TextAreaFieldWrapper
                        title="Begrunnelse"
                        value={remark}
                        onChange={onDescriptionChange}
                    />
                </DialogForm>
                <Overlay isBusy={isBusy} />
            </Dialog>
            
        </>
        
    )

    async function initializeAsync() {
        if (!isNullOrEmpty(compensation.overriddenNumber)) {
            const tmpHours = Math.floor(compensation.overriddenNumber);
            const tmpMinutes = Math.ceil((compensation.overriddenNumber - tmpHours) * 60);

            setHours(tmpHours);
            setMinutes(tmpMinutes);
        }

        if (!isNullOrEmpty(compensation)) {
            setRemark(compensation.overrideReasonRemark);
        } else {
            setRemark("");
        }
    }

    async function onSaveClickAsync() {
        if (!canSave) return;

        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.overrideReasonRemark = remark;
        compensationCopy.overriddenNumber = overriddenNumber;
        compensationCopy.overrideReasonType = {
            value: 1,
            systemName: "OTHER",
            nameNor: "Annet"
        }

        setIsBusy(true);
        const response = await CompensationApiRepository.updatePilotageVariableCompensation(compensationCopy);
        if (response.ok) {
            callback(compensationCopy);
            onClose();
        } else {
            const errorMessage = await response.text();
            publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
        }
        setIsBusy(false);
    }

    function onDescriptionChange(e) {
        setRemark(e.value);
        validate(e.value);
    }

    function onHoursChange(e) {
        setHours(e.value);
        updateOverriddenNumber(e.value, minutes);
    }

    function onMinutesChange(e) {
        setMinutes(e.value);
        updateOverriddenNumber(hours, e.value);
    }

    function updateOverriddenNumber(hours, minutes) {
        let overriddenNumber = null;

        if (isNumeric(hours) && isNumeric(minutes)) {
            overriddenNumber = (hours + (minutes / 60)).toFixed(6);
        } else {
            if (isNumeric(hours)) {
                overriddenNumber = hours;
            }
            if (isNumeric(minutes)) {
                overriddenNumber = (minutes / 60).toFixed(6);
            }
        }

        setOverriddenNumber(isNullOrEmpty(overriddenNumber) ? null : Number(overriddenNumber));
        validate(remark, hours, minutes);
    }

    function validate(text = remark, overrideHours = hours, overrideMinutes = minutes) {
        if (!isNumeric(overrideHours) && !isNumeric(overrideMinutes)) {
            setCanSave(true);
        } else {
            setCanSave(!isNullOrEmpty(text));
        }
    }

    function getCalculatedNumber() {
        if (isObjectNull(compensation)
            || (compensation.calculatedNumber === 0 || !isNumeric(compensation.calculatedNumber))) 
            return "0t 0min (0.00)";
       
        const tmpHours = Math.floor(compensation.calculatedNumber);
        const tmpMinutes = Math.ceil((compensation.calculatedNumber - tmpHours) * 60);

        return `${tmpHours}t ${tmpMinutes}min (${compensation.calculatedNumber.toFixed(2)})`;
    }
}
