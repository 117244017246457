import React  from 'react';
import Icons from './icons/Icons';
import { DropDownButton as KendoDropDownButton } from "@progress/kendo-react-buttons";
import { DropDownButtonActionRender } from './render/DropDownButtonActionRender';
import uuid from 'react-uuid';

export default function DropDownButton(
    {
        items = [],
        disabled = false,
        hasValidationError = false,
        onCallback = null,
        icon = "edit",
        id = uuid()
    }
) {
    return (
        <KendoDropDownButton
            id={id}
            items={items}
            disabled={disabled}
            itemRender={DropDownButtonActionRender}
            onItemClick={onItemClick}
            style={{ width: "100%" }}
            themeColor={hasValidationError ? "error" : "base" }
            text={<Icons iconName={icon} dimensions={24} />}
            fillMode="flat" />
    );

    function onItemClick(e) {
        onCallback(e.item);
    }
}
