import './floatingactionbutton.css'
import { FloatingActionButton as KendoFloatingActionButton } from "@progress/kendo-react-buttons";
import { Avatar } from "@progress/kendo-react-layout";
import React from 'react';
import { SvgIcon } from "@progress/kendo-react-common";
import { isArrayEmpty } from '../helpers/ObjectHelpers';

export default function FloatingActionButton(
    {
        themeColor = "",
        svgIcon,
        onClick,
        onItemClick,
        disabled = false,
        items = []
    }
) {

    return (
        (isArrayEmpty(items)) ?
            <KendoFloatingActionButton
                themeColor={themeColor}
                svgIcon={svgIcon}
                onClick={onClick}
                disabled={disabled}
            />
            :
            <KendoFloatingActionButton
                themeColor="primary"
                svgIcon={svgIcon}
                onItemClick={onItemClick}
                item={FloatingActionButtonItem}
                popupSettings={{
                    popupClass: "floating-action-button-items"
                }}
                items={items}
            />
    );

}

const FloatingActionButtonItem = (props) => {
    return (
        <div className="floating-action-button-item">
            <div className="text-wrap">
                &nbsp;{props.item.text}&nbsp;
            </div>
            <div>
                <Avatar type="icon" size="large" themeColor="secondary">
                    <SvgIcon icon={props.item.svgIcon} size="xlarge"/>
                </Avatar>
            </div>
        </div>
    );
}
