import { getLoggedInPersonId } from '../../components/helpers/AuthHelpers';
import { isArrayEmpty, isObjectNull } from '../../components/helpers/ObjectHelpers';
import {
    IndexedDbNames,
    IndexedDbKeyNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.EvaluationStore;

const EvaluationIdbRepository = {

    async setEvaluationFormAsync(obj) {
        await BaseIdbRepository.setAsync(Store, IndexedDbKeyNames.EvaluationForm, { id: IndexedDbKeyNames.EvaluationForm, value: obj });
    },

    async getEvaluationFormAsync() {
        const obj = await BaseIdbRepository.getAsync(Store, IndexedDbKeyNames.EvaluationForm);
        if (isObjectNull(obj)) return null;

        return obj.value;
    },

    async setAsync(key, obj) {
        const personId = getLoggedInPersonId();
        if (obj.pilotPersonId !== personId) return;
        
        await BaseIdbRepository.setAsync(Store, key, { id: key, value: obj });
    },

    async getAsync(key) {
        const obj = await BaseIdbRepository.getAsync(Store, key);
        if (isObjectNull(obj)) return null;

        const personId = getLoggedInPersonId();
        if (obj.value.pilotPersonId !== personId) return null;

        return obj.value;
    },

    async getAllAsync() {
        const objs = await BaseIdbRepository.getAllAsync(Store);
        const data = [];
        const personId = getLoggedInPersonId();

        for (let i = 0; i < objs.length; i++) {
            const obj = objs[i];
            if (obj.id.includes("pilotage-pilot-id") && obj.id.includes("pilotage-pec-exam-id")) {
                if (obj.value.pilotPersonId === personId) {
                    data.push(obj.value);
                }
            }
        }
        return data;
    },

    async deleteAsync(key) {
        const obj = this.getAsync(key);
        if (isObjectNull(obj)) return;
        
        await BaseIdbRepository.deleteAsync(Store, key);
    },

    async deleteAllAsync() {
        const objs = await BaseIdbRepository.getAllAsync(Store);
        if (isArrayEmpty(objs)) return;

        const personId = getLoggedInPersonId();
        for (let i = 0; i < objs.length; i++) {
            const obj = objs[i];
            if (obj.id.includes("pilotage-pilot-id") && obj.id.includes("pilotage-pec-exam-id")) {
                if (obj.value.pilotPersonId === personId) {
                    await BaseIdbRepository.deleteAsync(Store, obj.id);
                }
            }
        }
    }
    
};

export default EvaluationIdbRepository;