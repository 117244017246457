import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import DateTimePicker, { DateTimePickerModes } from '../../../../components/layout/DateTimePicker';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Label } from '../../../../components/layout/Label';
import { getFormattedDateTime } from '../../../../components/helpers/DateTimeHelpers';
import { addPilotArrivalTimeCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TimeArrivalCard = forwardRef((
    {
        pilotAssignmentId,
        initializeIsDirty,
        onArrivalTimeChanged,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            setHasValidationError(codeStrings.includes(PilotAssignmentErrorCodes.PilotArrivalTimeIsMissing));
        }
    }));

    const [arrivalTime, setArrivalTime] = useState(null);
    const [show, setShow] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        <FieldWrapper>
            <Label hasValidationError={hasValidationError}>
                Oppm&oslash;te:
            </Label>
            <div className="k-form-field-wrap">
                <DateTimePicker
                    disabled={!isEditable}
                    hasValidationError={hasValidationError}
                    onClick={() => setShow(true)}
                    title="Velg oppm&oslash;te tid"
                    value={arrivalTime}
                    show={show}
                    onClose={() => setShow(false)}
                    mode={DateTimePickerModes.DateTime}
                    onChange={onChangedAsync} />
            </div>
        </FieldWrapper>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        setArrivalTime(pilotAssignment.arrivalTime);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function onChangedAsync(event) {

        setArrivalTime(event.value);

        const obj = event.value;

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        pilotAssignment.arrivalTime = getFormattedDateTime(obj);

        pilotAssignment = addPilotArrivalTimeCommand(pilotAssignment, obj);
        
        await onUpdatePilotAssignmentAsync(pilotAssignment);

        onArrivalTimeChanged();

        initializeIsDirty();
    }
})
