import React from 'react'
import { sniffPlatform } from '../../../components/helpers/DeviceHelpers';
import { publishHeaderTopic } from '../../../components/helpers/PubSubHelpers';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import ContentGrid from '../../../components/layout/ContentGrid';
import Spacer from '../../../components/layout/Spacer';
import NotificationsBottomMenu from '../components/NotificationsBottomMenu';


function LocationSharing() {
    publishHeaderTopic("Tillatelser", "Lokasjonsdeling");
    const [permissionType, setPermissionType] = React.useState(null);
    const [action, setAction] = React.useState("");

    React.useEffect(() => {
        if (permissionType === null) {
            getPermissionType();
        }
        if (action === "" && permissionType !== null) {
            determineAction(permissionType);
        }
    }, [permissionType, action]);


    function determineAction(permissionType) {
        let action;
        switch (permissionType) {
            case "granted":
                action = "disable";
                break;
            case "denied":
                action = "denied";
                break;
            case "none":
                action = "none";
                break;
            case "prompt":
                action = "denied";
                break;
            default:
                break;
        }
        setAction(action)
    }

    async function getPermissionType() {
        if (sniffPlatform() === "ios") {
            return await navigator.geolocation.getCurrentPosition(
                async (position) => {
                    if (position === null || position === undefined) {
                        setPermissionType("denied");
                    }
                    else {
                        setPermissionType("granted");
                    }
                },
                (e) => {
                    setPermissionType("denied");
                }
            )
        }

        return await navigator.permissions.query({name:'geolocation'}).then((result) => {
            setPermissionType(result.state);
        })
    }

    return (
        <>
            <ContentGrid title="Lokasjonsdeling" titleIcon="pin" defaultContentClassName="" className="p-r-20 p-l-20">
                <Card properties={{
                    ...CardProperties,
                    title: getPermissionStatus(permissionType)
                } }>
                    <Disclaimer status={permissionType} />
                    <Instructions />
                    {sniffPlatform() === "ios" && <IOSInstrunctions />}
                </Card>
                <Spacer height={70}/>
            </ContentGrid>
            <NotificationsBottomMenu index={2} />
        </>
    );

    function getPermissionStatus (status) {
        switch (status) {
            case 'granted':
                return "Du har tillatt deling av din posisjon";
            default:
                return "Du har ikke tillatt deling av din posisjon";
        }
    }
}

const GrantedMessage = () => <p>Husk at din posisjon fjernes for losformidlingen når du starter arbeidstiden</p>

const DefaultMessage = () => <>
    <p>Deling av din posisjon hjelper losformidlingen med å tildele deg nye losoppdrag og deles når du stopper arbeidstiden.</p>
    <p>Losformidlingen vil aldri se din nøyaktige posisjon, kun stedsnavnet og området som du befinner deg i.</p>
    <p>Din posisjon fjernes automatisk for losformidler når du starter arbeidstiden</p>
</>

const Instructions = () => <p className='semi-bold m-t-20'>For å endre tillatelser for deling av lokasjon, gå til innstillinger for nettleseren.</p>

const IOSInstrunctions = () => <>
    <p>For å endre, gå til:</p>
    <p>1. Innstillinger {"->"} <br />
        2. Personvern og Sikkerhet{"->"} <br />
        3. Stedstjenester (må settes på) <br />
        4. Scroll ned og velg din nettlser<br />
        5. Velg "Når appen er i bruk" (anbefalt)<br />
    </p>
</>

const Disclaimer = ({ status }) => {
    return (
        <div>
            {status === "granted" ? <GrantedMessage /> : <DefaultMessage />}
        </div>
    )
}

export default LocationSharing;