import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Spacer from '../../../../components/layout/Spacer';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { getPilotAssignmentStatusColor, getPilotAssignmentStatusColorRgba, isPilotAssignmentCorrectionNeeded } from '../helpers/PilotAssignmentHelpers';

export const CorrectionCard = ({ pilotAssignmentId }) => {

    const [show, setShow] = useState(false);
    const [pilotAssignment, setPilotAssignment] = useState("");

    useEffect(() => {
        async function initializeAsync() {
            const result = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
            setShow(isPilotAssignmentCorrectionNeeded(result));
            setPilotAssignment(result);
        }

        initializeAsync()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        show &&
        <>
            <Card
                properties={{
                    ...CardProperties,
                    title: "Begrunnelse for korrigerning",
                    borderColor: getPilotAssignmentStatusColor(pilotAssignment),
                    backgroundColor: getPilotAssignmentStatusColorRgba(pilotAssignment)
                }}>
                {pilotAssignment.correctionRemark}
            </Card>
            <Spacer height={20} />
        </>
    )
    
}
