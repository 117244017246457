import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Moment from 'moment';
import PilotagePilotWaitingHoursReasonTypeIdbRepository from '../../../../repositories/idb/PilotagePilotWaitingHoursReasonTypeIdbRepository';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { TextArea } from "@progress/kendo-react-inputs";
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import SelectOption from '../../../../components/layout/SelectOption';
import Spacer from '../../../../components/layout/Spacer';
import { isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { getDateDiffInMinutes } from '../../../../components/helpers/DateTimeHelpers';
import { addWaitingHourReasonCommand, addWaitingHourReasonRemarkCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import Slide from '../../../../components/layout/Slide';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TimeWaitingHoursCard = forwardRef((
    {
        pilotAssignmentId,
        initializeIsDirty,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        onLocationChanged() {
            initializeAsync();
        },
        onArrivalTimeChanged() {
            initializeAsync();
        }
    }));

    const [shouldShow, setShouldShow] = useState(false);
    const [reason, setReason] = useState({ id: 0, name: "Ikke valgt", isRemarkRequired: false });
    const [remark, setRemark] = useState("");
    const [hours, setHours] = useState("");
    const [reasonTypes, setReasonTypes] = useState([]);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    return (
        <Slide show={shouldShow}>
            <Spacer height={5} />
            <FieldWrapper>
                <Label className="k-form-label">
                    Ventetid
                </Label>
                <div className="k-form-field-wrap">
                    {hours} timer
                </div>
            </FieldWrapper>
            <Spacer height={10} />
            <FieldWrapper>
                <Label className="k-form-label">
                    Begrunnelse
                </Label>
                <div className="k-form-field-wrap">
                    <SelectOption
                        options={reasonTypes}
                        selectedOption={reason}
                        optionTextField="name"
                        title={`Endre ventetid begrunnelse`}
                        onCallback={onCallback}
                        disabled={!isEditable}
                    />
                </div>
            </FieldWrapper>

            {
                reason.isRemarkRequired &&
                <FieldWrapper>
                    <Spacer height={10} />
                    <div className="k-form-field-wrap">
                        <TextArea
                            disabled={!isEditable}
                            value={remark}
                            rows={2}
                            onChange={onRemarkChangeAsync} />
                    </div>
                </FieldWrapper>
            }
        </Slide>
    )

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function initializeAsync() {

        let show = false;
        let hours = 0;

        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));

        if (pilotAssignment.isWastedTrip) {
            setShouldShow(false);
            return;
        }
        
        setRemark(pilotAssignment.waitingHoursReasonRemark);

        const startTime = pilotAssignment.locations[0].fromTime;
        const availableTime = getAvailableTimeForPilot(pilotAssignment);
        
        if (!isObjectNull(availableTime) && !isObjectNull(startTime)) {

            const deductableMinutesForFirstLeg = 60;
            let waitingMinutes = 0;

            const shipReadyForPilotageMoment = Moment(pilotAssignment.pilotage.pilotageDetail.fromTime);
            const availableMoment = Moment(availableTime);
            const startMoment = Moment(startTime);

            if (availableMoment >= shipReadyForPilotageMoment && startMoment > availableMoment) {
                waitingMinutes = getDateDiffInMinutes(availableMoment, startMoment);
            }
            else if (availableMoment < shipReadyForPilotageMoment && startMoment > shipReadyForPilotageMoment) {
                waitingMinutes = getDateDiffInMinutes(shipReadyForPilotageMoment, startMoment);
            }
            
            if (waitingMinutes > deductableMinutesForFirstLeg) {
                show = true;
                hours = (waitingMinutes / 60).toFixed(1);
            }
        }

        setHours(hours);
        setShouldShow(show);

        let reason = {};
        
        if (!isObjectNull(pilotAssignment.waitingHoursReasonType)) {

            const dto = await PilotagePilotWaitingHoursReasonTypeIdbRepository.getAsync(pilotAssignment.waitingHoursReasonType.pilotagePilotWaitingHoursReasonTypeId);
            reason = dto;
        }

        setReason(reason);

        initializeReasonTypesAsync(reason);
    }

    async function initializeReasonTypesAsync(reason) {
        const reasons = [];
        const dtos = await PilotagePilotWaitingHoursReasonTypeIdbRepository.getAllAsync();
        for (let i = 0; i < dtos.length; i++) {
            const dto = dtos[i];
            reasons.push({
                name: dto.name,
                selected: isObjectNull(reason) ? false : dto.systemName === reason.systemName,
                source: dto
            })
        }

        setReasonTypes(reasons);
    }

    async function onCallback(item) {
        const obj = item.source;
        setReason(obj);

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        
        pilotAssignment.waitingHoursReasonType = obj;
        pilotAssignment = addWaitingHourReasonCommand(pilotAssignment, obj, pilotAssignment.waitingHoursReasonRemark);

        await onUpdatePilotAssignmentAsync(pilotAssignment);

        initializeReasonTypesAsync(obj);

        initializeIsDirty();
    }

    async function onRemarkChangeAsync(e) {
        if (!isEditable) return;

        setRemark(e.value);

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        pilotAssignment.waitingHoursReasonRemark = e.value;
        const systemName = "OTHER";

        pilotAssignment = addWaitingHourReasonRemarkCommand(pilotAssignment, e.value, systemName);

        await onUpdatePilotAssignmentAsync(pilotAssignment);
    }

    function getAvailableTimeForPilot(pilotAssignment) {

        if (isObjectNull(pilotAssignment.arrivalTime) && !pilotAssignment.isWastedTrip) {
            return pilotAssignment.pilotage.pilotageDetail.shipReadyForPilotageTime
        }

        return pilotAssignment.arrivalTime;
    }
})
