
import React  from 'react';
import Spacer from '../../../components/layout/Spacer';
import EvaluationDetails from './EvaluationDetails';
import parse from 'html-react-parser';
import { isArrayEmpty } from '../../../components/helpers/ObjectHelpers';
import { EvaluationListItemStates } from '../services/EvaluationHelpers';

export default function EvaluationList({
    evaluations,
    onClick,
    onShowDocumentClick,
    addTopSpacer = false,
    title
}) {
    return (
        (!isArrayEmpty(evaluations)) ?
            <>
                {
                    addTopSpacer &&
                        <Spacer height={50} />
                }
                <div className="default-content-sub-title">{parse(title)}</div>
                <Spacer height={20} />
                {
                    evaluations.map((evaluation, index) =>
                        <div key={index}>
                            <EvaluationDetails
                                evaluation={evaluation}
                                itemState={EvaluationListItemStates.Default}
                                onClick={onClick}
                                onShowDocumentClick={() => onShowDocumentClick(evaluation)}
                            />
                            <Spacer height={20} />
                        </div>
                    )
                }
            </>
            : null
    );
}
