import { useEffect } from 'react';
import {
    useMap
} from '@vis.gl/react-google-maps';
import { isObjectNull } from '../../../helpers/ObjectHelpers';
import { MapClickTypes } from '../GoogleMapHelpers';

export const MapSelectedPositionCircle = ({ properties, localRef, position }) => {

    const map = useMap();   
    
    useEffect(() => {
        if (!properties.canMapClick || properties.mapClickType !== MapClickTypes.Circle) {
            removeCircle();
            return;
        }
        if (!map) return;

        function createNewCircle() {
            return new window.google.maps.Circle({
                strokeColor: "#0B5E18",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "#90EE90",
                fillOpacity: 0.4,
                map,
                center: position,
                radius: 750
            });
        }

        function removeCircle() {
            if (isObjectNull(localRef.circle)) return;
            localRef.circle.set("map", null);
            localRef.circle = null;
        }

        if (localRef.circle === null) {
            localRef.circle = createNewCircle();
        } else {
            removeCircle();
            localRef.circle = createNewCircle();
        }
        
    }, [properties, localRef, position, map]);
}
