import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.PilotagePilotWaitingHoursReasonTypeStore;

const PilotagePilotWaitingHoursReasonTypeIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(obj) {
        await BaseIdbRepository.setAsync(Store, obj.pilotagePilotWaitingHoursReasonTypeId, obj);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },

    async getAsync(key) {
        return await BaseIdbRepository.getAsync(Store, key);
    },
};

export default PilotagePilotWaitingHoursReasonTypeIdbRepository;