import { useState } from "react";
import { getSearchParam } from "../../components/helpers/UrlHelpers";
import { useSearchParams } from "react-router-dom";
import { NoticeTimeBottomNavigation, NoticeTimeBottomNavigationActions } from "./components/NoticeTimeBottomNavigation";
import Overridden from "./Overridden";
import General from "./General";
import Locations from "./Locations";

const Index = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [menu, setMenu] = useState(getSearchParam(searchParams, "menu", NoticeTimeBottomNavigationActions.General));

    return (
        <>

            {(() => {
                switch (menu) {
                    case NoticeTimeBottomNavigationActions.Locations:
                        return (<Locations menu={menu} />);

                    case NoticeTimeBottomNavigationActions.Overridden:
                        return (<Overridden/>);

                    default:
                        return (<General/>);
                }
            })()}


            <NoticeTimeBottomNavigation menu={menu} onClick={onMenuClick} />
        </>
    )

    function onMenuClick(e) {
        setSearchParams({
            menu: e
        }, { replace: true });

        setMenu(e);
    }
}

export default Index;
