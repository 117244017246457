import React from 'react';
import './slide.css'

function Slide({
    children,
    show}) {

    return (
        <div className={`slide-grid ${show ? 'slide-grid-active' : ''}`}>
            <div>
                {children}
            </div>
        </div>
    );
}

export default Slide; 