import './layout.css';

import React, { useRef, useState } from 'react';
import { NewVersion } from './NewVersion';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { load, loadMessages } from '@progress/kendo-react-intl';
import weekData from 'cldr-core/supplemental/weekData.json';
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json';
import nbMessages from '../../nb.json';
import Header from './header/Header';
import AppContent from './AppContent';
import Notifications from './Notifications';
import { NavigationBurgerMenu } from './navigation/NavigationBurgerMenu';
import { isLoggedIn } from '../helpers/AuthHelpers';
import { Ripple } from '@progress/kendo-react-ripple';

load(
    weekData,
    nbCaGregorian,
    nbDateFields
);
loadMessages(nbMessages, 'nb-NO');

export default function Layout({ children }) { 

    const menuRef = useRef();
    const newVersionRef = useRef();
    const selectedLocale = { language: 'nb-NO', locale: 'nb' };
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

    return (
        <React.Fragment>
            <LocalizationProvider language={selectedLocale.language}>
                <IntlProvider locale={selectedLocale.locale}>
                    <Notifications />
                    <Ripple>
                        <div>
                            <NewVersion
                                ref={newVersionRef}
                                isNewVersionAvailable={isNewVersionAvailable}
                                setIsNewVersionAvailable={setIsNewVersionAvailable}
                            />
                            {
                                isLoggedIn() &&
                                <NavigationBurgerMenu
                                    ref={menuRef}
                                    newVersionRef={newVersionRef}
                                    isNewVersionAvailable={isNewVersionAvailable}
                                    setIsNewVersionAvailable={setIsNewVersionAvailable}
                                />
                            }
                            <Header burgerButtonClick={burgerButtonClick} />
                            <div id="outer-container">
                                <div/>
                                <AppContent>
                                    {children}
                                </AppContent>
                            </div>
                        </div>
                    </Ripple>
                </IntlProvider>
            </LocalizationProvider>
        </React.Fragment>
    );

    function burgerButtonClick() {
        if (!menuRef.current) return;
        menuRef.current.toggle();
    }

}
