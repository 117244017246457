import React, { useState } from 'react';
import LocationIdbRepository from '../../../repositories/idb/LocationIdbRepository';
import { Dialog } from './Dialog';
import { DialogProperties } from './DialogProperties';
import AppInsightsService from '../../../services/AppInsightsService';
import { isNullOrEmpty, isObjectNull } from '../../helpers/ObjectHelpers';

export const LocationSearchDialog = ({ onClose, callback, locationBefore = null, locationAfter = null }) => {

    const [locations, setLocations] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                mode: "search",
                placeholder: "S&oslash;k i lokasjoner...",
                className: "dialog-title",
                onSearch: onSearchAsync,
                onClose: onClose,
                onSelect: handleSelectLocation,
                items: locations,
                hasSearched: hasSearched,
                description: (!isObjectNull(locationBefore) && !isObjectNull(locationAfter)) &&
                    `Lokasjonen legges mellom <b>${locationBefore.name}</b> og <b>${locationAfter.name}</b>`
            }}/>
    );

    async function onSearchAsync(e) {
        
        if (isNullOrEmpty(e.target.value)) {
            setLocations([]);
            setHasSearched(false);
        } else {
            const result = [];
            const dtos = await LocationIdbRepository.searchAsync(e.target.value);

            for (let i = 0; i < dtos.length; i++) {
                result.push({
                    name: dtos[i].name,
                    source: dtos[i]
                })
            }

            setLocations(result);
            setHasSearched(true);
        }
    }

    function handleSelectLocation(location) {
        try {
            callback(location, locationBefore);
            onClose();
        } catch (e) {
            AppInsightsService.trackException(e);
            console.warn(e);
        }
    }
}
