
function getRandomCoordinates(radius, uniform) {
    // Generate two random numbers
    var a = Math.random(),
        b = Math.random();

    // Flip for more uniformity.
    if (uniform) {
        if (b < a) {
            var c = b;
            b = a;
            a = c;
        }
    }

    // It's all triangles.
    return [
        b * radius * Math.cos(2 * Math.PI * a / b),
        b * radius * Math.sin(2 * Math.PI * a / b)
    ];
}

function getRandomLocation(latitude, longitude, radiusInMeters) {

    var randomCoordinates = getRandomCoordinates(radiusInMeters, true);

    // Earths radius in meters via WGS 84 model.
    var earth = 6378137;

    // Offsets in meters.
    var northOffset = randomCoordinates[0],
        eastOffset = randomCoordinates[1];

    // Offset coordinates in radians.
    var offsetLatitude = northOffset / earth,
        offsetLongitude = eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));

    // Offset position in decimal degrees.
    return [
        latitude + (offsetLatitude * (180 / Math.PI)),
        longitude + (offsetLongitude * (180 / Math.PI))
    ];
}

function fromPointToLatLng(point) {
    return {
        lat: (2 * Math.atan(Math.exp((point.y - 128) / -(256 / (2 * Math.PI)))) -
            Math.PI / 2) / (Math.PI / 180),
        lng: (point.x - 128) / (256 / 360)
    };
}

function normalizeTile(tile) {
    let t = Math.pow(2, tile.z);
    tile.x = ((tile.x % t) + t) % t;
    tile.y = ((tile.y % t) + t) % t;
    return tile;
}

function getTileBounds(tile) {
    tile = normalizeTile(tile);
    let t = Math.pow(2, tile.z),
        s = 256 / t,
        sw = {
            x: tile.x * s,
            y: (tile.y * s) + s
        },
        ne = {
            x: tile.x * s + s,
            y: (tile.y * s)
        };
    return {
        sw: fromPointToLatLng(sw),
        ne: fromPointToLatLng(ne)
    }
}

function getCenter(x1, x2, y1, y2) {
    return [(y1 + y2) / 2, (x1 + x2) / 2];
}

function getCenterLatLng(x1, x2, y1, y2) {
    return { lat: ((y1 + y2) / 2), lng: ((x1 + x2) / 2) };
}

function getTileUrl(coordinates, zoom) {
    const tile = normalizeTile({ x: coordinates.x, y: coordinates.y, z: zoom })
    const tileBounds = getTileBounds(tile);

    const box = `${tileBounds.sw.lng},${tileBounds.sw.lat},${tileBounds.ne.lng},${tileBounds.ne.lat}`;

    // todo: add seamap wms layer to indexeddb -> MetaStore
    return `https://wms.geonorge.no/skwms1/wms.sjokartraster2?service=wms&version=1.3.0&request=GetMap&SRS=EPSG:4326&FORMAT=image/png&BGCOLOR=0xFFFFFF&TRANSPARENT=TRUE&LAYERS=all&VERSION=1.1.1&WIDTH=256&HEIGHT=256&BBOX=${box}`
}

function calculateDistance(lat1, lon1, lat2, lon2, unit = "N") {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        return dist;
    }
}

function processPoints(geometry, callback, thisArg) {
    if (geometry instanceof window.google.maps.LatLng) {
        callback.call(thisArg, geometry);
    } else if (geometry instanceof window.google.maps.Data.Point) {
        callback.call(thisArg, geometry.get());
    } else {
        geometry.getArray().forEach(function (g) {
            processPoints(g, callback, thisArg);
        });
    }
}

export {
    getRandomCoordinates,
    getRandomLocation,
    fromPointToLatLng,
    normalizeTile,
    getTileBounds,
    getCenter,
    getCenterLatLng,
    getTileUrl,
    calculateDistance,
    processPoints
}