import "./quaymanual.css"

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import PanelBar from "../../components/layout/PanelBar";
import QuayObservation from "./QuayObservation";
import ContentGrid from '../../components/layout/ContentGrid';
import QuayDocumentCard from "./components/QuayDocumentCard";
import Spacer from "../../components/layout/Spacer";
import Slide from "../../components/layout/Slide";
import QuayPDF from "./components/QuayPDF";
import { publishHeaderTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import { NoContentMessage } from "../../components/layout/NoContentMessage";

const QuayLocations = () => {

    const [locations, setLocations] = useState([]);
    const {department, station} = useParams();
    const [noLocations, setNoLocations] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const initializeAsync = async () => {
        var response = await QuayManualApiRepository.getLocations(station);
        if(response.ok)
        {
            var result = (await response.json()).sort((a,b) => a.name > b.name ? 1 : -1);
            if(result === null || result.length === 0)
            {
                setNoLocations(true);
            }
            setLocations(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
    };

    useEffect(() => {
        publishHeaderTopic("Kaibok");
        initializeAsync();
    }, []);

    return (
        <ContentGrid title="Kaibok" titleIcon="book">
            {noLocations ?
                <NoContentMessage message="Det finnes ingen kaibøker for denne losstasjonen."/>
                :
                locations.map((item) => (
                    <PanelBar key={item.locationId} title={item.name} className="quaymanual-panelbar">
                        {item.documents.map((doc) => (
                            <PanelBar key={doc.binaryContentId} title={doc.name} className="sub-expansion-panel">
                                <QuayDocumentCard
                                    onClick={() => setShowPdf(!showPdf)}
                                    doc={doc} />
                                <Slide show={showPdf}>
                                    <QuayPDF documentId={showPdf ? doc.binaryContentId : 0 } />
                                </Slide>
                                <Spacer height={3}/>
                                <QuayObservation doc={doc} />
                            </PanelBar>
                        ))}
                    </PanelBar>
                ))
            }
        </ContentGrid>
    );
};

export default QuayLocations;
