import './counterfieldwrapper.css'

import React from 'react';
import parse from 'html-react-parser';
import {
	FieldWrapper,
} from "@progress/kendo-react-form";
import {
	Label,
} from "@progress/kendo-react-labels";
import {
    TextBox,
} from "@progress/kendo-react-inputs";
import {
    Button
} from "@progress/kendo-react-buttons";
import { AddIcon, RemoveIcon } from '../icons/Icons';
import { Error } from '../labels/Error';

function CounterFieldWrapper({
	title = "",
	value = 0,
	disabled = false,
    onDecrease,
    onIncrease,
	warnings = []
}) {

	return (
		<FieldWrapper>
			<Label className="k-form-label">
				{parse(title)}:
			</Label>
            <div className="k-form-field-wrap">
                <div className="counter-input">
                    <div>
                        <Button
                            svgIcon={RemoveIcon}
                            disabled={disabled}
                            onClick={onDecrease}
                        />
                    </div>
                    <div>
                        <TextBox
                            value={value}
                            disabled={true} />
                    </div>
                    <div>
                        <Button
                            disabled={disabled}
                            svgIcon={AddIcon}
                            onClick={onIncrease}
                        />
                    </div>
                </div>
                
			</div>
			{
                warnings.map((warning, index) =>
                    <Error key={index}>
						{parse(warning)}
					</Error>
				)
			}
		</FieldWrapper>
	);
}

export default CounterFieldWrapper; 
