import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';
import MetaIdbRepository from './MetaIdbRepository';
import Moment from 'moment';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../components/helpers/ObjectHelpers';
import { isPilotAssignmentCompleted } from '../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
import { getLoggedInPersonId } from '../../components/helpers/AuthHelpers';

const Store = IndexedDbNames.PilotagePilotStore;

const PilotAssignmentIdbRepository = {

    async getAllAsync() {
        const result = await BaseIdbRepository.getAllAsync(Store);

        if (isArrayEmpty(result)) return [];

        const personId = getLoggedInPersonId();

        return result.filter((pp) => pp.pilotPersonId === personId);
    },

    async setAsync(pilotAssignment) {
        const personId = getLoggedInPersonId();
        if (personId !== pilotAssignment.pilotPersonId) return;

        await BaseIdbRepository.setAsync(Store, pilotAssignment.pilotagePilotId, pilotAssignment);
    },

    async getAsync(id) {
        const pilotAssignment = await BaseIdbRepository.getAsync(Store, id);
        if (isObjectNull(pilotAssignment)) return null;

        const personId = getLoggedInPersonId();
        if (personId !== pilotAssignment.pilotPersonId) return null;

        return pilotAssignment;
    },

    async getByPilotageIdAsync(pilotageId) {
        const pilotAssignments = await this.getAllAsync();
        const pilotAssignment = pilotAssignments.find((pp) => pp.pilotage.pilotageId === pilotageId);

        if (isObjectNull(pilotAssignment)) return null;

        return pilotAssignment;
    },

    async getAllActualAsync() {
        const pilotAssignments = await this.getAllAsync();

        if (isArrayEmpty(pilotAssignments)) return [];

        return pilotAssignments.filter((pp) => !isPilotAssignmentCompleted(pp));
    },

    async deleteAsync(pilotAssignment) {
        const personId = getLoggedInPersonId();
        if (personId !== pilotAssignment.pilotPersonId) return;

        await BaseIdbRepository.deleteAsync(Store, pilotAssignment.pilotagePilotId);
    },

    async deleteAllAsync() {
        const dtos = await this.getAllAsync();
        for (let dto of dtos) {
            await BaseIdbRepository.deleteAsync(Store, dto.pilotagePilotId);
        }
    },

    async getAllExecutedAsync() {
        const pilotAssignments = await BaseIdbRepository.getAllAsync(Store);
        const personId = getLoggedInPersonId(); 

        return pilotAssignments.filter((pp) => isPilotAssignmentCompleted(pp) && pp.pilotPersonId === personId);
    },

    async cleanUpAsync() {
        const config = await MetaIdbRepository.getConfigurationAsync();
        const pilotAssignments = await BaseIdbRepository.getAllAsync(Store);

        if (isArrayEmpty(pilotAssignments)) return;

        const personId = getLoggedInPersonId();

        for (let pilotAssignment of pilotAssignments) {

            if (pilotAssignment.pilotPersonId === personId) continue;
            
            const diff = Moment().diff(Moment(pilotAssignment.modifiedDate), 'days');
            if (diff < config.pilotagePilotGracePeriod) continue;
            
            await BaseIdbRepository.deleteAsync(Store, pilotAssignment.pilotagePilotId);
        }
    },

    async searchAsync(searchText) {

        if (isNullOrEmpty(searchText)) return []

        if (searchText.length < 3) return [];

        const all = await this.getAllAsync();

        const shipNameResult = all.filter(pp => pp.pilotage.pilotageShip.shipName.toUpperCase().includes(searchText.toUpperCase()));
        const locationFromResult = all.filter(pp => pp.pilotage.fromLocation.name.toUpperCase().includes(searchText.toUpperCase()));
        const locationToResult = all.filter(pp => pp.pilotage.toLocation.name.toUpperCase().includes(searchText.toUpperCase()));
        const pilotageNoResult = all.filter(pp => pp.pilotage.pilotageNo.toString().includes(searchText));

        let searchResult = this.addItemsToSearchResult([], shipNameResult);
        searchResult = this.addItemsToSearchResult(searchResult, locationFromResult);
        searchResult = this.addItemsToSearchResult(searchResult, locationToResult);
        searchResult = this.addItemsToSearchResult(searchResult, pilotageNoResult);

        
        return searchResult;
    },

    addItemsToSearchResult(searchResult, items) {

        for (let item of items) {
            if (searchResult.some(pp => pp.pilotagePilotId === item.pilotagePilotId)) continue;
            searchResult.push(item);
        }

        return searchResult;
    }
};

export default PilotAssignmentIdbRepository;
