import React, { useEffect, useState } from 'react';
import "./styles/evaluation.css"

import {
    PageStates,
} from '../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import Spacer from '../../components/layout/Spacer';
import EvaluationService from './services/EvaluationService';
import PDFViewer from '../../components/layout/PDFViewer';
import EvaluationApiRepository from '../../repositories/api/EvaluationApiRepository';
import EvaluationIdbRepository from '../../repositories/idb/EvaluationIdbRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import EvaluationSearch from './components/EvaluationSearch';
import EvaluationList from './components/EvaluationList';
import { DialogConfirm } from '../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../components/layout/dialogs/DialogProperties';
import { isArrayEmpty } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { scrollElementIntoView } from '../../components/helpers/ElementHelpers';
import { EvaluationListItemStates } from './services/EvaluationHelpers';

export default function Index() {

    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErros] = useState(false);
    const [pageState, setPageState] = useState(PageStates.Default);
    const [archivedEvaluations, setArchivedEvaluations] = useState([]);
    const [completedEvaluations, setCompletedEvaluations] = useState([]);
    const [startedEvaluations, setStartedEvaluations] = useState([]);
    const [newEvaluations, setNewEvaluations] = useState([]);
    const [evaluation, setEvaluation] = useState(null);
    const [listItemIndex, setListItemIndex] = useState(0);
    const [pdfDocument, setPdfDocument] = useState(null);
    const [isRetracting, setIsRetracting] = useState(false);

    // Initialize function
    useEffect(() => {
        publishHeaderTopic("Evalueringer");
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <>
            <ContentGrid title="Evalueringer" titleIcon="evaluation">

                {(() => {
                    switch (pageState) {
                        case PageStates.Default:
                            return (
                                <>
                                    {
                                    (
                                        isArrayEmpty(newEvaluations) &&
                                        isArrayEmpty(startedEvaluations) &&
                                        isArrayEmpty(completedEvaluations)
                                    ) ?
                                        <div>
                                            <div className="default-content-sub-title">Du har ingen aktive evalueringer</div>
                                            <Spacer height={70} />
                                        </div>
                                        :
                                        <>
                                            <EvaluationList
                                                evaluations={newEvaluations}
                                                onClick={onListItemClick}
                                                onShowDocumentClick={onListItemShowDocumentClickAsync}
                                                title="Ny"
                                            />
                                            <EvaluationList
                                                evaluations={startedEvaluations}
                                                onClick={onListItemClick}
                                                onShowDocumentClick={onListItemShowDocumentClickAsync}
                                                title="P&aring;begynt"
                                                addTopSpacer={true}
                                            />
                                            <EvaluationList
                                                evaluations={completedEvaluations}
                                                onClick={onListItemClick}
                                                onShowDocumentClick={onListItemShowDocumentClickAsync}
                                                title="Utf&oslash;rt"
                                                addTopSpacer={true}
                                            />
                                        </>
                                    }
                                    <Spacer height={70} />

                                    <EvaluationSearch
                                        archivedEvaluations={archivedEvaluations}
                                        onClick={onListItemClick}
                                        onShowDocumentClick={onListItemShowDocumentClickAsync}
                                    />
                                </>
                            );
                        case PageStates.Pdf:
                            return (<PDFViewer className="pdf-viewer" data={pdfDocument} onCloseClick={onClosePdfClick} />);

                        default:
                            return null;
                    }
                })()}
            </ContentGrid>

            <Overlay
                isBusy={isBusy}
                onReloadClick={onReloadClick}
                hasErrors={hasErrors} />

            {
                isRetracting &&
                    <DialogConfirm properties={{
                        ...DialogProperties,
                        title: "Endre evaluering",
                        size: "xsmall",
                        onClose: handleCancelRetractClick,
                        onClick: onRetractClickAsync,
                        clickText: "Endre"
                    }}>
                        Evalueringen er klar for saksbehandling.<br />
                        Vi du endre p&aring; en fullf&oslash;rt evaluering?
                    </DialogConfirm>
            }
        </>
    );

    async function initializeAsync () {

        if (navigator.onLine) {

            const emptyFormResponse = await EvaluationApiRepository.getEmptyAsync();
            if (emptyFormResponse.ok === true) {
                const emptyFormData = await emptyFormResponse.json();
                await EvaluationService.setEmptyEvaluationFormAsync(emptyFormData);
            } else {
                handleError(emptyFormResponse, "Kunne ikke hente inn tomt evalueringsskjema");
                return;
            }

            const response = await EvaluationApiRepository.getMineAsync();

            if (response.ok === true) {
                const data = await response.json();
                processEvaluationsAsync(data);

            } else {
                handleError(response, "En feil oppstod ved behandling av evalueringer.");
            }
        } else {
            const data = await EvaluationIdbRepository.getAllAsync();
            processEvaluationsAsync(data);
        }

        setIsBusy(false);
    }

    async function retractAsync() {

        setIsBusy(true);
        setIsRetracting(false);

        const response = await EvaluationApiRepository.retractAsync(evaluation);

        if (response.ok === true) {
            const data = await response.json();
            await EvaluationService.updateEvaluationAsync(data);
            initializeAsync();
        } else {
            handleError(response, "En feil oppstod ved tilbaketrekning evaluering.");
        }

        setIsBusy(false);
    }

    async function processEvaluationsAsync(evaluations) {
        const processedEvaluations = await EvaluationService.processEvaluationsAsync(evaluations);
        
        setArchivedEvaluations(processedEvaluations.archivedEvaluations);
        setCompletedEvaluations(processedEvaluations.completedEvaluations);
        setStartedEvaluations(processedEvaluations.startedEvaluations);
        setNewEvaluations(processedEvaluations.newEvaluations);
    }

    function onListItemClick(evaluation, itemState) {
        
        if (evaluation.isCompleted) {
            setEvaluation(evaluation);
            setIsRetracting(true);
        } else {
            switch (itemState) {
                case EvaluationListItemStates.Default:
                    navigate(EvaluationService.getEvaluationEditUrl(evaluation));
                    break;
                case EvaluationListItemStates.Editing:
                    navigate('/evaluation');
                    break;
                default:
                    break;
            }
        }
    }

    async function onListItemShowDocumentClickAsync(evaluation, index = 0) {
        if (!evaluation.isCompleted) return;

        setIsBusy(true);
        setListItemIndex(index);

        const response = await EvaluationApiRepository.getPdfDocumentAsync(evaluation);

        if (response.ok === true) {
            const data = await response.json();

            setPdfDocument(data);
            setPageState(PageStates.Pdf);
        } else {
            handleError(response, "Kunne ikke generere pdf for evaluering.");
        }

        setIsBusy(false);
    }

    function onClosePdfClick() {
        setPageState(PageStates.Default);

        if (listItemIndex === 0) return;

        setTimeout(function () {
            const id = `eval-item-${listItemIndex}`
            scrollElementIntoView(id);
        }, 125);
    }

    function handleCancelRetractClick() {
        setIsRetracting(false);
    }

    async function onRetractClickAsync() {
        await retractAsync();
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErros(false);

        initializeAsync();
    }

    function handleError(response, warning) {
        publishWarningNotificationTopic(warning, response.status);
        setHasErros(true);
    }
}
