import React from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { loadMessages } from '@progress/kendo-react-intl';
import nbMessages from '../../nb.json';
import { NumericTextBox as KendoNumericTextBox } from "@progress/kendo-react-inputs";

loadMessages(nbMessages, 'nb-NO');

export function NumericTextBox({ value = null, onChange }) {

    return (
        <LocalizationProvider language='nb-NO'>
            <IntlProvider locale='en'>
                <KendoNumericTextBox min={0} value={value} onChange={onChange} />
            </IntlProvider>
        </LocalizationProvider>
    );
}

