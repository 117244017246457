import { useEffect, useState } from "react";
import PilotStationApiRepository from '../../../repositories/api/PilotStationApiRepository';
import "./favouriteStations.css"
import Icons from "../icons/Icons";
import { IconColors } from "../../../services/SystemNames";
import { publishWarningNotificationTopic } from "../../helpers/PubSubHelpers";

const FavouriteStations = () => {
    
    const [stations, setStations] = useState([]);
    
    const getStations = async () => {
        var response = await PilotStationApiRepository.getStations();
        if(response.ok)
        {
            var result = await response.json();
            setStations(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
    };

    const setStationFavourite = async (station) => {
        var response = await PilotStationApiRepository.setFavouriteStation(station);
        if(response.ok)
        {
            const nextStations = stations.map((s) => {
                if (s.departmentID === station.departmentID) {
                    s.isFavourite = station.isFavourite;
                    return s;
                } else {
                    return s;
                }
            })
            setStations(nextStations);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
    };

    const toggleStationFavourite = (station) => {
        station.isFavourite = !station.isFavourite;
        setStationFavourite(station)
    };

    useEffect(() => {
        getStations();
    }, []);

    return (
        <div className="favourites-table">
            {stations.map((station, s) => (
                <div key={s}
                    style={{ backgroundColor: station.isFavourite ? "var(--favourites-active-bg)" : "var(--favourites-bg)" }}
                    className="favourites-row"
                    onClick={() => { toggleStationFavourite(station) }}>
                    <div className="favourites-name">
                        {station.departmentName}
                    </div>
                    <div className="favourites-icon">
                        <Icons className="pointer" color={IconColors.Active} iconName={station.isFavourite ? "star" : "starOutline"} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FavouriteStations;
