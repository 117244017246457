import React, { useImperativeHandle, useState } from 'react';
import parse from 'html-react-parser';
import Spacer from '../../../components/layout/Spacer';
import { IconColors } from '../../../services/SystemNames';
import { useEffectOnce } from '../../../components/useEffectOnce';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import "../styles/pcs.css"
import TitleContainer from '../../../components/layout/TitleContainer';
import PanelBar from '../../../components/layout/PanelBar';
import { RadioButton } from "@progress/kendo-react-inputs";
import Icons from '../../../components/layout/icons/Icons';
import { isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { DialogConfirm } from '../../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { Button } from "@progress/kendo-react-buttons";
import Switch from '../../../components/layout/switch';
import { forwardRef } from 'react';

export const EvaluationCard = forwardRef((
    {
    setIsBusy,
    evaluation,
    handleError,
    onCancelClick,
    onSaved
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onDelete() {
            if (isObjectNull(evaluation)) return;
            if (evaluation.id === 0) return;
            setShowDialog(true);
        },
    }));

    const [canSave, setCanSave] = useState(false);
    const [canComplete, setCanComplete] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isCompleted, setIsCompleted] = useState(evaluation.isCompleted);

    // Initialize function
    useEffectOnce(() => {
        updateTotalCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            <div className="pcs-evaluation-grid">
                <div className="pcs-evaluation-form">
                    {evaluation.sections.map((section, index) =>
                        <EvaluationSection key={index} section={section} onSelectAlternative={onSelectAlternative} />
                    )}
                    <Spacer height={15} />
                </div>
                <div className="pcs-evaluation-form-controls">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <Switch
                                    onLabel="Fullf&oslash;r"
                                    disabled={!canComplete}
                                    checked={isCompleted}
                                    onChange={onCompleteChange}
                                />
                            </div>
                            <div className="col-auto">
                                <Button
                                    onClick={onCancelClick}>
                                    Avbryt
                                </Button>
                            </div>
                            <div className="col-auto">
                                <Button
                                    disabled={!canSave}
                                    onClick={onSaveClick}
                                    themeColor="primary">
                                    Lagre
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showDialog &&
                <EvaluationDelete
                    onCancelClick={() => setShowDialog(false)}
                    onSaveClick={deleteAsync} />
            }
        </>

    );

    function onSelectAlternative(section, subject, alternative) {
        let currentSection = evaluation.sections.find(s => s.id === section.id);
        let currentSubject = currentSection.subjects.find(s => s.id === subject.id);

        currentSubject.selectedAlternative = alternative;

        updateTotalCount();
        setCanSave(true);
    }

    function onSaveClick() {
        if (!canSave) return;
        saveAsync();
    }

    function onCompleteChange(e) {
        setIsCompleted(e.value);
        evaluation.isCompleted = e.value;
        setCanSave(true);
    }

    async function saveAsync() {
        setIsBusy(true);

        const response = await PcsApiRepository.setGroupEvaluationAsync(evaluation);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Evaluering for sertifikaromr&aring;det ${evaluation.groupName} ble lagret.`);
            onSaved();
        } else {
            setIsBusy(false);
            handleError(response);
            publishWarningNotificationTopic(`Evaluering for sertifikaromr&aring;det ${evaluation.groupName} ble ikke lagret.`);
        }
    }

    async function deleteAsync() {

        setShowDialog(false);
        setIsBusy(true);

        const response = await PcsApiRepository.deleteGroupEvaluationAsync(evaluation);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Evaluering for sertifikaromr&aring;det ${evaluation.groupName} ble slettet.`);
            onSaved();

        } else {
            handleError(response);
        }
    }

    function updateTotalCount() {
        const sections = evaluation.sections;
        let current = 0;
        let total = 0;

        for (let i = 0; i < sections.length; i++) {
            const subjects = sections[i].subjects;
            for (let j = 0; j < subjects.length; j++) {
                const subject = subjects[j];

                current += subject.selectedAlternative === null ? 0 : 1;
                total += 1;
            }
        }
        setCanComplete(current === total);
    }
})

const EvaluationSection = ({
    section,
    onSelectAlternative
}) => {

    const totalSubjects = section.subjects.length;
    const [subjectsCompleted, setSubjectsCompleted] = useState(getSubjectsCompleted());

    return (
        <div>
            <Spacer height={15} />
            <PanelBar
                title={section.sectionName}
                subTitle={`${subjectsCompleted}/${totalSubjects}`}>
                <div>
                    {section.subjects.map((subject, index) =>
                        <EvaluationSubject
                            key={index}
                            subject={subject}
                            onSelectAlternative={onSelect} />
                    )}
                </div>    
            </PanelBar>
        </div>
    );

    function onSelect(subject, alternative, increase) {
        if (increase) {
            setSubjectsCompleted(subjectsCompleted + 1);
        }

        onSelectAlternative(section, subject, alternative);
    }

    function getSubjectsCompleted() {
        let completedCount = 0;

        for (var i = 0; i < section.subjects.length; i++) {
            const subject = section.subjects[i];
            if (subject.selectedAlternative !== null) {
                completedCount = completedCount + 1;
            }
        }

        return completedCount;
    }
}

const EvaluationSubject = ({
    subject,
    onSelectAlternative
}) => {

    const [selectedAlternativeId, setSelectedAlternativeId] =
        useState(subject.selectedAlternative !== null ? subject.selectedAlternative.id : 0);
    const [tooltip, setTooltip] = useState(false);

    return (
        <div className="pcs-evaluation-subject">
            <div>
                {parse(subject.subjectName)}
            </div>
            <div>
                {parse(subject.description)}
            </div>
            <div>
                <div>
                    {subject.alternatives.map((alternative, index) =>
                        <EvaluationSubjectAlternative
                            key={index}
                            alternative={alternative}
                            onMouseOver={setTooltip}
                            onMouseOut={() => setTooltip("")}
                            selectedAlternativeId={selectedAlternativeId}
                            onSelect={onSelect}
                        />
                    )}
                </div>
                {
                    (!isNullOrEmpty(tooltip)) &&
                    <div className="pcs-evaluation-form-subject-info">
                        {tooltip}
                    </div>
                }
            </div>
        </div>
    );

    function onSelect(alternative) {
        setSelectedAlternativeId(alternative.id)
        onSelectAlternative(subject, alternative, selectedAlternativeId === 0);
    }
}

const EvaluationSubjectAlternative = ({ alternative, selectedAlternativeId, onSelect, onMouseOver, onMouseOut }) => {
    return (
        <div>
            <Spacer height={10} />
            <div
                className="pcs-evaluation-radiobutton"
                onMouseOver={() => onMouseOver(alternative.description)}
                onMouseOut={onMouseOut}>
                <div>
                    <RadioButton
                        size="large"
                        label={alternative.alternativeName}
                        checked={(alternative.id === selectedAlternativeId)}
                        onClick={() => onSelect(alternative)}
                        />
                </div>
                {
                    (!isNullOrEmpty(alternative.description)) &&
                    <div>
                            <Icons color={IconColors.Secondary} iconName="information" />
                    </div>  
                }
            </div>
        </div>
    )
}

const EvaluationDelete = (
    {
        onCancelClick,
        onSaveClick
    }
) => {
    return (
        <DialogConfirm properties={{
            ...DialogProperties,
            title: "Slett evaluering",
            text: "&Oslash;nsker du &aring; slette evalueringen?",
            onClose: onCancelClick,
            onClick: onSaveClick
        } }/>
    );
}
