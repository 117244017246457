import { createContext, useContext } from 'react';

const TimelineViewContext = createContext();

export function useTimelineViewContext() {
    const context = useContext(TimelineViewContext);
    if (!context) {
        throw new Error(
            'TimelineViewContext.* component must be rendered as child of TimelineViewContext component'
        );
    }
    return context;
}

export default TimelineViewContext;