/*eslint unicode-bom: ["error", "always"]*/

import React from 'react';
import Overlay from '../layout/overlay/Overlay';

function ValidateAccess() {
    return (
        <Overlay isBusy={true} busyText={"Sjekker tilgang!<br/>Vennligst vent..."} />
    );
}

export default ValidateAccess;