import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import { HourCompensationDialog } from './dialogs/HourCompensationDialog';
import Moment from 'moment';
import uuid from 'react-uuid';
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import TitleContainer from '../../../../components/layout/TitleContainer';
import { CardList } from '../../../../components/layout/card/CardList';
import { CardModes, CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Spacer from '../../../../components/layout/Spacer';
import { formatStringToList, isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { formatMomentToDefault } from '../../../../components/helpers/DateTimeHelpers';
import { addDeletePilotHourCompensationCommand, addPilotHourCompensationCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { getPilotAssignmentCompensationId, getPilotAssignmentFilteredCompensations, isPilotAssignmentEditable, toggleSelectedItem } from '../helpers/PilotAssignmentHelpers';

export const HourCompensationCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [compensation, setCompensation] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [cards, setCards] = useState([]);
    const [toggleActivateSelect, setToggleActivateSelect] = useState(false);
    const [selectedCompensations, setSelectedCompensations] = useState([]);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);

        initializeAsync();

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TitleContainer
                title="C-tillegg"
                error={toggleActivateSelect && selectedCompensations.some(c => c.selected)}
                actions={getTitleActions()}
            />

            <CardList
                mode={CardModes.Multible}
                cards={cards}
                toggleActivateSelect={setToggleActivateSelect}
                toggleSelectItem={toggleSelectItem}
                disabled={!isEditable}
            />

            {
                showDialog &&
                <HourCompensationDialog
                    onClose={() => setShowDialog(false)}
                    compensation={compensation}
                    callback={onCallback}
                />
            }
            
            {
                showConfirmationDialog && 
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Slett C-tillegg",
                        text: "&Oslash;nsker du &aring; slette C-tillegg?",
                        onClose: () => setShowConfirmationDialog(false),
                        onClick: onConfirmCallbackAsync
                    }}
                    
                />
            }
            
        </>
    )

    function getTitleActions() {
        const actions = [];

        if (isEditable) {
            if (toggleActivateSelect) {
                actions.push(
                    {
                        icon: "trash",
                        onClick: onDeleteSelectedClick,
                        iconColor: "white"
                    });
            } else {
                actions.push(
                    {
                        icon: "plus",
                        onClick: () => {
                            setCompensation(null);
                            setShowDialog(true);
                        },
                        iconColor: "white"
                    });
            }
        }

        return actions;
    }
    
    async function getPilotAssignmentAsync() {
        return await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function onUpdateAsync(pilotAssignment) {
        await onUpdatePilotAssignmentAsync(pilotAssignment);
        initializeAsync();
    }

    async function initializeAsync() {

        const pilotAssignment = await getPilotAssignmentAsync();
        const isEditable = isPilotAssignmentEditable(pilotAssignment);
        setIsEditable(isEditable);

        const result = [];
        if (!isObjectNull(pilotAssignment.pilotagePilotCompensation)) {
            if (!isObjectNull(pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations)) {
                pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations.map((compensation) =>
                    result.push({
                        guid: uuid(),
                        properties: {
                            ...CardProperties,
                            id: getPilotAssignmentCompensationId(compensation, "pilotagePilotHourCompensationId"),
                            sourceId: getPilotAssignmentCompensationId(compensation, "pilotagePilotHourCompensationId"),
                            isDirty: !compensation.hasOwnProperty("pilotagePilotCompensationId"),
                            title: getCompensationTimeDescription(compensation),
                            titleClassName: "card-item-content-header-title-small",
                            mode: CardModes.Multible,
                            borderColor: "var(--card-swipe-border)",
                            backgroundColor: "var(--card-swipe-bg)",
                            onDeleteClick: isEditable ? () => onDeleteClick(compensation) : null,
                            onClick: isEditable ? () => {
                                setCompensation(compensation);
                                setShowDialog(true);
                            } : null
                        },
                        body: <HourCompensationContent compensation={compensation} />
                    }));
            }
        }

        setCards(result);
    }

    function onDeleteClick(compensation) {
        setCompensation(compensation);
        setShowConfirmationDialog(true);
    }

    function toggleSelectItem(id, selected) {
        const result = toggleSelectedItem(selectedCompensations, id, selected);
        setSelectedCompensations(result);
    }

    function onDeleteSelectedClick() {
        setCompensation(null);
        setShowConfirmationDialog(true);
    }

    function onCallback(obj) {
        
        switch (obj.command) {

            case PilotAssignmentCommands.AddPilotHourCompensation:
            case PilotAssignmentCommands.UpdatePilotHourCompensation:
                onPutPilotHourCompensationAsync(obj);
                break;

            case PilotAssignmentCommands.DeletePilotHourCompensation:
                onDeletePilotHourCompensationAsync(obj);
                break;


            default:
                return;
        }
    }

    async function onConfirmCallbackAsync() {

        if (isObjectNull(compensation)) {
            await onDeletePilotAssignmentHourCompensationsAsync();
        } else {
            await onDeletePilotHourCompensationAsync(compensation);
        }

        setShowConfirmationDialog(false);
        setSelectedCompensations([]);
        setToggleActivateSelect(false);
    }

    async function onPutPilotHourCompensationAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync();
        let compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations;
        let pilotagePilotHourCompensationId = 0

        if (isObjectNull(compensations)) {
            compensations = [];
        }

        switch (obj.command) {
            case PilotAssignmentCommands.AddPilotHourCompensation:
                const addCompensation = compensations.find(c => c.guid === obj.guid);
                if (!isObjectNull(addCompensation)) {
                    const addIndex = compensations.indexOf(addCompensation);
                    compensations[addIndex] = obj;
                } else {
                    compensations.push(obj);
                }
                break;

            default:
                const updateCompensation = compensations.find(c => c.pilotagePilotHourCompensationId === obj.pilotagePilotHourCompensationId);
                const updateIndex = compensations.indexOf(updateCompensation);

                compensations[updateIndex] = obj;

                pilotagePilotHourCompensationId = obj.pilotagePilotHourCompensationId;

                break;
        }

        pilotAssignment = addPilotHourCompensationCommand(pilotAssignment, obj, pilotagePilotHourCompensationId);

        await onUpdateAsync(pilotAssignment);
    }

    async function onDeletePilotHourCompensationAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync();
        const compensations = [...pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations];

        pilotAssignment = processDeleteHourCompensation(pilotAssignment, compensations, obj);

        await onUpdateAsync(pilotAssignment);
    }

    async function onDeletePilotAssignmentHourCompensationsAsync() {

        let pilotAssignment = await getPilotAssignmentAsync();
        
        for (let i = 0; i < selectedCompensations.length; i++) {
            const selectedCompensation = selectedCompensations[i];
            if (selectedCompensation.selected) {

                const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations;
                let objs = compensations
                    .filter(c =>
                        c.pilotagePilotHourCompensationId === selectedCompensation.id ||
                        c.guid === selectedCompensation.id);
                
                if (!isArrayEmpty(objs)) {
                    pilotAssignment = processDeleteHourCompensation(pilotAssignment, compensations, objs[0]);
                }
            }
        }
        await onUpdateAsync(pilotAssignment);
    }

    function processDeleteHourCompensation(pilotAssignment, compensations, obj) {

        pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations = 
            getPilotAssignmentFilteredCompensations(compensations, obj, "pilotagePilotHourCompensationId");

        return addDeletePilotHourCompensationCommand(pilotAssignment, obj);
    }

    function getCompensationTimeDescription(compensation) {
        const toTime = isNullOrEmpty(compensation.toTime) ?
            Moment(compensation.fromTime).add(compensation.number, "hours") : Moment(compensation.toTime);

        return `${formatMomentToDefault(compensation.fromTime)} &rarr; ${toTime.format('DD.MM HH:mm')}`;
    }
})

const HourCompensationContent = ({ compensation }) => {
    
    return (
        <>
            <div className="align-items-center">
                <div className="col">
                    {compensation.number} timer
                </div>
            </div>
            <div className="align-items-center">
                <div className="col">
                    {compensation.pilotagePilotHourCompensationName}
                </div>
            </div>
            <Spacer height={5} />
            <div className="row align-items-center">
                <div className="col">
                    <i>{formatStringToList(compensation.description)}</i>
                </div>
            </div>
        </>
    );
};
