/*eslint unicode-bom: ["error", "always"]*/

import React, { useEffect } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

function NoAccess() {

    const navigate = useNavigate();

    // Initialize function
    useEffect(() => {
        const redirectTimer = setInterval(() => navigate('/'), 15000); // Navigate to frontpage after 15 seconds

        return () => {
            clearInterval(redirectTimer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    return (
         <div className="home">
            <div className="welcome">
                <div className="title">Oops...!</div>
                <div className="description">
                    <p>
                        Du har ikke tilgang til modulen.
                    </p>
                    <p>
                        Vennligst ta kontakt med administrator.
                    </p>
                    <p>
                        Klikk <NavLink to="/">her</NavLink>  for å gå navigere til forsiden.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NoAccess;
