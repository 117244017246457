import './card.css'

export const CardService =
{
    getStyle(borderColor, backgroundColor) {
        return this.defaultStyle(borderColor, backgroundColor);
    },

    defaultStyle(borderColor, backgroundColor) {
        return {
            borderColor: borderColor,
            backgroundColor: backgroundColor
        }
    },

    leftStyle(tentative, borderColor, backgroundColor, isFat) {
        if (tentative) {
            return {
                backgroundImage: `repeating-linear-gradient(45deg,${borderColor},${borderColor} 10px,var(--tertiary-bg)0px,var(--tertiary-bg) 15px)`,
                border: 0
            }
        }
        return {
            borderColor: borderColor,
            backgroundColor: isFat ? borderColor : backgroundColor
        }
        
    }
};
