import React from 'react';
import {
    CardBody as KendoCardBody,
} from "@progress/kendo-react-layout";
import { isObjectNull } from '../../../helpers/ObjectHelpers';

export const CardContentBody = (
    {
        children,
        properties = null,
        touchHandlers
    }) => {
        

    return (
        (!isObjectNull(children)) &&
            <KendoCardBody className={`card-item-content-body ${getClassName()} ${properties.contentBodyClassName}`}>
                <div
                    {...touchHandlers}
                    style={{
                        paddingLeft: properties.isBorderLeftFat ? 10 : 2
                    }}
                >
                    {children}
                </div>
            </KendoCardBody>
    );

    function getClassName() {

        if (properties.hasHeaderContent()) return '';

        if (properties.canDelete()) return 'card-item-content-body-header-content'

        return '';
    }
};
