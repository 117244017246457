import { useEffect, useRef, useState } from "react";
import { PageStates } from "../../../services/SystemNames";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ModalImage from "react-modal-image";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Icons, { CancelIcon, OkIcon, PlusIcon } from "../../../components/layout/icons/Icons";
import { CardImage } from "@progress/kendo-react-layout";
import { TextArea } from "@progress/kendo-react-inputs";
import { isNullOrEmpty } from "../../../components/helpers/ObjectHelpers";
import { formatDateTimeForDisplay } from "../../../components/helpers/DateTimeHelpers";
import uuid from "react-uuid";
import { CardList } from "../../../components/layout/card/CardList";
import { CardModes, CardProperties } from "../../../components/layout/card/components/CardProperties";
import PullToRefresh from "react-simple-pull-to-refresh";
import FloatingButtonContainer from "../../../components/layout/FloatingButtonContainer";
import FloatingActionButton from "../../../components/layout/FloatingActionButton";
import { CardListEmpty } from "../../../components/layout/card/CardListEmpty";
import { publishErrorNotificationTopic } from "../../../components/helpers/PubSubHelpers";

export const PhoneLink = ({ phoneNumber, title }) => {
    return !isNullOrEmpty(phoneNumber) &&
        <>
            <div>{title}</div>
            <div> <a className="phone-link" href={`tel:${phoneNumber}`}>{phoneNumber}</a> </div>
        </>;
}

export const DialogIconTitle = ({ title, icon }) => {
    return (
        <div className="ob-dialog-title">
            {title}
            <div>
                {icon}
            </div>
        </div>
    );
};


export const FileUploader = ({ handleFile, title, inputType }) => {
    const hiddenFileInput = useRef(null);
    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        handleFile(event);
    };
    return (
        <>
            <Button className="ob-image-upload-button" onClick={handleClick}>
                {title}
            </Button>
            <input
                type="file"
                onChange={handleChange}
                id={inputType}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
        </>
    );
}

const SelectOrCaptureImageDialog = ({
    onImageSelected, isImageDialogVisible, onClose
}) => {
    const handleImageSelected = (e) => {
        onClose();
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imageUrl = reader.result;
                const imageType = file.type;
                const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];

                if (allowedFormats.includes(imageType)) {
                    const imageDimensions = await getImageDimensions(imageUrl);
                    onImageSelected(imageUrl, imageDimensions);
                } else {
                    publishErrorNotificationTopic("Ugyldig bildeformat. Kun JPG, JPEG og PNG er tillatt.");
                }
            };
            reader.readAsDataURL(file);
        }
};

    const getImageDimensions = async (imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
        await img.decode();
        return { width: img.width, height: img.height };
    };

    return (
        <>
            {isImageDialogVisible &&
                <Dialog className="select-capture-image-dialog" onClose={onClose} title="Velg bilde" >
                    <div>
                        Vil du laste opp et eksisterende eller ta et nytt bilde?
                    </div>
                    <DialogActionsBar>
                        <FileUploader handleFile={handleImageSelected} title={"Last opp"} inputType={"imageInput"} />
                        <FileUploader handleFile={handleImageSelected} title={"Ta et bilde"} inputType={"cameraInput"} />
                    </DialogActionsBar>
                </Dialog>
            }
        </>
    );
};

const ObservationImage = ({ capturedImage = "", handleImageSelected }) => {
    const [isImageDialogVisible, setIsImageDialogVisible] = useState(false);

    const toggleImageDialog = () => {
        setIsImageDialogVisible(!isImageDialogVisible);
    };

    return (
        <div className="ob-image-container">
            {capturedImage && (
                <ModalImage
                    className="ob-image"
                    small={capturedImage}
                    large={capturedImage}
                    alt="Observasjon"
                />
            )}
            {!capturedImage && (
                <Icons
                    className="ob-dummy-image"
                    iconName="dummy"
                    dimensions={128}
                    onClick={toggleImageDialog}
                    alt="Observasjon"
                />
            )}
            <SelectOrCaptureImageDialog
                isImageDialogVisible={isImageDialogVisible}
                onImageSelected={handleImageSelected}
                onClose={toggleImageDialog} />

            <div onClick={toggleImageDialog}>
                Klikk her for &aring; ta eller velge et bilde
            </div>
        </div>
    );
};

const ShipObservationBody = ({ shipObservation }) => {
    return (
        <>
            <div style={{ maxHeight: (window.innerHeight * 0.9) / 2, }}>
                {shipObservation.imageBase64Url && (
                    <div className="ob-image-container">
                        <CardImage className="ob-image"
                            style={{
                                objectFit: "contain",
                                paddingTop: "5px",
                                maxWidth: window.innerWidth * 0.9,
                                maxHeight: window.innerHeight * 0.25
                            }}
                            src={shipObservation.imageBase64Url}
                        />
                    </div>
                )}
                <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {shipObservation.observation}
                </div>
            </div>
        </>
    );
}

export const ObservationsList = ({
    shipObservations,
    isAllowedEdit,
    onAddObservation,
    onPullToRefresh,
    onDeleteObservation,
    onObservationClick,
    setTitle,
    closeAndCleanupState,
    width,
    height,
    title
}) => {
    const [cards, setCards] = useState([]);
    const [isDeleteActive, setIsDeleteActive] = useState(false);
    const [observationToBeDeleted, setObservationToBeDeleted] = useState({});

    const onDeleteClicked = (shipObservation) => {
        setObservationToBeDeleted(shipObservation);
        setIsDeleteActive(true);
    }
    useEffect(() => {
        setTitle();
        function generateCards() {
            const result = [];

            for (let i = 0; i < shipObservations.length; i++) {
                const shipObservation = shipObservations[i];
                const guid = uuid();

                result.push({
                    guid: guid,
                    properties: {
                        ...CardProperties,
                        id: guid,
                        sourceId: shipObservation.shipObservationId,
                        title: formatDateTimeForDisplay(shipObservation.modifiedByPerson.modifiedDate),
                        subTitle: shipObservation.modifiedByPerson.name,
                        borderColor: shipObservation.shipObservationType.color,
                        backgroundColor: "var(--tertiary-bg)",
                        className: "ob-card",
                        isBorderLeftFat: true,
                        canSelect: false,
                        toggleSelect: false,
                        onSelect: false,
                        toggleDelete: isAllowedEdit,
                        onClick: () => onObservationClick(shipObservation),
                        onDeleteClick: () => onDeleteClicked(shipObservation),
                        isSwipeEnabled: true
                    },
                    body: <ShipObservationBody shipObservation={shipObservation} />
                });
            }

            setCards(result);
        }

        generateCards();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipObservations]);

    return (
        <>
            {
                <Dialog
                    onClose={closeAndCleanupState}
                    width={width}
                    height={height}
                    title={title}
                >
                    {isDeleteActive ? (
                        <DeleteObservationDialog
                            resetState={() => setIsDeleteActive(false)}
                            onDeleteObservation={() =>
                                onDeleteObservation(observationToBeDeleted)
                            }
                        />
                    ) : (
                        <div>
                            <PullToRefresh
                                isPullable={true}
                                onRefresh={onPullToRefresh}
                                className="ob-card-list"
                            >
                                {isNullOrEmpty(cards) ? <CardListEmpty text="Ingen observasjoner" /> : <CardList mode={CardModes.Single} cards={cards} />}
                            </PullToRefresh>

                            <FloatingButtonContainer >
                                <FloatingActionButton
                                    disabled={!isAllowedEdit}
                                    themeColor={isAllowedEdit ? "primary" : "secondary"}
                                    svgIcon={PlusIcon}
                                    onClick={onAddObservation}
                                />
                            </FloatingButtonContainer>
                        </div>
                    )}
                </Dialog>
            }
        </>
    );
};
const DeleteObservationDialog = ({ resetState, onDeleteObservation, }) => {

    return (
        <Dialog >
            <p >Er du sikker på at du vil slette observasjon?</p>
            <DialogActionsBar >
                <Button onClick={onDeleteObservation} >
                    Ja
                </Button>
                <Button onClick={resetState} >
                    Nei
                </Button>
            </DialogActionsBar>
        </Dialog>
    );

}

export const EditObservation = ({
    isStateChanged,
    isAllowedEdit,
    shipObservationTypes,
    shipObservation,
    onSaveButtonClicked,
    onTypeChanged,
    setState,
    onObservationCommentUpdated,
    observationDate,
    title,
    resetNewObservationState,
    onSelectImage,
    closeAndCleanupState,
    width,
    height
}) => {

    function onCancelButtonClick() {
        if (resetNewObservationState) resetNewObservationState();
        setState(PageStates.Default);
        isStateChanged.current = false;
    }

    const defaultObservationType = shipObservationTypes[0];

    useEffect(() => {
        if (isNullOrEmpty(shipObservation?.shipObservationType)) {
            onTypeChanged(defaultObservationType);
        }
    }, [shipObservation, shipObservationTypes, defaultObservationType, onTypeChanged]);

    const handleOnTypeChanged = (event) => {
        onTypeChanged(event.target.value);
    }

    return (
        <Dialog
            onClose={closeAndCleanupState}
            width={width}
            height={height}
            title={title}>
            <div className="ob-add-container">
                <ObservationImage
                    handleImageSelected={onSelectImage}
                    capturedImage={shipObservation?.imageBase64Url}
                />
                <div className="vertical-bar-container">
                    <div className="vertical-bar" style={{ backgroundColor: shipObservation?.shipObservationType?.color ?? defaultObservationType.color }}>
                        <DropDownList
                            className="ob-type-dropdown-list"
                            adaptive={true}
                            fillMode={null}
                            textField={"name"}
                            onChange={handleOnTypeChanged}
                            adaptiveTitle={"Velg type"}
                            value={shipObservationTypes.find(t => t.name === shipObservation?.shipObservationType?.name) ?? defaultObservationType}
                            data={shipObservationTypes}
                        />
                    </div>
                </div>
                <TextArea
                    disabled={!isAllowedEdit}
                    placeholder="Beskriv observasjon..."
                    className="ob-add-textarea"
                    onChange={onObservationCommentUpdated}
                    value={shipObservation?.observation ?? ""} />
                <div>{observationDate}</div>
            </div>
            <DialogActionsBar>
                <Button
                    onClick={onCancelButtonClick}
                    svgIcon={CancelIcon}>
                    Avbryt
                </Button>
                <Button
                    disabled={(!isAllowedEdit || !isStateChanged.current)}
                    onClick={onSaveButtonClicked}
                    themeColor="primary"
                    svgIcon={OkIcon}>
                    Lagre
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
};
