import { createApi } from '@reduxjs/toolkit/query/react';
import { apiSliceService } from './apiSliceService';
import uuid from 'react-uuid';
import { ApplicationRights } from '../../services/SystemNames';

export const apiSlice = createApi({
    recuderPath: 'apiSlice',
    baseQuery: apiSliceService.baseQuery(),
    tagTypes: ['HseTypeGroup', 'HseType', 'AuthApplicationRights', 'SourceConfig'],
    endpoints: (builder) => ({
        // ApplicationRights
        getApplicationRights: builder.query({
            query: () => ({
                url: '/auth/application-rights',
                method: 'POST',
                headers: apiSliceService.headers(),
                body: ApplicationRights
            }),
            providesTags: ['AuthApplicationRights']
        }),

        // Hse
        getHseTypeGroups: builder.query({
            query: () => ({
                url: '/hse/type-groups',
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            providesTags: ['HseTypeGroup']
        }),

        getHseTypesByGroupTypeSystemName: builder.query({
            query: (systemName) => ({
                url: `hse/type-group/${systemName}`,
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            transformResponse: (response) => {
                const result = [];
                for (const item of response) {
                    item.guid = uuid();
                    item.eTagValue = "";
                    result.push(item);
                }
                return result;
            },
            providesTags: (result, error, arg) =>
                result ?
                    [
                        ...result.map(({ id }) => ({ type: 'HseType', id })),
                        'HseType'
                    ]
                    :
                    ['HseType'],
        }),

        getHseTypeBySystemName: builder.query({
            query: (systemName) => ({
                url: `hse/type/${systemName}`,
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            providesTags: ['HseType']
        }),

        saveHseType: builder.mutation({
            query: (obj) => ({
                url: 'hse/type',
                method: 'PUT',
                headers: apiSliceService.headers(),
                body: obj
            }),
            invalidatesTags: ['HseType']
        }),
        
        deleteHseType: builder.mutation({
            query: (obj) => ({
                url: 'hse/type',
                method: 'DELETE',
                headers: apiSliceService.headers(),
                body: obj
            }),
            invalidatesTags: ['HseType']
        }),

        // Source
        getSourceConfig: builder.query({
            query: (systemName) => ({
                url: `source/config`,
                method: 'GET',
                headers: apiSliceService.headers()
            }),
            providesTags: ['SourceConfig']
        }),
    })
});

export const {
    // ApplicationRights
    useGetApplicationRightsQuery,
    useLazyGetApplicationRightsQuery,

    // Hse
    useGetHseTypeGroupsQuery,
    useLazyGetHseTypeGroupsQuery,
    useGetHseTypesByGroupTypeSystemNameQuery,
    useLazyGetHseTypesByGroupTypeSystemNameQuery,
    useGetHseTypeBySystemNameQuery,
    useLazyGetHseTypeBySystemNameQuery,
    useSaveHseTypeMutation,
    useDeleteHseTypeMutation,

    // Source
    useGetSourceConfigQuery,
    useLazyGetSourceConfigQuery

} = apiSlice;
