import React from 'react';

export default function StartAndStop({
    hasOngoingWorkTime = false,
    handleWorkTime
}) {

    return (
        <React.Fragment>
            <div className={`workTimebutton workTimebutton-${(hasOngoingWorkTime ? "red" : "green")}`}>
                <div className={`workTimebutton-inner-${(hasOngoingWorkTime ? "red" : "green")}`}
                    onClick={handleWorkTime}>{hasOngoingWorkTime ? 'Stopp' : 'Start'}
                </div>
            </div>
        </React.Fragment>
    )
}
