import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import SearchTextbox from '../SearchTextbox';
import { isObjectNull } from '../../helpers/ObjectHelpers';

function SearchTextBoxFieldWrapper({
    title = "",
    value = "",
    disabled = false,
    onChange,
    boldTitle = false
}) {

    return (
        <FieldWrapper>
            {
                title &&
                <Label className="k-form-label" style={{
                    fontWeight: boldTitle ? "500" : "normal"
                }}>
                    {parse(title)}:
                </Label>
            }
            <div className="k-form-field-wrap">
                <SearchTextbox value={value} disabled={disabled} onChange={handleOnChange} />
            </div>
        </FieldWrapper>
    );

    function handleOnChange(e) {
        if (isObjectNull(onChange)) return;

        onChange(e);
    }
}

export default SearchTextBoxFieldWrapper; 
