import React, { useEffect, useState } from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { getPilotAssignmentBottomMenuItems } from '../pilot-assignment/helpers/PilotAssignmentHelpers';

export default function PilotageDetailsBottomMenu({ state, onClick }) {

    const [isBusy, setIsBusy] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setIsBusy(true);
        const menuItems = getPilotAssignmentBottomMenuItems(state.pilotAssignment);
        setItems(menuItems);
        
        setTimeout(() => {
            setIsBusy(false);
        }, 125);

    }, [state.pilotAssignment]);

    return (
        !isBusy &&
        <BottomNavigation
            items={items}
            selectedAction={state.pageState}
            onCallback={onSelect}
        />
    )

    function onSelect(item) {
        onClick(item.action);
    }
}


