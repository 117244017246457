import { AppointmentTypeEnum } from '../timelineView/services/SystemNames';
import { Dialog } from './Dialog';
import { DialogProperties } from './DialogProperties';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../components/layout/icons/Icons';
import { IconColors } from '../../../services/SystemNames';
import Spacer from '../../../components/layout/Spacer';

export const PilotageInfoDialog = ({ onClose, pilotage, isPilotagePilot, loggedInPersonId, pilotId }) => {
    const dateFormat = 'DD.MM HH:mm'
    if(loggedInPersonId && pilotId){
        isPilotagePilot = loggedInPersonId === pilotId;
    }
    return (
        <Dialog
            properties={{
                ...DialogProperties,
                mode: "default",
                onClose: onClose,
                title: pilotage.title,
                subTitle: moment(pilotage.start).format(dateFormat),
                actions: [
                    {
                        onClick: onClose,
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            {
                {
                    [AppointmentTypeEnum.Pilotage]: <PilotageInfoDialogContent pilotage={pilotage} isPilotagePilot={isPilotagePilot} />,
                    [AppointmentTypeEnum.Calendar]: <CalendarInfoDialogContent pilotage={pilotage} />
                }[pilotage.type]
            }
        </Dialog>
    )
}

export function CalendarInfoDialogContent({ pilotage }) {
    return (
        <>
            {pilotage.attributes.remark}
        </>
    )
}

export function PilotageInfoDialogContent({ pilotage, isPilotagePilot }) {
    const navigate = useNavigate();

    const handlePilotageClick = (pilotageId) => {
        return () => {
            if (isPilotagePilot) {
                navigate(`/pilot/pilotage/${pilotageId}`);
            }else {
                navigate(`/pilotage/${pilotageId}`);
            }
        };
    };

    return (
        <>
            <div>
                <div className="link-btn" onClick={handlePilotageClick(pilotage.pilotageId)}>{pilotage.attributes.pilotageNo}</div>
                    <br />
                    <br />
                    <div>{pilotage.attributes.from}</div>
                <div>
                    <Icons iconName="arrowTo" dimensions={20} color={IconColors.Primary} />
                    &nbsp;{pilotage.attributes.to}
                </div>
            </div>
            <Spacer height={10} />
        </>
    )
}