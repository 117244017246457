import React, { useEffect, useState } from 'react';
import PilotStationApiRepository from '../../../repositories/api/PilotStationApiRepository';
import "../pilot-station.css"
import Pilot from './Pilot';
import { BusySpinner } from '../../../components/layout/overlay/components/Busy';
import { publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';

const Station = ({id, title, from, to, loggedInPersonId, currentTime=null}) => {

    const [station, setStation] = useState([]);
    const [loading, setLoading] = useState(false);

    const getStation = async () => {
        setLoading(true);
        var response = await PilotStationApiRepository.getStationOverView(id);

        if(response.ok)
        {
            var result = await response.json();
            setStation(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
        setLoading(false);
    };

    useEffect(() => {
        getStation();
    }, []);

    return (
        <div className="station">
            <div className="station-header">
                <div>{title}
                    {
                        loading && 
                        <BusySpinner type="oval" dimensions={20} />
                    }
                </div>
            </div>
            {station.map(pilot => (
                <Pilot key={pilot.pilotID} pilot={pilot} from={from} to={to} loggedInPersonId={loggedInPersonId} currentTime={currentTime} />
            ))}
        </div>
    );
};

export default Station;