import React from 'react';

export default function OptimizableWorkTime({
    description = "",
    workTimeIsOptimizable = false,
    header = "",
}) {
    return (
        workTimeIsOptimizable ? 
        <React.Fragment>
            <div className="workTime-optimized">
                <center>
                    <b>{header}</b>
                    {description}
                </center>
            </div>
        </React.Fragment>
        :
        null
    )
}