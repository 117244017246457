import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const MyPageApiRepository = {

    async getDetailsAsync() {
        return await BaseApiRepository.getAsync('my-page/details');
    },

    async getCalendarDetailsAsync() {
        return await BaseApiRepository.getAsync('my-page/calendar/details');
    },

    async resetUrl(url) {
        return await BaseApiRepository.getAsync(`my-page/reset/url/${url}`);
    },

    async setContactValues(obj) {
        return await BaseApiRepository.postAsync("my-page/contact-values", obj);;
    },

    async getNoticeTimeSettings() {
        return await BaseApiRepository.getAsync('my-page/notice-time/settings');
    },

    async setNoticeTimeSettings(obj) {
        return await BaseApiRepository.postAsync('my-page/notice-time/settings', obj);
    }
};
wrapMethodsWithLogging(MyPageApiRepository);

export default MyPageApiRepository;