import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompensationApiRepository from '../../repositories/api/CompensationApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import { PilotageHourCompensationCard } from './components/PilotageHourCompensationCard'
import { PilotageOvertimeCard } from './components/PilotageOvertimeCard';
import Spacer from '../../components/layout/Spacer';
import { VariableCompensationCard } from './components/VariableCompensationCard';
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import { UploadIcon } from '../../components/layout/icons/Icons';
import { Dialog } from '../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../components/layout/dialogs/DialogProperties';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import parse from 'html-react-parser';
import Moment from 'moment';
import { CardProperties } from '../../components/layout/card/components/CardProperties';
import { Card } from '../../components/layout/card/Card';
import Icons from '../../components/layout/icons/Icons';
import { IconColors } from '../../services/SystemNames';
import { publishHeaderTopic, publishReloadAlertsTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { removeQuotesFromString } from '../../components/helpers/ObjectHelpers';
import TextAreaFieldWrapper from '../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import TitleContainer from '../../components/layout/TitleContainer';

export const PilotageCompensationOverview = () => {
    const navigate = useNavigate();
    const dateFormat = 'DD.MM HH:mm';
    const [pilotageCompensations, setPilotageCompensations] = useState(null);
    const { id } = useParams();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <>
            {pilotageCompensations &&
                <>
                    <ContentGrid
                        title={"Korrigering av oppdrag"}
                        titleIcon="ship">
                        <FieldWrapper>
                            <Card properties={{
                                ...CardProperties,
                                title: pilotageCompensations.shipName,
                                subTitle: `${Moment(pilotageCompensations.fromTime).format(dateFormat)}`,
                                onClick: () => navigateToPilotageInfoPage()
                            }}>
                                <div className="pilotage-card-correction">
                                    <div>
                                        <div>
                                            {parse(pilotageCompensations.pilotageFromLocationName)}
                                        </div>
                                        <div>
                                            {pilotageCompensations.pilotageNo}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Icons iconName="arrowTo" dimensions={20} color={IconColors.Secondary} />
                                        </div>
                                        <div>
                                            &nbsp;{parse(pilotageCompensations.pilotageToLocationName)}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </FieldWrapper>
                        <Spacer height={20} />
                        <TitleContainer
                            title={"Tilbakemelding"}
                        />
                        <TextAreaFieldWrapper
                            canCopyToClipboard={false}
                            rows={3}
                            value={pilotageCompensations.feedbackRemark}
                            className={"text-box-correction-message"}
                            readOnly={true}
                        />
                        <Spacer height={20} />
                        <PilotageHourCompensationCard pilotageCompensations={pilotageCompensations} canEdit={pilotageCompensations.isAttentionRequired} pilotageCompensationId={pilotageCompensations.id} updatePilotageCompensation={handlePilotageCompensationUpdate} refresh={() => refresh()} />
                        <PilotageOvertimeCard pilotageCompensations={pilotageCompensations} canEdit={pilotageCompensations.isAttentionRequired} pilotageCompensationId={pilotageCompensations.id} updatePilotageCompensation={handlePilotageCompensationUpdate} refresh={() => refresh()} />
                        <VariableCompensationCard pilotageCompensations={pilotageCompensations} onCallback={handlePilotageCompensationUpdate} />
                    </ContentGrid>

                    <Spacer height={100} />
                    <FloatingButtonContainer>
                        <FloatingActionButton
                            themeColor={"primary"}
                            svgIcon={UploadIcon}
                            onClick={() => setShowConfirmationDialog(true)}
                        />
                    </FloatingButtonContainer>
                </>
            }
            {showConfirmationDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Send korrigering",
                        text: "&Oslash;nsker du &aring; sende korrigeringen?",
                        onClose: () => setShowConfirmationDialog(false),
                        onClick: () => savePilotageCorrection()
                    }}
                />
            }
        </>
    )

    async function initializeAsync() {
        await CompensationApiRepository.getPilotageCompensations(id)
            .then((response) => response.json())
            .then((result) => {
                if (!result.isAttentionRequired) return;
                publishHeaderTopic("Korrigering av oppdrag");
                setPilotageCompensations(result);
            })
            .catch((error) => { console.error(error); })
    }

    function refresh() {
        initializeAsync();
    }

    function navigateToPilotageInfoPage() {
        navigate(`/pilot/pilotage/${pilotageCompensations.pilotageId}`, -1);
    }

    function handlePilotageCompensationUpdate(compensations) {
        setPilotageCompensations(compensations);
    }

    async function savePilotageCorrection() {
        const response = await CompensationApiRepository.savePilotageCompensationCorretion(pilotageCompensations)
        if (response.ok === true) {
            publishSuccessNotificationTopic("Korrigeringen din ble sendt");
            publishReloadAlertsTopic();
            navigate(`/pilot/compensation`);
        } else {
            const errorMessage = await response.text();
            publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
        }
        setShowConfirmationDialog(false);
    }
}
