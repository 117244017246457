
export const DispatcherActions = {
    PageState: 'PageState',
    IsBusy: 'IsBusy',
    NotBusy: 'NotBusy',
    HasErrors: 'HasErrors',
    Initialized: 'Initialized',
    FetchedPilotageDetails: 'FetchedPilotageDetails',
    FetchedSimulatedPeriods: 'FetchedSimulatedPeriods',
    FetchedPilotAppointments: 'FetchedPilotAppointments',
    NowTime: 'NowTime',
    TimeoutId: 'TimeoutId',
    Reset: 'Reset',
    Reload: 'Reload',
    Start: 'Start',
    Stop: 'Stop',
    WorkTimes: 'WorkTimes',
    PilotDetails: 'PilotDetails'
}