import { isEmptyObject } from "jquery";
import AuthApiRepository from "../../repositories/api/AuthApiRepository";
import PilotApiRepository from "../../repositories/api/PilotApiRepository";
import SourceApiRepository from "../../repositories/api/SourceApiRepository";
import MetaIdbRepository from "../../repositories/idb/MetaIdbRepository";
import { LoginTypes } from "../../services/SystemNames";
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from "./ObjectHelpers";
import { publishLogoutTopic, publishWarningNotificationTopic } from "./PubSubHelpers";
import { getDecodedToken, getToken, getTokenFromUrl, hasTokenExpired, setToken, tokenExists } from "./TokenHelpers";

function isLoggedIn() {
    try {
        let token = getToken();
        if (isNullOrEmpty(token) === true || getDecodedToken(token) === null) {
            token = getTokenFromUrl();
            setToken(token);
        }
        const hasExpired = hasTokenExpired(token);
        return hasExpired === false;
    } catch (e) {
        return false;
    }
}

function isLoggedInPerson() {
    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return false;
        }

        const decodedToken = getDecodedToken(token);
        return decodedToken.loginType === LoginTypes.Person;
    } catch (e) {
        return false;
    }
}

function isBetaUser() {
    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return false;
        }

        const decodedToken = getDecodedToken(token);
        const betaAccessType = decodedToken.betaAccessType;
        return betaAccessType.toLowerCase() === "true" || false;

    } catch (e) {
        return false;
    }
}

function getLoggedInPersonId() {
    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return 0;
        }

        const decodedToken = getDecodedToken(token);
        return Number(decodedToken.sub);
    } catch (e) {
        return 0;
    }
}

function loggedInId() {
    try {
        let token = getToken();

        if (tokenExists(token) === false) {
            return 0;
        }

        const decodedToken = getDecodedToken(token);
        return decodedToken.sub;
    } catch (e) {
        return 0;
    }
}

async function handleLoginAsync(returnUrl = "") {
    if (!navigator.onLine) {
        this.history.push("/");
    }

    const response = await AuthApiRepository.getLoginUrlAsync(returnUrl);
    const data = await response.json();

    window.location.href = data.loginUrl;
}

async function handleLogoutAsync(navigate) {

    await MetaIdbRepository.deleteWhoAmIAsync();

    publishLogoutTopic();
    navigate("/");
}

function hasPersonType(personType) {

    try {
        let token = getToken();

        if (isNullOrEmpty(token) === true ||
            getDecodedToken(token) === null) {
            return false;
        }

        const decodedToken = getDecodedToken(token);
        return decodedToken.personType.toUpperCase() === personType.toUpperCase();
    } catch (e) {
        return false;
    }
}

function hasApplicationRights(userApplicationRights, componentApplicationRights) {
    if (isArrayEmpty(userApplicationRights)) return false;
    let hasRights = false;
    for (const componentApplicationRight of componentApplicationRights) {
        if (hasRights) continue;
        const applicationRight = userApplicationRights.find(r => r.applicationRight === componentApplicationRight);
        if (!isObjectNull(applicationRight)) {
            hasRights = applicationRight.value;
        }
    }
    return hasRights;
}

async function setWhoAmIAsync() {
    if (!navigator.onLine) return;
    const response = await SourceApiRepository.getWhoAmIAsync();
    if (response.ok === true) {
        const data = await response.json();
        await MetaIdbRepository.setWhoAmIAsync(data);
    }

    const dayTimeResponse = await PilotApiRepository.getDayTimeAsync();
    if (dayTimeResponse.ok === true) {
        const data = await dayTimeResponse.json();
        await MetaIdbRepository.setDayTimeAsync(data);
    }
}

async function onGuestCheckAsync(informIfGuest = false) {
    if (!navigator.onLine) return;

    const loggedInPersonId = getLoggedInPersonId();
    const idbPrimaryKey = `GuestPilotStation-${loggedInPersonId}`

    const localGuestPilotStation = await MetaIdbRepository.getGuestPilotStationAsync(idbPrimaryKey);
    const response = await PilotApiRepository.getGuestPilotStationAsync(loggedInPersonId);
    let guestPilotStation = null;

    if (response.ok && response.status === 200) {
        const data = await response.json();
        guestPilotStation = data;
        await MetaIdbRepository.setGuestPilotStationAsync(idbPrimaryKey, data);

        if (informIfGuest && isEmptyObject(localGuestPilotStation)) {
            publishWarningNotificationTopic(`Losformidler har satt deg som gjestelos på ${data.displayName}.<br/>Du kan avslutte gjestelosingen via Hjem - valget i menyen`);
        }
    } else {
        if (!isEmptyObject(localGuestPilotStation)) {
            publishWarningNotificationTopic(`Losformidlingen har fjernet deg som gjestelos på ${localGuestPilotStation.value.displayName}`);
            await MetaIdbRepository.deleteGuestPilotStationAsync(idbPrimaryKey);
        }
    }

    return guestPilotStation;
}

export {
    isLoggedIn,
    isLoggedInPerson,
    isBetaUser,
    getLoggedInPersonId,
    loggedInId,
    handleLoginAsync,
    handleLogoutAsync,
    hasPersonType,
    hasApplicationRights,
    setWhoAmIAsync,
    onGuestCheckAsync
}
