import React from 'react';
import { PageStates } from '../../../services/SystemNames';
import BottomNavigation from '../../../components/layout/BottomNavigation';

export default function AllPilotagesBottomMenu({ pageState, onClick }) {

    const items = [
        {
            text: "Losoppdrag",
            icon: "anchor",
            action: PageStates.Default
        },
        {
            text: "Stasjoner",
            icon: "star",
            action: PageStates.PilotStationSelector
        },
    ];
    
    return (
        <BottomNavigation
            items={items}
            selectedAction={pageState}
            onCallback={onSelect}
            />            
    )

    function onSelect(item) {
        onClick(item.action);
    }
}