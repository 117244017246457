import React from 'react';
import TimelineViewService from '../services/TimelineViewService';
import { useTimelineViewContext } from './TimelineViewContext';

export function TimelineViewNow() {
    const { timelineViewType } = useTimelineViewContext();

    return (
        <React.Fragment>
            <div id="timeLineNowAnchor" className="now-anchor"
                style=
                {{
                    top: `${calcAnchorTop()}px`
                }}
            />
            <div className="now"
                style=
                {{
                    top: `${calcTop()}px`
                }}>
                <div>{formatNow()}</div>
            </div>
        </React.Fragment>
    )

    function calcTop() {
        return TimelineViewService.calcHeight(timelineViewType.fromTime, timelineViewType.nowTime);
    }

    function calcAnchorTop() {
        return calcTop() - TimelineViewService.hoursToHeight(6);
    }

    function formatNow() {
        if(timelineViewType.nowTime === null) return "";
        return timelineViewType.nowTime.format('HH:mm');
    }
}
