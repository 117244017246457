import { useRef, useState } from "react";
import { isArrayEmpty, isNullOrEmpty } from "../../../../helpers/ObjectHelpers";
import SearchTextbox from "../../../SearchTextbox";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import LocationIdbRepository from "../../../../../repositories/idb/LocationIdbRepository";
import SourceApiRepository from "../../../../../repositories/api/SourceApiRepository";
import { useSearchParams } from "react-router-dom";
import { MapPanelTabContent } from "./MapPanelTabContent";

const initialState = {
    searchText: "",
    selectedTab: 0,
    locations: [],
    selectedLocation: null,
    ships: [],
    selectedShip: null,
    isBusy: false
};

export const MapPanelSearch = (
    {
        map,
        onUnselectShip,
        panelTabContentHeight,
        shouldCloseOnClick,
        onClose
    }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const timer = useRef(null); 
    const searchControlHeight = 50;

    const [{
        searchText,
        selectedTab,
        locations,
        selectedLocation,
        ships,
        selectedShip,
        isBusy
    }, setState] = useState(initialState);

    const setStateValue = (property, e) => {
        setState((prev) => ({
            ...prev,
            [property]: e
        }));
    }

    const setIsBusy = (e) => {
        setStateValue("isBusy", e);
    }

    const setSearchText = (e) => {
        setStateValue("searchText", e);
    }

    const setSelectedTab = (e) => {
        setStateValue("selectedTab", e);
    }

    const setLocations = (e) => {
        setStateValue("locations", e);
    }

    const setSelectedLocation = (e) => {
        setState((prev) => ({
            ...prev,
            selectedLocation: e,
            selectedShip: null
        }));
    }

    const setShips = (e) => {
        setStateValue("ships", e);
    }

    const setSelectedShip = (e) => {
        setState((prev) => ({
            ...prev,
            selectedLocation: null,
            selectedShip: e
        }));
    }

    const handleSelectTab = e => {
        setSelectedTab(e.selected);
    };

    return (
        <div className={`google-map-search`}>
            <div>
                <SearchTextbox value={searchText} onChange={onSearchChange} autoFocus={true} disabled={isBusy} />
            </div>
            <div>
                <TabStrip selected={selectedTab} onSelect={handleSelectTab}>
                    {
                        (!isArrayEmpty(locations)) &&
                        <TabStripTab title={`Lokasjoner ${getDataCount(locations)}`}>
                            <MapPanelTabContent panelTabContentHeight={panelTabContentHeight - searchControlHeight}>
                                <div className="google-map-search-result">
                                    {
                                        locations.map((location, index) =>
                                            <MapSearchResultItem
                                                key={index}
                                                name={location.name}
                                                onClick={() => onLocationClick(location)}
                                                selected={location === selectedLocation}
                                            />
                                        )
                                    }
                                </div>
                            </MapPanelTabContent>
                        </TabStripTab>
                    }
                    
                    {
                        (!isArrayEmpty(ships)) &&
                            <TabStripTab title={`Fartøy ${getDataCount(ships)}`}>
                                <MapPanelTabContent panelTabContentHeight={panelTabContentHeight - searchControlHeight}>
                                    <div className="google-map-search-result">
                                        {
                                            ships.map((ship, index) =>
                                                <MapSearchResultItem
                                                    key={index}
                                                    name={`${ship.shipName} (${ship.callSign})`}
                                                    onClick={() => onShipClick(ship)}
                                                    selected={ship === selectedShip}
                                                />
                                            )
                                        }
                                    </div>
                                </MapPanelTabContent>
                            </TabStripTab>
                        
                    }
                    
                </TabStrip>
            </div>
        </div>
    )

    async function onSearchChange(e) {
        if (isBusy) return;

        setSearchText(e.value);

        clearTimeout(timer.current);

        timer.current = setTimeout(async () => {
            if (isNullOrEmpty(e.value) || e.value.length < 3) {
                setLocations([]);
                setShips([]);
                return;
            }

            setIsBusy(true);

            await searchLocationsAsync(e.value);
            await searchShipsAsync(e.value);

            setIsBusy(false);
        }, 500);
    }

    async function searchLocationsAsync(searchText) {
        const locations = await LocationIdbRepository.searchAsync(searchText);
        setLocations(locations);
    }

    async function searchShipsAsync(searchText) {
        const response = await SourceApiRepository.getShipPositionsByNameAsync(searchText);
        if (response.ok) {
            const data = await response.json();
            setShips(data);
        }
    }

    function getDataCount(data) {
        const count = data.length;
        if (data.length === 0) return "";
        return `(${count})`
    }

    function onLocationClick(location) {
        onUnselectShip()
        const position = new window.google.maps.LatLng(location.latitude, location.longitude);
        setSearchParams({
            locationId: location.locationId,
        }, { replace: true });

        setCenter(position);
        setSelectedLocation(location);
    }

    function onShipClick(ship) {
        const position = new window.google.maps.LatLng(ship.latitude, ship.longitude);
        setSearchParams({
            mmsi: ship.mmsi,
        }, { replace: true });

        setCenter(position, 16);
        setSelectedShip(ship);
    }

    function setCenter(position, zoom = 11) {
        map.setCenter(position);
        map.setZoom(zoom);

        handleOnClose();
    }

    function handleOnClose() {
        if (!shouldCloseOnClick) return;
        onClose();
    }
}


const MapSearchResultItem = ({ name, description = "", onClick, selected = false }) => {
    return (
        <div className={`${selected ? 'google-map-search-result-item-selected' : 'google-map-search-result-item'}`} onClick={onClick}>
            <div className="prevent-selection">{name}</div>
            {
                (!isNullOrEmpty(description)) &&
                <div className="prevent-selection">{description}</div>
            }
        </div>
    )
}
