
import React from 'react';
import { CardService } from '../CardService';

export const CardBorderLeft = (
    {
        properties = {}
    }) => {
        

    return (
        <div className={`card-container-left ${getErrorClasses()}`}
            style={CardService.leftStyle(properties.isTentative, properties.borderColor, properties.backgroundColor, properties.isBorderLeftFat)}>
        </div>
    );

    function getErrorClasses() {
        if (!properties.hasValidationError) return '';

        let classNames = 'card-border-left-validation-border-error ';

        if (properties.isBorderLeftFat) {
            classNames += 'card-border-left-validation-bg-error';
        }
        
        return classNames;
    }
};