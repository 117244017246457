import './radioButton.css'

import React from 'react';
import { RadioButton as KendoRadioButton } from "@progress/kendo-react-inputs";

export default function RadioButton(
    {
        label = "",
        checked = false,
        onClick
    }
) {

    return (
        <div
            onClick={onClick}
            className={`radiobutton ${checked ? "radiobutton-checked" : ""}`}>
            <div>
                <KendoRadioButton
                    size="large"
                    checked={checked}
                />
            </div>
            <div>
                {label}
            </div>
        </div>
    )
}