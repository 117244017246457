import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import Spacer from '../../../../components/layout/Spacer';
import Switch from '../../../../components/layout/switch';
import { isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import { addCompletePilotageReceiptCommand, getCompletePilotageReceiptCommand, hasCommandType, removeCompletePilotageReceiptCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentCompleted, isPilotAssignmentPilotageIncl } from '../helpers/PilotAssignmentHelpers';
import { publishPilotAssignmentIsEdibleChangedTopic } from '../../../../components/helpers/PubSubHelpers';

export const CompleteCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync(true);
        }
    }));

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        initializeAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        show &&
        <>
            <Spacer height={20} />
            <hr />
            <Spacer height={10} />
            <Switch
                onLabel="Utf&oslash;rt"
                onChange={onChange}
                checked={isChecked}
                isDirty={isDirty}
            />
        </>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        const completeCommand = getCompletePilotageReceiptCommand(pilotAssignment);

        if (!isPilotAssignmentPilotageIncl(pilotAssignment)) {
            setShow(!isPilotAssignmentCompleted(pilotAssignment));
        } else {
            setShow(!pilotAssignment.pilotage.containsInvoices || (pilotAssignment.isTrainee && !isPilotAssignmentCompleted(pilotAssignment)));
        }

        if (isObjectNull(completeCommand)) {
            setIsChecked(isPilotAssignmentCompleted(pilotAssignment));
        } else {
            setIsChecked(completeCommand.BoolValueLevel1);
        }
        initializeIsDirty(pilotAssignment);
    }

    function onChange(e) {
        onUpdateAsync(e.value);
    }

    async function onUpdateAsync(isChecked) {
        setIsChecked(isChecked);
        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        if (isPilotAssignmentCompleted(pilotAssignment)) {
            if (isChecked) {
                pilotAssignment = removeCompletePilotageReceiptCommand(pilotAssignment);
            } else {
                pilotAssignment = addCompletePilotageReceiptCommand(pilotAssignment, isChecked);
            }
        } else {
            if (isChecked) {
                pilotAssignment = addCompletePilotageReceiptCommand(pilotAssignment, isChecked);
            } else {
                pilotAssignment = removeCompletePilotageReceiptCommand(pilotAssignment);
            }
        }
        
        await onUpdatePilotAssignmentAsync(pilotAssignment);
		publishPilotAssignmentIsEdibleChangedTopic();
        initializeIsDirty(pilotAssignment);
    }

    function initializeIsDirty(pilotAssignment) {
        setIsDirty(hasCommandType(pilotAssignment, PilotAssignmentCommands.CompletePilotageReceipt));
    }
})
