
export const TimelineViewType = {
    timelineTimes: [],
    nowTime: null,
    fromTime: null,
    toTime: null,
    workTimes: [],
    showHighlight: false,
    appointments: [],
    pilotPilotages: [],
}