import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import { getToken } from "../../components/helpers/TokenHelpers";
import BaseApiRepository from "./BaseApiRepository";

const EvaluationApiRepository = {

    async getAsync(identifier) {
        return await BaseApiRepository.getAsync(`eval?${identifier}`);
    },

    async setAsync(obj) {
        return await BaseApiRepository.postAsync('eval/update', obj);
    },

    async getSummaryAsync(identifier) {
        return await BaseApiRepository.getAsync(`eval/summary/?${identifier}`);
    },

    async getFairwayAreasAsync(identifier) {
        return await BaseApiRepository.getAsync(`eval/fairway-areas?${identifier}`);
    },

    async getEmptyAsync() {
        return await BaseApiRepository.getAsync(`eval/empty`);
    },

    async getMineAsync() {
        return await BaseApiRepository.getAsync(`eval/mine`);
    },

    async retractAsync(obj) {
        return await BaseApiRepository.postAsync(`eval/retract`, obj);
    },

    async renounceAsync(obj) {
        return await BaseApiRepository.postAsync(`eval/renounce`, obj);
    },

    async getPdfDocumentAsync(obj) {
        const url = `eval/export/pdf?pilotage-pilot-id=${obj.pilotagePilotId}&pilotage-pec-exam-id=${obj.pilotagePecExamId}&token=${getToken()}`
        return await BaseApiRepository.getAsync(url);
    },

    async completeAsync(obj) {
        return await BaseApiRepository.postAsync(`eval/complete`, obj);
    },
};
wrapMethodsWithLogging(EvaluationApiRepository);

export default EvaluationApiRepository;