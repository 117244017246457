import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import TextBoxFieldWrapper from '../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import Spacer from '../../../components/layout/Spacer';
import Icons, { AddIcon, CloseIcon } from '../../../components/layout/icons/Icons';
import NoticeTimeRow from './NoticeTimeRow';
import SearchTextBoxFieldWrapper from '../../../components/layout/fieldWrappers/SearchTextBoxFieldWrapper';
import LocationIdbRepository from '../../../repositories/idb/LocationIdbRepository';
import { deepCopyObject, isArrayEmpty, isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { getTimeMinutes } from '../../../components/helpers/DataHelpers';
export function LocationGroupDialog({ item, onClose, onSave, locationIds = [], general }) {

    const [minutes, setMinutes] = useState([]);
    const [selectedMinuteDay, setSelectedMinuteDay] = useState(null);
    const [selectedMinuteNight, setSelectedMinuteNight] = useState(null);
    const [dayTime, setDayTime] = useState("00:00");
    const [nightTime, setNightTime] = useState("00:00");
    const [canSave, setCanSave] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [searchText, setSearchText] = useState("");
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [searchLocations, setSearchLocations] = useState([]);
    
    // Initialize function
    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog properties={{
            ...DialogProperties,
            title: isNullOrEmpty(item) ? "Ny gruppe" : "Rediger gruppe",
            onClose: onClose,
            size: "large",
            actions: [
                {
                    onClick: onClose,
                    icon: "close",
                    text: "Avbryt"
                },
                {
                    icon: "ok",
                    disabled: !canSave,
                    onClick: handleOnSave,
                    themeColor: "primary",
                    text: "Lagre"
                }
            ]
        }}>
            <TextBoxFieldWrapper
                title="Navn"
                value={groupName}
                onChange={onNameChange}
            />
            <Spacer height={20} />
            <div className="container">
                <div className="row align-items-center">
                    <NoticeTimeRow
                        icon={<Icons iconName="day" />}
                        time={dayTime}
                        selectedMinute={selectedMinuteDay}
                        minutes={minutes}
                        onChange={handleOnDayChange}
                    />
                </div>
                <div className="row align-items-center">
                    <Spacer height={20} />
                </div>
                <div className="row align-items-center">
                    <NoticeTimeRow
                        icon={<Icons iconName="night" />}
                        time={nightTime}
                        selectedMinute={selectedMinuteNight}
                        isDay={false}
                        minutes={minutes}
                        onChange={handleOnNightChange}
                    />
                </div>
            </div>
            <Spacer height={20} />
            <SearchTextBoxFieldWrapper
                title="Legg til lokasjon"
                value={searchText}
                placeholder="Lokasjon eller kode"
                onChange={onSearchTextChange}
            />

            {
                shouldShowWarning() &&
                <>
                    <Spacer />
                    <div className="container font-warning">
                        <div className="row">
                            <div className="col">
                                Hvis gruppen inneholder kun &eacute;n lokasjon, s&aring; vil gruppen slettes og kun lokasjonen lagres med oppgitt varslingstid.
                            </div>
                        </div>
                    </div>
                </>
            }
            
            <LocationList
                locations={searchLocations}
                title="S&oslash;keresultat"
                icon={AddIcon}
                onClick={onAddLocation}
            />

            <LocationList
                locations={selectedLocations}
                title="Valgte lokasjoner"
                icon={CloseIcon}
                onClick={onRemoveLocation}
            />
            
        </Dialog>
    );

    async function initializeAsync() {

        const dayTimeStart = Moment("2000-01-01").add(general.dayStartTimeInMinutes, 'minutes');
        setDayTime(dayTimeStart.format("HH:mm"));

        const nightTimeStart = Moment("2000-01-01").add(general.dayEndTimeInMinutes, 'minutes');
        setNightTime(nightTimeStart.format("HH:mm"));

        const timeMinutes = getTimeMinutes(false);
        setMinutes(timeMinutes);

        let minutesBeforeToNoticeDay = general.defaultMinutesBeforeToNoticeDay;
        let minutesBeforeToNoticeNight = general.defaultMinutesBeforeToNoticeNight;

        if (!isNullOrEmpty(item)) {
            
            setGroupName(item.name);

            minutesBeforeToNoticeDay = item.minutesBeforeToNoticeDay;
            minutesBeforeToNoticeNight = item.minutesBeforeToNoticeNight;

            let locations = [];
            for (const location of item.locations) {
                let dto = await LocationIdbRepository.getByIdAsync(location.locationId);
                if (isNullOrEmpty(dto)) {
                    dto = {
                        name: location.locationName,
                        locationId: location.locationId
                    }
                }

                locations.push(dto);
            }
            locations = locations.sort((a, b) => a.name.localeCompare(b.name))
            setSelectedLocations(locations);
        }

        const selectedMinuteDay = timeMinutes.find(m => Number(m.value) === Number(minutesBeforeToNoticeDay));
        setSelectedMinuteDay(selectedMinuteDay);

        const selectedMinuteNight = timeMinutes.find(m => Number(m.value) === Number(minutesBeforeToNoticeNight));
        setSelectedMinuteNight(selectedMinuteNight);

    }

    function onNameChange(e) {
        setGroupName(e.value);
        onValidate(e.value, selectedLocations);
    }

    function onSearchTextChange(e) {
        const selectedIds = getLocationIds(selectedLocations);

        setSearchText(e.value);
        onSearchLocationsAsync(e.value, selectedIds);
    }

    async function onSearchLocationsAsync(searchText, selectedIds) {

        const result = await LocationIdbRepository.searchAsync(searchText);
        const locations = [];

        for (var location of result) {
            if (selectedIds.includes(location.locationId)) continue;

            const shouldAdd = locationIds.generalIds.includes(location.locationId);

            if (!shouldAdd) continue;

            locations.push({
                name: location.name,
                locationId: location.locationId,
                type: "searched"
            });
        }

        setSearchLocations(locations);
    }

    function handleOnDayChange(e) {
        handleMinuteChange(e, setSelectedMinuteDay);
    }

    function handleOnNightChange(e) {
        handleMinuteChange(e, setSelectedMinuteNight);
    }

    function handleMinuteChange(e, set) {
        const minute = e.target.value;
        set(minute);
        onValidate(groupName, selectedLocations);
    }

    function onAddLocation(e) {
        let newSelectedLocations = deepCopyObject(selectedLocations);
        newSelectedLocations.push(e);
        newSelectedLocations = newSelectedLocations.sort((a, b) => a.name.localeCompare(b.name))

        setSelectedLocations(newSelectedLocations);

        const selectedIds = getLocationIds(newSelectedLocations);

        onSearchLocationsAsync(searchText, selectedIds);

        onValidate(groupName, newSelectedLocations);
    }

    function onRemoveLocation(e) {
        const newSelectedLocations = selectedLocations.filter(l => l.locationId !== e.locationId);
        setSelectedLocations(newSelectedLocations);

        const selectedIds = getLocationIds(newSelectedLocations);

        onSearchLocationsAsync(searchText, selectedIds);

        onValidate(groupName, newSelectedLocations);
    }

    function getLocationIds(locations) {
        const result = [];

        for (var location of locations) {
            result.push(location.locationId);
        }
        return result;
    }

    function onValidate(groupName, locations) {
        setCanSave(!isNullOrEmpty(groupName) && !isArrayEmpty(locations));
    }

    function shouldShowWarning() {

        if (isArrayEmpty(selectedLocations)) return false;
        if (selectedLocations.length > 1) return false;

        return true;
    }

    function handleOnSave() {
        if (!canSave) return;

        const group = { pilotNoticeGroupID: 0 };
        if (!isNullOrEmpty(item)) {
            group.pilotNoticeGroupID = item.id;
        }

        group.groupName = groupName;
        group.pilotLocationNoticeTimes = selectedLocations;
        group.minutesBeforeToNoticeDay = selectedMinuteDay.value;
        group.minutesBeforeToNoticeNight = selectedMinuteNight.value;

        onSave(group);
    }
}

export const LocationList = ({ title, disabled, locations, icon, onClick }) => {
    return (
        (!isArrayEmpty(locations)) &&
        <>
            <Spacer height={20} />
            <div>
                <div className="fw-bold">
                    {title}
                </div>
                <Spacer height={10} />
                <div className="container">
                    {
                        locations.map((location, index) =>
                            <div className="row m-b-5 align-items-center pointer" key={index}>
                                <div className="col-2 col-sm-1">
                                    <Button
                                        svgIcon={icon}
                                        onClick={() => handleOnClick(location)}
                                    />
                                </div>
                                <div className="col-10 col-sm-11 p-t-3">
                                    {location.name}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )

    function handleOnClick(location) {
        if (disabled) return;
        onClick(location);
    }
}
