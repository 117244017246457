/*eslint unicode-bom: ["error", "always"]*/
import React from 'react';
import Moment from 'moment';
import Spacer from '../../../../../components/layout/Spacer';
import { isNullOrEmpty } from '../../../../../components/helpers/ObjectHelpers';

function Summary(
    {
        summary = {}
    }
) {

    return (
        <div className="evaluation-summary-form">
            <div className="semi-bold evaluation-summary-title">
                Evaluering av farledsbevisprøve
            </div>

            <Spacer height={40} />

            <div className="evaluation-summary-header-grid">
                <div>Evaluert av:</div>
                <div className="semi-bold">{summary.examinatorName}</div>
                <div>Evalueringsdato:</div>
                <div className="semi-bold">{formatDate(new Date(), 'DD.MM.YY')}</div>
                <div>Mobilnr:</div>
                <div className="semi-bold">{summary.examinatorMobilePhone}</div>
            </div>

            <Spacer height={20} />
            <div className="evaluation-summary-separator"></div>
            <Spacer height={20} />

            <div className="evaluation-summary-default-grid">
                <div>Farledsbevisnr:</div>
                <div className="semi-bold">{summary.certificateNo}</div>
                <div>navn på kandidat:</div>
                <div className="semi-bold">{summary.candidateName}</div>
                <div>Fødselsdato:</div>
                <div className="semi-bold">{formatDate(summary.candidateDateOfBirth, 'DD.MM.YY')}</div>
                <div>Nasjonalitet:</div>
                <div className="semi-bold">{summary.candidateCountry}</div>
            </div>

            <Spacer height={20} />
            <div className="evaluation-summary-separator"></div>
            <Spacer height={20} />

            <div className="evaluation-summary-default-grid">
                <div>Fartøynavn:</div>
                <div className="semi-bold">{summary.shipName}</div>
                <div>Kj.sign:</div>
                <div className="semi-bold">{summary.shipCallSign}</div>
                <div>Nasjonalitet:</div>
                <div className="semi-bold">{summary.shipNationality}</div>
            </div>

            <Spacer height={20} />
            <div className="evaluation-summary-separator"></div>
            <Spacer height={20} />

            <div className="evaluation-summary-default-grid">
                <div>Prøveområde:</div>
                <div className="semi-bold">{summary.examName}</div>
                <div>SSN Prøvetid:</div>
                <div className="semi-bold">{formatDate(summary.examTime, 'DD.MM.YY')}</div>
                <div>Tidspunkt(er):</div>
                <div className="semi-bold">
                    {summary.isDayTime ? "Dag" : null}
                    {(summary.isDayTime && summary.isNightTime) ? " / " : null}
                    {summary.isNightTime ? "Natt" : null}
                </div>
            </div>

            <Spacer height={20} />
            <div className="evaluation-summary-separator"></div>
            <Spacer height={20} />

            <div className="evaluation-summary-remark">
                <div>Merknader (avvik):</div>
                <div className="semi-bold">
                    {
                        isNullOrEmpty(summary.examAreaDeviationRemark) ?
                            <React.Fragment>Intet notert</React.Fragment>
                            :
                            summary.examAreaDeviationRemark
                    }
                </div>
            </div>

            <Spacer height={20} />
            <div className="evaluation-summary-separator"></div>
            <Spacer height={20} />

            <div>
                {
                    summary.isRenounced ?
                        <div >
                            <div className="semi-bold font-error">
                                Losen har gitt avkall på evalueringen:&nbsp;
                                {
                                    summary.renounceType === "NO_RESPONSIBILITY" ?
                                        "Medsensor uten evalueringssansvar" : "Ikke deltakende på prøven"
                                }
                            </div>
                        </div>
                        :
                        <div >
                            <div>
                                Losens konklusjon vedrørende utstedelse av farledsbevis til kandidat:&nbsp;
                                <span className={'semi-bold ' + (summary.recommendationSystemName === "RECOMMENDED" ? 'font-active' : 'font-error')}>
                                    {summary.recommendation}
                                </span>
                            </div>
                        </div>
                }
            </div>

        </div>
    );

    function formatDate(date, format) {
        if (date === undefined || date === null) return "";

        let parsedDate = new Date(date);
        return Moment(parsedDate).format(format);
    }
}

export default Summary;