/* eslint-disable react-hooks/exhaustive-deps */
import './styles/worktime.css';

import React, { useState } from 'react';
import { useEffectOnce } from '../../components/useEffectOnce';
import moment from 'moment';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import WorkTimeApiRepository from '../../repositories/api/WorkTimeApiRepository';
import Overlay from '../../components/layout/overlay/Overlay';
import WorkTimeBottomNavigation from './components/WorkTimeBottomNavigation';
import ContentGrid from '../../components/layout/ContentGrid';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { formatMoment, getFormatMoment } from '../../components/helpers/DateTimeHelpers';
import Spacer from '../../components/layout/Spacer';

function Future() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [workTime, setWorkTime] = useState({});

    // Initialize function
    useEffectOnce(() => {
        publishHeaderTopic("De neste timene");
        initializeAsync();
    }, []);

    return (
        <>
            <ContentGrid title="De neste timene" titleIcon="information">
                <center>
                    {
                        workTime.hasRemainingWorkTime &&
                        <>
                            <h3>Rest arbeidstid</h3>
                            <div>{workTime.remainingWorktimeSpan} {workTime.totalWorktimeSpan}</div>
                        </>
                    }

                    {
                        workTime.hasNextWorkTime &&
                        <>
                            <Spacer height={20} />

                            <h3>Hvis ingen nye oppdrag</h3>
                            {
                                workTime.hasNextReducedWorkTime && <div>{workTime.nextReducedWorkTime}</div>
                            }
                            {
                                workTime.hasNextFullWorkTime && <div>{workTime.nextFullWorkTime}</div>
                            }
                        </>
                    }
                    {
                        workTime.hasIncreasedWorktimeByRestingLonger &&
                        <>
                            <Spacer height={20} />

                            <h3>Restarbeidstid n&aring;r l&oslash;pende hvile blir 8 timer</h3>
                            <div>{workTime.nextFullyRestedWorkime}</div>
                        </>
                    }

                    {
                        
                        workTime.hasRestTimeToConvert &&
                        <>
                            <Spacer height={20} />

                            <h3>Resttid dersom p&aring;g&aring;ende eller forrige hvile gj&oslash;res om til arbeidstid:</h3>
                            {
                                workTime.hasCurrentRest && <div>{workTime.currentRestIsConverted}</div>
                            }
                            {
                                workTime.hasPreviousRest && <div>{workTime.previousRestIsConverted}</div>
                            }
                        </>
                    }
                </center>
    
            </ContentGrid>
            <Overlay isBusy={isBusy} onReloadClick={onReset} hasErrors={hasErrors} />

            <WorkTimeBottomNavigation id="future" />
        </>
    );
    async function initializeAsync() {
        const fromTime = moment().add(-3, 'd');
        const toTime = moment().add(3, 'd');

        const response = await PilotApiRepository.getPilotDetailsAsync(formatMoment(fromTime), formatMoment(toTime));

        if (response.ok === true) {
            const data = await response.json();
            getFuturePeriodsAsync(data, fromTime);
            
        } else {
            handleError(response);
        }
    }

    async function getFuturePeriodsAsync(pilot, fromTime) {
        const now = getFormatMoment(moment());
        const query = {
            pilot: pilot,
            fromTime: formatMoment(fromTime),
            now: formatMoment(now)
        };

        const response = await WorkTimeApiRepository.searchFutureAsync(query);
        if (response.ok === true) {
            const data = await response.json();
            setWorkTime(data);
            setIsBusy(false);
        } else {
            handleError(response);
        }
    }
    function handleError(response) {
        publishWarningNotificationTopic("Kunne ikke hente inn fremtidig beregning", response.status);
        setIsBusy(false);
        setHasErrors(true);
    }

    function onReset() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }
}

export default Future;