import React from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '../../../components/layout/BottomNavigation';

function BottomMenu({ selectedAction = 1 }) {

    const navigate = useNavigate();

    const items = [
        {
            text: "Vaktliste",
            icon: "bulletList",
            action: 1
        },
        {
            text: "Neste vakt",
            icon: "next",
            action: 2
        },
        {
            text: "Logg",
            icon: "document",
            action: 3
        },
    ]

    return (
        <BottomNavigation
            items={items}
            selectedAction={selectedAction}
            onCallback={onSelect}
        />
    );

    function onSelect(e) {
        switch (e.action) {
            case 2:
                navigate("/pilot-rotation/next");
                break;
            case 3:
                navigate("/pilot-rotation/log");
                break;
            default:
                navigate("/pilot-rotation/current");
                break;
        }
    }
}

export default BottomMenu;