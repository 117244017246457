
import EvaluationIdbRepository from '../../../repositories/idb/EvaluationIdbRepository';
import { publishWarningNotificationTopic } from "../../../components/helpers/PubSubHelpers";
import { isBoolean } from "../../../components/helpers/ObjectHelpers";
import { EvaluationAssessmentEnum } from './EvaluationHelpers';

const EvaluationService = {

    async setEmptyEvaluationFormAsync(form) {
        await EvaluationIdbRepository.setEvaluationFormAsync(form);
    },

    getEvaluationIdentifierByObj(evaluation) {
        return this.getEvaluationIdentifierById(evaluation.pilotagePilotId, evaluation.pilotagePecExamId);
    },

    getEvaluationIdentifierById(pilotagePilotId, pilotagePecExamId) {
        return `pilotage-pilot-id=${pilotagePilotId}&pilotage-pec-exam-id=${pilotagePecExamId}`;
    },

    async getEmptyEvaluationFormAsync() {
        return await EvaluationIdbRepository.getEvaluationFormAsync();
    },

    getEvaluationDocumentUrl(evaluation, token) {
        return `/api/eval/export/pdf?pilotage-pilot-id=${evaluation.pilotagePilotId}&pilotage-pec-exam-id=${evaluation.pilotagePecExamId}&token=${token}`;
    },

    getEvaluationEditUrl(evaluation) {
        return `/evaluation/pecExamEvaluation?${this.getEvaluationIdentifierByObj(evaluation)}`;
    },

    getEvaluationSummaryUrl(evaluation) {
        return `/evaluation/pecExamEvaluation-summary?pilotage-pilot-id=${evaluation.pilotagePilotId}&pilotage-pec-exam-id=${evaluation.pilotagePecExamId}`;
    },

    async updateEvaluationAsync(evaluation) {
        const identifier = this.getEvaluationIdentifierByObj(evaluation);

        await EvaluationIdbRepository.setAsync(identifier, evaluation);
    },

    isEvaluationArchived(evaluation) {
        return evaluation.isExamFinalized === true || evaluation.isExamBeingProcessedByCaseworker === true;
    },

    getAssesmentAlternativesYesNo() {
        return [
            {
                text: "Ja",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.Yes
            },
            {
                text: "Nei",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.No
            }
        ];
    },
    getAssesmentAlternativesLevel() {
        return [
            {
                text: "Ingen",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.NoKnowledge
            },
            {
                text: "Svak",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.WeakKnowledge
            },
            {
                text: "Ok",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.OkKnowledge
            },
            {
                text: "Bra",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.GoodKnowledge
            }
        ];
    },
    getAssesmentAlternativesLevelWithNotApplicable() {
        return [
            {
                text: "Ingen",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.NoKnowledge
            },
            {
                text: "Svak",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.WeakKnowledge
            },
            {
                text: "Ok",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.OkKnowledge
            },
            {
                text: "Bra",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.GoodKnowledge
            },
            {
                text: "Ikke aktuelt",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.NotApplicable
            }
        ];
    },
    getAssesmentAlternativesCompleteType() {
        return [
            {
                text: "Utført",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.Completed
            },
            {
                text: "Ikke utført",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.NotCompleted
            }
        ];
    },
    getAssesmentAlternativesDayNight() {
        return [
            {
                text: "Dag",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.Day
            },
            {
                text: "Natt",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.Night
            },
            {
                text: "Dag og natt",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.DayNight
            }
        ];
    },
    getAssesmentAlternativesRecommendationType() {
        return [
            {
                text: "Anbefalt",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.Recommended
            },
            {
                text: "Ikke anbefalt",
                isSelected: false,
                systemName: EvaluationAssessmentEnum.NotRecommended
            }
        ];
    },

    getYesNoAlternative(obj) {
        if (!isBoolean(obj)) return obj;

        switch (obj) {
            case true:
                return EvaluationAssessmentEnum.Yes;
            case false:
                return EvaluationAssessmentEnum.No;
            default:
                return null;
        }
    },

    async processEvaluationsAsync(evaluations) {

        let localArchivedEvaluations = [];
        let localCompletedEvaluations = [];
        let localStartedEvaluations = [];
        let localNewEvaluations = [];

        for (var i = 0; i < evaluations.length; i++) {
            try {
                let evaluation = evaluations[i];
                const identifier = EvaluationService.getEvaluationIdentifierByObj(evaluation);

                const evaluationIdb = await EvaluationIdbRepository.getAsync(identifier);

                if (EvaluationService.isEvaluationArchived(evaluation)) {
                    localArchivedEvaluations.push(evaluation);

                    await EvaluationIdbRepository.setAsync(identifier, evaluation);

                } else {
                    if (navigator.onLine) {
                        //Fetch evaluation to make sure it is put in dynamic cache
                        //CommonService.runFetchAsync(null, 'evaluation?' + identifier, null, null, this.token);
                    }

                    const hasMultibleExaminers = evaluation.hasMultibleExaminers;
                    const examiners = evaluation.examiners;

                    if (evaluationIdb) {
                        if (evaluationIdb.eTagLocal === evaluation.eTagLocal) {
                            evaluation = evaluationIdb;
                            evaluation.evaluationDocument = evaluationIdb.evaluationDocument;
                        }

                        if (evaluation.hasMultibleExaminers !== evaluationIdb.hasMultibleExaminers) {
                            evaluation.hasMultibleExaminers = hasMultibleExaminers;
                            evaluation.examiners = examiners;
                            if (evaluation.hasMultibleExaminers === false) {
                                evaluation.renounceType = null;
                                evaluation.renounceTypeName = null;
                                evaluation.isRenounced = false;
                                evaluation.isRenounceable = false;
                            }
                        }

                    }

                    if (evaluation.evaluationDocument === null) {
                        evaluation.evaluationDocument = await EvaluationIdbRepository.getEvaluationFormAsync();
                    }

                    evaluation.evaluationDocumentJson = JSON.stringify(evaluation.evaluationDocument);
                    await EvaluationIdbRepository.setAsync(identifier, evaluation);

                    // Completed
                    if (evaluation.isCompleted === true) {
                        localCompletedEvaluations.push(evaluation);
                    }

                    // Started
                    else if (evaluation.isEvaluationDocumentEmpty === false || evaluation.isRenounced) {
                        localStartedEvaluations.push(evaluation);
                    }

                    // New
                    else {
                        localNewEvaluations.push(evaluation);
                    }
                }
            } catch {
                publishWarningNotificationTopic("En feil oppstod i prossesering av evalueringer.");
            }
        }

        return {
            archivedEvaluations: localArchivedEvaluations,
            completedEvaluations: localCompletedEvaluations,
            startedEvaluations: localStartedEvaluations,
            newEvaluations: localNewEvaluations,
        };
    }
};

export default EvaluationService;
