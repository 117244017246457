import React, { useEffect, useState } from 'react';
import { PilotagePilotVariableCompensationTypeEnums } from '../../../services/SystemNames';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import { VariableCompensationItem } from './VariableCompensationCard';
import { VariableCompensationBridgeDialog } from './dialogs/VariableCompensationBridgeDialog';
import CompensationApiRepository from '../../../repositories/api/CompensationApiRepository';
import { deepCopyObject, isArrayEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishErrorNotificationTopic } from '../../../components/helpers/PubSubHelpers';

const itemAction = {
    Calculate: "Calculate",
    View: "View"
}

const dialogStates = {
    Override: "Override",
    Calculated: "Calculated",
    Edit: "Edit"
}

export const VariableCompensationBridgeCard = ({ pilotageCompensations, handleUpdate}) => {
    const [hasDayValidationError, setDayHasValidationError] = useState(false);
    const [hasNightValidationError, setNightHasValidationError] = useState(false);
    const [dialogState, setDialogState] = useState("");
    const [bridgeTimeDay, setBridgeTimeDay] = useState(null);
    const [bridgeTimeNight, setBridgeTimeNight] = useState(null);
    const [currentCompensation, setCurrentCompensation] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isAttentionRequired, setIsAttentionRequired] = useState(false);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Card
                properties={{
                    ...CardProperties,
                    hasValidationError: (hasDayValidationError || hasNightValidationError),
                    title: "Brotillegg",
                    subTitle: isAttentionRequired ? "Korrigeringskrav" : "",
                    subTitleClassName: "semi-bold",
                }}>
                <div className="row">
                    <VariableCompensationItem
                        title="Dag"
                        hasValidationError={hasDayValidationError}
                        isEditable={isEditable}
                        compensation={bridgeTimeDay}
                        onEdit={onBridgeTimeClick}
                        onDelete={onDeleteBridgeTime}
                    />
                    <VariableCompensationItem
                        title="Natt"
                        hasValidationError={hasNightValidationError}
                        isEditable={isEditable}
                        compensation={bridgeTimeNight}
                        onEdit={onBridgeTimeClick}
                        onDelete={onDeleteBridgeTime}
                    />
                </div>
            </Card>
            {
                (dialogState === dialogStates.Edit) &&
                    <VariableCompensationBridgeDialog
                        onClose={() => setDialogState("")}
                        compensation={currentCompensation}
                        callback={onBridgeTimeCallback}
                    />
            }
        </>
    )

    async function initializeAsync() {
        setIsEditable(true);

        const calculatedDay = pilotageCompensations.variableCompensations.find(v => v.type.systemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay);
        const calculatedNight = pilotageCompensations.variableCompensations.find(v => v.type.systemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight);

        setBridgeTimeDay(calculatedDay.calculatedNumber);
        setBridgeTimeNight(calculatedNight.calculatedNumber);

        setCompensation(setBridgeTimeDay, PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay);
        setCompensation(setBridgeTimeNight, PilotagePilotVariableCompensationTypeEnums.BridgeTimeNight);
    }

    function setCompensation(set, systemName) {
        const compensations = pilotageCompensations.variableCompensations;
        if (isArrayEmpty(compensations)) return;

        const compensation = compensations.find(c => c.type.systemName === systemName);

        if (isObjectNull(compensation)) return;
        compensation.isAttentionRequired && setIsAttentionRequired(true);

        set(compensation);
    }

    function onBridgeTimeCallback(compensation) {

        if (compensation.type.systemName === PilotagePilotVariableCompensationTypeEnums.BridgeTimeDay) {
            setBridgeTimeDay(compensation);
        } else {
            setBridgeTimeNight(compensation);
        }

        onUpdateVariableCompensationOverriddenNumberAsync(compensation);
    }

    async function onUpdateVariableCompensationOverriddenNumberAsync(obj) {

        const compensations = pilotageCompensations.variableCompensations;
        const compensation = compensations.find(c => c.type.systemName === obj.type.systemName);
        const index = compensations.indexOf(compensation);

        compensations[index] = obj;

        handleUpdate(compensations);
    }

    function onBridgeTimeClick(compensation) {
        if (!isEditable) return;

        setCurrentCompensation(compensation);
        setDialogState(dialogStates.Edit);
    }

    async function onDeleteBridgeTime(compensation) {
        const compensationCopy = deepCopyObject(compensation);

        compensationCopy.overriddenNumber = null;
        compensationCopy.overrideReasonType = null;
        compensationCopy.overrideReasonRemark = null;

        const response = await CompensationApiRepository.updatePilotageVariableCompensation(compensationCopy);
        if (response.ok) {
            onBridgeTimeCallback(compensationCopy);
        } else {
            publishErrorNotificationTopic("Noe gikk galt");
        } 
    }
}