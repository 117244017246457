import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Spacer from '../../../../components/layout/Spacer';
import ContentGrid from '../../../../components/layout/ContentGrid';
import { OvertimeCard } from './OvertimeCard';
import { HourCompensationCard } from './HourCompensationCard';
import { ManeuverResponsibleCard } from './ManeuverResponsibleCard';
import { VariableCompensationCard } from './VariableCompensationCard';
import { StandardTripCard } from './StandardTripCard';
import { RemarkCard } from './RemarkCard';
import { ReimbursementCard } from './ReimbursementCard';
import { WastedTripCard } from './WastedTripCard';
import { CompleteCard } from './CompleteCard';
import { TimeCard } from './TimeCard';
import { CorrectionCard } from './CorrectionCard';
import { ConvertToPilotInclCard } from './ConvertToPilotInclCard';
import { BothPilotAndExaminerCard } from './BothPilotAndExaminerCard';
import { TowingCard } from './TowingCard';
import { getPilotAssignmentAsync, hasPilotAssignmentInvoice, isPilotAssignmentCompleted } from '../helpers/PilotAssignmentHelpers';
import { hasLocalChanges } from '../helpers/PilotAssignmentCommandHelpers';
import { UploadIcon } from '../../../../components/layout/icons/Icons';
import FloatingButtonContainer from '../../../../components/layout/FloatingButtonContainer';
import FloatingActionButton from '../../../../components/layout/FloatingActionButton';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';

export const PilotAssignmentCard = forwardRef((
    {
        pilotAssignment,
        isConfirmed,
        onShowModalMenu,
        setIsBusy,
        onSaveAsync
    }, ref) => {

    const [canSave, setCanSave] = useState(false);

    const wastedTripRef = useRef();
    const timeRef = useRef();
    const standardTripRef = useRef();
    const towingRef = useRef();
    const maneuverResponsibleRef = useRef();
    const variableCompensationRef = useRef();
    const overtimeRef = useRef();
    const hourCompensationRef = useRef();
    const reimbursementRef = useRef();
    const remarkRef = useRef();
    const convertToPilotInclRef = useRef();
    const bothPilotAndExaminerRef = useRef();
    const completeRef = useRef();

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();

            wastedTripRef.current.onPilotageChanged();
            timeRef.current.onPilotageChanged()
            standardTripRef.current.onPilotageChanged()
            towingRef.current.onPilotageChanged()
            maneuverResponsibleRef.current.onPilotageChanged()
            variableCompensationRef.current.onPilotageChanged()
            overtimeRef.current.onPilotageChanged()
            hourCompensationRef.current.onPilotageChanged()
            reimbursementRef.current.onPilotageChanged()
            remarkRef.current.onPilotageChanged()
            convertToPilotInclRef.current.onPilotageChanged()
            bothPilotAndExaminerRef.current.onPilotageChanged()
            completeRef.current.onPilotageChanged()
        },
        onValidationChanged(codeStrings) {
            variableCompensationRef.current.onValidationChanged(codeStrings);
            timeRef.current.onValidationChanged(codeStrings);
            towingRef.current.onValidationChanged(codeStrings);
            standardTripRef.current.onValidationChanged(codeStrings);
            maneuverResponsibleRef.current.onValidationChanged(codeStrings);
        }
    }));

    useState(() => {
        initializeAsync();
    }, []);

    return (
        <>
            <ContentGrid
                className="pilotage-pilot-content"
                title={pilotAssignment.pilotage.pilotageShip.shipName}
                titleIcon="ship"
                subTitle={`${pilotAssignment.pilotage.pilotageNo}`}
                canCopySubTitle={true}>

            <div className="pilotage-pilot">
                <CorrectionCard
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}/>

                <WastedTripCard
                    ref={wastedTripRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onWastedTripChanged={onWastedTripChanged}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />
                <Spacer height={30} />

                <TimeCard
                    ref={timeRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <Spacer height={30} />

                <StandardTripCard
                    ref={standardTripRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    isConfirmed={isConfirmed}
                    onShowModalMenu={onShowModalMenu}
                    onLocationChanged={onLocationChanged}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <TowingCard
                    ref={towingRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <ManeuverResponsibleCard
                    ref={maneuverResponsibleRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <VariableCompensationCard
                    ref={variableCompensationRef}
                    setIsBusy={setIsBusy}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onShowModalMenu={onShowModalMenu}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <OvertimeCard
                    ref={overtimeRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onShowModalMenu={onShowModalMenu}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                    />

                <Spacer height={15} />

                <HourCompensationCard
                    ref={hourCompensationRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                    onShowModalMenu={onShowModalMenu}/>

                <Spacer height={15} />

                <ReimbursementCard
                    ref={reimbursementRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <Spacer height={30} />

                <RemarkCard
                    ref={remarkRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <ConvertToPilotInclCard
                    ref={convertToPilotInclRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onExaminerOnlyChanged={onExaminerOnlyChanged}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <BothPilotAndExaminerCard
                    ref={bothPilotAndExaminerRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />

                <CompleteCard
                    ref={completeRef}
                    pilotAssignmentId={pilotAssignment.pilotagePilotId}
                    onShowModalMenu={onShowModalMenu}
                    onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                />
                <Spacer height={30} />
            </div>
            </ContentGrid>

            {
                (isSaveActive()) &&
                <FloatingButtonContainer hasBottomNavigation={true}>
                    <FloatingActionButton
                        themeColor={canSave ? "primary" : "secondary"}
                        disabled={!canSave}
                        svgIcon={UploadIcon}
                        onClick={onSaveAsync}
                    />
                </FloatingButtonContainer>
            }
        </>
    )

    async function initializeAsync() {
        const dto = await getPilotAssignmentAsync(pilotAssignment.pilotagePilotId);
        setCanSave(hasLocalChanges(dto));
    }

    function onLocationChanged() {
        timeRef.current.onLocationChanged();
        variableCompensationRef.current.onLocationChanged();
    }

    function onExaminerOnlyChanged() {
        variableCompensationRef.current.onExaminerOnlyChanged();
    }

    function onWastedTripChanged(isWasted) {
        maneuverResponsibleRef.current.onWastedTripChanged();
        variableCompensationRef.current.onWastedTripChanged();
        standardTripRef.current.onWastedTripChanged(isWasted);
    }

    function isSaveActive() {
        if (!navigator.onLine) return false;

        return !isPilotAssignmentCompleted(pilotAssignment) ||
            (isPilotAssignmentCompleted(pilotAssignment) && !hasPilotAssignmentInvoice(pilotAssignment))
    }

    async function onUpdatePilotAssignmentAsync(pilotAssignment) {
        await PilotAssignmentIdbRepository.setAsync(pilotAssignment);
        setCanSave(hasLocalChanges(pilotAssignment));
    }
})
