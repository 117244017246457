import BaseApiRepository from "./BaseApiRepository";
import Moment from 'moment';
import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";

const PilotageApiRepository = {

    async getFromToAsync(id) {
        return await BaseApiRepository.getAsync(`pilotage/${id}/from-to`);
    },

    async getByIdAsync(id) {
        return await BaseApiRepository.getAsync(`pilotage/${id}/pilotage-pilot`);
    },

    async orderPilotTransportOrder(pilotagePilotId, pilotTransportOrderId, pilotTransportOrderTime) {
        pilotTransportOrderTime = Moment(pilotTransportOrderTime).format('YYYY-MM-DDTHH:mm:ss');
        return await BaseApiRepository.postAsync(`pilotage/${pilotagePilotId}/transport-order/${pilotTransportOrderId}?pilotTransportOrderTime=${pilotTransportOrderTime}`);
    },

    async updatePilotTransportOrder(pilotagePilotId, pilotTransportOrderId, pilotTransportOrderTime) {
        pilotTransportOrderTime = Moment(pilotTransportOrderTime).format('YYYY-MM-DDTHH:mm:ss');
        return await BaseApiRepository.putAsync(`pilotage/${pilotagePilotId}/transport-order/${pilotTransportOrderId}?pilotTransportOrderTime=${pilotTransportOrderTime}`);
    },

    async acceptPilotage(pilotAssignmentId) {
        return await BaseApiRepository.putAsync(`pilotage/accept/${pilotAssignmentId}`);
    },

    async rejectPilotage(pilotAssignmentId, rejectionReason) {
        return await BaseApiRepository.putAsync(`pilotage/reject/${pilotAssignmentId}/${rejectionReason}`);
    },

    async updatePilotageDuration(pilotageDetailId, toTime) {
        toTime = Moment(toTime).format('YYYY-MM-DDTHH:mm:ss');
        return await BaseApiRepository.putAsync(`pilotage/${pilotageDetailId}/pilotage-duration?toTime=${toTime}`);
    },

    async getPilotageReceipt(pilotageId) {
        return await BaseApiRepository.getAsync(`pilotage/pilotage-receipt/${pilotageId}`);
    },

    async getPilotageVoyageAsync(pilotageId) {
        return await BaseApiRepository.getAsync(`pilotage/${pilotageId}/voyage`);
    },
};
wrapMethodsWithLogging(PilotageApiRepository);

export default PilotageApiRepository;
