import React from 'react';
import {
    Card as KendoCard
} from "@progress/kendo-react-layout";
import { CardService } from '../CardService';
import { Asterisk } from "phosphor-react-sc";
import { isArrayEmpty } from '../../../helpers/ObjectHelpers';

export const CardContent = (
    {
        children,
        properties = {},
        isSwipeLeftActive = false
    }) => {
        

    return (
        <KendoCard
            className={`card-item-content ${isSwipeLeftActive ? 'card-content-right-active' : ''} ${properties.isTentative ? 'card-item-content-border-tentative' : properties.className} ${properties.hasValidationError ? 'card-validation-error' : ''}`}
            style={CardService.getStyle(properties.borderColor, properties.backgroundColor)}>
            {
                (properties.isDirty) &&
                <div className={`card-item-content-dirty ${getDirtyClassName()}`} style={getDirtyStyle()}>
                    <Asterisk color="var(--card-dirty-color)" />
                </div>
            }
            {children}
        </KendoCard>
    );

    function getDirtyClassName() {
        if (!isArrayEmpty(properties.actions)) return "";
        return "card-item-content-dirty-no-action";
    }

    function getDirtyStyle() {

        let right = 0;

        if (!isSwipeLeftActive) {
            if (!isArrayEmpty(properties.actions)) {
                right += 46 * properties.actions.length;
            }

            if (properties.canDelete()) {
                right += 46;
            }
        }

        if (right > 0) {
            right += 2;
        }

        return {
            right: right
        };
    }
};