import { isNullOrEmpty } from "./ObjectHelpers";
import moment from 'moment';

function prepareDate(date) {
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}

function formatDateTime(dateTime) {
    return moment(dateTime).format('YYYY-MM-DDTHH:mm:ss');
}

function getFormattedDateTime(dateTime) {
    if (isNullOrEmpty(dateTime)) return null;
    return formatDateTime(dateTime);
}

function copyMoment(obj) {
    return moment(obj.toDate());
}

function getFormatMoment(obj) {
    return moment(formatMoment(obj));
}

function formatMoment(obj) {
    return `${obj.format('YYYY')}-${obj.format('MM')}-${obj.format('DD')}T${obj.format('HH')}:${obj.format('mm')}:00`;
}

function fullFormatMoment(obj) {
    return `${obj.format('YYYY')}-${obj.format('MM')}-${obj.format('DD')}T${obj.format('HH')}:${obj.format('mm')}:${obj.format('ss')}`;
}

function formatMomentToHourMinutes(obj) {
    return moment(obj).format('HH:mm');
}

function formatMomentToDefault(obj) {
    return moment(obj).format('DD.MM HH:mm');
}

function formatMomentToDate(obj) {
    return moment(obj).format('DD:MM');
}

function formatDateTimeForDisplay(dateTime) {
    return moment(dateTime).format('DD.MM.YY HH:mm');
}

function getDateDiffInMinutes(start, stop) {
    const diff = stop.diff(start, 'minutes');
    return diff;
}

export {
    prepareDate,
    getFormattedDateTime,
    formatDateTime,
    copyMoment,
    getFormatMoment,
    formatMoment,
    fullFormatMoment,
    formatMomentToHourMinutes,
    formatMomentToDefault,
    formatMomentToDate,
    formatDateTimeForDisplay,
    getDateDiffInMinutes
}