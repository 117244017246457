import './buttonsgrid.css'
import React from 'react';

export function ButtonsGrid(
    {
        children
    }
) {
    return (
        <div className="buttons-grid">
            <div>
                {children}
            </div>
        </div>
    );
}


