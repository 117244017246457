import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MapPanelSettings } from "./panels/MapPanelSettings"
import { MapPanelSearch } from "./panels/MapPanelSearch";
import { useMap } from "@vis.gl/react-google-maps";
import { getElementById } from "../../../helpers/ElementHelpers";
import { Button } from "@progress/kendo-react-buttons";
import { X } from "phosphor-react-sc";
import { IconColors } from "../../../../services/SystemNames";
import { isObjectNull } from "../../../helpers/ObjectHelpers";

export const PanelsState = {
    Settings: "Settings",
    Search: "Search",
    Empty: "Empty"
}

export const MapPanels = forwardRef(({
    mapId,
    properties,
    pilotage,
    shipPosition,
    onSeaMapClick,
    onLocationsClick,
    onUnselectShip
}, ref) => {

    const settingsRef = useRef();

    const map = useMap();

    const [isAdded, setIsAdded] = useState(false);
    const contentId = `googleMapPanelsContent-${mapId}`;
    const minWindowSize = 470;

    const [isActive, setIsActive] = useState(false);
    const [panelState, setPanelState] = useState(PanelsState.Settings);
    const [panelTabContentHeight, setPanelTabContentHeight] = useState(0);
    const [shouldCloseOnClick, setShouldCloseOnClick] = useState(false);

    const componentRef = useRef({
        isActive: false
    });
    const { current: localRef } = componentRef;

    useImperativeHandle(ref, () => ({
        deactivateLegend() {
            settingsRef.current?.deactivateLegend();
        },
        onShow(state) {
            setPanelState(state);
            setIsActive(true);
            localRef.isActive = true;
        }
    }));

    useEffect(() => {

        if (!properties.showSettings) return;
        if (!map || isAdded) return;

        const content = getElementById(contentId);
        map.controls[window.google.maps.ControlPosition.LEFT_CENTER].push(content);

        setIsAdded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties]);

    useEffect(() => {
        calculateContentHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    useEffect(() => {
        function handleResize() {
            calculateContentHeight();
        }

        window.addEventListener("resize", handleResize);

        return (() => {
            window.removeEventListener("offline", handleResize);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id={contentId} className={`google-map-panels ${localRef.isActive ? 'google-map-panels-active' : ''}`}>
            <div className="google-map-panels-menu">
                <div>
                    {getTitle()}
                </div>
                <div>
                    <Button fillMode="flat" onClick={onClose}>
                        <X color={IconColors.Primary} size={20} />
                    </Button>
                </div>
            </div>
            <div className="google-map-panels-content">
                <MapPanelContent show={panelState === PanelsState.Search}>
                    <MapPanelSearch
                        map={map}
                        onUnselectShip={onUnselectShip}
                        panelTabContentHeight={panelTabContentHeight}
                        shouldCloseOnClick={shouldCloseOnClick}
                        onClose={onClose}
                    />
                </MapPanelContent>
                <MapPanelContent show={panelState === PanelsState.Settings}>
                    <MapPanelSettings
                        ref={settingsRef}
                        mapId={mapId}
                        properties={properties}
                        pilotage={pilotage}
                        shipPosition={shipPosition}
                        onSeaMapClick={onSeaMapClick}
                        onLocationsClick={onLocationsClick}
                        onClose={onClose}
                        panelTabContentHeight={panelTabContentHeight}
                    />
                </MapPanelContent>
            </div>
        </div>
    )

    function getTitle() {
        switch (panelState) {
            case PanelsState.Search:
                return "Søk i kart";
            case PanelsState.Settings:
                return "Innstillinger";
            default:
                return "";
        }
    }

    function calculateContentHeight() {
        if (!localRef.isActive) return;
        const elem = getElementById(contentId);
        if (!isObjectNull(elem)) {
            setPanelTabContentHeight(elem.offsetHeight - 145);
        }

        setShouldCloseOnClick(window.innerWidth <= minWindowSize);
    }

    function onClose() {
        setIsActive(false);
        localRef.isActive = false;
    }
})

const MapPanelContent = ({ children, show }) => {

    return (
        <div style={{
            display: (show) ? "grid" : "none"
        }}>
            {children}
        </div>
    )
}
