import "../quaymanual.css"
import { useEffect, useState } from "react";
import QuayManualApiRepository from "../../../repositories/api/QuayManualApiRepository";
import PDFViewer from "../../../components/layout/PDFViewer";
import { isNullOrEmpty } from "../../../components/helpers/ObjectHelpers";
import { publishWarningNotificationTopic } from "../../../components/helpers/PubSubHelpers";

const QuayPDF = ({ documentId = 0 }) => {

    const [documentData, setDocumentData] = useState({});

    useEffect(() => {
        fetchDocumentData();
    }, [documentId]);

    const fetchDocumentData = async () => {

        if (documentId > 0 && isNullOrEmpty(documentData.fileName)) {
            var response = await QuayManualApiRepository.getDocument(documentId);

            if (response.ok) {
                var result = await response.json();
                setDocumentData(result);
            }
            else {
                publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
            }
        }
        
    };

    return (
        <PDFViewer
            data={documentData} />
    );
};

export default QuayPDF;