/* eslint-disable react-hooks/exhaustive-deps */
import '../styles/pilot.css';
import React, { useReducer, useEffect, useState } from 'react';
import {
    PageStateAssigned,
    PageStateExecuted,
} from '../components/PilotagesPageStates';
import {
    PageStates
} from '../../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import { DispatcherActions } from '../services/DispatcherActions';
import { useSearchParams } from 'react-router-dom';
import { PilotAssignmentUpdatedDialog } from '../pilot-assignment/components/dialogs/PilotAssignmentUpdatedDialog';
import PubSub from 'pubsub-js';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import Overlay from '../../../components/layout/overlay/Overlay';
import { DialogConfirm } from '../../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import AppInsightsService from '../../../services/AppInsightsService';
import { deepCopyObject, isArrayEmpty, isNumeric, isObjectNull, isTrue } from '../../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic, PubSubTopics } from '../../../components/helpers/PubSubHelpers';
import { addWindowEventListener, hasWindowScrolledToBottom, removeWindowEventListener, scrollCardElementIntoView } from '../../../components/helpers/ElementHelpers';
import { hasLocalChanges } from '../pilot-assignment/helpers/PilotAssignmentCommandHelpers';
import { processPilotAssignment } from '../pilot-assignment/helpers/PilotAssignmentProcessHelpers';
import { convertExecutedPilotAssignmentApiDtoToIdb, convertPilotAssignmentApiDtoToIdb, getPilotAssignmentUrl } from '../pilot-assignment/helpers/PilotAssignmentHelpers';

const headerHeightWithMargin = 70 + 20;

export default function Assigned() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pilotAssignment, setPilotAssignment] = useState(null);
    const [pilotAssignmentIds, setPilotAssignmentIds] = useState([]);
    const [hasImplementedSearchParams, setHasImplementedSearchParams] =
        useState(false);

    const initialState = {
        pageState: PageStates.Default,
        isBusy: true,
        hasErrors: false,
        pilotAssignments: [],
        executedPilotAssignments: [],
        isFetchingMore: false,
        canFetchMore: false,
        toggle: false,
    };

    const reducer = (state = initialState, action) => {
        switch (action.type) {
            case DispatcherActions.IsBusy:
                return Object.assign({}, state, {
                    isBusy: action.payload,
                    hasErrors: false,
                });

            case DispatcherActions.HasErrors:
                return Object.assign({}, state, {
                    hasErrors: true,
                    isBusy: false,
                });

            case DispatcherActions.Initialized:
                return Object.assign({}, state, {
                    pilotAssignments: action.payload,
                    isBusy: false,
                    hasErrors: false,
                });

            case DispatcherActions.IsFetchingMore:
                return Object.assign({}, state, {
                    isFetchingMore: action.payload,
                });

            case DispatcherActions.CanFetchMore:
                return Object.assign({}, state, {
                    canFetchMore: action.payload,
                });

            case DispatcherActions.Toggle:
                return Object.assign({}, state, {
                    toggle: !state.toggle,
                });

            case DispatcherActions.PilotAssignments:
                return Object.assign({}, state, {
                    pilotAssignments: action.payload,
                    toggle: !state.toggle,
                });

            case DispatcherActions.ExecutedPilotAssignments:
                return Object.assign({}, state, {
                    executedPilotAssignments: action.payload,
                });

            case DispatcherActions.Reset:
                return Object.assign({}, state, {
                    pilotAssignments: [],
                    executedPilotAssignments: [],
                    isFetchingMore: false,
                    canFetchMore: false,
                });

            case DispatcherActions.PageState:
                return Object.assign({}, state, {
                    pageState: action.payload,
                });

            default:
                return state;
        }
    };

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    };

    // Initialize function
    useEffect(() => {
        publishHeaderTopic("Mine losoppdrag");
        PubSub.subscribe(PubSubTopics.RefreshedData, initializeAsync);

        initializeAsync();

        return () => {
            PubSub.unsubscribe(PubSubTopics.RefreshedData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        addWindowEventListener("scroll", onScroll);
        return () => {
            removeWindowEventListener("scroll", onScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onScroll]);

    useEffect(() => {
        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        return () => {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleOnOffline]);

    useEffect(() => {
        const pilotagePilotIdParam = searchParams.get("pilotagePilotId");
        if (isNumeric(pilotagePilotIdParam)) {
            scrollCardElementIntoView(
                pilotagePilotIdParam,
                headerHeightWithMargin,
            );
        }
    }, [state.executedPilotAssignments]);

    return (
        <>
            {(() => {
                switch (state.pageState) {
                    case PageStates.Default:
                        return (
                            <PageStateAssigned
                                state={state}
                                onClick={onClickAsync}
                                initialize={initializeAsync}
                                onDelete={onDeleteAsync}
                                onDeleteMultible={onDeleteMultibleAsync}
                            />
                        );
                    case PageStates.Executed:
                        return (
                            <PageStateExecuted
                                state={state}
                                onClick={onClickAsync}
                                onPullToRefresh={() => {
                                    fetchExecutedAsync(false, true);
                                }}
                                onDelete={onDeleteAsync}
                            />
                        );
                    default:
                        return null;
                }
            })()}

            <BottomNavigation
                onCallback={onBottomMenuSelect}
                selectedAction={state.pageState}
                items={[
                    {
                        text: "Aktuelle",
                        icon: "anchor",
                        action: PageStates.Default,
                    },
                    {
                        text: "Utførte",
                        icon: "ok",
                        action: PageStates.Executed,
                    },
                ]}
            />

            <Overlay
                isBusy={state.isBusy}
                onReloadClick={onReloadClick}
                hasErrors={state.hasErrors}
            />

            {showDialog && (
                <PilotAssignmentUpdatedDialog
                    onClose={onDialogClickAsync}
                    onClick={onDialogClickAsync}
                />
            )}

            {showConfirmDialog && (
                <DialogConfirm
                    properties={{
                        ...DialogProperties,
                        title: "Slett losoppdrag",
                        text: "Ønsker du å slette valgte losoppdrag?",
                        onClose: () => {
                            setShowConfirmDialog(false);
                            setPilotAssignmentIds([]);
                        },
                        onClick: onConfirmDeleteCallback,
                    }}
                />
            )}
        </>
    );

    function handleOnOffline() {
        dispatch(DispatcherActions.Reset);

        switch (state.pageState) {
            case PageStates.Executed:
                fetchExecutedAsync(false, true);
                break;
            default:
                initializeAsync();
                break;
        }
    }

    async function initializeAsync() {
        // clear lists
        dispatch(DispatcherActions.PilotAssignments, []);
        dispatch(DispatcherActions.ExecutedPilotAssignments, []);

        const completedParam = searchParams.get("completed");

        if (navigator.onLine) {
            let executed = false;
            if (!hasImplementedSearchParams) {
                executed = isTrue(completedParam);
            }

            if (executed) {
                dispatch(DispatcherActions.PageState, PageStates.Executed);
                publishHeaderTopic("Fullførte losoppdrag");
                await fetchExecutedAsync();
            } else {
                await initializeOnlineAsync();
            }
        } else {
            await initializeOfflineAsync();
        }

        await PilotAssignmentIdbRepository.cleanUpAsync();

        setHasImplementedSearchParams(true);
    }

    async function initializeOnlineAsync() {
        dispatch(DispatcherActions.IsBusy, true);

        const response = await PilotApiRepository.getAssignedPilotagesAsync();
        if (response.ok === true) {
            const data = await response.json();

            if (!isArrayEmpty(data)) {
                let result = [];

                for (const dto of data) {
                    const pilotAssignment =
                        convertPilotAssignmentApiDtoToIdb(dto);
                    const idbPilotAssignment =
                        await PilotAssignmentIdbRepository.getAsync(
                            pilotAssignment.pilotagePilotId,
                        );

                    try {
                        if (!isObjectNull(idbPilotAssignment)) {
                            const processedPilotAssignment =
                                processPilotAssignment(
                                    idbPilotAssignment,
                                    pilotAssignment,
                                );
                            await PilotAssignmentIdbRepository.setAsync(
                                processedPilotAssignment,
                            );
                            result.push(processedPilotAssignment);
                        } else {
                            await PilotAssignmentIdbRepository.setAsync(
                                pilotAssignment,
                            );
                            result.push(pilotAssignment);
                        }
                    } catch (e) {
                        console.warn(e);
                        AppInsightsService.trackException(e);
                        const warning = `Kunne ikke behandle losoppdrag ${pilotAssignment.pilotage.pilotageShip.shipName} ${pilotAssignment.pilotage.pilotageNo}.`;
                        if (!isObjectNull(idbPilotAssignment)) {
                            publishWarningNotificationTopic(warning);
                            result.push(idbPilotAssignment);
                        } else {
                            result.push(pilotAssignment);
                        }
                    }
                }

                const idbPilotages =
                    await PilotAssignmentIdbRepository.getAllActualAsync();
                if (!isArrayEmpty(idbPilotages)) {
                    for (const idbPilotage of idbPilotages) {
                        const dto = data.find(
                            (x) =>
                                x.pilotagePilotId ===
                                idbPilotage.pilotagePilotId,
                        );
                        if (isObjectNull(dto)) {
                            await PilotAssignmentIdbRepository.deleteAsync(
                                idbPilotage,
                            );
                        }
                    }
                }

                setPilotAssignments(result);
            } else {
                await deleteIdbPilotagesAsync();
            }

            dispatch(DispatcherActions.IsBusy, false);
        } else {
            handleError(response);
            await initializeIdbPilotAssignmentsAsync();
        }

        updateUrl(0, false, 0);
    }

    async function initializeOfflineAsync() {
        dispatch(DispatcherActions.IsBusy, true);

        await initializeIdbPilotAssignmentsAsync();

        dispatch(DispatcherActions.IsBusy, false);
    }

    async function initializeIdbPilotAssignmentsAsync() {
        const pilotAssignments =
            await PilotAssignmentIdbRepository.getAllActualAsync();
        setPilotAssignments(pilotAssignments);
    }

    async function deleteIdbPilotagesAsync() {
        const idbPilotages =
            await PilotAssignmentIdbRepository.getAllActualAsync();
        if (isArrayEmpty(idbPilotages)) return;

        for (const idbPilotage of idbPilotages) {
            await PilotAssignmentIdbRepository.deleteAsync(idbPilotage);
        }
    }

    function setPilotAssignments(pilotAssignments) {
        dispatch(DispatcherActions.Initialized, pilotAssignments);

        toggle();
    }

    function fetchMoreExecuted() {
        fetchExecutedAsync(true);
    }

    async function fetchExecutedAsync(
        isFetchingMore = false,
        forceReset = false,
    ) {
        const dtos = await PilotAssignmentIdbRepository.getAllExecutedAsync();
        for (const dto of dtos) {
            await PilotAssignmentIdbRepository.deleteAsync(dto);
        }
        if (!navigator.onLine) return;

        fetchExecutedOnline(isFetchingMore, forceReset);
    }

    async function fetchExecutedOnline(
        isFetchingMore = false,
        forceReset = false,
    ) {
        if (state.isFetchingMore) return;

        if (isFetchingMore) {
            dispatch(DispatcherActions.IsFetchingMore, true);
        } else {
            dispatch(DispatcherActions.IsBusy, true);
        }

        // batch number
        const toTime = getToTime(forceReset);
        const batchSize = getBatchSize();

        const response = await PilotApiRepository.getExecutedPilotagesAsync(
            toTime,
            batchSize,
        );
        if (response.ok === true) {
            const data = await response.json();
            const result = [];

            data.forEach((dto) => {
                result.push(convertExecutedPilotAssignmentApiDtoToIdb(dto));
            });

            let batchSize = 0;
            if (state.executedPilotAssignments.length === 0 || forceReset) {
                dispatch(DispatcherActions.ExecutedPilotAssignments, result);
                batchSize = result.length;
            } else {
                result.forEach((item) => {
                    state.executedPilotAssignments.push(item);
                });
                batchSize = state.executedPilotAssignments.length;
            }

            updateUrl(batchSize, true);

            if (isFetchingMore) {
                dispatch(DispatcherActions.IsFetchingMore, false);
            } else {
                dispatch(DispatcherActions.PageState, PageStates.Executed);
            }

            dispatch(DispatcherActions.CanFetchMore, data.length > 0);

            toggle();
        } else {
            handleError(response);
        }

        dispatch(DispatcherActions.IsBusy, false);
    }

    function onBottomMenuSelect(item) {
        const pageState = item.action;
        if (state.pageState === pageState) return;

        dispatch(DispatcherActions.PageState, pageState);

        switch (pageState) {
            case PageStates.Executed:
                if (state.executedPilotAssignments.length === 0) {
                    fetchExecutedAsync();
                } else {
                    updateUrl(state.executedPilotAssignments.length, true, 0);
                }
                publishHeaderTopic("Fullførte losoppdrag");
                break;
            default:
                if (state.pilotAssignments.length === 0) {
                    initializeAsync();
                } else {
                    updateUrl(0, false, 0);
                }
                publishHeaderTopic("Mine losoppdrag");
                break;
        }
    }

    async function onClickAsync(pilotAssignment) {
        updateUrl(
            state.executedPilotAssignments.length,
            state.pageState === PageStates.Executed,
            pilotAssignment.pilotagePilotId,
        );

        if (pilotAssignment.wasServerUpdated === true) {
            setShowDialog(true);
            setPilotAssignment(pilotAssignment);
        } else {
            navigate(getPilotAssignmentUrl(pilotAssignment));
        }
    }

    async function onDialogClickAsync() {
        const dto = await PilotAssignmentIdbRepository.getAsync(
            pilotAssignment.pilotagePilotId,
        );
        dto.wasServerUpdated = false;
        await PilotAssignmentIdbRepository.setAsync(dto);

        navigate(getPilotAssignmentUrl(dto));
    }

    function handleError(response) {
        publishWarningNotificationTopic(
            "Kunne ikke hente inn mine oppdrag fra serveren.",
        );
        dispatch(DispatcherActions.HasErrors);
    }

    function onReloadClick() {
        initializeAsync();
    }

    function onScroll() {
        if (!navigator.onLine) return;
        if (
            state.canFetchMore === false ||
            state.pageState !== PageStates.Executed
        )
            return;
        if (!hasWindowScrolledToBottom()) return;

        fetchMoreExecuted();
    }

    function getToTime(forceReset = false) {
        if (isArrayEmpty(state.executedPilotAssignments) || forceReset)
            return Moment();
        return Moment(
            state.executedPilotAssignments[
                state.executedPilotAssignments.length - 1
            ].pilotage.pilotageDetail.fromTime,
        ).add(-1, "S");
    }

    function getBatchSize() {
        if (hasImplementedSearchParams) return 0;
        const batchSizeParam = searchParams.get("batchSize");
        if (!isNumeric(batchSizeParam)) return 0;

        return batchSizeParam;
    }

    async function onDeleteAsync(pilotagePilot) {
        setPilotAssignmentIds([pilotagePilot.pilotagePilotId]);

        if (hasLocalChanges(pilotagePilot)) {
            setShowConfirmDialog(true);
        } else {
            thenDeleteAsync([pilotagePilot.pilotagePilotId]);
        }
    }

    async function onDeleteMultibleAsync(pilotAssignmentIds) {
        setPilotAssignmentIds(pilotAssignmentIds);

        let hasChanges = false;

        for (let i = 0; i < pilotAssignmentIds.length; i++) {
            const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(
                pilotAssignmentIds[i],
            );
            hasChanges = hasLocalChanges(pilotAssignment);

            if (hasChanges) break;
        }

        if (hasChanges) {
            setShowConfirmDialog(true);
        } else {
            thenDeleteAsync(pilotAssignmentIds);
        }
    }

    async function thenDeleteAsync(pilotAssignmentIds) {
        let filteredPilotAssignments = deepCopyObject(state.pilotAssignments);

        for (let i = 0; i < pilotAssignmentIds.length; i++) {
            const id = pilotAssignmentIds[i];
            const pilotAssignment =
                await PilotAssignmentIdbRepository.getAsync(id);

            if (!isObjectNull(pilotAssignment)) {
                await PilotAssignmentIdbRepository.deleteAsync(pilotAssignment);
            }

            filteredPilotAssignments = filteredPilotAssignments.filter(
                (pp) => pp.pilotagePilotId !== id,
            );
        }

        dispatch(DispatcherActions.PilotAssignments, filteredPilotAssignments);

        setPilotAssignmentIds([]);
        setShowConfirmDialog(false);
    }

    async function onConfirmDeleteCallback() {
        if (!isArrayEmpty(pilotAssignmentIds)) {
            thenDeleteAsync(pilotAssignmentIds);
        } else {
        }
    }

    function toggle() {
        setTimeout(() => {
            dispatch(DispatcherActions.Toggle);
        }, 100);
    }

    function updateUrl(batchSize = 0, completed = false, pilotagePilotId = -1) {
        const pilotagePilotIdParam = searchParams.get("pilotagePilotId");
        if (pilotagePilotId === -1 && isNumeric(pilotagePilotIdParam)) {
            pilotagePilotId = Number(pilotagePilotIdParam);
        }

        setSearchParams(
            {
                batchSize: batchSize,
                completed: completed,
                pilotagePilotId: pilotagePilotId,
            },
            { replace: true },
        );
    }
}
