import { useNavigate } from 'react-router-dom';
import { HomeContent, HomeOnlineCard } from './HomeComponents';

export const HomeAllPilotagesCard = ({ canShow, isPilot }) => {

    const navigate = useNavigate();

    return (
        <HomeOnlineCard
            canShow={canShow}
            onClick={onClick}
            className={isPilot ? "" : "sm-col-span-2"}>
            <HomeContent icon="ship">
                <div className="k-d-grid">
                    <div className="home-content-title text-truncate">Alle oppdrag</div>
                </div>
            </HomeContent>
        </HomeOnlineCard>
    )

    function onClick() {
        navigate('/pilotages/current');
    }
}
