/*eslint unicode-bom: ["error", "always"]*/

import React from 'react';
import Overlay from '../layout/overlay/Overlay';

function PleaseWait() {
    return (
        <Overlay isBusy={true} busyText={"Vennligst vent..."} />
    );
}

export default PleaseWait;