import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PubSub from 'pubsub-js';
import { Dialog } from './dialogs/Dialog';
import { DialogProperties } from './dialogs/DialogProperties';
import { PubSubTopics } from '../helpers/PubSubHelpers';

export const NewVersion = forwardRef(({
    setIsNewVersionAvailable
}, ref) => {

    useImperativeHandle(ref, () => ({
        reload() {
            reloadPageAsync();
        }
    }));

    const intervalRef = useRef(null);

    const [hasNewVersion, setHasNewVersion] = useState(true);
    const [showNewVersionAvailable, setShowNewVersionAvailable] = useState(false);
    const [serviceWorkerToInstall, setServiceWorkerToInstall] = useState(undefined);

    // Initialize function
    useEffect(() => {
        PubSub.subscribe(PubSubTopics.UpdateServiceWorker, handleMessage);

        intervalRef.current = setInterval(() => checkForNewVersionAsync(), 600000);// Check for new version every 10 minutes
        
        return () => {
            PubSub.unsubscribe(PubSubTopics.UpdateServiceWorker);

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        (hasNewVersion && showNewVersionAvailable) &&
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Ny versjon tilgjengelig",
                mode: "confirmation",
                text: "&Oslash;nsker du &aring; oppdatere til ny versjon?",
                onClick: reloadPageAsync,
                onClose: () => setShowNewVersionAvailable(false)
            }}
        />
    );


    function handleMessage(msg, data) {
        handleUpdateServiceWorker(data);
    }

    async function checkForNewVersionAsync() {
        if (!navigator.onLine) return;

        //Looking for waiting service worker
        await navigator.serviceWorker.getRegistrations()
            .then(registrations => {
                var waitingSW;
                if (registrations[0] && registrations[0].waiting && registrations[0].waiting.serviceWorker) {
                    waitingSW = registrations[0].waiting.serviceWorker;
                }
                setServiceWorkerToInstall(waitingSW);
            })
            .catch(er => { });

        if (serviceWorkerToInstall) {
            //Waiting service worker found, showing new version banner
            setHasNewVersion(true);
            setShowNewVersionAvailable(true);
            setIsNewVersionAvailable(true);
        }
        else {
            //No waiting service worker found, trying to update service worker registration manually
            await navigator.serviceWorker.getRegistrations()
                .then(registrations => {
                    var currentServiceWorker = registrations[0];

                    if (currentServiceWorker) {
                        currentServiceWorker.update();
                    }
                });
        }
    }

    function handleUpdateServiceWorker(data) {
        if (!navigator.onLine) return;

        if (data) {
            //New service worker is available
            setHasNewVersion(true);
            setShowNewVersionAvailable(true);
            setServiceWorkerToInstall(data);
            setIsNewVersionAvailable(true);
        }
    }

    async function reloadPageAsync() {
        if (navigator.onLine === false) return;

        if (serviceWorkerToInstall) {
            setHasNewVersion(false);
            serviceWorkerToInstall.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
            serviceWorkerToInstall.postMessage({ type: 'SKIP_WAITING' });
        }
    }
});
