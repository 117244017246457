import './copytoclipboard.css'

import React from 'react';
import { CopyToClipboard as CTC} from 'react-copy-to-clipboard';
import Icons from './icons/Icons';
import { publishSuccessNotificationTopic } from '../helpers/PubSubHelpers';

export default function CopyToClipboard(
    {
        text = ""
    }
) {
    return (
        <CTC text={text} onCopy={(text, result) => publishSuccessNotificationTopic("Teksten ble kopiert til utklippstavlen.")}>
            <div className="copy-to-clipboard pointer">
                <div>{text}</div>
                <div><Icons iconName="copy" dimensions={14} /></div>
            </div>
        </CTC>
    );
}
