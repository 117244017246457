import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const CompensationApiRepository = {

    async addOvertimeAsync(obj) {
        return await BaseApiRepository.postAsync("compensation/overtime", obj);
    },
    async updateOvertime(obj) {
        return await BaseApiRepository.putAsync(`compensation/overtime`, obj);
    },
    async addHourCompensationAsync(obj) {
        return await BaseApiRepository.postAsync("compensation/hour-compensation", obj);
    },
    async updateHourCompensation(obj) {
        return await BaseApiRepository.putAsync(`compensation/hour-compensation`, obj);
    },
    async getPilotCompensations() {
        return await BaseApiRepository.getAsync(`compensation/`);
    },
    async getCompensation(obj) {
        return await BaseApiRepository.getAsync(`compensation/${obj.id}?type=${obj.type}`);
    },
    async addPilotageHourCompensation(obj) {
        return await BaseApiRepository.postAsync('compensation/pilotage/hour', obj);
    },
    async updatePilotageHourCompensation(obj) {
        return await BaseApiRepository.putAsync(`compensation/pilotage/hour`, obj);
    },
    async deletePilotageHourCompensation(id) {
        return await BaseApiRepository.deleteAsync(`compensation/pilotage/hour/${id}`);
    },
    async addPilotageOvertime(obj) {
        return await BaseApiRepository.postAsync('compensation/pilotage/overtime', obj);
    },
    async updatePilotageOvertime(obj) {
        return await BaseApiRepository.putAsync(`compensation/pilotage/overtime`, obj);
    },
    async deletePilotageOvertime(id) {
        return await BaseApiRepository.deleteAsync(`compensation/pilotage/overtime/${id}`);
    },
    async getPilotageCompensations(id) {
        return await BaseApiRepository.getAsync(`compensation/pilotage/${id}`);
    },
    async savePilotageCompensationCorretion(obj) {
        return await BaseApiRepository.putAsync(`compensation/pilotage/compensation`, obj);
    },
    async updatePilotageVariableCompensation(obj) {
        return await BaseApiRepository.putAsync(`compensation/pilotage/variable`, obj);
    },
};
wrapMethodsWithLogging(CompensationApiRepository);

export default CompensationApiRepository;