import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.ManeuverResponsibleTypeStore;

const ManeuverResponsibleTypeIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(obj) {
        await BaseIdbRepository.setAsync(Store, obj.maneuverResponsibleTypeId, obj);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },

    async getDefaultAsync() {
        const objs = await BaseIdbRepository.getAllAsync(Store);
        return objs.find(o => o.systemName === 'NOT_RELEVANT');
    },
};

export default ManeuverResponsibleTypeIdbRepository;