import React from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { useNavigate } from 'react-router-dom';
import { HseService } from '../HseService';
import { useGetHseTypeGroupsQuery } from '../../../reducers/slices/api.slice';

export const HseBottomNavigation = ({ id }) => {

    const navigate = useNavigate();

    const {
        data: typeGroups,
        isSuccess,
    } = useGetHseTypeGroupsQuery();

    return (
        isSuccess &&
        <BottomNavigation
            onCallback={onClick}
            selectedAction={`${id}`}
            items={getNavItems()}
        />
    );

    function getNavItems() {
        const result = [];
        
        for (const typeGroup of typeGroups) {
            result.push({
                text: typeGroup.name,
                icon: HseService.getGroupTypeIcon(typeGroup.systemName),
                action: typeGroup.systemName
            })
        }

        return result;
    }

    function onClick(item) {
        navigate(`/hse/${item.action}`);
    }
}
