import React, { useState, useEffect } from 'react';
import { GoogleMapCard } from '../../../components/layout/map/GoogleMapCard';
import { GoogleMapProperties } from '../../../components/layout/map/GoogleMapProperties';
import Slide from '../../../components/layout/Slide';

export default function PilotagePecExamsInfo({ pecExam }) {

    const [showMap, setShowMap] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        function handleOnOffline() {
            setIsOnline(navigator.onLine);
        }

        window.addEventListener("offline", handleOnOffline);
        window.addEventListener("online", handleOnOffline);

        return () => {
            window.removeEventListener("offline", handleOnOffline);
            window.removeEventListener("online", handleOnOffline);
        };
    }, [])
    
    return (
        <>
            <div className="m-b-8">
                <div className="pilot-name">
                    {`${pecExam.examPerson}`}
                </div> 
                <div className="pecExam-location-to">
                    <div>
                        {`${pecExam.examName}`}
                    </div>
                    {
                        isOnline &&
                        <>
                            <div>
                                <div className="link" onClick={() => setShowMap(!showMap)}>
                                    { showMap ? "Skjul" : "Vis" }&nbsp;område
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                isOnline &&
                <Slide show={showMap}>
                    <div className="details-pec-map">
                        <GoogleMapCard
                            properties={{
                                ...GoogleMapProperties,
                                minZoom: 5,
                                zoom: 7,
                                pecExam: pecExam
                            }}
                        />
                    </div>
                </Slide>
            }
        </>
    )
}