import './styles/evaluation.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spacer from '../../components/layout/Spacer';
import EvaluationDetails from './components/EvaluationDetails';
import Form1Index from './components/forms/form1/Index';
import EvaluationService from './services/EvaluationService';
import Moment from 'moment';
import EvaluationApiRepository from '../../repositories/api/EvaluationApiRepository';
import EvaluationIdbRepository from '../../repositories/idb/EvaluationIdbRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import { Card } from '../../components/layout/card/Card';
import { CardProperties } from '../../components/layout/card/components/CardProperties';
import { isObjectNull } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { getNumericUrlParam } from '../../components/helpers/UrlHelpers';
import { EvaluationListItemStates, EvaluationResponsibilityReasonEnum } from './services/EvaluationHelpers';

export default function PecExamEvaluation() {

    const navigate = useNavigate();

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [isEvaluationInLocalStorage, setIsEvaluationInLocalStorage] = useState(false);
    const [evaluation, setEvaluation] = useState(null);
    const [identifier, setIdentifier] = useState("");

    useEffect(() => {
        publishHeaderTopic("Utf&oslash;r evaluering");
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Functions for rendering page
    return (
        <>
            <ContentGrid title="Utf&oslash;r evaluering" titleIcon="evaluation" defaultContentClassName="evaluation-content">
                {
                    (!isBusy) &&
                        (!isObjectNull(evaluation)) ?
                            <div>
                                <EvaluationDetails
                                    evaluation={evaluation}
                                    itemState={EvaluationListItemStates.Editing}
                                    showRenounceType={false}
                                    onClick={() => navigate('/evaluation')}
                                />
                                <Spacer height={20} />
                                <PecExamEvaluationForm
                                    evaluation={evaluation}
                                    identifier={identifier}
                                    onSaveClick={onSaveClick}
                                    onSaveNextClick={onSaveNextClick}
                                />
                            </div>
                            :
                            !isEvaluationInLocalStorage &&
                                <Card properties={{
                                    ...CardProperties,
                                    title: "Mangler evaluering",
                                    actions: [{
                                        icon: "close",
                                        onClick: () => navigate('/evaluation')
                                    }]
                                } }>
                                    <div>
                                        Evaluering ligger ikke lokalt.M&aring; v&aelig;re p&aring; nett for &aring; hente den inn f&oslash;r den kan brukes offline.
                                    </div>
                                </Card>
                        
                }
            </ContentGrid>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {

        const pilotagePilotId = getNumericUrlParam("pilotage-pilot-id");
        const pilotagePecExamId = getNumericUrlParam("pilotage-pec-exam-id");
        const identifier = EvaluationService.getEvaluationIdentifierById(pilotagePilotId, pilotagePecExamId);

        setIdentifier(identifier);

        if (navigator.onLine) {
            initializeEvaluationAsync(identifier);
        } else {

            const savedEvaluation = await EvaluationIdbRepository.getAsync(identifier);
            if (savedEvaluation) {
                if (savedEvaluation.formattedExamTime === null || savedEvaluation.formattedExamTime === undefined) {
                    const examTime = new Date(savedEvaluation.examTime);
                    savedEvaluation.formattedExamTime = Moment(examTime).format('DD.MM.YY');
                }

                if (savedEvaluation.isExamBeingProcessedByCaseworker === true
                    || savedEvaluation.isExamFinalized === true || savedEvaluation.isCompleted === true) {
                    navigate('/evaluation');
                } else {
                    setEvaluation(savedEvaluation);
                    setIsEvaluationInLocalStorage(true);
                }

            } else {
                setIsEvaluationInLocalStorage(false);
            }

            setIsBusy(false);
        }
    }

    async function initializeEvaluationAsync(identifier) {
        const response = await EvaluationApiRepository.getAsync(identifier);

        if (response.ok === true) {
            const json = await response.json();

            const savedEvaluation = await EvaluationIdbRepository.getAsync(identifier);
            let data = json;

            if (savedEvaluation) {
                if (savedEvaluation.eTagLocal === data.eTagLocal) {
                    data = savedEvaluation;
                }
            }

            if (data.hasMultibleExaminers !== json.hasMultibleExaminers) {
                data.hasMultibleExaminers = json.hasMultibleExaminers;
                data.examiners = json.examiners;
                if (data.hasMultibleExaminers === false) {
                    data.renounceType = null;
                    data.renounceTypeName = null;
                    data.isRenounced = false;
                    data.isRenounceable = false;
                }
            }

            if (data.formattedExamTime === null || data.formattedExamTime === undefined) {
                let examTime = new Date(data.examTime);
                data.formattedExamTime = Moment(examTime).format('DD.MM.YY');
            }

            await EvaluationIdbRepository.setAsync(identifier, data);

            if (data.isExamBeingProcessedByCaseworker === true
                || data.isExamFinalized === true || data.isCompleted === true) {
                await EvaluationIdbRepository.deleteAsync(identifier, data);
                navigate("/evaluation");
            } else {
                setEvaluation(data);
                setIsEvaluationInLocalStorage(true);
            }

        } else {
            handleError(response);
        }

        setIsBusy(false);
    }

    async function saveAsync(evaluationCopy, goToSummary = false) {

        setIsBusy(true);

        const response = await EvaluationApiRepository.setAsync(evaluationCopy);

        if (response.ok === true) {
            const data = await response.json();
            publishSuccessNotificationTopic("Evalueringen ble lagret");
            await EvaluationIdbRepository.setAsync(identifier, data);

            if (goToSummary) {
                navigate(EvaluationService.getEvaluationSummaryUrl(data));
            } else {
                navigate('/evaluation');
            }
        } else {
            handleError(response);
        }

        setIsBusy(false);
    }

    async function renounceAsync(evaluationCopy, reason) {
        evaluationCopy.isRenounced = true;
        evaluationCopy.renounceType = reason;

        const response = await EvaluationApiRepository.renounceAsync(JSON.stringify(evaluationCopy));

        if (response.ok === true) {
            publishSuccessNotificationTopic("Evalueringen ble lagret");
            const data = await response.json();

            await EvaluationIdbRepository.setAsync(identifier, data);
            navigate(EvaluationService.getEvaluationSummaryUrl(data));

        } else {
            handleError(response);
        }
    }

    function onReloadClick() {
        setHasErrors(false);
        setIsBusy(true);
        
        initializeAsync();
    }

    function handleError(response) {
        publishWarningNotificationTopic(getResponseStatusText(response.status), response.status);
        setHasErrors(true);
    }

    function getResponseStatusText(status) {
        switch (status) {
            case 401:
                return "Du er ikke sensor for evalueringen.";
            case 403:
                return "Kan ikke fullføre aksjonen på evalueringen.<br/>Vennligst last inn på ny.";
            case 412:
                return "Evalueringen har blitt oppdatert på en annen enhet. Frisk opp og prøv på nytt.";
            case 423:
                return "Evalueringen er låst.<br/>Den blir enten behandlet av saksbehandler eller er fullført.";
            default:
                return "En feil oppstod. Vennligst last inn på ny.";
        }
    }

    function onSaveClick(evaluationCopy) {
        switch (evaluationCopy.renounceType) {
            case EvaluationResponsibilityReasonEnum.NO_RESPONSIBILITY:
                renounceAsync(evaluationCopy, EvaluationResponsibilityReasonEnum.NO_RESPONSIBILITY);
                break;
            case EvaluationResponsibilityReasonEnum.NO_PARTICIPATION:
                renounceAsync(evaluationCopy, EvaluationResponsibilityReasonEnum.NO_PARTICIPATION);
                break;
            default:
                if (evaluationCopy.isRenounced) {
                    evaluationCopy.isRenounced = false;
                }
                saveAsync(evaluationCopy);
                break;
        }
    }

    function onSaveNextClick(evaluationCopy) {
        saveAsync(evaluationCopy, true);
    }
}

const PecExamEvaluationForm = ({ evaluation, identifier, onSaveClick, onSaveNextClick }) => {
    if (evaluation.evaluationDocument === undefined) {
        return <div>Laster inn...</div>
    }

    if (evaluation.evaluationDocument === null) {
        console.log("EvaluationDocument is null:" + evaluation.evaluationDocument);
        return (<h2>Virker slett ikke.</h2>);
    }

    switch (evaluation.evaluationDocument.version) {
        case 1:
            return (<Form1Index identifier={identifier} evaluation={evaluation} onSaveClick={onSaveClick} onSaveNextClick={onSaveNextClick} />);
        case 2:
            return (<div>Skjema v2 er ikke implementert.</div>);
        default:
            return (<div>Virker ikke</div>);
    }
}
