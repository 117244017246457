import { useEffect } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';

export const MapProviderSeaMap = ({ isSeamapActive, localRef }) => {

    const map = useMap();  

    useEffect(() => {
        if (!map) return;

        if (!isSeamapActive) {
            // remove
            map.overlayMapTypes.removeAt(0);
        } else {
            // add
            map.overlayMapTypes.push(localRef.seamap);
        }

    }, [isSeamapActive, map, localRef]);
    
}
