import React, { useEffect, useState } from 'react';
import HourCompensationTypeIdbRepository from '../../../../../repositories/idb/HourCompensationTypeIdbRepository';
import uuid from 'react-uuid';
import Moment from 'moment';
import { PilotAssignmentCommands } from '../../../services/DispatcherActions';
import Spacer from '../../../../../components/layout/Spacer';
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import DateTimePickerFieldWrapper from '../../../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import TextBoxFieldWrapper from '../../../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import DropDownListFieldWrapper from '../../../../../components/layout/fieldWrappers/DropDownListFieldWrapper';
import TextAreaFieldWrapper from '../../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { DateTimePickerModes } from '../../../../../components/layout/DateTimePicker';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from '../../../../../components/helpers/ObjectHelpers';
import { formatMoment } from '../../../../../components/helpers/DateTimeHelpers';
import { DialogForm } from '../../../../../components/layout/DialogForm';

export const HourCompensationDialog = ({ onClose, compensation = null, callback }) => {

    const [selectedOption, setSelectedOption] = useState({ name: "" });
    const [options, setOptions] = useState([]);
    const [description, setDescription] = useState("");
    const [number, setNumber] = useState(0);
    const [canSave, setCanSave] = useState(false);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [warnings, setWarnings] = useState([]);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "C-Tillegg",
                onClose: onClose,
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClick,
                        disabled: !canSave,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <DialogForm>

                <DropDownListFieldWrapper
                    title="Type"
                    adaptiveTitle="Velg type"
                    data={options}
                    dataItemKey="pilotagePilotHourCompensationTypeId"
                    textField="name"
                    adaptive={true}
                    value={selectedOption}
                    onChange={handleChange}
                />

                <Spacer height={20} />

                <DateTimePickerFieldWrapper
                    title="Fra dato"
                    value={fromTime}
                    onChange={onFromTimeChange}
                    mode={DateTimePickerModes.DateTime}
                />

                <Spacer height={20} />

                <DateTimePickerFieldWrapper
                    title="Til dato"
                    value={toTime}
                    onChange={onToTimeChange}
                    warnings={warnings}
                    mode={DateTimePickerModes.DateTime}
                />

                <Spacer height={20} />

                <TextBoxFieldWrapper
                    title="Antall"
                    value={number}
                    disabled={true}
                    readOnly={true}
                />

                <Spacer height={20} />

                <TextAreaFieldWrapper
                    title="Beskrivelse"
                    rows={3}
                    value={description}
                    onChange={onDescriptionChange}
                />

            </DialogForm>
        </Dialog>
    )

    async function initializeAsync() {
        const dtos = await HourCompensationTypeIdbRepository.getAllAsync();
        setOptions(dtos);
        setSelectedOption(dtos[0]);

        if (isObjectNull(compensation)) return;

        const selectedDto = dtos.find(d => d.systemName === compensation.pilotagePilotHourCompensationSystemName);
        setSelectedOption(selectedDto);

        const fromTime = Moment(compensation.fromTime);
        let toTime = Moment(compensation.fromTime).add(compensation.number, "hours");
        
        if (!isNullOrEmpty(compensation.toTime) && Moment(compensation.toTime) > fromTime) {
            toTime = Moment(compensation.toTime);
        }

        setFromTime(fromTime.toDate());
        setToTime(toTime.toDate());
        setNumber(compensation.number);
        setDescription(compensation.description);
    }

    function onDescriptionChange(e) {
        setDescription(e.value);
        validate(e.value, fromTime, toTime, selectedOption);
    }
        
    function onFromTimeChange(e) {
        setFromTime(e.value);
        validate(description, e.value, toTime, selectedOption);

        calculateNumber(e.value, toTime);
    }

    function onToTimeChange(e) {
        setToTime(e.value);
        validate(description, fromTime, e.value, selectedOption);

        calculateNumber(fromTime, e.value);
    }

    function handleChange(e) {
        setSelectedOption(e.target.value);
        validate(description, fromTime, toTime, e.target.value);
    }

    function validate(description = "", fromTime = null, toTime = null, option = null) {
        setCanSave(
            !isNullOrEmpty(description) &&
            !isNullOrEmpty(fromTime) &&
            !isNullOrEmpty(toTime) &&
            !isObjectNull(option));
    }

    function onSaveClick() {
        if (!canSave) return;

        let compensationCopy = {
            guid: uuid(),
            command: PilotAssignmentCommands.AddPilotHourCompensation
        };

        if (!isObjectNull(compensation)) {
            compensationCopy = deepCopyObject(compensation);
            compensationCopy.command = PilotAssignmentCommands.AddPilotHourCompensation;

            if (compensationCopy.hasOwnProperty("pilotagePilotHourCompensationId")) {
                if (compensationCopy.pilotagePilotHourCompensationId > 0) {
                    compensationCopy.command = PilotAssignmentCommands.UpdatePilotHourCompensation;
                }
            }

            if (isNullOrEmpty(compensationCopy.guid)) {
                compensationCopy.guid = uuid();
            }
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        compensationCopy.fromTime = formatMoment(from);
        compensationCopy.toTime = formatMoment(to);
        compensationCopy.pilotagePilotHourCompensationSystemName = selectedOption.systemName;
        compensationCopy.pilotagePilotHourCompensationName = selectedOption.name;
        compensationCopy.number = Number(number);
        compensationCopy.description = description;

        callback(compensationCopy);
        onClose();
    }

    function calculateNumber(fromTime, toTime) {

        const warnings = [];

        if (isObjectNull(fromTime) || isObjectNull(toTime)) {
            if (isNullOrEmpty(fromTime)) {
                warnings.push("Fra-dato kan ikke v&aelig;re tom.");
            }
            if (isNullOrEmpty(toTime)) {
                warnings.push("Til-dato kan ikke v&aelig;re tom.");
            }

            setNumber("");
            setWarnings(warnings);
            return;
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        if (from >= to) {
            warnings.push("Til-dato kan ikke v&aelig;re f&oslash;r fra-dato.")
        }

        const diff = to.diff(from, 'minutes');
        let hours = (diff / 60);

        setNumber(hours.toFixed(2));
        setWarnings(warnings);
    }
}
