import { useEffect } from "react"
import { useState } from "react"
import { isNullOrEmpty, isObjectNull } from "../../../components/helpers/ObjectHelpers"
import { Dialog } from "../../../components/layout/dialogs/Dialog"
import { DialogProperties } from "../../../components/layout/dialogs/DialogProperties"
import { GoogleMapCard } from "../../../components/layout/map/GoogleMapCard"
import { GoogleMapContainer } from "../../../components/layout/map/GoogleMapContainer"
import { GoogleMapProperties } from "../../../components/layout/map/GoogleMapProperties"
import SourceApiRepository from "../../../repositories/api/SourceApiRepository"
import MetaIdbRepository from "../../../repositories/idb/MetaIdbRepository"
import { useGetSourceConfigQuery } from "../../../reducers/slices/api.slice"

export const RegisterLocationStopDialog = ({
    onClose,
    onSave
}) => {

    const [mapClickPosition, setMapClickPosition] = useState(null);
    const [positionName, setPositionName] = useState("");
    const [canSave, setCanSave] = useState(false);
    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess)) return;

        async function initializeAsync() {
            const defaultMapLocation = JSON.parse(sourceConfig.defaultMapLocation);
            const position = {
                lat: defaultMapLocation.lat,
                lng: defaultMapLocation.lng
            };

            const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
            if (!isObjectNull(whoAmI.defaultLocation)) {
                if (!isNullOrEmpty(whoAmI.defaultLocation.latitude) && !isNullOrEmpty(whoAmI.defaultLocation.latitude)) {
                    position.lat = whoAmI.defaultLocation.latitude;
                    position.lng = whoAmI.defaultLocation.longitude;
                }
            }

            const positionName = await getPositionName(position);
            setPositionName(positionName);
        }

        initializeAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess]);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Velg hvilested i kartet",
                size: "large",
                onClose: () => onClose(false),
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: handleOnSave,
                        themeColor: "primary",
                        icon: "ok",
                        text: "Ok",
                        disabled: !canSave
                    },
                ]
            }}>
            <div className="worktime-location">
                <div>
                    Din lokasjon: {positionName}
                </div>
                <GoogleMapContainer height="100%">
                    <GoogleMapCard properties={{
                        ...GoogleMapProperties,
                        canMapClick: true,
                        mapClickType: "marker",
                        onMapClick: onMapClickAsync,
                        mapClickPosition: mapClickPosition
                    }} />
                </GoogleMapContainer>
            </div>
        </Dialog>
    )

    function handleOnSave() {
        onSave(mapClickPosition, positionName);
    }

    async function onMapClickAsync(e) {
        const position = {
            lat: e.lat,
            lng: e.lng
        };
        setMapClickPosition(position);

        const positionName = await getPositionName(position);
        setPositionName(positionName);

        setCanSave(true);
    }

    async function getPositionName(position) {

        const response = await SourceApiRepository.getPositionAsync(position);
        if (response.ok === true) {
            const data = await response.json();
            if (isNullOrEmpty(data) === false) {
                return data;
            }
        } 

        return "Ukjent";
    }
}
