import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import parse from 'html-react-parser';
import ManeuverResponsibleTypeIdbRepository from '../../../../repositories/idb/ManeuverResponsibleTypeIdbRepository';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { Card } from '../../../../components/layout/card/Card';
import SelectOption from '../../../../components/layout/SelectOption';
import { PilotAssignmentCommands, PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Slide from '../../../../components/layout/Slide';
import Spacer from '../../../../components/layout/Spacer';
import PubSub from 'pubsub-js';
import { errorsContainsFromObject, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import { addManeuverResponsibleTypeCommand, hasCommandType } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';

export const ManeuverResponsibleCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        async onWastedTripChanged() {
            const pilotAssignment = await await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
            setShow(!pilotAssignment.isWastedTrip);
        },
        onValidationChanged(codeStrings) {
            const errorCodes = {
                ManeuverResponsibleTypeIsMissing: PilotAssignmentErrorCodes.ManeuverResponsibleTypeIsMissing
            };

            setHasValidationError(errorsContainsFromObject(codeStrings, errorCodes));
        }
    }));

    const [show, setShow] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [maneuverResponsibleType, setManeuverResponsibleType] = useState(null);
    const [maneuverResponsibleTypes, setManeuverResponsibleTypes] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {

        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);

        initializeAsync();
        return () => {            
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Slide show={show}>
            <Card
                properties={{
                    ...CardProperties,
                    title: parse("Utf&oslash;rende ved man&oslash;vrering"),
                    hasValidationError: hasValidationError,
                    isDirty: isDirty
                }}>
                <div>
                    Kapteinen er alltid ansvarlig, men hvem var mest aktiv/utf&oslash;rende med man&oslash;vrering.
                </div>
                <Spacer height={10} />
                <SelectOption
                    options={maneuverResponsibleTypes}
                    selectedOption={maneuverResponsibleType}
                    optionTextField="name"
                    onCallback={onCallback}
                    disabled={!isEditable}
                    hasValidationError={hasValidationError}
                />
            </Card>
            <Spacer height={30} />
        </Slide>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
        setShow(!pilotAssignment.isWastedTrip);

        initializeManeuverResponsibleAsync(pilotAssignment.maneuverResponsibleType);

        setIsDirty(hasCommandType(pilotAssignment, PilotAssignmentCommands.ManueverResponsibleType))
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function initializeManeuverResponsibleAsync(maneuverResponsibleType) {
        let maneuverResponsibleTypeSystemName = "";
        if (!isObjectNull(maneuverResponsibleType)) {
            setManeuverResponsibleType(maneuverResponsibleType)
            maneuverResponsibleTypeSystemName = maneuverResponsibleType.systemName;
        }

        const types = [];
        const dtos = await ManeuverResponsibleTypeIdbRepository.getAllAsync();

        for (let i = 0; i < dtos.length; i++) {
            types.push({
                name: dtos[i].name,
                selected: dtos[i].systemName === maneuverResponsibleTypeSystemName,
                source: dtos[i]
            })
        }

        setManeuverResponsibleTypes(types);
    }

    async function onCallback(item) {

        setIsDirty(true);

        const obj =item.source;
        initializeManeuverResponsibleAsync(obj);

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        pilotAssignment.maneuverResponsibleType = obj;
        pilotAssignment = addManeuverResponsibleTypeCommand(pilotAssignment, obj);

        await onUpdatePilotAssignmentAsync(pilotAssignment);
    }
})

