import React from "react";
import Icons from "../../../components/layout/icons/Icons";
import { IconColors } from "../../../services/SystemNames";
import "../quaymanual.css"

const QuayDocumentCard = ({ doc, onClick }) => {

    return (
        <div className="quaymanual-document">
            <div className="quaymanual-document-header">
                <div className="quaymanual-document-header-text">{doc.name}</div>
                <div className="quaymanual-document-header-icon" onClick={onClick}>
                    <Icons color={IconColors.Primary} className="icon" iconName="pdf" />
                </div>
            </div>
        </div>
    );
};

export default QuayDocumentCard;