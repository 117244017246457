import { createTimeObject } from "./ObjectHelpers";

function getTimeHours() {
    let arrHours = [];

    let currentHour = 0;
    while (currentHour < 24) {
        arrHours.push(createTimeObject(currentHour));
        currentHour++;
    }

    return arrHours;
}

function getTimeMinutes(isTime = true) {
    let arrMinutes = [];

    let currentMinute = 0;
    const max = isTime ? 60 : 125;

    while (currentMinute < max) {
        arrMinutes.push(createTimeObject(currentMinute));
        currentMinute = currentMinute + 5;
    }

    return arrMinutes;
}

export {
    getTimeHours,
    getTimeMinutes,
}
