import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import TitleContainer from '../../../components/layout/TitleContainer';
import { CardList } from '../../../components/layout/card/CardList';
import { CardModes, CardProperties } from '../../../components/layout/card/components/CardProperties';
import { PilotageOvertimeDialog } from './dialogs/PilotageOvertimeDialog';
import { PilotAssignmentCommands } from '../../pilot/services/DispatcherActions';
import CompensationApiRepository from '../../../repositories/api/CompensationApiRepository';
import { formatStringToList, isNullOrEmpty, isObjectNull, removeQuotesFromString } from '../../../components/helpers/ObjectHelpers';
import { formatMomentToDefault } from '../../../components/helpers/DateTimeHelpers';
import { publishErrorNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { toggleSelectedItem } from '../../pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
 
export const PilotageOvertimeCard = ({ pilotageCompensations, canEdit = false, pilotageCompensationId, updatePilotageCompensation, refresh}) => {

    const [compensation, setCompensation] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [cards, setCards] = useState([]);
    const [toggleActivateSelect, setToggleActivateSelect] = useState(false);
    const [selectedCompensations, setSelectedCompensations] = useState([]);

    useEffect(() => {
        initializeAsync();
    }, [pilotageCompensations]);

    return (
        <>
            <TitleContainer
                title="Overtid"
                error={toggleActivateSelect && selectedCompensations.some(c => c.selected)}
                actions={getTitleActions()}
            />

            <CardList
                mode={CardModes.Multible}
                cards={cards}
                toggleActivateSelect={setToggleActivateSelect}
                toggleSelectItem={toggleSelectItem}
            />

            {
                showDialog &&
                <PilotageOvertimeDialog
                    onClose={() => setShowDialog(false)}
                    overtime={compensation}
                    callback={onCallbackAsync}
                />
            }

            {
                showConfirmationDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Slett overtid",
                        text: "&Oslash;nsker du &aring; slette overtiden?",
                        size: "small",
                        onClose: () => setShowConfirmationDialog(false),
                        onClick: onConfirmCallbackAsync
                    }}
                />
            }

        </>
    )

    function getTitleActions() {
        const actions = [];
        if (canEdit) {
            if (toggleActivateSelect) {
                actions.push(
                    {
                        icon: "trash",
                        onClick: onDeleteSelectedClick
                    });
            } else {
                actions.push(
                    {
                        icon: "plus",
                        onClick: () => {
                            setCompensation(null);
                            setShowDialog(true);
                        }
                    });
            }
        }

        return actions;
    }

    async function initializeAsync() {
        const result = [];

        if (!isObjectNull(pilotageCompensations.overtimes)) {
            const overtimes = pilotageCompensations.overtimes;
            overtimes.map((compensation) =>
                result.push({
                    guid: uuid(),
                    properties: {
                        ...CardProperties,
                        id: compensation.id,
                        sourceId: compensation.id,
                        title: getCompensationTimeDescription(compensation),
                        subTitle: compensation.isAttentionRequired ? `Korrigeringskrav` : "",
                        subTitleClassName: "semi-bold",
                        titleClassName: "card-item-content-header-title-small",
                        isSwipeEnabled: canEdit,
                        mode: CardModes.Multible,
                        borderColor: "var(--card-swipe-border)",
                        backgroundColor: "var(--card-swipe-bg)",
                        onDeleteClick: canEdit ? () => onDeleteClick(compensation) : null,
                        onClick: canEdit ? () => {
                            setCompensation(compensation);
                            setShowDialog(true);
                        } : null
                    },
                    body: <OvertimeContent compensation={compensation} />
                }));
            }
        setCards(result);
    }

    function onDeleteClick(compensation) {
        setCompensation(compensation);
        setShowConfirmationDialog(true);
    }

    function toggleSelectItem(id, selected) {
        const result = toggleSelectedItem(selectedCompensations, id, selected);
        setSelectedCompensations(result);
    }

    function onDeleteSelectedClick() {
        setCompensation(null);
        setShowConfirmationDialog(true);
    }

    async function onCallbackAsync(obj) {

        switch (obj.command) {
            case PilotAssignmentCommands.AddPilotAssignmentOvertime:
            case PilotAssignmentCommands.UpdatePilotAssignmentOvertime:
                await onPilotAssignmentOvertimeAsync(obj);
                break;
            default:
                return;
        }
        setShowConfirmationDialog(false);
    }

    async function onConfirmCallbackAsync() {

        if (isNullOrEmpty(compensation)) {
            onDeletePilotageOvertimesAsync();
        } else {
            onDeletePilotageOvertimeAsync(compensation);
        }

        setSelectedCompensations([]);
        setToggleActivateSelect(false);
        setShowConfirmationDialog(false);
    }

    async function onPilotAssignmentOvertimeAsync(obj) {
        switch (obj.command) {
            case PilotAssignmentCommands.AddPilotAssignmentOvertime:
                obj.pilotageCompensationId = pilotageCompensationId;
                const response = await CompensationApiRepository.addPilotageOvertime(obj);
                if (response.ok) {
                    pilotageCompensations.overtimes.push(obj);
                    refresh();
                } else {
                    const errorMessage = await response.text();
                    publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
                }
                break;
            default:
                const index = pilotageCompensations.overtimes.findIndex(c => c.id === obj.id);
                const updateResponse = await CompensationApiRepository.updatePilotageOvertime(obj);
                if (updateResponse.ok) {
                    pilotageCompensations.overtimes[index] = obj;
                    updatePilotageCompensation(pilotageCompensations);
                    initializeAsync();
                } else {
                    const errorMessage = await updateResponse.text();
                    publishWarningNotificationTopic(removeQuotesFromString(errorMessage));
                }
                break;
        }
    }

    async function onDeletePilotageOvertimeAsync(obj) {
        if (isNullOrEmpty(pilotageCompensations.overtimes)) return;
        const index = pilotageCompensations.overtimes.findIndex(x => x.id === obj.id);
        const response = await CompensationApiRepository.deletePilotageOvertime(obj.id);
        if (response.ok === true) {
            pilotageCompensations.overtimes.splice(index, 1);
            refresh();
        } else {
            publishErrorNotificationTopic("Noe gikk galt ved slettingen av overtiden");
        }        
    }

    async function onDeletePilotageOvertimesAsync() {
        for (var i = 0; i < selectedCompensations.length; i++) {
            if (selectedCompensations[i].selected) {
                const response = await CompensationApiRepository.deletePilotageOvertime(selectedCompensations[i].id);
                const selectedOvertime = selectedCompensations[i];
                const index = pilotageCompensations.overtimes.findIndex(x => x.id === selectedOvertime.id);
                pilotageCompensations.overtimes.splice(index, 1);
                if (response.ok !== true) {
                    publishErrorNotificationTopic("Noe gikk galt under slettingen");
                    pilotageCompensations.overtimes.splice(i, 1);
                }
            }
        }
        refresh();
        initializeAsync();
    }

    function getCompensationTimeDescription(compensation) {
        return `${formatMomentToDefault(compensation.fromTime)} &rarr; ${formatMomentToDefault(compensation.toTime)}`
    }
}

const OvertimeContent = ({ compensation }) => {

    return (
        <>
            <div className="row align-items-center">
                <div className="col">
                    {compensation.number} timer
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <i>{formatStringToList(compensation.description)}</i>
                </div>
            </div>
        </>
    );
};