import './dialogs.css';

import React, { useEffect } from 'react';
import { DialogProperties } from './DialogProperties';
import { DialogSelectItem } from './components/DialogSelectItem';
import { DialogSearch } from './components/DialogSearch';
import { DialogConfirm } from './components/DialogConfirm';
import { DialogDefault } from './components/DialogDefault';

export const Dialog = ({
    children,
    properties = { ...DialogProperties }
}) => {

    useEffect(() => {
        const elem = document.querySelector("body");
        elem.classList.add("overflow-hidden");

        return (() => {
            const elem = document.querySelector("body");
            elem.classList.remove("overflow-hidden");
        });
    }, []);

    return (
        (() => {
            switch (properties.mode) {
                case "search":
                    return (
                        <DialogSearch properties={properties}/>
                    )
                case "select":
                    return (
                        <DialogSelectItem properties={properties}/>
                    )
                case "confirmation":
                    return (
                        <DialogConfirm properties={properties}>{children}</DialogConfirm>
                        )
                default:
                    return (
                        <DialogDefault properties={properties}>
                            {children}
                        </DialogDefault>
                    )
            }
        })()
    )
}
