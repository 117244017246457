import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import {
    TextBox,
    InputPrefix
} from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { NumberIcon } from '../icons/Icons';
import { IconsContext } from '@progress/kendo-react-common';
import { isNullOrEmpty, isObjectNull } from '../../helpers/ObjectHelpers';

export default function TextBoxFieldWrapper({
    title = "",
    value = "",
    icon = "",
    disabled = false,
    readOnly = false,
    onChange,
    prefix = "",
    boldTitle = false
}) {

    const icons = {
        "number": NumberIcon,
    };

    const InputPrefixIcon = () => {

        switch (icon) {
            case "number":
                return <SvgIcon icon={NumberIcon} />
            default:
                return null;
        }
    }

    return (
        <FieldWrapper>
            <Label className="k-form-label" style={{
                fontWeight: boldTitle ? "500" : "normal"
            }}>
                {parse(title) }:
            </Label>
            <div className="k-form-field-wrap">
                <IconsContext.Provider
                    value={{
                        type: 'svg',
                        icons: icons,
                    }}
                >
                    <TextBox
                        value={value}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={handleOnChange}
                        prefix={() => (
                            <>
                                <InputPrefix>
                                    {!isNullOrEmpty(icon) && <InputPrefixIcon />}
                                    {!isNullOrEmpty(prefix) && prefix}
                                </InputPrefix>
                            </>
                        )}
                    />
                </IconsContext.Provider>
            </div>
        </FieldWrapper>
    );

    function handleOnChange(e) {
        if (isObjectNull(onChange)) return;
        onChange(e);
    }
}

