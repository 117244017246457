import { isNullOrEmpty } from "../../helpers/ObjectHelpers";

function getDialogSizeClassName(properties) {
    if (isNullOrEmpty(properties.size)) return "";
    switch (properties.size.toLowerCase()) {
        case "large":
            return "dialog-large";
        case "small":
            return "dialog-small";
        case "xsmall":
            return "dialog-xsmall";
        default:
            return "";
    }
}

export {
    getDialogSizeClassName
}