import React, { useEffect, useRef, useState } from "react";
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import Moment from "moment";
import "./quaymanual.css"
import Icons from "../../components/layout/icons/Icons";
import { IconColors } from "../../services/SystemNames";
import { Button } from '@progress/kendo-react-buttons';
import { publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import { TextArea } from "@progress/kendo-react-inputs";
import Spacer from "../../components/layout/Spacer";

const QuayObservation = ({doc}) => {

    const [editable, setEditable] = useState(false);

    const [observationObject, setObservationObject] = useState({
        observation: doc.observation,
        modifiedDate: doc.observationModifiedDate
    });

    const edit = useRef(null);

    useEffect(() => {
        if(editable && edit.current)
        {
            getObservation();

            edit.current.focus();
            //if(edit.current.textContent.trimRight())
            //{
            //    let textLen = edit.current.textContent.trimRight().length;
            //    edit.current.setSelectionRange(textLen, textLen);
            //}
        }
    }, [editable]);

    const getObservation = async () => {
        var response = await QuayManualApiRepository.getObservation(doc.binaryContentId);

        if(response.ok)
        {
            var result = await response.json();
            setObservationObject(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
    };

    const saveChanges = async () => {
        var response = await QuayManualApiRepository.putObservation(observationObject);
        if(response.ok)
        {
            var result = await response.json();
           setObservationObject(result);
           doc.observation = result.observation;
           doc.observationModifiedDate = response.modifiedDate;
           setEditable(false);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste. Kunne ikke lagre observasjon.");
            getObservation();
        }
    };

    const observationChanged = (event) => {
        setObservationObject({...observationObject,  observation: event.target.value });
    };

    const toggleEditable = async () => {
        setEditable(!editable);
        if(editable)
        {
            setObservationObject({
                ...observationObject,
                observation: doc.observation,
                modifiedDate: doc.observationModifiedDate
            });
        }
    };

    return (
        <>
            <div className="quaymanual-document">
                <div className="quaymanual-document-header">
                    <div className="quaymanual-document-header-text">
                        <div>Observasjon</div>
                    </div>
                    <div className="quaymanual-document-header-icon" onClick={() => toggleEditable()}>
                        <Icons color={IconColors.Primary} className="icon" iconName="edit" />
                    </div>
                </div>
                {editable ?
                    <div className="quaymanual-document-content">
                        <TextArea ref={edit} autoFocus value={observationObject.observation ? observationObject.observation : ""} onChange={observationChanged}  />
                        {doc.observation && <span>Sist endret: {Moment(observationObject.modifiedDate).format('DD.MM.YYYY HH:mm')}</span>}
                        <Spacer height={5} />
                        <div className="buttons">
                            <Button themeColor="success" onClick={() => saveChanges()}>Oppdater</Button> &nbsp;
                            <Button themeColor="error" onClick={() => toggleEditable()}>Avbryt</Button>
                        </div>
                    </div>
                :
                observationObject.observation && 
                        <div className="quaymanual-document-content">
                            <div className="observation">{observationObject.observation}</div>
                            {doc.observation && <span>Sist endret: {Moment(observationObject.modifiedDate).format('DD.MM.YYYY HH:mm')}</span> }
                        </div>
                }
            </div>
        </>
    );
};

export default QuayObservation;