import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.DepartmentStore;

const DepartmentIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(department) {
        await BaseIdbRepository.setAsync(Store, department.departmentID, department);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },

};

export default DepartmentIdbRepository;