import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import Spacer from '../../../../components/layout/Spacer';
import { TextBox } from "@progress/kendo-react-inputs";
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { errorsContainsFromObject, isNullOrEmpty, isNumeric } from '../../../../components/helpers/ObjectHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import { addAddTowingTonnageCommand } from '../helpers/PilotAssignmentCommandHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TowingCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            const errorCodes = {
                GrossTonnageIsMissing: PilotAssignmentErrorCodes.GrossTonnageIsMissing,
                GrossTonnageMustBeLarger: PilotAssignmentErrorCodes.GrossTonnageMustBeLarger,
                GrossTonnageCannotBeLarger: PilotAssignmentErrorCodes.GrossTonnageCannotBeLarger
            };

            setHasValidationError(errorsContainsFromObject(codeStrings, errorCodes));
        }
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState("");
    const [hasValidationError, setHasValidationError] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);
        initializeAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        show &&
        <>
            <Card
                properties={{
                ...CardProperties,
                    title: "Samlet BT ved slep",
                    hasValidationError: hasValidationError,
            }}>
                <TextBox
                    disabled={!isEditable}
                    value={value}
                    onChange={onChangeAsync}
                    maxLength={10}
                    style={{
                        width: 200
                    } }
            />
            </Card>
            <Spacer height={30} />
        </>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        
        setShow(pilotAssignment.pilotage.isTowing);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));

        if (!isNumeric(pilotAssignment.pilotage.towingGrossTonnage)) return;

        setValue(`${pilotAssignment.pilotage.towingGrossTonnage}`);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function onChangeAsync(e) {
        
        if (!isEditable) return;

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        if((isNullOrEmpty(e.value))){
            pilotAssignment.pilotage.towingGrossTonnage = null;
        } else {
            if(!isNumeric(e.value)) return;
            pilotAssignment.pilotage.towingGrossTonnage = Number(e.value);
        }

        setValue(e.value);

        pilotAssignment = addAddTowingTonnageCommand(pilotAssignment, e.value);
        await onUpdatePilotAssignmentAsync(pilotAssignment);
    }
})
