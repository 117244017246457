import React from 'react'
import Moment from 'moment'
import './appointment.css';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";

function AppointmentCard({ data }) {
    
    return (
        <div className="row">
            <div className="col">
                <FieldWrapper>
                    <Label className="k-form-label">
                        <b>Fra:</b>
                    </Label>
                    <div className="k-form-field-wrap">
                        {Moment(data.fromTime).format("DD.MM HH:mm")}
                    </div>
                </FieldWrapper>
            </div>
            <div className="col">
                <FieldWrapper>
                    <Label className="k-form-label">
                        <b>Til:</b>
                    </Label>
                    <div className="k-form-field-wrap">
                        {Moment(data.toTime).format("DD.MM HH:mm")}
                    </div>
                </FieldWrapper>
            </div>
        </div>
    )
}

export default AppointmentCard
