import AppointmentCard from "./AppointmentCard"
import './appointment.css';
import { CardList } from "../../../components/layout/card/CardList";
import { useEffect, useState } from "react";
import { CardListEmpty } from "../../../components/layout/card/CardListEmpty";
import { CardModes, CardProperties } from "../../../components/layout/card/components/CardProperties";
import { isArrayEmpty } from "../../../components/helpers/ObjectHelpers";

const AppointmentCards = ({ isBusy, appointments, onClickEdit, onDelete, toggleActivateSelect , toggleSelectItem }) => {

    const [cards, setCards] = useState([]);

    useEffect(() => {

        function generateCards() {
            const result = [];

            for (let i = 0; i < appointments.length; i++) {
                const appointment = appointments[i];
                result.push({
                    guid: appointment.guid,
                    properties: {
                        ...CardProperties,
                        id: appointment.appointmentID,
                        sourceId: appointment.appointmentID,
                        title: appointment.appointmentType.name,
                        onClick: () => onClickEdit(appointment),
                        onDeleteClick: () => onDelete(appointment),
                        borderColor: appointment.appointmentType.color,
                        isBorderLeftFat: true,
                        className: "card-item-content-border-left",
                        isTentative: isTentative(appointment),
                    },
                    body: <AppointmentCard data={appointment}/>
                });
            }

            setCards(result);
        }
        generateCards();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointments]);

    return (
        (!isArrayEmpty(cards)) ?
            <CardList
                cards={cards}
                mode={CardModes.Multible}
                toggleActivateSelect={toggleActivateSelect}
                toggleSelectItem={toggleSelectItem}
            />
            :
            <CardListEmpty
                text={isBusy ? 'Vennligst vent...' : 'Du har ingen registrerte frav&aelig;r.'} />
    )

    function isTentative(appointment) {
        if (appointment.appointmentType.isApprovalRequired === false
            || appointment.appointmentStatus.appointmentStatusEnum === "APPROVED") {
            return false;
        } else {
            return true;
        }
    }
}

export default AppointmentCards;
