import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const WorkTimeApiRepository = {

    async getRecentAsync() {
        return await BaseApiRepository.getAsync("worktime/recent");
    },

    async getOptimizationAsync(date) {
        return await BaseApiRepository.postAsync(`worktime/optimization`, date);
    },

    async setAsync(url, obj) {
        return await BaseApiRepository.postAsync(`worktime/${url}`, obj);
    },

    async searchSimulateAsync(query) {
        return await BaseApiRepository.postAsync('worktime/simulate', query);
    },

    async searchFutureAsync(query) {
        return await BaseApiRepository.postAsync('worktime/future', query);
    }
};
wrapMethodsWithLogging(WorkTimeApiRepository);

export default WorkTimeApiRepository;
