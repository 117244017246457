import React, { useEffect } from 'react';
import {
    Dialog as KendoDialog,
    DialogActionsBar as KendoDialogActionsBar
} from "@progress/kendo-react-dialogs";
import parse from 'html-react-parser';
import Spacer from '../../Spacer';
import { xIcon } from "@progress/kendo-svg-icons";
import {
    ListView,
} from "@progress/kendo-react-listview";
import {
    Button
} from "@progress/kendo-react-buttons";
import {
    InputPrefix,
    InputSuffix,
    TextBox,
} from "@progress/kendo-react-inputs";
import { IconColors } from '../../../../services/SystemNames';
import { DialogActions } from './DialogActions';
import { BusySpinnerContent } from '../../overlay/components/Busy';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { getElementById } from '../../../helpers/ElementHelpers';
import { getDialogSizeClassName } from '../DialogHelpers';
import { MagnifyingGlass, Trash } from "phosphor-react-sc";

export const DialogSearch = ({
    properties = {}
}) => {

    useEffect(() => {
        if (!properties.isBusy) {
            setTimeout(() => {
                const elem = getElementById("DialogSearchTextBox");
                elem.focus()
            }, 500);
        }
    }, [properties.isBusy]);

    const SearchResultRender = (props) => {
        let item = props.dataItem;

        return (

            <div className="k-listview-item row dialog-row align-items-center"
                style={{
                    paddingLeft: 10,
                    paddingBottom: 10,
                    paddingTop: 10,
                    paddingRight: 10,
                    borderBottom: "1px solid lightgrey",
                }}
            >
                <div className="col" onClick={() => handleOnSelect(item)}>
                    {item.name}
                </div>
            </div>
        )
    };

    return (
        <KendoDialog title={
            <div>
                <div className="dialog-search-textbox">
                    <TextBox
                        id="DialogSearchTextBox"
                        placeholder={parse(properties.placeholder)}
                        onChange={handleOnSearch}
                        disabled={properties.isBusy}
                        className="dialog-search-textbox-input"
                        prefix={() => (
                            <>
                                <InputPrefix>
                                    <MagnifyingGlass color={IconColors.Primary} />
                                </InputPrefix>
                            </>
                        )}
                        suffix={() => (
                            <>
                                <InputSuffix>
                                    <Trash size={20} onClick={onClear} />&nbsp;
                                </InputSuffix>
                            </>
                        )}
                    />
                </div>
            </div>
        } className={`dialog-title dialog-list ${getDialogSizeClassName(properties)}`} onClose={handleOnClose}>

            <div className="dialog-search-form">
                {
                    (!isNullOrEmpty(properties.description)) ?
                        <div>
                            <div className="dialog-description">
                                {parse(properties.description)}
                            </div>
                            <Spacer height={20} />
                        </div>
                        :
                        <div />
                }

                <div>
                    {
                        properties.isBusy ?
                            <BusySpinnerContent type="magnifyingglass" dimensions={100} />
                            :
                            (!isArrayEmpty(properties.items)) ?
                                <ListView
                                    data={properties.items}
                                    item={isObjectNull(properties.searchResultRender) ? SearchResultRender : properties.searchResultRender}
                                />
                                :
                                properties.hasSearched &&
                                <div className="dialog-search-no-result">S&oslash;ket ga ikke noe resultat.</div>

                    }

                </div>

            </div>

            <KendoDialogActionsBar>
                {
                    !isObjectNull(properties.actions) ?
                        <DialogActions properties={properties} />
                        :
                        <Button
                            onClick={handleOnClose}
                            svgIcon={xIcon}>Avbryt</Button>
                }
            </KendoDialogActionsBar>
        </KendoDialog>
    )

    function handleOnClose() {
        if (isObjectNull(properties.onClose)) {
            alert("Mangler aksjon for � lukke dialog.");
        } else {
            properties.onClose();
        }
    }

    function handleOnSearch(e) {
        if (isObjectNull(properties.onSearch)) {
            alert("Mangler aksjon for � s�ke.");
        } else {
            properties.onSearch(e);
        }
    }

    function onClear() {
        if (isObjectNull(properties.onSearch)) return;
        properties.onSearch({ target: { value: "" } });
    }
    
    function handleOnSelect(item) {
        if (isObjectNull(properties.onSelect)) {
            alert("Mangler aksjon for � velge.");
        } else {
            properties.onSelect(item.source);
        }
    }
}
