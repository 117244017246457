import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.PilotageStore;

const PilotageIdbRepository = {

    async setPilotageAsync(pilotage) {
        await BaseIdbRepository.setAsync(Store, pilotage.pilotageId, pilotage);
    },
};

export default PilotageIdbRepository;