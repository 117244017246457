import React, { useEffect } from 'react';
import { publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import Overlay from '../../../components/layout/overlay/Overlay';
import { useGetHseTypeBySystemNameQuery } from '../../../reducers/slices/api.slice';

export const ItemDeleteDialog = ({
    selectedItem,
    onClose,
    onDelete,
    onError
    
}) => {

    const {
        data: typeData,
        isFetching: typeGetIsFetching,
        isSuccess: typeGetIsSuccess,
        isError: typeGetIsError,
    } = useGetHseTypeBySystemNameQuery(selectedItem.systemName);

    useEffect(() => {
        if (!typeGetIsError) return;

        publishWarningNotificationTopic(`Kunne ikke hente HMS-data for ${selectedItem.displayName}.`);

        onError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeGetIsError])

    return (
        <>
            {
                (typeGetIsSuccess && !typeGetIsFetching) &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: `Slett ${typeData.displayName}`,
                        onClose: onClose,
                        onClick: () => onDelete(typeData)
                    }}>
                    <div>&Oslash;nsker du &aring; slette {typeData.displayName}?</div>
                </Dialog>
            }
            <Overlay isBusy={typeGetIsFetching} />
        </>
    );
}
