import React from 'react';

import {
    Label as KendoLabel,
} from "@progress/kendo-react-labels";
import { getValidationErrorClassName } from '../helpers/ElementHelpers';

export const Label = (
    {
        children,
        hasValidationError = false
    }
) => {
    return (
        <KendoLabel className={`k-form-label ${getValidationErrorClassName(hasValidationError)}`}>
            {children}
        </KendoLabel>
    );
}
