import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const PilotRotationApiRepository = {

    async getCurrentAsync() {
        return await BaseApiRepository.getAsync("pilot-rotation/current");
    },

    async getCurrentByIdAsync(id) {
        return await BaseApiRepository.getAsync(`pilot-rotation/current/${id}`);
    },

    async setCurrentAsync(obj) {
        return await BaseApiRepository.postAsync('pilot-rotation/current', obj);
    },

    async searchLogAsync(query) {
        return await BaseApiRepository.postAsync("pilot-rotation/log/search", query);
    },

    async getNextAsync() {
        return await BaseApiRepository.getAsync("pilot-rotation/next");
    }

};
wrapMethodsWithLogging(PilotRotationApiRepository);

export default PilotRotationApiRepository;