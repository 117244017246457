import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ValidateAccess from '../access/ValidateAccess';
import NoAccess from '../access/NoAccess';
import Offline from '../access/Offline';
import { isBoolean } from '../helpers/ObjectHelpers';
import { getToken } from '../helpers/TokenHelpers';
import { getLoggedInPersonId, hasPersonType, isBetaUser, isLoggedIn, onGuestCheckAsync } from '../helpers/AuthHelpers';
import { RouteGuardStates } from '../../services/SystemNames';
import Login from '../access/Login';
import MetaIdbRepository from '../../repositories/idb/MetaIdbRepository';
import { isEmptyObject } from 'jquery';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import { publishWarningNotificationTopic } from '../helpers/PubSubHelpers';

export const RouteGuardPersonType = ({ personType: PersonType, onlineOnly: OnlineOnly, beta: Beta, doGuestCheck: DoGuestCheck }) => {

    const location = useLocation();
    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);
    
    useEffect(() => {
        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (guardState !== RouteGuardStates.Valid || !DoGuestCheck) return;
        onDoGuestCheckAsync();
    }, [location.pathname, guardState, DoGuestCheck]);

    return (
        (() => {
            switch (guardState) {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.Offline:
                    return (<Offline />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.Login:
                    return (<Login />);

                default:
                    return (<ValidateAccess />);
            }
        })()
    )

    async function initialize() {

        setGuardState(RouteGuardStates.Busy);

        let canContinue = true;

        if (isBoolean(Beta)) {
            if (Beta) {
                canContinue = isBetaUser();
            }
        }

        if (canContinue) {
            if (OnlineOnly && !navigator.onLine) {
                setGuardState(RouteGuardStates.Offline);
            } else {
                if (isLoggedIn() === true) {
                    const token = getToken();
                    let flag = token !== undefined;

                    if (flag === true) {
                        setGuardState(hasPersonType(PersonType) ? RouteGuardStates.Valid : RouteGuardStates.NoAccess);
                    } else {
                        setGuardState(RouteGuardStates.NoAccess);
                    }
                } else {
                    setGuardState(RouteGuardStates.Login);
                }
            }
        } else {
            setGuardState(RouteGuardStates.NoAccess);
        }
    }

    function handleOffline() {
        if (!OnlineOnly) return;
        setGuardState(RouteGuardStates.Offline);
    }

    function handleOnline() {
        initialize();
    }

    async function onDoGuestCheckAsync() {
        if (!navigator.onLine) return;
        onGuestCheckAsync(true);
    }
};
