import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const ShipApiRepository = {

    async getShipDetailAsync(shipId) {
        return await BaseApiRepository.getAsync(`ship/detail/${shipId}`);
    },

    async getShipObservationAsync(shipId, shipObservationId, imageWidth, imageHeight) {
        return await BaseApiRepository.getAsync(`ship/${shipId}/observation/${shipObservationId}?imageWidth=${imageWidth}&imageHeight=${imageHeight}`);
    },

    async createShipObservationAsync(shipId, shipObservationRequest) {
        return await BaseApiRepository.postAsync(`ship/${shipId}/observation`, shipObservationRequest);
    },

    async updateShipObservationAsync(shipObservationId, shipObservationUpdate) {
        return await BaseApiRepository.putAsync(`ship/observation/${shipObservationId}`, shipObservationUpdate);
    },
    async deleteShipObservationAsync(shipObservationModel) {
        return await BaseApiRepository.deleteAsync(`ship/observation/${shipObservationModel.shipObservationId}`, shipObservationModel.etagValue);
    },
    async deleteShipObservationByIdAsync(shipObservationId, etagValue) {
        return await BaseApiRepository.deleteAsync(`ship/observation/${shipObservationId}`, etagValue);
    },
    async getShipObservationsAsync(shipId, imageWidth, imageHeight) {
        return await BaseApiRepository.getAsync(`ship/${shipId}/observations?imageWidth=${imageWidth}&imageHeight=${imageHeight}`);
    },

    async getShipObservationTypesAsync() {
        return await BaseApiRepository.getAsync(`ship/observation-types`);
    },
};
wrapMethodsWithLogging(ShipApiRepository);

export default ShipApiRepository;
