import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutesLoggedInPerson, AppRoutesPersonType, AppRoutesApplicationRights, AppRoutesDefault, AppRoutesLoggedIn } from './AppRoutes';
import Layout from './components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/dist/locale/nb';
import PubSub from 'pubsub-js';
import RouteGuardLoggedInPerson from "./components/routes/RouteGuardLoggedInPerson"
import BaseIdbRepository from './repositories/idb/BaseIdbRepository';
import { RouteGuardPersonType } from './components/routes/RouteGuardPersonType';
import { RouteGuardApplicationRights } from './components/routes/RouteGuardApplicationRights';
import NotFound from './components/access/NotFound';
import RouteGuardLoggedIn from './components/routes/RouteGuardLoggedIn';
import { RouteGuardDefault } from './components/routes/RouteGuardDefault';
import {AppInsightsService} from './services/AppInsightsService';
import { isNullOrEmpty } from './components/helpers/ObjectHelpers';
import { PubSubTopics } from './components/helpers/PubSubHelpers';
import { getToken, initializeToken } from './components/helpers/TokenHelpers';
import { getLoggedInPersonId, hasApplicationRights, isLoggedIn, setWhoAmIAsync } from './components/helpers/AuthHelpers';
import { initCacheAsync } from './components/helpers/CacheHelpers';
import { ProgressBar } from 'react-loader-spinner';
import { RestoreDatabase } from './components/access/RestoreDatabase';
import { useGetSourceConfigQuery, useLazyGetApplicationRightsQuery } from './reducers/slices/api.slice';
import Login from './components/access/Login';
import { unsubscribe } from './firebase';

moment.locale('nb');

BaseIdbRepository.init();

const AppStates = {
    Processing: 'Processing',
    RestoreDatabase: 'RestoreDatabase',
    Valid: 'Valid',
    Login: 'Login'
}

export default function App() {

    const navigate = useNavigate();
    const connectionString = useRef("");

    const [appState, setAppState] = useState(AppStates.Processing);
    const [getApplicationRightsQuery] = useLazyGetApplicationRightsQuery();
    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {

        initializeToken();

        let theme = localStorage.getItem("theme");
        if (!isNullOrEmpty(theme)) {
            document.documentElement.classList = theme;
        } else {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.documentElement.classList = "theme-dark";
                localStorage.setItem("theme", "theme-dark");
            } else {
                document.documentElement.classList = "theme-light";
            }
        }

        async function initializeAsync(applicationRights = []) {
            // hvis man er offline, og datbasen er korrupt... gi beskjed om at man m� v�re online for � restore db
            const isDatabaseValid = await BaseIdbRepository.isDatabaseValidAsync();
            const canAccessPilotWeb = hasApplicationRights(applicationRights, ['NJORD_PILOT_WEB_LOGIN']);
            const newState = !canAccessPilotWeb ? AppStates.Login :
                isDatabaseValid ? AppStates.Valid : AppStates.RestoreDatabase;
            
            if (isDatabaseValid && canAccessPilotWeb) {
                await setWhoAmIAsync();
            }

            // for dramatic purpose! :)
            setTimeout(() => {
                setAppState(newState);
            }, 1500);
        }

        getApplicationRightsQuery()
            .unwrap()
            .then(data => initializeAsync(data))
            .catch(() => initializeAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Initialize application insights
    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess)) return;

        async function initializeApplicationInsights() {
            connectionString.current = sourceConfig.applicationInsightsConnectionString;
            await AppInsightsService.initAsync(connectionString.current, getLoggedInPersonId);
        }

        if (isSourceConfigSuccess) {
            initializeApplicationInsights();
        } else {
            throw new Error(`An error occurred while fetching the Application Insights configuration.`);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectionString, isSourceConfigSuccess]);

    // Initialize function
    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess)) return;

        PubSub.subscribe(PubSubTopics.Logout, handlePubSubTopic);

        const isLoggedInTimer = setInterval(() => onIntervalAsync(), 300000); // Validate login every 5 minutes

        initCacheAsync(sourceConfig);

        return () => {
            clearInterval(isLoggedInTimer);
            PubSub.unsubscribe(PubSubTopics.Logout);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, isSourceConfigSuccess]);

    return (
        (() => {
            switch (appState) {
                case AppStates.Valid:
                    return (
                        <Layout>
                            <Routes>

                                {AppRoutesDefault.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={<RouteGuardDefault beta={route.beta} />}>
                                        <Route exact {...rest} element={element} />
                                    </Route>
                                })}

                                {AppRoutesLoggedInPerson.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={<RouteGuardLoggedInPerson beta={route.beta} />}>
                                        <Route {...rest} element={element} />
                                    </Route>
                                })}

                                {AppRoutesLoggedIn.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={<RouteGuardLoggedIn beta={route.beta} />}>
                                        <Route {...rest} element={element} />
                                    </Route>
                                })}

                                {AppRoutesPersonType.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={<RouteGuardPersonType personType={route.type} onlineOnly={route.onlineOnly} beta={route.beta} doGuestCheck={route.doGuestCheck} />}>
                                        <Route {...rest} element={element} />
                                    </Route>
                                })}

                                {AppRoutesApplicationRights.map((route, index) => {
                                    const { element, ...rest } = route;

                                    return <Route key={index} {...rest} element={<RouteGuardApplicationRights applicationRights={route.applicationRights} onlineOnly={route.onlineOnly} beta={route.beta} />}>
                                        <Route {...rest} element={element} />
                                    </Route>
                                })}
                                <Route path='*' element={<NotFound />} />
                            </Routes>
                        </Layout>
                    );
                case AppStates.RestoreDatabase:
                    return (
                        <AppGrid>
                            <RestoreDatabase />
                        </AppGrid>
                    );
                case AppStates.Login:
                    return (<Login />);
                default:
                    return (
                        <AppGrid> 
                            <div className="k-d-grid k-grid-flow-row">
                                <div className="k-d-flex k-justify-content-center">
                                    <img alt="Kystverket" src="/images/kyv-logo-neg.svg" width="340"  />    
                                </div>
                                <div className="k-d-flex k-justify-content-center">
                                    <ProgressBar
                                        visible={true}
                                        height="160"
                                        width="160"
                                        barColor="#ebebeb"
                                        borderColor="#ebebeb"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                            </div>
                        </AppGrid>);
            }
        })()
    );
    
    async function onIntervalAsync() {

        if (!isNullOrEmpty(isSourceConfigSuccess) && isSourceConfigSuccess) {
            await initCacheAsync(sourceConfig);
        }

        const token = getToken();
        if (isNullOrEmpty(token)) return;

        const loggedIn = isLoggedIn();
        if (loggedIn) return;

        window.location.reload();
    }

    function handlePubSubTopic() {
        unsubscribe();
        navigate("/");
    }
}

const AppGrid = ({ children }) => {
    return(
        <div
            className="k-d-grid k-w-full k-h-full k-justify-content-center k-align-items-center app-grid">
            {children}
        </div>
    )
}
