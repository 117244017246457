import "../pilot-station.css"
import moment from 'moment';
import { useState } from "react";
import { PilotageInfoDialog } from '../../../components/layout/dialogs/PilotageInfoDialog';

const Timeline = ({ from, to, pilot, loggedInPersonId }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pilotage, setPilotage] = useState({});
    
    const distance = (from, to) => {
        if (from > to) {
            let temp = from;
            from = to;
            to = temp;
        }
        let duration = moment.duration(to.diff(from));
        return duration.asHours();
    };

    const position = (startTime, endTime) => {

        let start = distance(from, moment(startTime));
        let width = distance(moment(startTime), moment(endTime));

        if (moment(startTime) < from) {
            start = 0;
            width = width - distance(moment(startTime), from);
        }

        if (moment(endTime) > to) {
            width = width - distance(to, moment(endTime));
        }

        return (
            {
                left: `calc(var(--hour-width) * ${start})`,
                width: `calc(var(--hour-width) * ${width})`
            }
        );
    };

    const within = (startTime, endTime) => {
        if (moment(startTime) >= from && moment(startTime) < to) {
            return true;
        } else if (moment(endTime) <= to && moment(endTime) > from) {
            return true;
        }

        return false;
    };

    const showModal = (pilotage) => {
        setModalIsOpen(true);
        setPilotage({
            pilotageId: pilotage.pilotageId,
            title: pilotage.shipName,
            start: pilotage.fromTime,
            stop: pilotage.toTime,
            type: "PILOTAGE",
            attributes: {
                pilotageNo: pilotage.pilotageNo,
                from: pilotage.fromLocation,
                to: pilotage.toLocation,
            }
        });
    };

    const Pilotage = ({ pilotage }) => {
        if (within(pilotage.fromTime, pilotage.toTime)) {

            let status = "event-pilotage-not-processed";

            if (pilotage.isConfirmedByPilotDispatcher) {
                switch (pilotage.pilotagePilotStatus) {
                    case "CONFIRMED":
                        status = "event-pilotage-confirmed";
                        break;
                    case "REJECTED":
                        status = "event-pilotage-rejected";
                        break;
                    case "COMPLETED":
                        status = "event-pilotage-completed";
                        break;
                    case "CORRECTION_NEEDED":
                        status = "event-pilotage-correction-needed";
                        break;
                    default:
                        status = "event-pilotage-accepted";
                }
            }
            return (
                <div className={`event event-pilotage ${status}`} style={position(pilotage.fromTime, pilotage.toTime)} onClick={() => showModal(pilotage)}><div>{pilotage.shipName}</div></div>
            )
        }
    };

    const Appointment = ({ appointment }) => {
        if (within(appointment.fromTime, appointment.toTime)) {
            return (
                <div className="event event-appointment" style={position(appointment.fromTime, appointment.toTime)}></div>
            )
        }
    };

    const OffDuty = ({ offDutyPeriod }) => {
        if (within(offDutyPeriod.start, offDutyPeriod.end)) {
            return (
                <div className="event event-off-duty" style={position(offDutyPeriod.start, offDutyPeriod.end)}></div>
            )
        }
    };

    const Hour = () => {
        return (
            <div className="timeline-hour">
                <div className="timeline-hour-block"></div>
                <div className="timeline-hour-block"></div>
            </div>
        );
    };

    const Hours = () => {
        var result = [];
        var hours = moment.duration(to.diff(from)).asHours();
        for (var h = 0; h < hours; h++) {
            result.push(
                <Hour key={h} />
            );
        }
        return result;
    };

    return (
        <div className="timeline">
            {
                modalIsOpen &&
                <PilotageInfoDialog
                    onClose={() => setModalIsOpen(false)}
                    pilotage={pilotage}
                    pilotId={pilot.pilotID}
                    loggedInPersonId={loggedInPersonId.current}
                />
            }

            <div className="timeline-hours">
                {Hours()}
            </div>
            {pilot.pilotages.map((pilotage, k) => (
                <Pilotage key={k} pilotage={pilotage} />
            ))}
            {pilot.appointments.map((appointment, k) => (
                <Appointment key={k} appointment={appointment} />
            ))}
            {pilot.offDutyPeriods.map((offDutyPeriod, k) => (
                <OffDuty key={k} offDutyPeriod={offDutyPeriod} />
            ))}
        </div>
    );
};

export default Timeline;