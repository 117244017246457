import { isNullOrEmpty } from "./ObjectHelpers";

function isDarkTheme() {
    const theme = localStorage.getItem("theme");
    if (!isNullOrEmpty(theme)) {
        return theme === "theme-dark";
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
    }
    return false;
}

function toggleTheme() {
    let theme = "theme-light";
        if (!isDarkTheme()) {
        theme = "theme-dark";
    }
    document.documentElement.classList = theme;
    localStorage.setItem("theme", theme);
}

export {
    isDarkTheme,
    toggleTheme
}