import { useState } from "react";
import { PageStates } from "../../services/SystemNames";
import { useParams } from 'react-router-dom';
import PilotageDetailsShip from "../pilot/components/PilotageDetailsShip";
import PilotApiRepository from "../../repositories/api/PilotApiRepository";
import { useEffectOnce } from "../../components/useEffectOnce";
import PilotageDetailsDefault from "../pilot/pilotage-details/PilotageDetailsDefault";
import Overlay from "../../components/layout/overlay/Overlay";
import BottomNavigation from '../../components/layout/BottomNavigation';
import parse from 'html-react-parser';
import { GoogleMapCard } from "../../components/layout/map/GoogleMapCard";
import { GoogleMapProperties } from "../../components/layout/map/GoogleMapProperties";
import { publishErrorNotificationTopic, publishHeaderTopic } from "../../components/helpers/PubSubHelpers";

function Details() {

    const { id } = useParams();

    const [pageState, setPageState] = useState(PageStates.Busy);
    const [readOnlyPilotage, setReadOnlyPilotage] = useState({});

    useEffectOnce(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onBottomMenuClick(item) {
        if (pageState === item.action || pageState === PageStates.Error) return;
        setPageState(item.action);
    };

    const initializeAsync = async () => {
        try{
            const pilotage = await fetchReadOnlyPilotageAsync(id);
            setReadOnlyPilotage(pilotage);
            publishHeaderTopic(pilotage.pilotageShip.shipName, `${pilotage.pilotageNo}`, true, false, true);
    
            setPageState(PageStates.Default);
        }
        catch(error){
            publishErrorNotificationTopic("Feil ved henting av losoppdrag");
        }
    };

    return (
        <>
            {(() => {
                switch (pageState) {
                    case PageStates.Ship: {
                        return <PilotageDetailsShip pilotage={readOnlyPilotage} />;
                    }
                    case PageStates.Default: {
                        return (
                            <PilotageDetailsDefault
                                state={readOnlyPilotage}
                                onReload={initializeAsync}
                                isReadOnly={true}
                            />
                        )
                    }
                    case PageStates.Map: {
                        return (
                            <GoogleMapCard
                                properties={{
                                    ...GoogleMapProperties,
                                    pilotageId: Number(readOnlyPilotage.pilotageId),
                                    shipId: Number(readOnlyPilotage.pilotageShip.shipId),
                                    minZoom: 5,
                                    isAisActive: true,
                                    showSearch: true
                                }}
                            />
                        )
                    }
                    case PageStates.Busy: {
                        <Overlay isBusy={true} />
                        break;
                    }
                    default:
                        return null;
                }
            })()}

            <BottomNavigation
                onCallback={onBottomMenuClick}
                selectedAction={pageState}
                items={
                    [
                        {
                            text: "Info",
                            icon: "information",
                            action: PageStates.Default
                        },
                        {
                            text: parse("Fart&oslash;y"),
                            icon: "ship",
                            action: PageStates.Ship
                        },
                        {
                            text: "Kart",
                            icon: "map",
                            action: PageStates.Map
                        }
                    ]
                }
            />
        </>
    );
}

const fetchReadOnlyPilotageAsync = async (pilotageId) => {
    return await PilotApiRepository.getReadOnlyPilotageByIdAsync(pilotageId)
        .then((response) => { return response.json(); });
}

export default Details;
