import React from 'react';
import { isMobile } from '../../../helpers/DeviceHelpers';

export const CardContainer = ({
    children,
    properties = {},
    swipeHandlers }) => {

    return (
        <div className="card-container" {...swipeHandlers} onClick={handleOnClick}> {children}</div >
    )

    function handleOnClick() {
        if (!properties.canClick()) return;

        if (isMobile()) return;
        properties.onClick();
    }
}
