import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ValidateAccess from '../access/ValidateAccess';
import NoAccess from '../access/NoAccess';
import Offline from '../access/Offline';
import { isBoolean, isNullOrEmpty } from '../helpers/ObjectHelpers';
import { getToken } from '../helpers/TokenHelpers';
import { hasApplicationRights, isBetaUser, isLoggedIn } from '../helpers/AuthHelpers';
import { RouteGuardStates } from '../../services/SystemNames';
import Login from '../access/Login';
import { useGetApplicationRightsQuery } from '../../reducers/slices/api.slice';

export const RouteGuardApplicationRights = ({ applicationRights: ApplicationRights, onlineOnly = true, beta: Beta }) => {

    const location = useLocation();
    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    const {
        data: applicationRightsData,
        isSuccess,
    } = useGetApplicationRightsQuery();

    useEffect(() => {
        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);
        return function cleanupEventListeners() {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isNullOrEmpty(isSuccess)) return;
        if (!isSuccess) {
            setGuardState(RouteGuardStates.NoAccess);
        } else {
            validateApplicationRight();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, isSuccess]);

    function validateApplicationRight() {

        let canContinue = true;
        if (isBoolean(Beta)) {
            if (Beta) {
                canContinue = isBetaUser();
            }
        }

        if (isLoggedIn() === true && canContinue) {
            if (navigator.onLine) {
                const token = getToken();
                if (!isNullOrEmpty(token)) {
                    const hasRights = hasApplicationRights(applicationRightsData, ApplicationRights);
                    setGuardState(hasRights === true ? RouteGuardStates.Valid : RouteGuardStates.Login)
                } else {
                    setGuardState(RouteGuardStates.NoAccess);
                }
            } else if (navigator.onLine === false) {
                setGuardState(RouteGuardStates.Offline);
            }
        } else {
            setGuardState(RouteGuardStates.NoAccess);
        }
    }

    function handleOffline() {
        setGuardState(RouteGuardStates.Offline);
    }

    function handleOnline() {
        validateApplicationRight();
    }

    return (
        (() => {
            switch (guardState)
            {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.Offline:
                    return (<Offline />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.Login:
                    return (<Login />);

                default:
                    return (<ValidateAccess />);
            }
        })()
    )
};
