import React, { lazy, Suspense, useCallback } from 'react';
import './pdfViewer.css'
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { loadMessages } from '@progress/kendo-react-intl';
import nbMessages from '../../nb.json';
import Icons from './icons/Icons';
import { Button } from "@progress/kendo-react-buttons";
import { isObjectNull } from '../helpers/ObjectHelpers';
import { togglePdfToolbarWidth } from '../helpers/ElementHelpers';
loadMessages(nbMessages, 'nb-NO');

const ComponentLazyLoader = ({ componentName, ...props }) => {
    const Component = lazy(() => import('@progress/kendo-react-pdf-viewer')
        .then((module) => ({ default: module[componentName] })));
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    )
}

export default function PDFViewer(
    {
        data,
        onCloseClick = null,
        className = ""
    }
) {

    const pdfViewerTools = [
        "pager",
        "spacer",
        "zoomInOut",
        "zoom",
        "selection",
        "spacer",
        "search",
        "download",
        "print",
    ];

    const pdfViwerCustomTool = () => {
        return (
            (!isObjectNull(onCloseClick)) &&
            <Button
                key="Custom"
                className="pdf-toolbar-close"
                size="small"
                themeColor="error"
                onClick={onCloseClick}>
                <Icons iconName="close" />
            </Button>
        );
    };

    const resizePdfToolbar = () => {
        togglePdfToolbarWidth();
    }

    const onRenderToolbar = useCallback((toolbar) => {
        const buttons = React.Children.toArray(toolbar.props.children);

        const pager = buttons[pdfViewerTools.indexOf("pager")];
        const selection = buttons[pdfViewerTools.indexOf("selection")];
        const zoom = buttons[pdfViewerTools.indexOf("zoom")];
        const zoomInOut = buttons[pdfViewerTools.indexOf("zoomInOut")];
        const searchTool = buttons[pdfViewerTools.indexOf("search")];
        const download = buttons[pdfViewerTools.indexOf("download")];
        const spacer = buttons[pdfViewerTools.indexOf("spacer")];
        const printTool = buttons[pdfViewerTools.indexOf("print")];

        return (
            <toolbar.type {...toolbar.props}>
                {[
                    <React.Fragment key="pager"><div className="pdf-toolbar-large">{pager}</div></React.Fragment>,
                    <React.Fragment key="firstSpacer"><div className="pdf-toolbar-large">{spacer}</div></React.Fragment>,
                    <React.Fragment key="zoomInOut"><div className="pdf-toolbar pdf-toolbar-zoom">{zoomInOut}</div></React.Fragment>,
                    <React.Fragment key="zoom">
                        <div className="pdf-toolbar pdf-toolbar-zoom">{zoom}</div>
                    </React.Fragment>,
                    <React.Fragment key="selection"><div className="pdf-toolbar-medium">{selection}</div></React.Fragment>,
                    // wrap it in fragment to resolve keys duplication when multiple spacers are needed
                    <React.Fragment key="secondSpacer">{spacer}</React.Fragment>,
                    <React.Fragment key="searchTool"><div className="pdf-toolbar-medium">{searchTool}</div></React.Fragment>,
                    download,
                    printTool,
                    pdfViwerCustomTool()

                ]}
            </toolbar.type>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LocalizationProvider language='nb-NO'>
            <IntlProvider locale='en'>
                <div className={className}>
                    <ComponentLazyLoader
                        componentName="PDFViewer"
                        tools={pdfViewerTools}
                        saveFileName={data.fileName}
                        data={data.base64}
                        onRenderToolbar={onRenderToolbar}
                        defaultZoom={0.75}                    />
                    {resizePdfToolbar()}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    );
}
