import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Oval, Hourglass, MagnifyingGlass, Bars, ProgressBar, BallTriangle, ThreeDots } from 'react-loader-spinner';
import { useRef } from 'react';
import { isNullOrEmpty } from '../../../helpers/ObjectHelpers';
import { addClassToElementById, removeClassFromElementById } from '../../../helpers/ElementHelpers';

export default function Busy({ isBusy = false, text = "", type = "" }) {

    const componentRef = useRef({
        timer: null
    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        if (!isBusy) {
            clearTimeout(localRef.timer);
            removeClassFromElementById("BusyOverlay", "overlay-active");
        } else {
            localRef.timer = setTimeout(() => {
                addClassToElementById("BusyOverlay", "overlay-active");
            }, 2000);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBusy]);

    return (
        isBusy && 
        <>
            <div className={`overlay`} id="BusyOverlay"></div>
            <div className="overlay-content" onClick={onClick}>
                <div style={{
                    background: isNullOrEmpty(text) ? "transparent" : "var(--busy-overlay-content-bg)"
                }}>
                    <div className="overlay-busy-spinner">
                        <BusySpinner type={type} />
                    </div>
                    {
                        !isNullOrEmpty(text) &&
                        <div>{parse(text)}</div>
                    }

                </div>
            </div>
        </>
    );

    function onClick() {
        clearTimeout(localRef.timer);
        addClassToElementById("BusyOverlay", "overlay-active");
    }
}

// https://mhnpd.github.io/react-loader-spinner/docs/category/components/
export const BusySpinner = ({ type = "", dimensions = 100 }) => {
    return (
        (() => {
            switch (type.toLowerCase()) {
                case "bars":
                    return <Bars height={dimensions} width={dimensions} color="var(--progressbar-color)"/>
                case "hourglass":
                    return <Hourglass height={dimensions} width={dimensions} colors={['#007d97', '#33a271']} />
                case "magnifyingglass":
                    return <MagnifyingGlass height={dimensions} width={dimensions} />
                case "progressbar":
                    return <ProgressBar height={dimensions * 1.5} width={dimensions * 1.5} barColor="var(--progressbar-bg)" borderColor="var(--progressbar-border)" />
                case "oval":
                    return <Oval height={dimensions * 1.5} width={dimensions * 1.5} barColor="var(--progressbar-bg)" borderColor="var(--progressbar-border)" />
                case "balltriangle":
                    return <BallTriangle height={dimensions} width={dimensions} />
                default:
                    return <ThreeDots height={dimensions} width={dimensions} color="var(--progressbar-color)" />
            }
        })()
    )
}

export const BusySpinnerContent = ({ type = "", dimensions = 150 }) => {
    return (
        <div className="busy-spinner-content">
            <BusySpinner type={type} dimensions={dimensions} />
        </div>
    )
}
