import './card.css'

import React, { useEffect, useState } from 'react';
import { Card } from './Card';
import Spacer from '../Spacer';
import { CardModes } from './components/CardProperties';
import { isObjectNull } from '../../helpers/ObjectHelpers';


export const CardList = (
    {
        children,
        mode = CardModes.Single, // "single" or "multible"
        cards = [],
        toggleActivateSelect = null,
        toggleSelectItem = null,
        disabled = false
    }) => {

    const [toggleDelete, setToggleDelete] = useState(null);
    const [toggleSelect, setToggleSelect] = useState(null);
    const [toggleDisabled, setToggleDisabled] = useState(null);

    useEffect(() => {
        setToggleDelete(null);
        setToggleSelect(null);
    }, [cards]);

    useEffect(() => {
        setToggleDisabled(disabled);
    }, [disabled])

    return (
        <>
            <div className="card-list">
                {children}
                {
                    cards.map((card) => {
                        return (
                            <div key={card.guid}>
                                <Card
                                    properties={{
                                        ...card.properties,
                                        mode: mode,
                                        toggleSelect: toggleSelect,
                                        toggleDelete: toggleDelete,
                                        onToggleDelete: () => setToggleDelete(!toggleDelete),
                                        onSelect: onSelect,
                                        onToggleSelect: onToggleSelect,
                                        toggleDisabled: toggleDisabled
                                    }}>
                                    {card.body}
                                </Card>
                                <Spacer height={20} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    );

    function onToggleSelect(toggle) {
        setToggleSelect(toggle);

        if (isObjectNull(toggleActivateSelect)) return;

        toggleActivateSelect(toggle);
    }

    function onSelect(id = 0, selected = false, type = "") {

        if (isObjectNull(toggleSelectItem)) return;

        toggleSelectItem(id, selected, type);
    }

};
