import { useEffect, useState } from "react";
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { getElementById } from "../../../../helpers/ElementHelpers";
import { showMapControl } from "../../GoogleMapHelpers";
import { Plus, Minus, Polygon, X, Check } from "phosphor-react-sc";
import { isObjectNull } from "../../../../helpers/ObjectHelpers";

export const MapControlsBottom = ({
    mapId,
    properties,
    onMultiSelectClick,
    onMultiSelectOkClick,
}) => {

    const map = useMap();

    const [hasAddedBaseControls, setHasAddedBaseControls] = useState(false);
    const [hasAddedMultiControl, setHasAddedMultiControl] = useState(false);
    const [isMultiActive, setIsMultiActive] = useState(false);

    const zoomElemId = `googleMapControlZoom-${mapId}`;
    const loaderElemId = `googleMapControlLoader-${mapId}`;
    const multiElemId = `googleMapControlMulti-${mapId}`;
    const multiButtonElemId = `googleMapControlMultiButton-${mapId}`;
    const titleElemId = `googleMapTitle-${mapId}`;

    useEffect(() => {
        if (!map) return;

        if (properties.multiSelectControl) {
            if (!hasAddedMultiControl) {
                const multiElem = getElementById(multiElemId);
                map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(multiElem);
                setHasAddedMultiControl(true);
            }
        } else {
            let removeIndex = -1;
            map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].forEach(function (control, index) {
                if (control.id === multiElemId) {
                    removeIndex = index;
                }
            });
            if (removeIndex > -1) {
                map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].removeAt(removeIndex);
            }
            setIsMultiActive(false);
            setHasAddedMultiControl(false);
        }

        if (hasAddedBaseControls) return;

        const loaderElem = getElementById(loaderElemId);
        map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(loaderElem);

        const zoomElem = getElementById(zoomElemId);
        map.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(zoomElem);

        const titleElem = getElementById(titleElemId);
        map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(titleElem);

        setHasAddedBaseControls(true);

        if (properties.showPcsGroups || !isObjectNull(properties.pecExam)) {
            showMapControl(titleElemId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties]);


    return (
        <>
            <div id={titleElemId} className="google-map-title">
                <div className="google-map-title-content">
                    <div className="text-truncate"></div>
                </div>
            </div>  

            <div id={zoomElemId} className="google-map-control-zoom">
                <div className="google-map-control-zoom-button" onClick={() => onZoom(1)}>
                    <Plus size={20} />
                </div>
                <div/>
                <div className="google-map-control-zoom-button" onClick={() => onZoom(-1)}>
                    <Minus size={20} />
                </div>
            </div>

            <div id={multiElemId} className={`google-map-control-multi ${isMultiActive ? "google-map-control-multi-active" : ""}`}>
                <div>
                    <div
                        onClick={onMultiOkClick}
                        className="gmcmb gmcms">
                        <Check size={20} />
                    </div>
                    <div
                        onClick={onMultiCancelClick}
                        className="gmcmb gmcmc" style={{
                            backgroundColor: "var(--error)"
                        }}>
                        <X size={20} />
                    </div>
                </div>
                <div
                    id={multiButtonElemId}
                    onClick={onMultiClick}
                    data-active={isMultiActive}
                    className={`gmcmb gmcmm ${isMultiActive ? "google-map-control-button-active" : ""}`}>
                    <Polygon size={20} />
                </div>
            </div>
        </>
    )

    function onMultiClick() {
        if (isMultiActive) {
            onMultiSelectClick();
        }
        setIsMultiActive(!isMultiActive);
    }

    function onMultiOkClick() {
        setIsMultiActive(!isMultiActive);
        onMultiSelectOkClick();
    }

    function onMultiCancelClick() {
        setIsMultiActive(!isMultiActive);
        onMultiSelectClick();
    }

    function onZoom(zoom) {
        const currentZoom = map.getZoom();
        map.setZoom(currentZoom + zoom);
    }
}
