import { useEffect, useState } from "react";
import { isNullOrEmpty, isObjectNull } from "../../../components/helpers/ObjectHelpers";
import { BusySpinner } from "../../../components/layout/overlay/components/Busy";
import { IdentificationBadge } from "phosphor-react-sc";
import Icons from "../../../components/layout/icons/Icons";

export const HomeCard = ({
    children,
    className = "",
    backgroundColor = "var(--home-default-bg)",
    onClick = null
}) => {

    return (
        <div
            className={`home-card ${className}`}
            onClick={handleOnClick}
            style={{
                backgroundColor: backgroundColor
            }}>
            {children}
        </div>
    )

    function handleOnClick() {
        if (isObjectNull(onClick)) return;
        onClick();
    }
}

export const HomeOnlineCard = ({
    children,
    canShow = false,
    className = "",
    onClick = null,
    backgroundColor = "var(--home-default-bg)"
}) => {

    const [show, setShow] = useState(false);

    useEffect(() => {

        if (!canShow || !navigator.onLine) return;

        const initializeOnline = () => {
            setShow(true);
        }

        const initializeOffline = () => {
            setShow(false);
        }

        if (navigator.onLine) {
            initializeOnline();
        } else {
            initializeOffline();
        }

        window.addEventListener('online', initializeOnline);
        window.addEventListener('offline', initializeOffline);

        return () => {
            window.removeEventListener('online', initializeOnline);
            window.removeEventListener('offline', initializeOffline);
        }

    }, []);

    return (
        show &&
        <HomeCard className={className} onClick={onClick} backgroundColor={backgroundColor}>
            {children}
        </HomeCard>
    )
}

export const HomeContent = ({ children, icon = null, isBusy = false }) => {
    return (
        isBusy ?
            <HomeBusy/>
            :
            <div className="home-content">
                <div>
                    {
                        (!isNullOrEmpty(icon)) &&
                        (() => {
                            switch (icon) {
                                case "identificationBadge":
                                    return (<IdentificationBadge size={64} />)
                                default:
                                    return (<Icons iconName={icon} dimensions={64} color="var(--icon-primary-fill)" />);
                            }
                        })()
                    }
                </div>
                <div className="k-justify-self-start">
                    {children}
                </div>
            </div>
    )
}

export const HomeBusy = () => {
    return (
        <div className="k-d-grid k-justify-items-center k-mt-2">
            <BusySpinner type="bars" dimensions={70} />
        </div>
    )
}
