import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const PilotStationRepository = {

    async getStations() {
        return await BaseApiRepository.getAsync(`pilot-station/`);
    },

    async getFavouriteStations() {
        return await BaseApiRepository.getAsync(`pilot-station/favourites`);
    },

    async setFavouriteStation(station) {
        return await BaseApiRepository.putAsync(`pilot-station/favourites`, station);
    },

    async getStationOverView(id) {
        return await BaseApiRepository.getAsync(`pilot-station/${id}`);
    },

};
wrapMethodsWithLogging(PilotStationRepository);

export default PilotStationRepository;