import {
    IndexedDbNames,
    IndexedDbKeyNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';
import Moment from 'moment';
import { isObjectNull } from '../../components/helpers/ObjectHelpers';

const Store = IndexedDbNames.MetaStore;

const MetaIdbRepository = {

    async getAsync(keyName) {
        return await BaseIdbRepository.getAsync(Store, keyName);
    },

    async setAsync(keyName, value) {
        await BaseIdbRepository.setAsync(Store, keyName, {
            id: keyName,
            value: value
        });
    },

    async setCacheLastExpireDateAsync(expireDate) {
        await BaseIdbRepository.setAsync(Store, IndexedDbKeyNames.CacheLastExpireDate, { id: IndexedDbKeyNames.CacheLastExpireDate, value: expireDate });
    },

    async getCacheLastExpireDateAsync() {
        const obj = await this.getAsync(IndexedDbKeyNames.CacheLastExpireDate);
        if (isObjectNull(obj)) return { value: Moment().add(-1, 'd').toDate() };

        return obj;
    },

    async deleteCacheLastExpireDateAsync() {
        await BaseIdbRepository.deleteAsync(Store, IndexedDbKeyNames.CacheLastExpireDate);
    },

    async getWhoAmIAsync() {
        const obj = await this.getAsync(IndexedDbKeyNames.WhoAmI);
        if (isObjectNull(obj)) return {}
        return obj.value;
    },

    async setWhoAmIAsync(obj) {
        await this.setAsync(IndexedDbKeyNames.WhoAmI,
            {
                firstName: obj.firstName,
                lastName: obj.lastName,
                fullName: obj.fullName,
                personId: obj.personID,
                defaultLocation: obj.defaultLocation
            }
        );
    },

    async deleteWhoAmIAsync() {
        await BaseIdbRepository.deleteAsync(Store, IndexedDbKeyNames.WhoAmI);
    },

    async setDayTimeAsync(obj) {
        await this.setAsync(IndexedDbKeyNames.DayTime, obj);
    },

    async getDayTimeAsync() {
        const obj = await this.getAsync(IndexedDbKeyNames.DayTime);
        if (isObjectNull(obj)) return {}
        return obj.value;
    },

    async setConfigurationAsync(obj) {
        await this.setAsync(IndexedDbKeyNames.Configuration, obj);
    },

    async getConfigurationAsync() {
        const obj = await this.getAsync(IndexedDbKeyNames.Configuration);
        if (isObjectNull(obj)) return {}
        return obj.value;
    },

    async getGuestPilotStationAsync(id) {
        const obj = await this.getAsync(id);
        return obj;
    },

    async setGuestPilotStationAsync(id, obj) {
        await this.setAsync(id, obj);
    },

    async deleteGuestPilotStationAsync(id) {
        await BaseIdbRepository.deleteAsync(Store, id);
    }
};

export default MetaIdbRepository;
