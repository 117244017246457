import './panelbar.css'
import React, { useEffect, useRef, useState } from 'react';
import { PanelBar as KendoPanelBar, PanelBarItem as KendoPanelBarItem } from "@progress/kendo-react-layout";
import { isNullOrEmpty } from '../helpers/ObjectHelpers';

export default function PanelBar(
    {
        children,
        title = "",
        subTitle = "",
        className = "",
        subClassName = "",
        expanded = false
    }
) {

    const panelBarRef = useRef(null);
    const [panelBarKey, setPanelBarKey] = useState(999);

    useEffect(() => {
        if (!expanded) return;
        setPanelBarKey(panelBarKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    return (
        <KendoPanelBar
            ref={panelBarRef}
            key={panelBarKey}
            className={`panel-bar ${className}`}>
            <KendoPanelBarItem
                expanded={expanded}
                title={
                    isNullOrEmpty(subTitle) ?
                        title
                        :
                        <div className="panel-bar-title">
                            <div>{title}</div>
                            <div className={subClassName}>{subTitle}</div>
                        </div>
                }>
                {children}
            </KendoPanelBarItem>
        </KendoPanelBar>
    );
}
