import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.VariableCompensationOverrideReasonTypeStore;

const VariableCompensationOverrideReasonTypeIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(obj) {
        await BaseIdbRepository.setAsync(Store, obj.pilotagePilotVariableCompensationOverrideReasonTypeId, obj);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },

    async getBySystemName(systemName) {
        const all = await this.getAllAsync();
        return all.find(a => a.systemName === systemName);
    }
};

export default VariableCompensationOverrideReasonTypeIdbRepository;