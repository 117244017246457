import { isObjectNull } from "../../helpers/ObjectHelpers";
import { MapClickTypes } from "./GoogleMapHelpers";

export const GoogleMapProperties = {
    zoom: 11,
    minZoom: 4,
    minLocationsZoom: 9,
    pcsGroupId: 0,
    pilotageId: 0,
    shipId: 0,
    evaluation: null,
    pecExam: null,

    mapTypeControl: true,
    zoomControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    rotateControl: true,
    canMapClick: false,
    showLocations: false,
    showPcsGroups: false,
    showPcs: false,
    showSettings: true,
    showSearch: false,
    multiSelectControl: false,
    isAisActive: false,
    isInline: true,

    mapClickType: MapClickTypes.Marker, // circle or marker
    mapClickPosition: null,

    onBoundsChanged: null,
    onDragEnd: null,
    onZoomChanged: null,
    onTilesLoaded: null,
    onResize: null,
    onFeatureClick: null,
    onFeatureMouseOver: null,
    onFeatureMouseOut: null,
    onMapClick: null,

    handleOnBoundsChanged(obj) {
        if (isObjectNull(this.onBoundsChanged)) return;
        this.onBoundsChanged(obj);
    },

    handleOnDragEnd(obj) {
        if (isObjectNull(this.onDragEnd)) return;
        this.onDragEnd(obj);
    },

    handleOnZoomChanged(obj) {
        if (isObjectNull(this.onZoomChanged)) return;
        this.onZoomChanged(obj);
    },

    handleOnTilesLoaded: function (obj) {
        if (isObjectNull(this.onTilesLoaded)) return;
        this.onTilesLoaded(obj);
    },

    handleOnResize: function (obj) {
        if (isObjectNull(this.onResize)) return;
        this.onResize(obj);
    },

    handleOnFeatureClick: function (id) {
        if (isObjectNull(this.onFeatureClick)) return;
        this.onFeatureClick(id);
    },

    handleOnFeatureMouseOver: function (id) {
        if (isObjectNull(this.onFeatureMouseOver)) return;
        this.onFeatureMouseOver(id);
    },

    handleOnFeatureMouseOut: function () {
        if (isObjectNull(this.onFeatureMouseOut)) return;
        this.onFeatureMouseOut();
    },

    handleOnMapClick: function (e) {
        if (isObjectNull(this.onMapClick)) return;
        this.onMapClick(e);
    },
}

