import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";

function TextFieldWrapper({
    title = "",
    boldTitle = false,
    text = "",
    textIsLink = false
}) {

    return (
        <FieldWrapper>
            <Label className="k-form-label" style={{
                fontWeight: boldTitle ? "500" : "normal"
            } }>
                {parse(title) }:
            </Label>
            <div className="k-form-field-wrap">
                {
                    textIsLink ? 
                        <a className="phone-link" href={`tel:${text}`}>{text}</a>
                    :
                    text
                }
            </div>
        </FieldWrapper>
    );
}

export default TextFieldWrapper; 