import React from 'react';
import ContentGrid from '../../../components/layout/ContentGrid';
import Spacer from '../../../components/layout/Spacer';
import { IconColors } from '../../../services/SystemNames';
import { Button } from "@progress/kendo-react-buttons";
import { CaretUp, CaretDown, Circle } from "phosphor-react-sc";

export function Default({ children, onMouseUp }) {
    return (
        <ContentGrid titleIcon="hourglass" className="content-grid" onMouseUp={onMouseUp} title="Simuler arbeidstid">
            <div className="simulate-worktime">
                { children }
            </div>
        </ContentGrid>
    )
}

export function Controls({ state, swipeHandlers, onClickUp, onClickDown, onWheel, onReset, onReload, onStart, onStop }) {
    return (
        <div className="controls">
            <div>N&Aring;:</div>
            <div>{formatNow()}</div>
            <div>
                <div onWheel={(e) => onWheel(e)} {...swipeHandlers}>
                    <div onClick={onClickUp}><CaretUp color={IconColors.Primary} size={30} /></div>
                    <div><Circle color={IconColors.Disabled} size={30} /></div>
                    <div onClick={onClickDown}><CaretDown color={IconColors.Primary} size={30} /></div>
                </div>
            </div>
            <div>
                <Button
                    style={{width: 120}}
                    themeColor={getThemeColor()}
                    onClick={onClick}>{state.isCurrentlyWorking ? 'Stopp' : 'Start'}</Button>
            </div>
            <Spacer />
            <div>
                <Button
                    style={{ width: 120 }}
                    fillMode="outline"
                    themeColor="primary"
                    onClick={onReset}>Tilbakestill</Button>
            </div>
            <Spacer height={30} />
            <div>
                <Button
                    style={{ width: 120 }}
                    fillMode="outline"
                    themeColor="primary"
                    onClick={onReload}>Nullstill</Button>
            </div>
            <Spacer />
        </div>
    )

    function onClick() {
        if (state.isCurrentlyWorking) {
            onStop();
        } else {
            onStart();
        }
    }

    function getThemeColor() {
        if (state.isCurrentlyInMandatoryRest) return 'info';

        return state.isCurrentlyWorking ? 'warning' : 'primary';
    }

    function formatNow() {
        if (state.nowTime === null) return "";
        return state.nowTime.format('HH:mm')
    }
}

