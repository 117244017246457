import React from 'react';
import { WorkTimeTypeEnum } from '../services/SystemNames';
import parse from 'html-react-parser';
import moment from 'moment';
import TimelineViewService from '../services/TimelineViewService';
import { useTimelineViewContext } from './TimelineViewContext';

export function TimelineViewWorkTimes() {

    const { timelineViewType } = useTimelineViewContext();

    return (
        <>
            {
                timelineViewType.workTimes.map((workTime, index) => {
                    switch (workTime.type) {
                        case WorkTimeTypeEnum.NextFullLineIndicator:
                        case WorkTimeTypeEnum.NextReducedLineIndicator:
                            return <TimelineViewWorkLine
                                key={index}
                                workTime={workTime}
                                calcTop={calcTop}
                            />
                        default:
                            return <TimelineViewWorkTime
                                key={index}
                                showHighlight={timelineViewType.showHighlight}
                                workTime={workTime}
                                calcHeight={calcHeight}
                                calcTop={calcTop}
                            />
                    }
                })
            }
        </>
    )

    function calcHeight(time) {
        return TimelineViewService.calcHeight(moment(time.start), moment(time.stop));
    }

    function calcTop(time) {
        return TimelineViewService.calcHeight(moment(timelineViewType.fromTime), moment(time.start));
    }
}

export function TimelineViewWorkTime({ workTime, calcHeight, calcTop, showHighlight = false }) {
    return (
        <>
            <div style=
                {{
                height: `${calcHeight(workTime)}px`,
                top: `${calcTop(workTime)}px`

                }}
                className={`worktime ${getClassName(workTime)}`}><div>{workTime.description}</div></div>
            {
                workTime.showTitle ?
                    <div style=
                        {{
                            top: `${calcTitleTop()}px`
                        }}
                        className={`worktime-title`}>
                        <div className={`worktime-${getBaseClassName()}`}></div>
                        <div className={`worktime-${getBaseClassName()} ${getHighlightClassName()}`}>{parse(workTime.title)}</div>
                    </div>
                    : null
            }
        </>
    )

    function calcTitleTop() {
        const top = calcTop(workTime);
        const titleHeightAdjustment = 13;

        let height = calcHeight(workTime);
        let result = 0;

        if (height < 80) {
            result = top + (height / 2) - titleHeightAdjustment;
        } else {
            result = top + (height * 0.7) - titleHeightAdjustment;
        }

        return result;
    }

    function getHighlightClassName() {
        if (workTime.highlight === false || showHighlight === false) return '';
        return 'worktime-highlight';
    }

    function getClassName() {
        return `worktime-${workTime.isFuture ? 'future-' : ''}${getBaseClassName(workTime)}`;
    }

    function getBaseClassName() {
        let className = "";
        switch (workTime.type) {
            case WorkTimeTypeEnum.Available:
                className = "available";
                break;
            case WorkTimeTypeEnum.WorkingTime:
                className = "work";
                break;
            case WorkTimeTypeEnum.LineIndicator:
                className = "line";
                break;
            case WorkTimeTypeEnum.RestingTime:
                className = "rest";
                break;
            default:
                className = "unknown";
                break;
        }

        return className;
    }
}

export function TimelineViewWorkLine({ workTime, calcTop }) {
    return (
        <div style={{
            top: `${calcTop(workTime)}px`,
        }}
            className={`worktime worktime-line ${getClassName()}`}>
            <div>
                {parse(workTime.title.replace('\n', '<br/>'))}
            </div>
        </div>
    )

    function getClassName() {
        switch (workTime.type) {
            case WorkTimeTypeEnum.NextFullLineIndicator:
                return "worktime-line-full";
            case WorkTimeTypeEnum.NextReducedLineIndicator:
                return "worktime-line-reduced";
            default:
                return "";
        }
    }
}
