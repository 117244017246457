import React, { useEffect, useState } from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { isArrayEmpty } from '../../../components/helpers/ObjectHelpers';

export const NoticeTimeBottomNavigationActions = {
    General: "GENERAL",
    Locations: "LOCATIONS",
    Overridden: "OVERRIDDEN"
};

export const NoticeTimeBottomNavigation = (
    {
        menu,
        onClick
    }) => {

    const [selectedAction, setSelectedAction] = useState(null);
    const items =
        [
            {
                icon: "information",
                text: "Generell",
                action: NoticeTimeBottomNavigationActions.General
            },
            {
                icon: "pin",
                text: "Lokasjon",
                action: NoticeTimeBottomNavigationActions.Locations
            },
            {
                icon: "alarmClock",
                text: "Overstyrt",
                action: NoticeTimeBottomNavigationActions.Overridden
            },
        ];
    
    useEffect(() => {
        if (isArrayEmpty(items)) return;
        const item = items.find(i => i.action === menu);
        setSelectedAction(item.action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu]);
            


    return (
        <BottomNavigation
            onCallback={handleOnClick}
            selectedAction={selectedAction}
            items={items}
        />
    );

    function handleOnClick(e) {

        onClick(e.action);
    }
}
