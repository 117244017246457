import { useEffect, useState } from 'react';
import {
    useMap
} from '@vis.gl/react-google-maps';
import PecAdminApiRepository from '../../../../repositories/api/PecAdminApiRepository';
import { wktToGeoJSON } from "@terraformer/wkt"
import { isNullOrEmpty } from '../../../helpers/ObjectHelpers';
import { colorFairwayArea, colorFairwayAreaApplied, colorFairwayAreaStroke, setMapTitle } from '../GoogleMapHelpers';

export const MapPecExamFairwayAreas = ({
    mapId,
    properties
}) => {

    const map = useMap();   
    const [isInitialized, setIsInitialized] = useState(false);
    
    useEffect(() => {

        if (!map) return;

        if (isInitialized) return;

        function coordinatesToLatLng(coordinates) {
            const result = [];
            for (const coordinate of coordinates) {
                const latLng = { lat: Number(coordinate[1]), lng: Number(coordinate[0]) };
                result.push(latLng);
            }
            return result;
        }

        function formatCoordinates(coordinates) {
            const result = [];
            for (const coordinate of coordinates) {
                if (coordinate.length === 1) {
                    const latLngs = coordinatesToLatLng(coordinate[0]);
                    result.push(latLngs);
                } else {
                    for (const subCoordinate of coordinate) {
                        result.push(coordinatesToLatLng(subCoordinate));
                    }
                }
            }
            return result;
        }

        function addPolygon(fairwayArea, terraformedWkt) {
            let coordinates = [];
            if (terraformedWkt.type === "Polygon") {
                coordinates = coordinatesToLatLng(terraformedWkt.coordinates[0]);
            } else {
                coordinates = formatCoordinates(terraformedWkt.coordinates);
            }

            const isApplied = fairwayArea.applied;
            const polygonColor = isApplied ? colorFairwayAreaApplied : colorFairwayArea;

            const polygon = new window.google.maps.Polygon({
                paths: coordinates,
                strokeColor: isApplied ? polygonColor : colorFairwayAreaStroke,
                strokeOpacity: 0.9,
                strokeWeight: 3,
                fillColor: polygonColor,
                fillOpacity: 0.5,
                zIndex: isApplied ? 2 : 1,
                properties: {
                    name: isApplied ? 'Innvilges etter pr&oslash;ve' : 'Allerede innvilgede omr&aring;der',
                }
            });

            polygon.setMap(map);

            // Add a listener for the click event.
            polygon.addListener("mouseover", onMouseOver);
            polygon.addListener("mouseout", onMouseOut);
            polygon.addListener("click", onClick);

            return coordinates;
        }

        function addFeature(fairwayArea) {

            if (isNullOrEmpty(fairwayArea.wkt)) return [];

            let terraformedWkt = null;
            
            try {
                terraformedWkt = wktToGeoJSON(fairwayArea.wkt);
            } catch {
                console.warn("Could not terraform Wkt");
            }

            if (isNullOrEmpty(terraformedWkt)) return [];

            const coordinates = addPolygon(fairwayArea, terraformedWkt);

            return coordinates;
        }
       

        async function initializePecFairwayAreasAsync() {
            const response = await PecAdminApiRepository.getPecFairwayAreasAsync(properties.pecExam.pecId, properties.pecExam.pilotagePecExamId);
            if (response.ok) {
                const bounds = new window.google.maps.LatLngBounds();
                const data = await response.json();
                for (const fairwayArea of data) {
                    const coordinates = addFeature(fairwayArea);
                    extendBounds(bounds, coordinates);
                }

                map.setCenter(bounds.getCenter());
                map.fitBounds(bounds);
            }
        }

        function extendBounds(bounds, obj) {
            if (Array.isArray(obj)) {
                for (const item of obj) {
                    extendBounds(bounds, item);
                }
            } else {
                bounds.extend(obj);
            }
        }

        function onMouseOver(e) {
            const polygon = this;
            polygon.setOptions({ fillOpacity: 0.8 })

            setMapTitle(mapId, polygon.properties.name);
        }

        function onMouseOut() {
            const polygon = this;
            polygon.setOptions({ fillOpacity: 0.5 })
            
            setMapTitle(mapId, "");
        }

        function onClick() {
            const polygon = this;
            setMapTitle(mapId, polygon.properties.name);
        }

        initializePecFairwayAreasAsync();
        setIsInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, properties])
    
}
