import { useTimelineViewContext } from "./TimelineViewContext";

export function TimelineViewDays() {

    const { timelineViewType } = useTimelineViewContext();

    return (
        <div className="days">
            {
                timelineViewType.timelineTimes.map((time, index) =>
                    <TimelineViewHour time={time} key={index} index={index} timelineTimes={timelineViewType.timelineTimes} />
                )
            }
        </div>
    )
}

export function TimelineViewHour({ time, index, timelineTimes = [] }) {

    const evenDay = Number(time.format('DD')) % 2 === 0;
    const evenHour = time.hour() % 2 === 0;
    let description = "";
    let classNames = "";
    let date = ""

    if (evenHour) {
        description = time.format('HH');
    }

    if (index > 0) {
        if (index < timelineTimes.length - 1) {
            const nextTime = timelineTimes[index + 1];
            if (time.day() !== nextTime.day()) {
                classNames = 'last';
            }
        }
        if (index < timelineTimes.length + 1) {
            const previousTime = timelineTimes[index - 1];
            if (time.day() !== previousTime.day()) {
                date = `${getDayOfWeek()} ${time.format('DD')}`;
            }
        }
    }

    classNames += ` ${evenDay ? 'even' : 'odd'}-day`;

    return (
        <div className={classNames}><div>{description}</div><div>{date}</div></div>
    )

    function getDayOfWeek() {
        const dayOfWeek = time.day();
        switch (dayOfWeek) {
            case 6:
                return "Lør";
            case 0:
                return "Søn";
            default:
                return time.format('ddd');
        }
    }
}