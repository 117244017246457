
export const WorkTimeTypeEnum = {
    WorkingTime: 'WORKING_TIME',
    RestingTime: 'RESTING_TIME',
    Available: 'AVAILABLE',
    ArrivalOvertimeTravel: 'ARRIVAL_OVERTIME_TRAVEL',
    NextFullLineIndicator: 'NEXT_FULL_LINE_INDICATOR',
    NextReducedLineIndicator: 'NEXT_REDUCED_LINE_INDICATOR'
}

export const AppointmentTypeEnum = {
    Pilotage: 'PILOTAGE',
    Calendar: 'Calendar'
}