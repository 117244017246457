import React, { useState } from 'react';
import {
    Dialog as KendoDialog,
    DialogActionsBar as KendoDialogActionsBar
} from "@progress/kendo-react-dialogs";
import parse from 'html-react-parser';
import { xIcon, checkIcon } from "@progress/kendo-svg-icons";
import {
    Button
} from "@progress/kendo-react-buttons";
import { TextArea as KendoTextArea } from "@progress/kendo-react-inputs";
import { isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { getDialogSizeClassName } from '../DialogHelpers';
import { DialogForm } from '../../DialogForm';

export const DialogConfirm = ({ children, properties = {} }) => {
    
    const [disabled, setDisabled] = useState(properties.requiresDescription);
    const [description, setDescription] = useState("");

    return (
        <KendoDialog title={parse(properties.title)} onClose={handleOnClose} className={`dialog ${getDialogSizeClassName(properties)}`}>
            <DialogForm>
                {
                    (!isNullOrEmpty(properties.text)) &&
                    <div className="row">
                        <div className="col text-center">
                            {parse(properties.text)}
                        </div>
                    </div>
                }                
                {children}
                {
                    (properties.requiresDescription) &&
                        <div>
                            <KendoTextArea
                                value={description}
                                onChange={handleOnChange}
                                rows={6}
                                autoSize={false}
                                placeholder={`Begrunnelse...`}
                            />
                        </div>
                }
            </DialogForm>
            <KendoDialogActionsBar>
                <Button
                    onClick={handleOnClose}
                    svgIcon={xIcon}>{properties.closeText}</Button>
                <Button
                    onClick={handleOnClick}
                    svgIcon={checkIcon}
                    disabled={disabled || !properties.isClickActive}
                    themeColor="primary">{properties.clickText}</Button>
               
            </KendoDialogActionsBar>
        </KendoDialog>
    )

    function handleOnClose() {
        if (isObjectNull(properties.onClose)) {
            alert("Mangler aksjon for ? lukke dialog.");
        } else {
            properties.onClose();
        }
    }

    function handleOnClick() {
        if (isObjectNull(properties.onClick)) {
            alert("Mangler aksjon.");
        } else {
            properties.onClick();
        }
    }

    function handleOnChange(e) {
        if (properties.requiresDescription) {
            setDisabled(isNullOrEmpty(e.value));
        }
        setDescription(e.value);
        properties.onChange(e.value)
    }
}
