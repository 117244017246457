import React from 'react';
import { VariableCompensationBoardingCard } from './VariableCompensationBoardingCard';
import { VariableCompensationBridgeCard } from './VariableCompensationBridgeCard';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import uuid from 'react-uuid';
import { Label } from '../../../components/layout/Label';
import Icons from '../../../components/layout/icons/Icons';
import DropDownButton from '../../../components/layout/DropDownButton';
import Spacer from '../../../components/layout/Spacer';
import { PageStates } from '../../../services/SystemNames';
import { getValidationErrorClassName } from '../../../components/helpers/ElementHelpers';
import { isNumeric, isObjectNull } from '../../../components/helpers/ObjectHelpers';

export const VariableCompensationCard = ({ pilotageCompensations, onCallback }) => {

    return (
        <>
            <VariableCompensationBoardingCard pilotageCompensations={pilotageCompensations} handleUpdate={handleVariableCompensationCorrection} />
            <Spacer height={30} />
            <VariableCompensationBridgeCard pilotageCompensations={pilotageCompensations} handleUpdate={handleVariableCompensationCorrection} />
        </>
    )

    function handleVariableCompensationCorrection() {
        onCallback(pilotageCompensations);
    }
}

export const VariableCompensationItem = ({ title, compensation, onEdit, onDelete, isEditable, decimals = 2, hasValidationError = false }) => {

    const dropDownButtonId = uuid();

    return (
        <FieldWrapper className="col">
            <Label hasValidationError={hasValidationError} >
                {title}:
            </Label>
            <div className="k-form-field-wrap">
                <div className="row align-items-center">
                    <div
                        onClick={onTextClick}
                        className={`col ${getValidationErrorClassName(hasValidationError)}`}>
                        {getNumber()}
                    </div>
                    <div className="col-auto">
                        {
                            isEditable &&
                                (!isObjectNull(compensation) && isNumeric(compensation.overriddenNumber)) ?
                                <DropDownButton
                                    id={dropDownButtonId}
                                    disabled={!isEditable}
                                    items={[
                                        {
                                            name: "Rediger",
                                            action: PageStates.Edit,
                                            source: compensation
                                        }
                                        ,
                                        {
                                            name: "Slett",
                                            action: PageStates.Delete,
                                            source: compensation
                                        }
                                    ]}
                                    icon="chevronDown"
                                    hasValidationError={hasValidationError}
                                    onCallback={onItemClick} />
                                :
                                <Button
                                    disabled={!isEditable}
                                    onClick={() => onEdit(compensation)}
                                    rounded="large"
                                    fillMode="flat"
                                    themeColor={hasValidationError ? "warning" : "base"}>
                                    <Icons iconName="edit" dimensions={24} />
                                </Button>
                        }

                    </div>
                </div>
            </div>
        </FieldWrapper>
    )

    function getNumber() {
        if (isObjectNull(compensation)) return "";
        if (isNumeric(compensation.overriddenNumber)) return compensation.overriddenNumber;
        return Number(compensation.calculatedNumber).toFixed(decimals);
    }

    function onItemClick(e) {
        switch (e.action) {
            case PageStates.Delete:
                onDelete(compensation);
                break;
            default:
                onEdit(compensation);
                break;
        }
    }

    function onTextClick() {
        if (!isEditable) return;

        if (!isObjectNull(compensation) && isNumeric(compensation.overriddenNumber)) {
            const button = document.getElementById(dropDownButtonId);
            if (isObjectNull(button)) return;
            button.click();
        } else {
            onEdit(compensation);
        }
    }
}