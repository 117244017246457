import './styles/pilot-rotation.css';

import React, { useState } from 'react';

import PilotRotationListItem from "./components/PilotRotationListItem"
import PilotRotationApiRepository from '../../repositories/api/PilotRotationApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import BottomMenu from './components/BottomMenu';
import { useEffect } from 'react';
import CurrentEditDialog from './components/CurrentEditDialog';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';

function Current() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [onDutyItems, setOnDutyItems] = useState([]);
    const [offDutyItems, setOffDutyItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    // Initialize function
    useEffect(() => {
        publishHeaderTopic("T&oslash;rn", "Vaktliste");
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <ContentGrid
                title="Vaktliste"
                titleIcon="bulletList"
                canPullToRefresh={true}
                onPullToRefresh={initializeAsync}
            >
                <div className="pilot-rotation-list">
                    {onDutyItems.map((item, index) => (
                        <PilotRotationListItem
                            key={index}
                            row={index + 1}
                            pilotRotationItem={item}
                            editClick={() => onEditClickAsync(item)} />
                    ))}
                    <div className="hr" style={{ gridRow: (onDutyItems.length + 1) }} />
                    {offDutyItems.map((item, index) => (
                        <PilotRotationListItem
                            key={index}
                            row={(onDutyItems.length + 2) + (index + 1)}
                            pilotRotationItem={item}
                            editClick={() => onEditClickAsync(item)} />
                    ))}
                </div>
            </ContentGrid>

            {
                showDialog &&
                <CurrentEditDialog
                    item={selectedItem}
                    onCancel={() => {
                        setShowDialog(false);
                        setSelectedItem(null);
                    }}
                    onSave={onSaveAsync}
                />
            }

            <BottomMenu />

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {

        const response = await PilotRotationApiRepository.getCurrentAsync();
        if (response.ok === true) {
            const data = await response.json();

            setOnDutyItems(data.filter(obj => obj.wasOnDutyOnRotationShift === true));
            setOffDutyItems(data.filter(obj => obj.wasOnDutyOnRotationShift === false));

            setIsBusy(false);
        } else {
            handleError(response.status, "Kunne ikke hente inn vaktliste");
        }
    }

    function onReloadClick() {
        setHasErrors(false);
        setIsBusy(true);
        initializeAsync();
    }

    async function onEditClickAsync(item) {

        setIsBusy(true);

        const response = await PilotRotationApiRepository.getCurrentByIdAsync(item.personID);
        if (response.ok) {
            const data = await response.json();
            setSelectedItem(data);
            setShowDialog(true);
            setIsBusy(false);
        } else {
            handleError(response.status,`Kunne ikke hente detaljer for ${item.personFullName}`);
        }
    }

    function handleError(response, warning = "") {
        publishWarningNotificationTopic(warning, response.status);
        setIsBusy(false);
        setHasErrors(true);
    }

    async function onSaveAsync(item) {

        setShowDialog(false);
        setIsBusy(true);
        
        const response = await PilotRotationApiRepository.setCurrentAsync(item);

        if (response.ok === true) {
            setSelectedItem(null);
            initializeAsync();
        } else {
            handleError(response);
        }
    }
}

export default Current;
