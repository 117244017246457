import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Spacer from '../../../../components/layout/Spacer';
import { VariableCompensationBoardingCard } from './VariableCompensationBoardingCard';
import { VariableCompensationBridgeCard } from './VariableCompensationBridgeCard';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { PageStates } from '../../../../services/SystemNames';
import { Label } from '../../../../components/layout/Label';
import { Button } from "@progress/kendo-react-buttons";
import Icons  from '../../../../components/layout/icons/Icons';
import DropDownButton from '../../../../components/layout/DropDownButton';
import uuid from 'react-uuid';
import Slide from '../../../../components/layout/Slide';
import PubSub from 'pubsub-js';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { isNumeric, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import { getElementById, getValidationErrorClassName } from '../../../../components/helpers/ElementHelpers';

export const VariableCompensationCard = forwardRef((
    {
        pilotAssignmentId,
        setIsBusy,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    const boardingRef = useRef();
    const bridgeRef = useRef();

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            boardingRef.current.onPilotageChanged();
            bridgeRef.current.onPilotageChanged();
        },
        onLocationChanged() {
            boardingRef.current.onLocationChanged();
            bridgeRef.current.onLocationChanged();
        },
        onExaminerOnlyChanged() {
            bridgeRef.current.onExaminerOnlyChanged();
        },
        onWastedTripChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            boardingRef.current.onValidationChanged(codeStrings);
            bridgeRef.current.onValidationChanged(codeStrings);
        }
    }));

    const [show, setShow] = useState(false);

    useEffect(() => {

        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, initializeAsync);

        initializeAsync();

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Slide show={show}>
            <VariableCompensationBoardingCard
                ref={boardingRef}
                onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                pilotAssignmentId={pilotAssignmentId} />
            <Spacer height={30} />
            <VariableCompensationBridgeCard
                ref={bridgeRef}
                onUpdatePilotAssignmentAsync={onUpdatePilotAssignmentAsync}
                pilotAssignmentId={pilotAssignmentId}
                setIsBusy={setIsBusy}
            />
            <Spacer height={30} />
        </Slide>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        setShow(!pilotAssignment.isWastedTrip);
    }
})

export const VariableCompensationItem = ({ title, compensation, onEdit, onDelete, isEditable, decimals = 2, hasValidationError = false }) => {
    
    const dropDownButtonId = uuid();
    return (
        <FieldWrapper className="col">
            <Label hasValidationError={hasValidationError} >
                {title}:
            </Label>
            <div className="k-form-field-wrap">
                <div className="row align-items-center">
                    <div
                        onClick={onTextClick}
                        className={`col ${getValidationErrorClassName(hasValidationError)}`}>
                        {getNumber()}
                    </div>
                    <div className="col-auto">
                        {
                            isEditable &&
                                (!isObjectNull(compensation) && isNumeric(compensation.overridenNumber)) ?
                                <DropDownButton
                                    id={dropDownButtonId}
                                    disabled={!isEditable}
                                    items={[
                                        {
                                            name: "Rediger",
                                            action: PageStates.Edit,
                                            source: compensation
                                        }
                                        ,
                                        {
                                            name: "Slett",
                                            action: PageStates.Delete,
                                            source: compensation
                                        }
                                    ]}
                                    icon="chevronDown"
                                    hasValidationError={hasValidationError}
                                    onCallback={onItemClick} />
                                :
                                <Button
                                    disabled={!isEditable}
                                    onClick={() => onEdit(compensation)}
                                    rounded="large"
                                    fillMode="flat"
                                    themeColor={hasValidationError ? "warning" : "base"}>
                                    <Icons iconName="edit" dimensions={24} />
                                </Button>
                        }

                    </div>
                </div>
            </div>
        </FieldWrapper>
    )

    function getNumber() {
        if (isObjectNull(compensation)) return "";
        if (isNumeric(compensation.overridenNumber)) return formatNumber(Number(compensation.overridenNumber));
        if (isNumeric(compensation.calculatedNumber)) return formatNumber(Number(compensation.calculatedNumber));
        return 0;
    }

    function formatNumber(number) {
        if (number === 0) return number;
        return number.toFixed(decimals);
    }

    function onItemClick(e) {
        switch (e.action) {
            case PageStates.Delete:
                onDelete(compensation);
                break;
            default:
                onEdit(compensation);
                break;
        }
    }

    function onTextClick() {
        if (!isEditable) return;

        if (!isObjectNull(compensation) && isNumeric(compensation.overridenNumber)) {
            const button = getElementById(dropDownButtonId);
            if (isObjectNull(button)) return;
            button.click();
        } else {
            onEdit(compensation);
        }
    }
}