
export const DialogProperties = {
    actions: null,
    mode: "default",
    title: "",
    text: "",
    placeholder: "S&oslash;...",
    itemTextField: "name",
    className: "",
    size: "",
    description: "",
    closeText: "Nei",
    clickText: "Ja",
    items: [],
    isBusy: false,
    isClickActive: true,
    hasSearched: false,
    requiresDescription: false,
    onClose: null,
    onSearch: null,
    onSelect: null,
    onClick: null,
    onChange: null,
    searchResultRender: null,
    showActionBar: true
}