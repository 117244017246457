import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import PcsApiRepository from '../../../../repositories/api/PcsApiRepository';
import { deepCopyObject, isArrayEmpty, isNumeric, isObjectNull } from '../../../helpers/ObjectHelpers';
import { colorAvailable, colorBorder, colorInactive, colorSelected, createPolygon, createPolyline, getBoundsRectangle, hideLoader, isMultiSelectActive, pcsFeatureType, showLoader, updateZoomByUrlParameter } from '../GoogleMapHelpers';

export const MapPilotCoastalSegmentGroup = forwardRef((
    {
        mapId,
        properties,
        config,
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onBoundsChanged() {
            onChange();
        },
        onZoomChanged() {
            onChange();
        },
        onDragEnd() {
            onChange();
        },
        onOkMultiSelect() {
            searchAsync();
        },
        onCancelMultiSelect() {
            cancelSearch();
        }
    }));

    const map = useMap();

    const componentRef = useRef({
        sourceSegmentIds: [],
        selectedSegmentIds: [],
        selectedCoordinates: [],
        segmentIds: [],
        hasInitialized: false,
        isBusy: false,
        selectedPolyline: null,
        selectedPolygon: null,
        dataClickListener: null,
        clickListener: null

    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        if (!map) return;

        if (properties.showPcs) {
            initializeAsync(map);
            loadSegmentsAsync(map);
            return;
        }

        function clearAllFeatures(map) {

            if (!localRef.hasInitialized) return;
            
            map.data.forEach(function (feature) {
                const featureType = feature.getProperty("featureType");
                if (featureType === pcsFeatureType) {
                    map.data.remove(feature);
                }
            });

            if (!isObjectNull(localRef.dataClickListener)) {
                window.google.maps.event.removeListener(localRef.dataClickListener);
            }
            if (!isObjectNull(localRef.clickListener)) {
                window.google.maps.event.removeListener(localRef.clickListener);
            }

            localRef.dataClickListener = null;
            localRef.clickListener = null;
            localRef.hasInitialized = false;
            localRef.sourceSegmentIds = [];
            localRef.selectedSegmentIds = [];
            localRef.segmentIds = [];
        }

        clearAllFeatures(map);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.showPcs]);

    function onChange() {
        if (!map) return;

        const pcsGroupId = getPcsGroupId();
        if (!isPcsActive(pcsGroupId, map)) return;

        initializeAsync(map);
        loadSegmentsAsync(map);
    }

    async function initializeAsync(map) {

        if (localRef.hasInitialized) return;

        const pcsGroupId = getPcsGroupId();

        map.data.setStyle(function (feature) {
            const groupId = feature.getProperty('segmentGroupId');
            const isSelected = feature.getProperty('isSelected');
            let color = groupId === 0 ? colorAvailable : colorInactive;
            let currentColorBorder = colorBorder;

            if (isSelected === false && map.getZoom() < config.PilotCoastalSegmentZoomTreshold) {
                color = "transparent";
                currentColorBorder = "transparent";
            }
            if (isSelected) {
                color = colorSelected;
            }

            return {
                fillColor: color,
                strokeWeight: 1,
                strokeColor: currentColorBorder
            };
        });

        localRef.dataClickListener = map.data.addListener("click", async (event) => {

            if (localRef.isBusy) return;

            if (isMultiSelectActive(mapId)) {
                onMultiClick(event.latLng);
            } else {
                onFeatureSingleClick(event, pcsGroupId);
            }
        });

        localRef.clickListener = map.addListener("click", (event) => {
            if (localRef.isBusy || !isMultiSelectActive(mapId)) return;
            onMultiClick(event.latLng);
        });

        if (pcsGroupId > 0) {
            const response = await PcsApiRepository.getGroupAsync(pcsGroupId);
            if (response.ok) {
                const data = await response.json();

                localRef.sourceSegmentIds = deepCopyObject(data.coastalSegmentIds);
                localRef.selectedSegmentIds = deepCopyObject(data.coastalSegmentIds);

                addFeatures(data.geoJson.features);

                const bounds = new window.google.maps.LatLngBounds();
                for (const feature of data.geoJson.features) {
                    for (const coordinates of feature.geometry.coordinates) {
                        for (const coordinate of coordinates) {
                            const position = new window.google.maps.LatLng(coordinate[1], coordinate[0]);
                            bounds.extend(position);
                        }
                    }
                }
                map.fitBounds(bounds);

                localRef.hasInitialized = true;

                loadSegmentsAsync(map);
            }
        } else {
            updateZoomByUrlParameter(map);

            localRef.hasInitialized = true;
            loadSegmentsAsync(map);
        }
    }

    async function loadSegmentsAsync(map) {
        if (!localRef.hasInitialized || localRef.isBusy) return;

        if (map.getZoom() < config.PilotCoastalSegmentZoomTreshold) return;

        isBusy(true);

        const searchParameter = createSearchParameter(getPcsGroupId());
        const response = await PcsApiRepository.searchAsync(searchParameter);
        if (response.ok) {
            const data = await response.json();
            addFeatures(data.features);
        }

        isBusy(false);
    }

    async function searchAsync() {
        if (!map) return;

        const pcsGroupId = getPcsGroupId();
        if (!isPcsActive(pcsGroupId, map)) return;

        isBusy(true);

        if (localRef.selectedCoordinates.length > 2) {

            const response = await PcsApiRepository.searchByAreaAsync(localRef.selectedCoordinates);
            if (response.ok) {

                const data = await response.json();
                const searchedSegmentIds = [];

                for (const feature of data.features) {
                    searchedSegmentIds.push(feature.properties.segmentId);
                }

                if (!isArrayEmpty(searchedSegmentIds)) {
                    map.data.forEach(function (feature) {
                        const featureType = feature.getProperty("featureType");

                        if (featureType !== pcsFeatureType) return;

                        const segmentId = feature.getProperty("segmentId");
                        if (!searchedSegmentIds.includes(segmentId)) return;

                        toggleSelectFeature(true, segmentId, pcsGroupId);

                        if (!isInSelectedSegmentIds(segmentId)) {
                            localRef.selectedSegmentIds.push(segmentId);
                        }
                        if (!isInSourceSegmentIds(segmentId)) {
                            feature.setProperty("isNew", true);
                        }
                    });
                }
            }
        }

        resetSelectedPolyline();
        resetSelectedPolygon();
        localRef.selectedCoordinates = [];

        validateSelectedSegmentsAsync(pcsGroupId);
    }

    function cancelSearch() {
        if (!map) return;

        const pcsGroupId = getPcsGroupId();

        if (!isPcsActive(pcsGroupId, map)) return;

        resetSelectedPolyline();
        resetSelectedPolygon();
        localRef.selectedCoordinates = [];
    }

    function getPcsGroupId() {
        if (!isNumeric(properties.pcsGroupId)) return 0;
        return properties.pcsGroupId;
    }

    function isPcsActive(pcsGroupId) {
        if (pcsGroupId === 0 && !properties.showPcs) {
            return false;
        }

        return true;
    }

    function createSearchParameter(pcsGroupId) {
        const rectangle = getBoundsRectangle(map);

        return {
            Rectangle: rectangle,
            SegmentIds: localRef.segmentIds,
            GroupId: pcsGroupId
        }
    }

    function hasSegmentId(feature) {
        return localRef.segmentIds.includes(feature.properties.segmentId);
    }

    function addFeatures(features) {
        for (let feature of features) {
            if (hasSegmentId(feature)) continue;

            feature.properties.isVisible = true;
            feature.properties.featureType = pcsFeatureType;

            map.data.addGeoJson(feature);
            addSegmentId(feature);
        }
    }

    function addSegmentId(feature) {
        localRef.segmentIds.push(feature.properties.segmentId);
    }

    function isBusy(busy) {
        localRef.isBusy = busy;
        if (busy) {
            showLoader();
        } else {
            hideLoader();
        }
    }

    function onFeatureSingleClick(event, pcsGroupId) {
        const feature = event.feature;
        const featureType = feature.getProperty("featureType");

        if (featureType !== pcsFeatureType) return;

        isBusy(true);

        const segmentId = event.feature.getProperty("segmentId");
        const isSelected = !feature.getProperty("isSelected");

        toggleSelectFeature(isSelected, segmentId, pcsGroupId);

        if (isSelected) {
            if (!isInSelectedSegmentIds(segmentId)) {
                localRef.selectedSegmentIds.push(segmentId);
            }
            if (!isInSourceSegmentIds(segmentId)) {
                feature.setProperty("isNew", true);
            }
        } else {
            localRef.selectedSegmentIds = localRef.selectedSegmentIds.filter(i => i !== segmentId);
        }

        validateSelectedSegmentsAsync(pcsGroupId);
    }

    function onMultiClick(latLng) {

        localRef.selectedCoordinates.push({ lat: latLng.lat(), lng: latLng.lng() });

        let copyCoordinates = []
        let lineWeight = 2;

        for (let i = 0; i < localRef.selectedCoordinates.length; i++) {
            copyCoordinates.push(localRef.selectedCoordinates[i]);
        }
        if (localRef.selectedCoordinates.length === 0) return;

        if (localRef.selectedCoordinates.length <= 2) {
            if (localRef.selectedCoordinates.length === 1) {
                copyCoordinates.push(localRef.selectedCoordinates[0]);
                lineWeight = 10;
            }
            resetSelectedPolyline();

            localRef.selectedPolyline = createPolyline(copyCoordinates, lineWeight);
            localRef.selectedPolyline.setMap(map);
        } else {

            resetSelectedPolyline();

            copyCoordinates.push(localRef.selectedCoordinates[0]);

            resetSelectedPolygon()

            localRef.selectedPolygon = createPolygon(copyCoordinates);
            localRef.selectedPolygon.setMap(map);
        }
    }

    function resetSelectedPolyline() {
        resetMapObj(localRef.selectedPolyline);
        localRef.selectedPolyline = null;
    }

    function resetSelectedPolygon() {
        resetMapObj(localRef.selectedPolygon);
        localRef.selectedPolygon = null;
    }

    function resetMapObj(obj) {
        if (isObjectNull(obj)) return;
        obj.setMap(null);
    }

    async function validateSelectedSegmentsAsync(pcsGroupId) {

        const segmentIds = deepCopyObject(localRef.selectedSegmentIds);
        const validate = {
            groupId: pcsGroupId,
            segmentIds: segmentIds
        };

        let isSegmentsValid = false;
        const response = await PcsApiRepository.validateAsync(validate);
        if (response.ok === true) {
            const data = await response.json();
            isSegmentsValid = data;
        } else {
            isSegmentsValid = false;
        }

        const result = {
            segmentIds: segmentIds,
            isSegmentsValid: isSegmentsValid
        };

        properties.handleOnFeatureClick(result);

        isBusy(false);
    }

    function isInSourceSegmentIds(segmentId) {
        return !isObjectNull(localRef.sourceSegmentIds.find(i => i === segmentId));
    }

    function isInSelectedSegmentIds(segmentId) {
        return !isObjectNull(localRef.selectedSegmentIds.find(i => i === segmentId));
    }

    function toggleSelectFeature(isSelected, segmentId, pcsGroupId) {
        map.data.forEach(function (feature) {

            let featureSegmentId = feature.getProperty("segmentId");
            if (featureSegmentId !== segmentId) return;

            const color = isSelected ? colorSelected : colorAvailable;

            feature.setProperty("isSelected", isSelected);
            feature.setProperty("segmentGroupId", pcsGroupId);

            map.data.overrideStyle(feature, { fillColor: color, strokeColor: colorBorder });
        });
    }
})
