import { useEffect } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import EvaluationApiRepository from '../../../../repositories/api/EvaluationApiRepository';
import { isNullOrEmpty } from '../../../helpers/ObjectHelpers';
import { processPoints } from '../../../helpers/GeometryHelpers';
import { colorFairwayArea, colorFairwayAreaApplied, colorFairwayAreaStroke } from '../GoogleMapHelpers';

export const MapEvaluation = ({ properties } ) => {

    const evaluationFeatureType = "evaluation";
    const map = useMap();

    useEffect(() => {

        if (!map) return;

        if (isNullOrEmpty(properties.evaluation)) return;

        async function initializeAsync() {

            const response = await EvaluationApiRepository.getFairwayAreasAsync(properties.evaluation);
            if (response.ok) {
                const data = await response.json();
                const bounds = new window.google.maps.LatLngBounds();

                for (let feature of data.fairwayAreaGeoJson.features) {
                    feature.properties.featureType = evaluationFeatureType;
                }

                map.data.forEach(function (feature) {
                    const featureType = feature.getProperty("featureType");
                    if (featureType === evaluationFeatureType) {
                        map.data.remove(feature);
                    }
                });

                map.data.addListener('addfeature', function (e) {
                    processPoints(e.feature.getGeometry(), bounds.extend, bounds);
                    map.fitBounds(bounds);
                });

                map.data.addGeoJson(data.fairwayAreaGeoJson);
                map.data.setStyle(function (feature) {
                    var ascii = feature.getProperty('ascii');
                    var color = ascii === 1 ? colorFairwayAreaApplied : colorFairwayArea;
                    return {
                        fillColor: color,
                        strokeWeight: 1,
                        strokeColor: colorFairwayAreaStroke
                    };
                });
            }
        }

        initializeAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);
}
