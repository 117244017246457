import "./styles/evaluation.css"

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form1Summary from './components/forms/form1/Summary';
import EvaluationService from './services/EvaluationService';
import PDFViewer from '../../components/layout/PDFViewer';
import EvaluationApiRepository from '../../repositories/api/EvaluationApiRepository';
import { PageStates } from '../../services/SystemNames';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import { Card } from '../../components/layout/card/Card';
import { CardProperties } from '../../components/layout/card/components/CardProperties';
import { ButtonsGrid } from '../../components/layout/ButtonsGrid';
import { Button } from "@progress/kendo-react-buttons";
import { CloseIcon, OkIcon } from '../../components/layout/icons/Icons';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import { getNumericUrlParam } from "../../components/helpers/UrlHelpers";

function PecExamEvaluationSummary() {

    const navigate = useNavigate();

    const [pageState, setPageState] = useState(PageStates.Default);
    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [canComplete, setCanComplete] = useState(false);
    const [evaluation, setEvaluation] = useState(null);
    const [summary, setSummary] = useState(null);
    const [pdfDocument, setPdfDocument] = useState(null);

    // Initialize function
    useEffect(() => {

        publishHeaderTopic("Oppsummering");

        if (!navigator.onLine) {
            navigate.push("/evaluation");
        } else {
            initializeAsync();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Functions for rendering page
    const renderEvaluationFormSummary = () => {
        if (evaluation.evaluationDocument === undefined) {
            return <div>Laster inn...</div>
        }

        if (evaluation.evaluationDocument === null) {
            console.log("EvaluationDocument is null:" + evaluation.evaluationDocument);
            return (<h2>Virker slett ikke.</h2>);
        }
        switch (evaluation.evaluationDocument.version) {
            case 1:
                return (<Form1Summary summary={summary} />);
            case 2:
                return (<div>Skjema Oppsummering v2 er ikke implementert.</div>);
            default:
                return (<div>Virker ikke</div>);
        }
    }

    return (
        <>
            <ContentGrid title="Oppsummering" titleIcon="evaluation">
                {
                    !isBusy &&
                    (() => {
                        switch (pageState) {
                            case PageStates.Default:
                                return (
                                    <>
                                        <Card properties={{
                                            ...CardProperties,
                                            title: evaluation.shipName,
                                            actions: [{
                                                icon: "pdf",
                                                onClick: onShowPdfClickAsync
                                            }]
                                        }}>
                                            {renderEvaluationFormSummary()}
                                        </Card>

                                        <ButtonsGrid>
                                            <Button
                                                onClick={() => navigate('/evaluation')}
                                                svgIcon={CloseIcon}>
                                                Avbryt
                                            </Button>
                                            <Button
                                                themeColor="primary"
                                                svgIcon={OkIcon}
                                                onClick={onCompleteClickAsync}
                                                disabled={!canComplete}>
                                                Fullf&oslash;r
                                            </Button>
                                        </ButtonsGrid>
                                    </>
                                );
                            case PageStates.Pdf:
                                return (
                                    <PDFViewer
                                        data={pdfDocument}
                                        className="pdf-viewer"
                                        onCloseClick={onClosePdfClick} />
                                );

                            default:
                                return null;
                        }
                    })()
                }
                
            </ContentGrid>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {

        const pilotagePilotId = getNumericUrlParam("pilotage-pilot-id");
        const pilotagePecExamId = getNumericUrlParam("pilotage-pec-exam-id");
        const identifier = EvaluationService.getEvaluationIdentifierById(pilotagePilotId, pilotagePecExamId);

        const responseEvaluation = await EvaluationApiRepository.getAsync(identifier);
        const responseSummary = await EvaluationApiRepository.getSummaryAsync(identifier);

        if (responseEvaluation.ok === true && responseSummary.ok === true) {
            const dataEvaluation = await responseEvaluation.json();
            const dataSummary = await responseSummary.json();

            if (dataEvaluation.isExamFinalized || dataEvaluation.isExamBeingProcessedByCaseworker || dataEvaluation.isCompleted) {
                navigate('/evaluation');
            }

            setCanComplete(
                dataSummary.isExamBeingProcessedByCaseworker === false &&
                dataSummary.isExamFinalized === false &&
                dataSummary.isCompleted === false &&
                (dataSummary.isDocumentValid || dataSummary.isRenounced)
            );
            setEvaluation(dataEvaluation);
            setSummary(dataSummary);

        } else {
            let response = null;
            if (responseEvaluation.ok === false) {
                response = responseEvaluation;
            }
            else if (responseSummary.ok === false) {
                response = responseSummary;
            }
            handleError(response, "En feil oppstod ved henting av evalueringen.");
        }

        setIsBusy(false);
    }

    async function completeAsync() {
        setIsBusy(true);

        const response = await EvaluationApiRepository.completeAsync(summary);

        if (response.ok === true) {
            const data = await response.json();
            publishSuccessNotificationTopic("Evalueringen ble fullf&oslash;rt");
            await EvaluationService.updateEvaluationAsync(data);

            navigate('/evaluation');

        } else {
            publishWarningNotificationTopic("En feil oppstod ved henting av evalueringen.");
            handleError(response);
        }
    }

    async function onCompleteClickAsync() {
        await completeAsync();
    }

    async function onShowPdfClickAsync() {

        setIsBusy(true);

        const response = await EvaluationApiRepository.getPdfDocumentAsync(evaluation);

        if (response.ok === true) {
            const data = await response.json();

            setPdfDocument(data);
            setPageState(PageStates.Pdf);

        } else {
            handleError(response, "En feil oppstod i henting av evalueringens pdf dokument.");
        }

        setIsBusy(false);
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);

        initializeAsync();
    }

    function onClosePdfClick() {
        setPageState(PageStates.Default);
    }

    function handleError(response, warning) {
        publishWarningNotificationTopic(warning, response.status);
        setHasErrors(true);
    }

}

export default PecExamEvaluationSummary;