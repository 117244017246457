import "../styles/evaluation.css"
import React, { useState } from 'react';
import EvaluationService from '../services/EvaluationService';
import { IconColors } from '../../../services/SystemNames';
import Icons from '../../../components/layout/icons/Icons';
import { GoogleMapCard } from '../../../components/layout/map/GoogleMapCard';
import { GoogleMapProperties } from '../../../components/layout/map/GoogleMapProperties';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import Spacer from '../../../components/layout/Spacer';
import Slide from '../../../components/layout/Slide';
import { EvaluationListItemStates, EvaluationResponsibilityReasonEnum } from "../services/EvaluationHelpers";


export default function EvaluationDetails(
    {
        evaluation,
        itemState = EvaluationListItemStates.Archived,
        showRenounceType = true,
        isArchive = false,
        onClick,
        onShowDocumentClick
    }
) {

    const [isMapRevealed, setIsMapRevealed] = useState(false);
    const [isOverMap, setIsOverMap] = useState(false);
    
    return (
        <Card properties={{
            ...CardProperties,
            title: evaluation.shipName,
            subTitle: getSubTitle(),
            subTitleClassName: getSubTitleClassName(),
            onClick: (isArchive || itemState === EvaluationListItemStates.Editing) ? null : handleOnClick
        } }>
            <div className="container">
                <div className="row align-items-center g-2">
                    <div className="col-6 col-md-2">
                        <ListItemIconText icon="calendarWarning" text={evaluation.formattedExamTime} />
                    </div>
                    <div className="col-6 col-md-2">
                        <ListItemIconText icon="number" text={evaluation.pilotageNo} />
                    </div>
                    <div className="col-12 col-md-8">
                        <ListItemIconText icon="mypage" text={evaluation.candidateName} />
                    </div>
                </div>

                <Spacer height={10} />

                <div className="row align-items-center g-2">
                    <div className="col-12 col-sm-6">
                        <ListItemIconText icon="pin" text={evaluation.examName} />
                    </div>
                    <div className="col-12 col-sm-6 text-end">
                        <ListItemPdf evaluation={evaluation} itemState={itemState} onClick={onShowDocumentClick} />
                        <ListItemMap evaluation={evaluation} itemState={itemState} isMapRevealed={isMapRevealed} setIsMapRevealed={setIsMapRevealed} />
                    </div>
                </div>

                <Spacer height={10} />

                <div className="row">
                    <div className="col">
                        <Slide show={isMapRevealed}>
                            <div
                                style={{ height: 400 }}
                                onMouseOver={() => setIsOverMap(true)}
                                onMouseOut={() => setIsOverMap(false)}                            >
                                <GoogleMapCard
                                    properties={{
                                        ...GoogleMapProperties,
                                        evaluation: EvaluationService.getEvaluationIdentifierByObj(evaluation)
                                    }}
                                />
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
        </Card>
    );

    function getSubTitle() {
        if (evaluation.isCompleted && !evaluation.isRenounced) {
            return evaluation.pilotagePilotPecExamEvaluationStatus;
        }
        else if (evaluation.isRenounced && showRenounceType) {
            return evaluation.renounceType === EvaluationResponsibilityReasonEnum.NO_RESPONSIBILITY ?
                "Ikke evalueringssansvar" : "Ikke deltakende";
        } 
    }

    function getSubTitleClassName() {
        if (evaluation.isCompleted && !evaluation.isRenounced) {
            return `font-${(evaluation.pilotagePilotPecExamEvaluationStatusSystemName) === 'RECOMMENDED' ? 'active' : 'error'}`;
        }
        else if (evaluation.isRenounced && showRenounceType) {
            return "font-warning";
        }
    }

    function handleOnClick() {
        if (isOverMap) return;

        switch (itemState) {
            case EvaluationListItemStates.Archived:
                onShowDocumentClick(evaluation);
                break;
            default:
                onClick(evaluation, itemState);
                break;
        }
    }
}

const ListItemIconText = ({ icon, text }) => {
    return (
        <div className="evaluation-listitem">
            <div><Icons color={IconColors.Primary} iconName={icon} dimensions={24} /></div>
            <div>{text}</div>
        </div>
    )
}

const ListItemPdf = ({ evaluation, itemState, onClick }) => {

    if (!navigator.onLine) return;

    if (evaluation.evaluationFormBinaryContentId === 0) return;

    return (
        (itemState === EvaluationListItemStates.Archived || evaluation.isCompleted) &&
        <div className="evaluation-listitem-toggle-area" onClick={handleOnClick}>
                <div>VIS PDF</div>
                <div><Icons color={IconColors.Active} iconName="pdf" /></div>
            </div>
    )

    function handleOnClick(e) {
        e.stopPropagation();
        onClick();
    }
}

const ListItemMap = ({ evaluation, itemState, isMapRevealed, setIsMapRevealed }) => {

    if (!navigator.onLine) return;

    if (itemState === EvaluationListItemStates.Archived || evaluation.isCompleted) return;

    return (
        <div className="evaluation-listitem-toggle-area">
            <div className="pointer card-action" onClick={onClick}>
                {`${isMapRevealed ? "SKJUL" : "VIS"} OMRÅDE`}    
            </div>
            <div className="pointer card-action" onClick={onClick}>
                <Icons color={IconColors.Active} iconName={isMapRevealed ? "chevronUp" : "chevronDown"} dimensions={24} />
            </div>
        </div>
    )

    function onClick(e) {
        e.stopPropagation();
        setIsMapRevealed(!isMapRevealed);
    }
}
