import React from 'react';
import {
    Dialog as KendoDialog,
    DialogActionsBar as KendoDialogActionsBar
} from "@progress/kendo-react-dialogs";
import parse from 'html-react-parser';
import Spacer from '../../Spacer';
import { xIcon } from "@progress/kendo-svg-icons";
import {
    ListView,
} from "@progress/kendo-react-listview";
import {
    Button
} from "@progress/kendo-react-buttons";
import { isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { getDialogSizeClassName } from '../DialogHelpers';
import { DialogForm } from '../../DialogForm';

export const DialogSelectItem = ({ properties = {}}) => {

    const ItemRender = (props) => {
        let item = props.dataItem;

        return (

            <div className={`k-listview-item row dialog-row align-items-center pointer ${item.selected ? "dialog-row-selected" : ""}`}
                onClick={() => handleSelectItem(item)}>
                <div className="col text-truncate" >
                    {item[properties.itemTextField]}
                </div>
            </div>
        )
    };

    return (
        <KendoDialog
            title={parse(properties.title)}
            className={`dialog-list ${getDialogSizeClassName(properties)}`}
            onClose={handleOnClose}>
            <DialogForm>
                {
                    !isNullOrEmpty(properties.text) &&
                    <>
                        <div>{parse(properties.text)}</div>
                        <Spacer height={20}/>
                    </>

                }
                <div>
                    <ListView
                        data={properties.items}
                        item={ItemRender}
                    />
                </div>
            </DialogForm>
            <KendoDialogActionsBar>
                <Button
                    onClick={handleOnClose}
                    svgIcon={xIcon}>Avbryt</Button>
            </KendoDialogActionsBar>
        </KendoDialog>
    );

    function handleOnClose() {
        if (isObjectNull(properties.onClose)) {
            alert("Mangler aksjon for � lukke dialog.");
        } else {
            properties.onClose();
        }
    }

    function handleSelectItem(item) {
        if (item.selected) return;

        try {
            properties.onSelect(item);
            properties.onClose();
        } catch (e) {
            console.warn(e);
        }
    }
}
