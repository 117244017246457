import './datetimepicker.css'

import React, { useState, useRef } from 'react';
import {
    DateTimePicker as KendoDateTimePicker,
    DatePicker as KendoDatePicker,
    TimePicker as KendoTimePicker,
    DateInput as KendoDateInput
} from '@progress/kendo-react-dateinputs';
import Moment from 'moment';
import parse from 'html-react-parser';
import Icons from './icons/Icons';
import { DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownButtonActionRender } from './render/DropDownButtonActionRender';
import { useEffect } from 'react';
import { isComponentAdaptive, onToggleComponent } from '../helpers/ComponentHelpers';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../helpers/ObjectHelpers';
import { getElementsByClassName, getValidationErrorClassName } from '../helpers/ElementHelpers';

export const DateTimePickerModes = {
    Time: "TIME",
    Date: "DATE",
    Year: "YEAR",
    DateTime: "DATETIME",
    Full: "FULL"
}

export default function DateTimePicker ({
    title = "",
    value = null,
    onChange,
    disabled = false,
    hasValidationError = false,
    canDelete = true,
    mode = DateTimePickerModes.Time
})
{
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [defaultValue, setDefaultValue] = useState(Moment().toDate());
    const [renderPicker, setRenderPicker] = useState(false);
    const [showPicker, setShowPicker] = useState(false); 

    useEffect(() => {

        function setElementPattern() {
            const elems = getElementsByClassName("date-time-picker");
            
            if (isArrayEmpty(elems)) return;

            for (const elem of elems) {
                const inputs = elem.querySelectorAll('.k-input-inner');
                for (const input of inputs) {
                    input.pattern = "[0-9]*";
                }
            }
        }

        setElementPattern();
        
    }, []);

    useEffect(() => {
        onToggleComponent(isPickerOpen);

        if (isPickerOpen) {
            setRenderPicker(true);
            setTimeout(() => {
                setShowPicker(true);
            }, 125);
        } else {
            setShowPicker(false);
            setTimeout(() => {
                setRenderPicker(false);
            }, 500);
        }

    }, [isPickerOpen]);

    return (
        (() => {
            switch (mode)
            {
                case DateTimePickerModes.Time:
                    return <TimePickerText
                        value={value}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        hasValidationError={hasValidationError}
                        handlePickerChange={handlePickerChange}
                        getItems={getItems}
                        renderPicker={renderPicker}
                        showPicker={showPicker}
                        onItemClick={onItemClick}
                        handleClose={handleClose}
                    />;
                case DateTimePickerModes.DateTime:
                case DateTimePickerModes.Full:
                    return <DateTimePickerText
                        value={value}
                        defaultValue={defaultValue}
                        mode={mode}
                        disabled={disabled}
                        title={title}
                        hasValidationError={hasValidationError}
                        handlePickerChange={handlePickerChange}
                        getItems={getItems}
                        renderPicker={renderPicker}
                        showPicker={showPicker}
                        onItemClick={onItemClick}
                        handleClose={handleClose}
                    />;
                default:
                    return <DatePickerText
                        value={value}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        title={title}
                        hasValidationError={hasValidationError}
                        mode={mode}
                        handlePickerChange={handlePickerChange}
                        getItems={getItems}
                        renderPicker={renderPicker}
                        showPicker={showPicker}
                        onItemClick={onItemClick}
                        handleClose={handleClose}
                    />;
            }
        }) ()
    )

    function onItemClick(e) {
        switch (e.item.action) {
            case "delete":
                onTrashClick();
                break;
            case "now":
                setNow();
                break;
            default:
                onEditClick();
                break;
        }
    }

    function onEditClick() {
        if (disabled || isPickerOpen) return;

        const date = Moment(value);
        if (date.isValid()) {
            setDefaultValue(date.toDate());
        } else {
            setDefaultValue(Moment().toDate());
        }

        setIsPickerOpen(true)

        setTimeout(() => {
            const elements = getElementsByClassName("k-animation-container");
            if (isArrayEmpty(elements)) return;

            const element = elements[0];

            if (isComponentAdaptive()) {
                if (mode !== DateTimePickerModes.DateTime && mode !== DateTimePickerModes.Full) return;
                if (!isNullOrEmpty(value)) return;

                const footer = element.querySelector('.k-actionsheet-footer');
                if (isObjectNull(footer)) return;

                const buttons = footer.querySelectorAll('.k-button');
                if (isArrayEmpty(buttons)) return;

                const selectButton = buttons[1];
                selectButton.setAttribute("aria-disabled", true);

                const calendarElements = getElementsByClassName("k-calendar-td");
                for (const calendarElement of calendarElements) {
                    calendarElement.addEventListener('click', () => {
                        toggleSelectButton(selectButton);
                    });
                }

                const header = element.querySelector('.k-calendar-header');
                if (isObjectNull(header)) return;

                const todayButton = header.querySelector('.k-calendar-nav-today');
                if (isObjectNull(todayButton)) return;

                todayButton.addEventListener('click', () => {
                    toggleSelectButton(selectButton);
                });

            } else {
                const top = parseFloat(element.style.top);
                if (window.innerHeight - top > element.offsetHeight) return;

                element.classList.add(getContainerClassName());
            }
        }, 50);
    }

    function toggleSelectButton(button) {
        if (!button.classList.contains("k-disabled")) return;
        button.setAttribute("aria-disabled", false);
        button.classList.remove("k-disabled");
    }

    function getContainerClassName() {
        switch (mode) {
            case DateTimePickerModes.Date:
                return "date-picker-container"
            case DateTimePickerModes.Time:
                return "time-picker-container"
            default:
                return "datetime-picker-container";
        }
    }

    function onTrashClick() {
        if (value === null) {
            setNow();
        } else {
            resetTime();
        }
    }

    function resetTime() {
        onChange({ value: null });
    }

    function setNow() {
        const date = Moment().toDate();

        date.setSeconds(0);
        date.setMilliseconds(0);

        if (mode === DateTimePickerModes.Date) {
            date.setMinutes(0);
            date.setHours(0);
        }

        handleOnChange(date);
    }

    function handlePickerChange(e) {
        let newDate = Moment(e.value).toDate();

        newDate.setSeconds(0);
        newDate.setMilliseconds(0);

        if (mode === DateTimePickerModes.Date || mode === DateTimePickerModes.Year) {
            newDate.setMinutes(0);
            newDate.setHours(0);
        }
        handleOnChange(newDate);
    }

    function getItems() {
        const result = [
            {
                name: parse("N&aring;"),
                action: "now"
            },
            {
                name: "Rediger",
                action: "edit"
            }
        ];

        if (!isNullOrEmpty(value) && canDelete) {
            result.push({
                name: "Slett",
                action: "delete"
            })
        }

        return result;
    }

    function handleOnChange(date) {
        if (isObjectNull(onChange)) return;

        onChange({ value: Moment(date).format("yyyy-MM-DDTHH:mm:ss") });
        
    }

    function handleClose() {

        setIsPickerOpen(false);

        setTimeout(() => {
            const elements = getElementsByClassName("k-animation-container");
            if (isArrayEmpty(elements)) return;
            const element = elements[0];

            element.classList.remove(getContainerClassName());
        }, 500);
    }
}

const DatePickerText = ({
    value,
    defaultValue,
    disabled,
    title,
    hasValidationError,
    mode,
    handlePickerChange,
    getItems,
    renderPicker,
    showPicker,
    onItemClick,
    handleClose
}) => {

    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        const date = Moment(value);
        if (date.isValid()) {
            setLocalValue(date.toDate());
        } else {
            setLocalValue(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={`date-time-picker date-time-picker-text ${getValidationErrorClassName(hasValidationError)}`}>
            <div className="date-time-picker-input">
                <div>
                {
                    renderPicker &&
                    <KendoDatePicker
                        format={getFormat()}
                        placeholder="Ikke satt"
                        defaultValue={defaultValue}
                        adaptive={true}
                        show={showPicker}
                        adaptiveTitle={parse(title)}
                        onChange={onChange}
                        onClose={handleClose}
                        disabled={disabled}
                    />
                    }
                </div>
                <div className="date-time-picker-components">
                    <div>
                        <KendoDateInput
                            format={getFormat()}
                            placeholder="Ikke satt"
                            disabled={disabled}
                            onChange={onChange}
                            value={localValue}
                        />
                    </div>
                    <div>
                        <DropDownButton
                            className="date-time-picker-button"
                            items={getItems()}
                            disabled={disabled}
                            itemRender={DropDownButtonActionRender}
                            onItemClick={onItemClick}
                            text={<Icons iconName="calendar" dimensions={24} />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

    function getFormat() {
        if (mode === DateTimePickerModes.Year) return "dd.MM.yyyy";
        return "dd.MM";
    }

    function onChange(e) {
        handlePickerChange(e);
    }
}

const DateTimePickerText = ({
    value,
    defaultValue,
    mode,
    disabled,
    title,
    hasValidationError,
    handlePickerChange,
    getItems,
    renderPicker,
    showPicker,
    onItemClick,
    handleClose
}) => {
    
    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        const date = Moment(value);
        if (date.isValid()) {
            setLocalValue(date.toDate());
        } else {
            setLocalValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={`date-time-picker date-time-picker-text ${getValidationErrorClassName(hasValidationError)}`}>
            <div className="date-time-picker-input">
                <div>
                    {
                        renderPicker &&
                        <KendoDateTimePicker
                            defaultValue={defaultValue}
                            format={getFormat()}
                            placeholder="Ikke satt"
                            show={showPicker}
                            adaptive={true}
                            adaptiveTitle={parse(title)}
                            onChange={onChange}
                            onClose={handleClose}
                            disabled={disabled}
                        />
                        }
                </div>
                <div className="date-time-picker-components">
                    <div>
                        <KendoDateInput
                            format={getFormat()}
                            formatPlaceholder={{ year: '�r', month: 'mnd', day: 'dag', hour: 'time', minute: 'min' }}
                            placeholder="Ikke satt"
                            disabled={disabled}
                            onChange={onChange}
                            value={localValue}
                        />
                    </div>
                    <div>
                        <DropDownButton                            
                            className="date-time-picker-button"
                            items={getItems()}
                            disabled={disabled}
                            itemRender={DropDownButtonActionRender}
                            onItemClick={onItemClick}
                            text={<Icons iconName="calendar" dimensions={24} />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

    function getFormat() {
        if (mode === DateTimePickerModes.DateTime) return "dd.MM HH:mm";
        return "dd.MM.yyyy HH:mm";
    }

    function onChange(e) {
        handlePickerChange(e);
    }
}

const TimePickerText = ({
    value,
    defaultValue,
    disabled,
    hasValidationError,
    handlePickerChange,
    getItems,
    renderPicker,
    showPicker,
    onItemClick,
    handleClose
}) => {

    const [localValue, setLocalValue] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const date = Moment(value);
        if (date.isValid()) {
            setLocalValue(date.toDate());
        } else {
            setLocalValue(null);
        }
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={`date-time-picker date-time-picker-text ${getValidationErrorClassName(hasValidationError)}`}>
            <div className="date-time-picker-input">
                <div>
                    {
                        renderPicker &&
                        <KendoTimePicker
                            ref={ref}
                            show={showPicker}
                            format={'HH:mm'}
                            placeholder="Ikke satt"
                            adaptiveTitle={"Velg tidspunkt"}
                            adaptive={true}
                            defaultValue={defaultValue}
                            onChange={handlePickerChangeOverride}
                            onClose={handleClose}
                            disabled={disabled}
                        />
                    }
                </div>
                <div className="date-time-picker-components">
                    <div>
                        <KendoDateInput
                            format={'HH:mm'}
                            formatPlaceholder={{ hour: 'time', minute: 'min' }}
                            placeholder="Ikke satt"
                            disabled={disabled}
                            onChange={onChange}
                            value={localValue}
                            onFocus={() => console.log("on foccus")}
                        />
                    </div>
                    <div>
                        <DropDownButton
                            className="date-time-picker-button"
                            items={getItems()}
                            disabled={disabled}
                            itemRender={DropDownButtonActionRender}
                            onItemClick={onItemClick}
                            text={<Icons iconName="clock" dimensions={24} />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

    function onChange(e) {
        handlePickerChange(e);
    }

    function handlePickerChangeOverride(e) {
        let newDate = new Date();
        const refValue = ref.current.state.value;
        const candidate = ref.current.state.candidate;

        if (!refValue && !candidate) {
            newDate = Moment(e.value).toDate();
        } else if (!candidate) {
            newDate = new Date(e.value);
        }
        else {
            newDate = new Date(candidate);
        }

        newDate.setSeconds(0);

        handlePickerChange({ value: newDate })
    }
}
