import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import Switch from '../switch';

function SwitchFieldWrapper({
    title = "",
    onLabel = "",
    offLabel = "",
    checked = false,
    disabled = false,
    onChange = null,
    boldTitle = false
}) {

    return (
        <FieldWrapper>
            <Label className="k-form-label" style={{
                fontWeight: boldTitle ? "500" : "normal"
            }}>
                {parse(title)}:
            </Label>
            <div className="k-form-field-wrap">
                <Switch
                    onLabel={onLabel}
                    offLabel={offLabel}
                    size="medium"
                    onChange={onChange}
                    disabled={disabled}
                    checked={checked}
                />
            </div>
        </FieldWrapper>
    );
}

export default SwitchFieldWrapper; 