import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  ApplicationRightSystemNames,
} from "../../../services/SystemNames";
import ContentGrid from "../../../components/layout/ContentGrid";
import { useEffectOnce } from "../../../components/useEffectOnce";
import ShipApiRepository from "../../../repositories/api/ShipApiRepository";
import { Button } from "@progress/kendo-react-buttons";
import { PilotageDetailsShipDialog } from "./PilotageDetailsShipDialog";
import { PhoneLink } from "./PilotageDetailsShipDialogContent";
import { BusySpinner } from "../../../components/layout/overlay/components/Busy";
import Icons from '../../../components/layout/icons/Icons';
import AppInsightsService from "../../../services/AppInsightsService";
import { Card } from "../../../components/layout/card/Card";
import Spacer from "../../../components/layout/Spacer";
import { isNullOrEmpty, isNumeric } from "../../../components/helpers/ObjectHelpers";
import { useGetApplicationRightsQuery } from "../../../reducers/slices/api.slice";
import { hasApplicationRights } from "../../../components/helpers/AuthHelpers";

export default function PilotageDetailsShip({ pilotage }) {
  const initialState = {
    shipContactInformation: {},
    hasImage: false,
    shipObservations: [],
    isFetchingObservations: true,
  };

  const Actions = {
    AddShipContactInformation: "AddShipContactInformation",
  };

  const pilotageShip = pilotage.pilotageShip;
  const imageUrl = `https://www.ship-info.com/vessels/${pilotageShip.imoNo}.jpg`;

  const [isModalMenuActive, setIsModalMenuActive] = useState(false);
  const [shipObservations, setShipObservations] = useState([]);
  const [shipObservationTypes, setShipObservationTypes] = useState([]);
  const [isFetchingObservations, setIsFetchingObservations] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const isAllowedEdit = useRef(false);

  const [pilotageDetails, dispatch] = useReducer(
    pilotageDetailsReducer,
    initialState,
    );

    const {
        data: applicationRights,
        isSuccess,
    } = useGetApplicationRightsQuery();

  useEffectOnce(() => {
    fetchShipContactInformation();
  });

    useEffect(() => {
        if (isNullOrEmpty(isSuccess)) return;
        const hasRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditShipObservations]);
        isAllowedEdit.current = hasRight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

  useEffect(() => {
    const loadImage = async () => {
      const img = new Image();
      img.src = imageUrl;
      
      await new Promise((resolve, reject) => {
        if (!isNumeric(pilotageShip.imoNo) || !pilotageShip.imoNo > 0) {
          setIsError(true);
          setIsImageLoaded(true)
        }

        img.onload = () => {
          setIsImageLoaded(true);
          resolve();
        };
        img.onerror = () => {
          setIsError(true);
          resolve();
        };
      }).catch((error) => {
          AppInsightsService.trackException(error);
          if (isNumeric(pilotageShip.imoNo) && pilotageShip.imoNo > 0) {
              console.error(error);
          }
      });

      setIsImageLoaded(true);
    };

    loadImage();
    fetchObservationsAsync();
    fetchObservationTypesAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  async function fetchShipContactInformation() {
    await ShipApiRepository.getShipDetailAsync(
      pilotageShip.shipId,
      pilotage.pilotageId,
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        handleAddShipContactInfo(result);
      })
      .catch((error) => {
        console.error(`An error occurred when fetching ship details.`, error);
      });
  }

  function handleAddShipContactInfo(shipContactInfo) {
    dispatch({
      type: Actions.AddShipContactInformation,
      shipContactInfo: shipContactInfo,
    });
  }

  function pilotageDetailsReducer(state, action) {
    switch (action.type) {
      case Actions.AddShipContactInformation: {
        return {
          ...state,
          shipContactInformation: action.shipContactInfo.shipContactInformation,
        };
      }
      default: {
        console.error(`Unknown action: ${action.type}`);
      }
    }
  }

  return (
    <>
      <ContentGrid
              title={pilotageShip.shipName}
              titleIcon="ship"
              subTitle={`${pilotage.pilotageNo}`}
              canCopySubTitle={true}>

                <div>
                    {isError ? (
                        <Icons className="ob-dummy-image" iconName="dummy" dimensions={128} />
                    ) : isImageLoaded ? (
                        <img className="ob-image" alt={pilotageShip.shipName} src={imageUrl} />
                    ) : (
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <BusySpinner type="dots" />
                        </div>
                    )}
                </div>

                <Spacer/>
              <Card>
                  <Spacer height={10}/>
                  <div className="pilotage-ship">
                      <div>{pilotageShip.typeName}</div>
                      <div>
                          <span>
                              Kj.sign
                              <br />
                          </span>
                          {pilotageShip.callSign}
                      </div>

                      <div>
                          <span>
                              Land
                              <br />
                          </span>
                          {pilotageShip.country}
                      </div>

                      <div>
                          <span>
                              BT
                              <br />
                          </span>
                          {pilotageShip.grossTonnage}
                      </div>

                      <div>
                          <span>
                              Lengde
                              <br />
                          </span>
                          {pilotageShip.length}
                      </div>

                      <div>
                          <span>
                              Bredde
                              <br />
                          </span>
                          {pilotageShip.breadth}
                      </div>

                      <div>
                          <span>
                              Maks DPG
                              <br />
                          </span>
                          {pilotageShip.draught}
                      </div>

                      <div>
                          <span>
                              Aktuell DPG
                              <br />
                          </span>
                          {pilotage.actualDraft}
                      </div>

                      <div>
                          <span>
                              Airdraft
                              <br />
                          </span>
                          {pilotage.airDraft}
                      </div>
                  </div>
              </Card>
              <Spacer/>
              <div className="observation-button">
                  {isFetchingObservations === true ? (
                      <BusySpinner type="progressbar" dimensions={20} />
                  ) : (
                      <div>Observasjoner registrert ({shipObservations.length})</div>
                  )}
                  {
                      (navigator.onLine) &&
                      <Button
                          onClick={onShowObservationsClick}
                          size="large"
                          themeColor="primary"
                          className="ob-button"
                      >Observasjoner</Button>
                  }
              </div>
              <Spacer/>
              {
                  (
                      !isNullOrEmpty(pilotageDetails.shipContactInformation.shipPhoneNo) &&
                      !isNullOrEmpty(pilotageDetails.shipContactInformation.shipMobilePhoneNo) &&
                      !isNullOrEmpty(pilotageDetails.shipContactInformation.shipAssistancePhoneNo)
                  ) &&
                    <Card>
                          <div className="contact-info">
                              <Spacer height={10} />
                          <PhoneLink
                              title={"Telefon"}
                              phoneNumber={pilotageDetails.shipContactInformation.shipPhoneNo}
                          />
                          <PhoneLink
                              title={"Mobil"}
                              phoneNumber={
                                  pilotageDetails.shipContactInformation.shipMobilePhoneNo
                              }
                          />
                          <PhoneLink
                              title={"Vakttelefon"}
                              phoneNumber={
                                  pilotageDetails.shipContactInformation.shipAssistancePhoneNo
                              }
                          />
                          </div>
                      </Card>
              }

              <Spacer height={30} />
          </ContentGrid>

        {
            <PilotageDetailsShipDialog
                isActive={isModalMenuActive}
                closeModalMenu={closeModalMenu}
                isAllowedEdit={isAllowedEdit.current}
                reloadObservations={fetchObservationsAsync}
                shipObservations={shipObservations}
                shipObservationTypes={shipObservationTypes}
                shipId={pilotageShip.shipId}
            />
        }
    </>
  );

  async function fetchObservationsAsync() {
    const imageWidth = Math.floor(window.innerWidth * 1);
    const imageHeight = Math.floor(window.innerHeight * 1);

      const response = await ShipApiRepository.getShipObservationsAsync(
      pilotage.shipId,
      imageWidth,
      imageHeight,
    );
    if (response.ok === true) {
      const data = await response.json();

      setShipObservations(data);
    }
    setIsFetchingObservations(false);
  }

  async function fetchObservationTypesAsync() {
    const response = await ShipApiRepository.getShipObservationTypesAsync();

    if (response.ok === true) {
      const data = await response.json();
      setShipObservationTypes(data);
    }
  }

  function onShowObservationsClick() {
    setIsModalMenuActive(true);
  }

  function closeModalMenu() {
    setIsModalMenuActive(false);
  }
}
