/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchResultItem } from './SearchResultItem';
import { SearchResultSettings } from '../../../../services/SystemNames';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { Dialog } from '../../dialogs/Dialog';
import { DialogProperties } from '../../dialogs/DialogProperties';
import { isObjectNull } from '../../../helpers/ObjectHelpers';
import { getPilotAssignmentUrl } from '../../../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';


export const NavigationMobileSearchDialog = ({ setShowSearchDialog  }) => {

    const navigate = useNavigate();

    const [searchResult, setSearchResult] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);

    const componentRef = useRef({
        timeout: null
    });
    const { current: localRef } = componentRef;

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleResize]);

    const searchResultRender = (props) => {
        const item = props.dataItem;
        return (
            <SearchResultItem item={item}
                onClick={() => onSearchResultClick(getPilotAssignmentUrl(item))} />
        )
    }

    // todo: her m� s�keresultat bli item med en source
    return (
        <Dialog
            properties={{
                ...DialogProperties,
                mode: "search",
                placeholder: "S&oslash;k i mine oppdrag",
                className: "dialog-title",
                isBusy: isBusy,
                hasSearched: hasSearched,
                items: searchResult,
                searchResultRender: searchResultRender,
                onSearch: onSearchAsync,
                onSelect: onSearchResultClick,
                onClose: () => {
                    setShowSearchDialog(false);
                    setSearchResult([]);
                }
            }}/>
    )

    function handleResize() {
        if (window.innerWidth < SearchResultSettings.SearchDialogMaxWindowWidth) return;
        onSearchResultClick();
    }
    
    async function onSearchAsync(e) {

        if (!isObjectNull(localRef.timeout)) {
            clearTimeout(localRef.timeout);
        }

        localRef.timeout = setTimeout(async() => {
            const shouldSearch = e.target.value.length >= 3;

            setHasSearched(false);
            setIsBusy(shouldSearch);

            const result = await PilotAssignmentIdbRepository.searchAsync(e.target.value);

            setSearchResult(result);

            // wait for glorious purpose :)
            setTimeout(() => {
                setIsBusy(false);
                setHasSearched(shouldSearch);
            }, 1500);
        }, 500);
    }

    function onSearchResultClick(url) {
        setSearchResult([]);
        setHasSearched(false);
        setShowSearchDialog(false);
        navigate(url);
    }
}

