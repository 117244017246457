import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const QuayManualApiRepository = {

    async getDispatcherDepartments() {
        return await BaseApiRepository.getAsync(`quaymanual/dispatcher-departments`);
    },

    async getPilotStation(id) {
        return await BaseApiRepository.getAsync(`quaymanual/stations/${id}`);
    },

    async getByLocationAndProximity(id) {
        return await BaseApiRepository.getAsync(`quaymanual/location/${id}/proximity`);
    },

    async getByLocation(id) {
        return await BaseApiRepository.getAsync(`quaymanual/location/${id}`);
    },

    async getLocations(id) {
        return await BaseApiRepository.getAsync(`quaymanual/locations/${id}`);
    },

    async getDocument(id) {
        return await BaseApiRepository.getAsync(`quaymanual/document/${id}`);
    },

    async getObservation(id) {
        return await BaseApiRepository.getAsync(`quaymanual/observation/${id}`);
    },

    async putObservation(observation) {
        return await BaseApiRepository.putAsync(`quaymanual/observation/`, observation);
    },

    async searchQuayManuals(search) {
        return await BaseApiRepository.getAsync(`quaymanual?search=${search}`);
    },
};
wrapMethodsWithLogging(QuayManualApiRepository);

export default QuayManualApiRepository;