import React from 'react';

export const CardItem = (
    {
        children,
        properties = {}
    }) => {
        

    return (
        <div
            id={`card-item-${properties.sourceId}`}
            className={`card-item ${properties.canClick() ? properties.hoverClassName : ''}`}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}>
            {children}
        </div>
    );

    function onMouseOver() {
        properties.handleOnMouseOver();
    }

    function onMouseOut() {
        properties.handleOnMouseOut();
    }
};
