import { initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken, isSupported } from 'firebase/messaging';
import PilotApiRepository from './repositories/api/PilotApiRepository';
import SourceApiRepository from './repositories/api/SourceApiRepository';
import { AppInsightsService } from './services/AppInsightsService';

let messaging;

if ('serviceWorker' in navigator && isSupported()) {
    navigator.serviceWorker.ready.then(async () => {
        const response = await SourceApiRepository.getConfigAsync();
        if (response.ok) {
            const config = await response.json();
            const parsedConfig = typeof data === 'string' ? JSON.parse(config) : config;

            const firebaseApp = initializeApp({
                apiKey: parsedConfig.firebaseApiKey,
                appId: parsedConfig.firebaseAppId,
                authDomain: parsedConfig.firebaseAuthDomain,
                measurementId: parsedConfig.firebaseMeasurementId,
                messagingSenderId: parsedConfig.firebaseMessagingSenderId,
                projectId: parsedConfig.firebaseProjectId,
                storageBucket: parsedConfig.firebaseStorageBucket,
            });
            messaging = getMessaging(firebaseApp);
        }
    })
}

function hasNotifyPermission(permission = window.Notification.permission) {
    return permission === "granted";
}

export const subscribe = async () => {
    if (hasNotifyPermission() && 'serviceWorker' in navigator) {
        const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
        if (!serviceWorkerRegistration) return;
        const response = await SourceApiRepository.getConfigAsync();
        if (response.ok) {
            const config = await response.json();
            const parsedConfig = typeof data === 'string' ? JSON.parse(config) : config;

            const options = {
                vapidKey: parsedConfig.firebaseVapidKey,
                serviceWorkerRegistration
            };

            return getToken(messaging, options).catch((err) => {
                AppInsightsService.trackException(err);
                return "";
            });
        }
    }
    return console.log(hasNotifyPermission ? "No Service Worker" : "No Permission")
}

export const unsubscribe = async () => {
    return deleteToken(messaging).catch((err) => {
        console.log(err);
        return false;
    })
}

export const enablePush = async (callback) => {
    return window.Notification.requestPermission().then(async (permission) => {
        if (permission !== "granted") {
            if (callback) callback(permission);
            return;
        }

        const subscription = await subscribe();
        if (!subscription) return;

        await PilotApiRepository.savePushTokensAsync(subscription).then(() => {
            if (callback) {
                console.log("setting permissions", permission);
                callback(permission);
            }
        })
    })
}

