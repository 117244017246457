import React from 'react';

import Spacer from '../../../components/layout/Spacer';
import { Button } from "@progress/kendo-react-buttons";
import { CloseIcon } from '../../../components/layout/icons/Icons';

export default function DeviationSelector({
    handleSelect,
    cancel
}) {

    return (
        <React.Fragment>
            <div className="worktime-subtitle">
                Velg grunn for lang arbeidsperiode
            </div>
            <Spacer classname="worktime-spacer-medium" />
            <div className="worktime-deviation-item" onClick={() => handleSelect(1)}>Mer enn 12 timer arbeid</div>
            <div className="worktime-deviation-item" onClick={() => handleSelect(2)}>Avtalt returreise p&aring; overtid</div>
            <div className="worktime-deviation-item" onClick={() => handleSelect(3)}>Returreise uten overtid</div>
            <div className="grid justify-content-flex-end m-r-25">
                <Button
                    onClick={cancel}
                    fillMode="outline"
                    svgIcon={CloseIcon}
                    size="large">
                    Avbryt
                </Button>
            </div>
        </React.Fragment>
    )
}
