import { addClassToElementByClassName, removeClassFromElementsByClassName } from "./ElementHelpers";

const OpenComponentClassName = "get-component-behind";
const ComponentClassNames = ["app-bar"];

function onToggleComponent(isOpen) {
    if (isOpen) {
        for (const className of ComponentClassNames) {
            addClassToElementByClassName(className, OpenComponentClassName);
        }
    } else {
        setTimeout(() => {
            for (const className of ComponentClassNames) {
                removeClassFromElementsByClassName(className, OpenComponentClassName);
            }
        }, 500);
    }
}

function isComponentAdaptive() {
    return window.innerWidth <= 768;
}

export {
    onToggleComponent,
    isComponentAdaptive
}
