import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.PilotBoardingTypeStore;

const PilotBoardingTypeIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(obj) {
        await BaseIdbRepository.setAsync(Store, obj.pilotBoardingTypeId, obj);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },

    async getAsync(key) {
        return await BaseIdbRepository.getAsync(Store, key);
    },

    async getDefaultAsync() {
        const objs = await BaseIdbRepository.getAllAsync(Store);
        return objs.find(o => o.systemName === 'NOT_APPLICABLE');
    },

    async getBySystemNameAsync(systemName) {
        const objs = await BaseIdbRepository.getAllAsync(Store);
        return objs.find(o => o.systemName === systemName);
    },
};

export default PilotBoardingTypeIdbRepository;