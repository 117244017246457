import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import PilotageApiRepository from '../../../repositories/api/PilotageApiRepository';
import Overlay from '../../../components/layout/overlay/Overlay';

export default function PilotageVoyageDialog({ onClose, pilotageId }) {

    const [voyageInfo, setVoyageInfo] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        initializeAsync()
    }, []);

    async function initializeAsync() {
        setIsBusy(true);
        var response = await PilotageApiRepository.getPilotageVoyageAsync(pilotageId);

        if (response.ok) {
            var voyageInfo = await response.json();
            setVoyageInfo(voyageInfo);
        }
        setIsBusy(false);
    }

    return (
        <>
            <Dialog
                properties={{
                    ...DialogProperties,
                    title: "Seilasens havner",
                    onClose: onClose,
                    className: "dialog-title",
                }}>

                {voyageInfo ?
                    <div>
                        <div className="dialog-row">
                            Fra: {voyageInfo.departureQuay}
                        </div>
                        <div className="dialog-row">
                            Til: {voyageInfo.arrivalQuay}
                        </div>
                    </div>            
                    :
                    <div>Ingen tidligere havner er tilgjengelig</div>
                }
            </Dialog>
            <Overlay isBusy={isBusy} />
        </>
    )
}
