
export const HseService = {
    getGroupTypeIcon(systemName) {
        switch (systemName) {
            case "CERTIFICATE":
                return "pdf";
            case "COURSE":
                return "trainee";
            case "DOCUMENT":
                return "layers";
            default:
                return "information";
        }
    }
}