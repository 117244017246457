import React from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { useNavigate } from 'react-router-dom';

export default function SettingsBottomMenu({ index = 1 }) {
    
    const navigate = useNavigate();    

    const items = [
        {
            text: "Persondata",
            icon: "myPage",
            action: 1
        },
        {
            text: "Kalender",
            icon: "calendar",
            action: 2
        },
        {
            text: "Definer dag/natt",
            icon: "settings",
            action: 3
        }
    ]

    return (
        <BottomNavigation
            items={items}
            onCallback={onCallback}
            selectedAction={index}
        />
    );

    function onCallback(e) {
        let url = "";
        switch (e.action) {
            case 2:
                url = 'calendar';
                break;
            case 3:
                url = 'daynight';
                break;
            default:
                url = 'personaldata';
                break;
        }

        navigate(`/settings/${url}`);
    }
}
