import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Switch from '../../../../components/layout/switch';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import { addUpdateReimbursmentStatusCommand, hasCommandType } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const ReimbursementCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [isReimbursementExcluded, setIsReimbursementExcluded] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    
    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initializeAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])
    
    return (
        <Switch
            disabled={!isEditable}
            onLabel="Ingen reiseregning"
            onChange={onChangeAsync}
            checked={isReimbursementExcluded}
            isDirty={isDirty}
        />
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId)
        setIsReimbursementExcluded(!pilotAssignment.pilotagePilotCompensation.isReimbursementIncluded)
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));

        initializeIsDirty(pilotAssignment);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }
    
    async function onChangeAsync(e) {
        setIsReimbursementExcluded(e.value);

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        pilotAssignment.pilotagePilotCompensation.isReimbursementIncluded = !e.value;

        pilotAssignment = addUpdateReimbursmentStatusCommand(pilotAssignment, !e.value);

        await onUpdatePilotAssignmentAsync(pilotAssignment);

        initializeIsDirty(pilotAssignment);
    }

    function initializeIsDirty(pilotAssignment) {
        setIsDirty(hasCommandType(pilotAssignment, PilotAssignmentCommands.UpdateReimbursmentStatus));
    }
})
