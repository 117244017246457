import React, { useEffect, useRef, useState } from 'react';
import Moment from 'moment';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { addClassToElementById, getElementById, removeClassFromElementById } from '../../../helpers/ElementHelpers';
import { calculateDistance } from '../../../helpers/GeometryHelpers';
import { isLocationAtSea } from '../../../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
import { minimumSpeed } from '../GoogleMapHelpers';
import { X, Clock } from "phosphor-react-sc";

export const MapLegend = ({
    properties,
    pilotage,
    shipPosition,
    mapId,
    onSettingsLegend
}) => {

    const map = useMap();
    const googleMapLegendId = `googleMapLegend-${mapId}`;
    const titleId = `googleMapTitle-${mapId}`;

    const componentRef = useRef({
        hasInitialized: false
    });
    const { current: localRef } = componentRef;
    
    useEffect(() => {

        if (!map) return;

        const legend = getElementById(googleMapLegendId);
        if (!isObjectNull(legend)) {
            map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(legend);
            legend.classList.remove("google-map-legend-active");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    useEffect(() => {
        if (isObjectNull(properties.pecExam)) return;
        if (localRef.hasInitialized) return;
        showLegend();
        localRef.hasInitialized = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties]);

    useEffect(() => {
        if (isObjectNull(pilotage)) return;
        if (localRef.hasInitialized) return;
        showLegend();
        localRef.hasInitialized = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pilotage]);

    useEffect(() => {
        if (isObjectNull(shipPosition)) return;
        if (localRef.hasInitialized) return;
        showLegend();
        localRef.hasInitialized = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipPosition]);

    return (
        <div id={googleMapLegendId} className="google-map-legend">
            <div><div onClick={hideLegend}><X size={16} /></div></div>
            <MapLegendPecExam properties={properties}/>
            <MapLegendPilotage pilotage={pilotage}/>
            <MapLegendShipPosition pilotage={pilotage} shipPosition={shipPosition} />
            
        </div>
    )

    function showLegend() {
        addClassToElementById(googleMapLegendId, "google-map-legend-active");
        addClassToElementById(titleId, "google-map-title-with-legend");
    }

    function hideLegend() {
        removeClassFromElementById(googleMapLegendId, "google-map-legend-active");
        onSettingsLegend();
        removeClassFromElementById(titleId, "google-map-title-with-legend");
    }
}

const MapLegendPecExam = ({ properties }) => {

    if (isObjectNull(properties.pecExam)) return;

    return (
        <div className="map-legend-pec-exam">
            <div>{properties.pecExam.examName}</div>
            <div>
                <div className="map-legend-rectangle map-legend-rectangle-granted"></div>
                <div>Allerede innvilgede omr&aring;der</div>
            </div>
            <div>
                <div className="map-legend-rectangle map-legend-rectangle-applied"></div>
                <div>Innvilgedes etter pr&oslash;ve</div>
            </div>
        </div>
    )
}

const MapLegendPilotage = ({ pilotage }) => {

    if (isObjectNull(pilotage)) return;

    return (
        <div className="map-legend-pilotage">
            <div>
                Fra: {pilotage.fromLocation.name}
            </div>
            <div>
                Til: {pilotage.toLocation.name }
            </div>
        </div>
    )
}

const MapLegendShipPosition = ({ pilotage, shipPosition }) => {

    const [fromTime, setFromTime] = useState(null);
    const [eta, setEta] = useState(null);
    const [stampAge, setStampAge] = useState(null);
    const [distance, setDistance] = useState(0);

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pilotage, shipPosition]);

    return (
        ((!isObjectNull(pilotage) && !isObjectNull(shipPosition))) &&
            <div className="map-legend-ais">
                {
                    (!isNullOrEmpty(fromTime)) ?
                        <div>
                            <div className="map-legend-ais-clock"><div><Clock/></div><div>{fromTime.format('HH:mm')}</div></div>
                            <div>{formatEta(eta)}</div>
                        </div>
                        :
                        (!isNullOrEmpty(eta)) &&
                            <div>{formatEta(eta)}</div>
                }
                <div>
                    SOG: {shipPosition.speedOverGround.toFixed(2)} knop
                </div>
                {
                    (!isNullOrEmpty(stampAge)) &&
                        <div>Sist oppdatert: {stampAge.minutes()}min</div>
                }
                {
                    (distance > 0) &&
                        <div>
                            Avstand: {distance} nm
                        </div>
                }
            
            </div>
    );

    function initialize() {
        if (isObjectNull(pilotage) || isObjectNull(shipPosition)) return;

        let distance = 0;

        const pilotageDetail = pilotage.pilotageDetail;
        const fromTime = Moment(pilotageDetail.fromTime);
        const stampAge = Moment.duration(Moment().diff(Moment(shipPosition.timestamp)));

        const isFromLocationAtSea = isLocationAtSea(pilotage.fromLocation);
        const isToLocationAtSea = isLocationAtSea(pilotage.toLocation);

        if (
            (isFromLocationAtSea && !isToLocationAtSea) ||
            (!isFromLocationAtSea && !isToLocationAtSea) ||
            (isFromLocationAtSea && isToLocationAtSea)
        ) {
            if (fromTime > Moment()) {
                distance = getDistance(pilotage.fromLocation);

                setFromTime(fromTime);

                const eta = getEta(shipPosition, distance);
                setEta(eta);
            }
        } else if (isToLocationAtSea) {
            distance = getDistance(pilotage.toLocation);
            const eta = getEta(shipPosition, distance);
            
            if (eta > Moment()) {
                setEta(eta)
            }
        }

        setStampAge(stampAge);
        setDistance(distance);
    }

    function getDistance(startLocation) {
        return calculateDistance(shipPosition.latitude, shipPosition.longitude, startLocation.latitude, startLocation.longitude).toFixed(2);
    }

    function getEta(shipPosition, distance) {
        if (shipPosition.speedOverGround < minimumSpeed || distance <= 0) return null;

        const travelTimeInMinutes = Math.round((distance * 60) / shipPosition.speedOverGround);
        const arrivalTime = Moment().add(travelTimeInMinutes, 'm');
        return arrivalTime;
    }

    function formatEta(eta) {
        if (isNullOrEmpty(eta)) return "";

        return `ETA: ${eta.format('DD.MM HH:mm')}`;
    }
}

