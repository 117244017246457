import './card.css'

import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { CardBorderLeft } from './components/CardBorderLeft';
import { CardContainer } from './components/CardContainer';
import { CardItem } from './components/CardItem';
import { CardModes, CardProperties, CardRightSectionModes } from './components/CardProperties';
import { CardContent } from './components/CardContent';
import { CardContentHeader } from './components/CardContentHeader';
import { CardContentBody } from './components/CardContentBody';
import { CardItemRightSection } from './components/CardItemRightSection';
import { isBoolean, isObjectNull } from '../../helpers/ObjectHelpers';
import { isMobile } from '../../helpers/DeviceHelpers';

export const Card = (
    {
        children,
        properties = { ...CardProperties }
    }) => {

    const [isSwipeLeftActive, setIsSwipeLeftActive] = useState(false);
    const [rightSectionMode, setRightSectionMode] = useState(CardRightSectionModes.None);
    const [wasSelected, setWasSelected] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const ref = useRef({
        canStart: true,
        canClick: properties.canClick(),
        counter: 0,
        hasExecuted: false,
        skipToggleDelete: false,
        onLoad: false,
        isSwipingDown: false,
        cancelClick: false,
        disabled: false
    });
    const { current: localRef } = ref;
    
    const intervalRef = useRef(null);

    const swipeHandlers = useSwipeable({
        trackMouse: true,
        onSwipedLeft: afterSwipeLeft,
        onSwipedRight: afterSwipeRight,
        onSwiped: onSwiped,
        onSwipeStart: onSwipeStart,
        onSwiping: onSwiping
    });

    const touchHandlers = useSwipeable({
        trackMouse: true,
        onTouchStartOrOnMouseDown: onTouchStart,
        onTouchEndOrOnMouseUp: onTouchEnd,
    });

    useEffect(() => {
        
        function reset() {
            localRef.hasExecuted = false;
            localRef.skipToggleDelete = false;
            localRef.onLoad = true

            setIsSwipeLeftActive(false);
            setIsSelected(false);
            setRightSectionMode(properties.canDelete() ? CardRightSectionModes.Delete : CardRightSectionModes.Select);
        }

        reset();
        
        return () => {

            stopInterval();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (properties.mode === CardModes.None || !isBoolean(properties.toggleDelete)) return;
        
        if (!localRef.skipToggleDelete) {
            setIsSwipeLeftActive(false);
        }

        localRef.skipToggleDelete = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.toggleDelete])

    useEffect(() => {

        if (properties.mode === CardModes.None ||
            !properties.canSelect ||
            !isBoolean(properties.toggleSelect) ||
            localRef.onLoad === true) {
            localRef.onLoad = false;
            return;
        }
        
        switch (rightSectionMode) {
            case CardRightSectionModes.None:
            case CardRightSectionModes.Select:
                const mode = properties.toggleSelect ? CardRightSectionModes.Select : CardRightSectionModes.None;
                toggleRightSectionMode(mode);
                toggleIsSwipeLeftActive(properties.toggleSelect);

                break;
            case CardRightSectionModes.Delete:
                if (properties.toggleSelect) {
                    toggleRightSectionMode(CardRightSectionModes.Select);
                    toggleIsSwipeLeftActive(properties.toggleSelect);
                }
                break;
            default:
                break;
        }

        setIsSelected(false);

        localRef.skipToggleDelete = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.toggleSelect]);

    useEffect(() => {
        if (!isBoolean(properties.toggleDisabled)) return;
        localRef.disabled = properties.toggleDisabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.toggleDisabled]);

    return (
        <>
            <CardItem properties={properties}>
                <CardContainer properties={properties} swipeHandlers={swipeHandlers}>
                    <CardBorderLeft properties={properties}/>
                    <CardContent properties={properties} isSwipeLeftActive={isSwipeLeftActive}>
                        <CardContentHeader
                            properties={properties}
                            isSwipeLeftActive={isSwipeLeftActive}
                            toggleIsSwipeLeftActive={toggleIsSwipeLeftActive}
                            touchHandlers={touchHandlers}
                        />
                        <CardContentBody
                            properties={properties}
                            touchHandlers={touchHandlers}>
                            {children}
                        </CardContentBody>
                    </CardContent>
                </CardContainer>
                <CardItemRightSection
                    properties={properties}
                    rightSectionMode={rightSectionMode}
                    isSelected={isSelected}
                    isSwipeLeftActive={isSwipeLeftActive}
                    setIsSwipeLeftActive={setIsSwipeLeftActive}
                    handleOnToggleDelete={handleOnToggleDelete}
                    handleOnselect={handleOnselect}/>
            </CardItem>
        </>
    );

    function onTouchStart(e) {
        if (!canTouch(e)) return;

        if (shouldCancelClick()) return;

        localRef.counter = 0;
        startInterval();
    }

    function onTouchEnd(e) {
        if (!canTouch(e)) return;
        
        if (shouldCancelClick()) return;
        
        let doTouchClick = false;
        let doSelect = false;

        switch (properties.mode) {
            case CardModes.Single:
                if (localRef.counter < properties.intervalCounterLimit) {
                    doTouchClick = localRef.canClick;
                }
                
                break;
            case CardModes.Multible:
                if (!isSwipeLeftActive && !wasSelected) {
                    doTouchClick = localRef.canClick;
                } else {
                    switch (rightSectionMode) {
                        case CardRightSectionModes.Delete:
                            doTouchClick = localRef.canClick;
                            break;
                        case CardRightSectionModes.Select:
                            doSelect = true;
                            break;
                        default:
                            break;
                    }
                        
                } 
                break;
            default:
                if (properties.canClick()) {
                    handleTouchOnClick(e);
                }
                break;
        }

        if (doTouchClick) {
            handleTouchOnClick(e);
        }

        if (doSelect) {
            handleOnselect(!isSelected);
        }

        if (!isSwipeLeftActive) {
            setWasSelected(false);
        }

        stopInterval();
        localRef.canClick = properties.canClick();
    }

    function onSwipeStart() {
        localRef.canStart = false;
        localRef.canClick = false;

        stopInterval();
    }

    function onSwiped() {
        stopInterval();

        localRef.canStart = true;
        localRef.counter = 0;
        localRef.isSwipingDown = false;
    }

    function afterSwipeLeft() {
        if (isSwipeLeftActive || !properties.canToggle || localRef.disabled) return;
        
        toggleRightSectionMode(getRightSectionDeleteMode());
        toggleIsSwipeLeftActive(true);
        handleOnToggleDelete();

        if (rightSectionMode !== CardRightSectionModes.Select) return;

        handleOnToggleSelect(false);
    }

    function afterSwipeRight() {
        if (!isSwipeLeftActive) return;

        handleOnselect(false);
        toggleIsSwipeLeftActive(false);
        handleOnToggleSelect(false);
        handleOnToggleDelete();
    }

    function onSwiping(e) {
        localRef.isSwipingDown = e.dir === "Down";

        if (e.dir !== "Up" && e.dir !== "Down") return;
        localRef.cancelClick = true;
    }

    function startInterval() {
        if (intervalRef.current) return;
        intervalRef.current = setInterval(() => {
            localRef.counter += 1;
            
            if (localRef.counter > properties.intervalCounterLimit &&
                !localRef.hasExecuted &&
                properties.canToggle &&
                !localRef.disabled) {

                localRef.canClick = false;

                switch (properties.mode) {
                    case CardModes.Multible:
                        toggleRightSectionMode(CardRightSectionModes.Select);
                        
                        if (rightSectionMode !== CardRightSectionModes.Select) {
                            setIsSelected(false);
                            toggleIsSwipeLeftActive(true);
                            handleOnToggleSelect(true);
                        } else {
                            toggleIsSwipeLeftActive(!isSwipeLeftActive);
                            handleOnToggleSelect(!isSwipeLeftActive);
                        }

                        break;
                    default:
                        if (properties.canDelete()) {
                            toggleRightSectionMode(getRightSectionDeleteMode());
                            toggleIsSwipeLeftActive(!isSwipeLeftActive);

                            if (!wasSelected && !isSwipeLeftActive) {
                                setWasSelected(true);
                            }
                            if (properties.mode === CardModes.Multible) {
                                if (rightSectionMode !== CardRightSectionModes.Delete) {
                                    handleOnselect(true);
                                }
                            } else {
                                handleOnToggleDelete();
                            }
                        }
                        
                        handleOnToggleSelect(false);
                        break;
                }
                
                localRef.hasExecuted = true;
            }
        }, 10);
    }

    function stopInterval() {
        if (!intervalRef.current) return;
        clearInterval(intervalRef.current);

        localRef.canStart = true;
        localRef.counter = 0;
        localRef.hasExecuted = false;

        intervalRef.current = null;
    }

    function handleTouchOnClick(e) {

        if (!localRef.cancelClick && !localRef.disabled) {
            properties.onClick();
        }

        localRef.cancelClick = false;
    }

    function handleOnselect(selected) {
        setIsSelected(selected);

        if (!properties.canParentSelect()) return;
        properties.onSelect(properties.sourceId, selected, properties.type);
    }

    function handleOnToggleDelete() {
        
        if (isObjectNull(properties.onToggleDelete)) return;
        localRef.skipToggleDelete = true;
        properties.onToggleDelete();
    }

    function handleOnToggleSelect(activate) {
        if (!properties.canToggle) return;
        if (isObjectNull(properties.onToggleSelect)) return;
        properties.onToggleSelect(activate);
    }

    function toggleRightSectionMode(mode) {
        if (mode !== CardModes.None) {
            setRightSectionMode(mode);
        } else {
            setRightSectionMode(CardRightSectionModes.None);
        }
    }

    function toggleIsSwipeLeftActive(isActive) {

        if (isActive) {
            setIsSwipeLeftActive(isActive && properties.mode !== CardModes.None);
        } else {
            setIsSwipeLeftActive(isActive);
        }
    }

    function getRightSectionDeleteMode() {
        if (properties.canDelete()) return CardRightSectionModes.Delete;
        return CardRightSectionModes.Select;
    }

    function canTouch(e) {
        let isAction = false;

        try {
            for (const className of e.event.target.classList) {
                if (className.toString() === 'header-action' || className.toString() === 'card-action') {
                    isAction = true;
                }
                if (className.toString().startsWith('k-button')) {
                    isAction = true;
                }
            }
        } catch {
            isAction = false;
        }

        if (
            (!isMobile() && (e.event.type === "mousedown" || e.event.type === "mouseup") )||
            !localRef.canStart ||
            localRef.isSwipingDown ||
            isAction
        )  return false;
        
        return true
    }

    function shouldCancelClick() {
        const result = localRef.cancelClick ? true : false;
        localRef.cancelClick = false;
        return result;
    }
}
