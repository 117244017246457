import React from 'react'
import ContentGrid from '../../../components/layout/ContentGrid'
import "../styles/my-page.css"
import { enablePush } from '../../../firebase';
import { checkLocalStorageForDeviceID, refreshDeviceKey, updateSubscriptionToken, getDeviceById, registerDevice, removeSubscription, addSubscription, getCurrentDevice } from "../../pilot/pushNotifications/PushHelpers";
import PleaseWait from '../../../components/access/PleaseWait';
import { sniffPlatform } from '../../../components/helpers/DeviceHelpers';
import { publishHeaderTopic } from '../../../components/helpers/PubSubHelpers';
import NotificationsBottomMenu from '../components/NotificationsBottomMenu';
import { isArrayEmpty } from '../../../components/helpers/ObjectHelpers';
import { Card } from '../../../components/layout/card/Card';
import SwitchFieldWrapper from '../../../components/layout/fieldWrappers/SwitchFieldWrapper';
import Spacer from '../../../components/layout/Spacer';
import { Button } from "@progress/kendo-react-buttons";

function PushNotifications() {
    publishHeaderTopic("Tillatelser", "Push Varsler");
    const [subscriptions, setSubscriptions] = React.useState([]);
    const [isSubscriptionMissing, setIsSubscriptionMissing] = React.useState(false);
    const [permissionStatus, setPermissionStatus] = React.useState("");
    const [isPermissionRequested, setIsPermissionRequested] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isBusy, setIsBusy] = React.useState(false);
    const [deviceId, setDeviceId] = React.useState(-1);
    const [isExpirationDatePassed, setExpirationDatePassed] = React.useState(false);
    const [isRegistered, setIsRegistered] = React.useState(false);

    React.useEffect(() =>
    {
        let status = window?.Notification?.permission;
        if (status !== "") {
            setPermissionStatus(status);
        }
        else
        {
            let isIos = sniffPlatform() === "ios";
            try {
                if (status === "" && isIos && window?.navigator?.standalone !== true)
                    setPermissionStatus("not-supported-ios");
            } catch (ex) {
                setPermissionStatus("not-supported-ios");
            }
        }
    }, []);

    React.useEffect(() =>
    {
        if (isPermissionRequested && window?.Notification?.permission === "default")
        {
            setIsBusy(true);
            enablePush().then(() =>
            {
                setIsBusy(false);
                setPermissionStatus(window?.Notification?.permission);
            });
        } 

    }, [isPermissionRequested]);
    React.useEffect(() => {
        if (permissionStatus === "granted")
        {
            getCurrentDevice(setSubscriptions, setDeviceId, setExpirationDatePassed, setIsSubscriptionMissing);
        }
    }, [permissionStatus]);

    React.useEffect(() =>
    {
        if (isExpirationDatePassed)
        {
            const id = checkLocalStorageForDeviceID();
            if (id) {
                refreshDeviceKey(Number(id));
                updateSubscriptionToken();
            }
        }
    }, [isExpirationDatePassed]);

    React.useEffect(() => {
        if (deviceId === 0) {
           
            registerDevice(setIsRegistered, setErrorMessage);
        }
    }, [deviceId]);

    React.useEffect(() => {
        if (isRegistered) {
            const id = checkLocalStorageForDeviceID();
            if (id) {
                getDeviceById(id, setSubscriptions, setErrorMessage, setIsSubscriptionMissing);
            }
        }
    }, [isRegistered]);

    React.useEffect(() => {
        if (isSubscriptionMissing)
        {
            updateSubscriptionToken(isSubscriptionMissing)
                .then(() => getDeviceById(checkLocalStorageForDeviceID(), setSubscriptions, setErrorMessage, () => { }));
        }
    }, [isSubscriptionMissing]);

    const DeniedMessage = ({ permission }) => {
        let content;
        if (permission === "denied") {
            content = (
                <>
                    <p>Varsler er blokkert for Njord Pilot Web.</p>
                    <p>
                        Opphev blokkering ved å navigere til varsler fra enhetens innstillinger.
                    </p>

                    <p><h5>Merk!</h5> Den installerte appen <u>må startes på nytt</u> før endringen får effekt.</p>
                </>);
            const m = <>   <div style={{ width: "60%", margin: "0 auto" }}>
                {content}
            </div></>
            return m;
        } else if (permission === "granted") {
            content = (<></>);
        } else if (permission === "not-supported-ios") {
            content = (
                <>
                    <p>Din nettleser støtter ikke push-varsler</p>
                    <p>For å motta push-varsler må du bruke Safari eller Chrome og legge til denne siden på hjem-skjermen</p>
                </>);
            const m = <>   <div style={{ width: "60%", margin: "0 auto" }}>
                {content}
            </div></>
            return m; 
        }
        const message =
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div>
                            {content}
                        </div>
                        {
                            permission !== "not-supported-ios" &&
                            <Button themeColor="primary" size="large" style={{ justifySelf: "center", marginTop: "20px" }} onClick={() => setIsPermissionRequested(true)}>Aktivere Push</Button>
                        }
                    </div>
                </div>
            </div>
        return message;
    }

    return (
        <>
            <ContentGrid title="Push-varsler" titleIcon="mobile">
                {(isBusy) ? <PleaseWait /> :
                    <Card>
                        {permissionStatus && isArrayEmpty(subscriptions) && <DeniedMessage permission={permissionStatus} />}
                        {permissionStatus === "granted" && !isArrayEmpty(subscriptions) && <ToggleSubscriptionButtons subscriptions={subscriptions} />}
                        {errorMessage}
                    </Card>
                }
            </ContentGrid>
            <NotificationsBottomMenu />
        </>
    )
}

const ToggleSubscriptionButtons = ({ subscriptions }) => {
    const [activeSubs, setActiveSubs] = React.useState({
        tentative: {
            active: subscriptions[0].isActive,
            value: 0
        },
        assigned: {
            active: subscriptions[1].isActive,
            value: 1,
        },
        shift: {
            active: subscriptions[2].isActive,
            value: 2
        }
    })

    const toggleIsActive = (target) => {
        setActiveSubs((prev) => ({
            ...prev,
            [target]: {
                ...prev[target],
                active: !prev[target].active
            }
        }));
    }

    const toggleSubscription = (target) => {
        const isActive = activeSubs[target].active;
        const value = activeSubs[target].value;
        if (isActive) {
            removeSubscription(value);
        } else {
            addSubscription(value);
        }
        toggleIsActive(target);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <SwitchFieldWrapper
                        title="Tildelt oppdrag"
                        boldTitle={true}
                        onChange={() => toggleSubscription("assigned")}
                        checked={activeSubs.assigned.active}
                    />
                </div>
            </div>
            <Spacer height={20} />
            <div className="row">
                <div className="col">
                    <SwitchFieldWrapper
                        title="Tentative tildelt oppdrag"
                        boldTitle={true}
                        onChange={() => toggleSubscription("tentative")}
                        checked={activeSubs.tentative.active}
                    />
                    
                </div>
            </div>
            <Spacer height={20} />
            <div className="row">
                <div className="col">
                    <SwitchFieldWrapper
                        title="Vaktplanendring"
                        boldTitle={true}
                        onChange={() => toggleSubscription("shift")}
                        checked={activeSubs.shift.active}
                    />
                </div>
            </div>
        </div>
    )
}

export default PushNotifications
