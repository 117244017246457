import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import Switch from '../../../../components/layout/switch';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import { addIsWastedTripCommand, hasCommandType } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';

export const WastedTripCard = forwardRef(({
    pilotAssignmentId,
    onWastedTripChanged,
    onUpdatePilotAssignmentAsync
}, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }
    }));

    const [isWastedTrip, setIsWastedTrip] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);
        initializeAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    return (
        <Switch
            disabled={!isEditable}
            onLabel="Bomtur"
            onChange={onChangeAsync}
            checked={isWastedTrip}
            isDirty={isDirty}
        />
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        setIsWastedTrip(pilotAssignment.isWastedTrip);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
        initializeIsDirty(pilotAssignment);
    }

    function handlePubSubTopic() {
        initializeAsync();
    }
    
    async function onChangeAsync(e) {
        setIsWastedTrip(e.value);

        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        pilotAssignment.isWastedTrip = e.value;

        pilotAssignment = addIsWastedTripCommand(pilotAssignment, e.value);

        await onUpdatePilotAssignmentAsync(pilotAssignment);
        
        onWastedTripChanged(e.value);

        initializeIsDirty(pilotAssignment);
    }

    function initializeIsDirty(pilotAssignment) {
        setIsDirty(hasCommandType(pilotAssignment, PilotAssignmentCommands.IsWastedTrip));
    }
})
