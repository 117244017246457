import DropDownList from '../../../components/layout/DropDownList';
import './noticetimerow.css'
import React from 'react';

export default function NoticeTimeRow(
    {
        icon,
        time,
        selectedMinute,
        isDay = true,
        minutes = [],
        onChange
    }
) {
    
    return (
        <div className="notice-time-general-row">
            <div>{icon}</div>
            <div>{isDay ? "Dag" : "Natt"}</div>
            <div>fra {time}</div>
            <div>
                <div>
                    <DropDownList
                        data={minutes}
                        value={selectedMinute}
                        adaptive={true}
                        adaptiveTitle={`Velg varslingstid ${isDay ? "dag" : "natt"}`}
                        textField="text"
                        onChange={onChange}
                    />
                </div>
                <div>min</div>
            </div>
            
        </div>
    );
}
