import React from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { useNavigate } from 'react-router-dom';

export default function NotificationsBottomMenu({ index = 1 }) {
    
    const navigate = useNavigate();    

    const items = [
        {
            text: "Push-varsler",
            icon: "mobile",
            action: 1
        },
        {
            text: "Lokasjonsdeling",
            icon: "pin",
            action: 2
        }
    ]

    return (
        <BottomNavigation
            items={items}
            onCallback={onCallback}
            selectedAction={index}
        />
    );

    function onCallback(e) {
        let url = "";
        switch (e.action) {
            case 2:
                url = 'locations';
                break;
            default:
                url = '';
                break;
        }

        navigate(`/settings/notifications/${url}`);
    }
}
