import React, { useRef, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import Spacer from '../../../components/layout/Spacer';
import { CloseIcon, OkIcon } from '../../../components/layout/icons/Icons';
import DateTimePicker, { DateTimePickerModes } from '../../../components/layout/DateTimePicker';

export default function SelfDefined({
    title = "",
    onChange,
    onSave,
    dateToShow,
    cancel
}) {

    const selfDefinedRef = useRef()

    useEffect(() => {
        selfDefinedRef.current.scrollIntoView();
    }, []);

    return (
        <React.Fragment>
            <div>
                <div className="worktime-subtitle self-defined-subtitle">
                    {title}
                </div>
                <Spacer classname="worktime-spacer-medium" />
                <div className="workTime-self-defined" ref={selfDefinedRef}>
                    <div>
                        <DateTimePicker
                            mode={DateTimePickerModes.DateTime}
                            value={dateToShow}
                            onChange={handleOnChange}
                            canDelete={false}
                            title="Velg egendefinert start"
                        />
                    </div>
                    <div>
                        <Button
                            svgIcon={CloseIcon}
                            onClick={cancel}>
                            Avbryt
                        </Button>
                    </div>
                    <div>
                        <Button
                            themeColor="primary"
                            svgIcon={OkIcon}
                            onClick={onSaveClick}>
                            Lagre
                        </Button>
                    </div>
                </div>
                <Spacer classname="worktime-spacer-high" />
            </div>
        </React.Fragment>
    )

    function onSaveClick() {
        onSave();
    }

    function handleOnChange(e) {
        onChange(e);
    }
}
