import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { MapMarker } from './MapMarker';
import Moment from 'moment';
import { IconColors } from '../../../../services/SystemNames';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { isLocationAtSea } from '../../../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
import { createShipPositionInformationObject, minimumSpeed } from '../GoogleMapHelpers';

export const MapPilotageMarkers = forwardRef(({
    pilotage,
    shipPosition,
    toggleMarkerInfoWindows,
    setToggleMarkerInfoWindows,
    onUnselectShip
}, ref) => {

    useImperativeHandle(ref, () => ({
        onBoundsChanged() {
            onChanged();
        },
        onZoomChanged() {
            onChanged();
        },
        onDragEnd() {
            onChanged();
        },
        onResize() {
            onChanged();
        },
    }));

    const map = useMap();  

    const [locationMarkers, setLocationMarkers] = useState([]);
    const [shipMarkers, setShipMarkers] = useState([]);

    const componentRef = useRef({
        hasLocationsFitBounds: false,
        hasShipFitBounds: false,
        pilotage: null,
        positionPolyline: null,
        shipPosition: null
    });
    const { current: localRef } = componentRef;

    useEffect(() => {
        if (!map) return;
        if (isObjectNull(pilotage)) return;
        initializeLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, pilotage]);

    useEffect(() => {
        if (!map) return;
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, pilotage, shipPosition]);

    return (
        <>
            <MapPilotageMarkerContent
                markers={locationMarkers}
                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
            />
            <MapPilotageMarkerContent
                markers={shipMarkers}
                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                onUnselectShip={onUnselectShip}
            />
        </>
    )

    function onChanged() {
        if (isArrayEmpty(shipMarkers)) return;
        if (isObjectNull(shipPosition)) return;
        initialize();
    }

    function initialize() {
        if (!map) return;

        if (isObjectNull(pilotage) || isObjectNull(shipPosition)) return;
        
        initializeShipPosition();
    }

    function initializeLocations() {
        if (localRef.hasLocationsFitBounds) return;

        const markers = [];
        const fromLocation = pilotage.fromLocation;
        const toLocation = pilotage.toLocation;

        markers.push({
            description: fromLocation.name,
            position: new window.google.maps.LatLng(fromLocation.latitude, fromLocation.longitude),
            type: "pin",
            color: IconColors.PinStart,
            topmost: true
        });
        markers.push({
            description: toLocation.name,
            position: new window.google.maps.LatLng(toLocation.latitude, toLocation.longitude),
            type: "pin",
            color: IconColors.PinStop,
            topmost: true
        });

        setLocationMarkers(markers);
        fitBounds(markers);
        localRef.hasLocationsFitBounds = true;
    }

    function initializeShipPosition() {

        if (isShipPositionsEqual()) return;
        
        const positionMarkers = [];
        const markers = [];
        let position = null;

        if (!isObjectNull(localRef.positionPolyline)) {
            localRef.positionPolyline.setMap(null);
        }

        if (!isObjectNull(shipPosition)) {

            position = new window.google.maps.LatLng(shipPosition.latitude, shipPosition.longitude);
            markers.push(createShipPositionInformationObject(shipPosition));

            if (!isObjectNull(pilotage)) {

                positionMarkers.push({
                    position: new window.google.maps.LatLng(pilotage.fromLocation.latitude, pilotage.fromLocation.longitude),
                });
                positionMarkers.push({
                    position: new window.google.maps.LatLng(pilotage.toLocation.latitude, pilotage.toLocation.longitude),
                });

                let shouldShowPolyline = false;
                let startPosition = null;

                const fromTime = Moment(pilotage.pilotageDetail.fromTime);
                const estimatedToTime = Moment(pilotage.pilotageDetail.fromTime).add(pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes, 'minutes');
                const isFromLocationAtSea = isLocationAtSea(pilotage.fromLocation);
                const isToLocationAtSea = isLocationAtSea(pilotage.toLocation);
                
                if (
                    (isFromLocationAtSea && !isToLocationAtSea) ||
                    (!isFromLocationAtSea && !isToLocationAtSea) ||
                    (isFromLocationAtSea && isToLocationAtSea)
                ) {
                    shouldShowPolyline = fromTime > Moment();
                    startPosition = positionMarkers[0].position;
                } else if (isToLocationAtSea) {
                    shouldShowPolyline = estimatedToTime > Moment() && shipPosition.speedOverGround > minimumSpeed;
                    startPosition = positionMarkers[1].position;
                }
                
                if (shouldShowPolyline) {
                    const positionCoordinates = [];

                    positionCoordinates.push(startPosition);
                    positionCoordinates.push(position);

                    const positionPolyline = new window.google.maps.Polyline({
                        path: positionCoordinates,
                        geodesic: true,
                        strokeColor: "#E16710",
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    });
                    positionPolyline.setMap(map);
                    localRef.positionPolyline = positionPolyline;
                }
            }
        }

        setShipMarkers(markers);

        localRef.shipPosition = shipPosition;

        if (isNullOrEmpty(position)) return;
        
        fitBounds(positionMarkers, position);
        localRef.hasShipFitBounds = true;
    }

    function fitBounds(markers, position = null) {

        if (localRef.hasShipFitBounds ||
            (localRef.hasLocationsFitBounds && localRef.hasShipFitBounds)) return;

        let bounds = new window.google.maps.LatLngBounds();
        for (let marker of markers) {
            bounds.extend(marker.position);
        }
        if (!isObjectNull(position)) {
            bounds.extend(position);
        }
        map.fitBounds(bounds);
    }

    function isShipPositionsEqual() {
        if (isArrayEmpty(shipMarkers)) return false;
        if (isObjectNull(shipPosition)) return null;
        const position = shipMarkers[0].position;
        return position.lat() === shipPosition.latitude && position.lng() === shipPosition.longitude;
    }
})

const MapPilotageMarkerContent = ({ markers, toggleMarkerInfoWindows, setToggleMarkerInfoWindows, onUnselectShip = null }) => {
    return (
        markers.map((marker, index) =>
            <MapMarker
                key={index}
                marker={marker}
                toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                onUnselect={onUnselectShip}
            />
        )
    )
}
