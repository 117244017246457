import React, { useState } from 'react';
import PilotageApiRepository from '../../../repositories/api/PilotageApiRepository';
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from 'react-router-dom';
import Spacer from '../../../components/layout/Spacer';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import Overlay from '../../../components/layout/overlay/Overlay';
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';

const PilotageDetailConfirmPilotage = ({ state: pilotagePilot, handleAssignmentStatus }) => {
    const assignmentId = pilotagePilot.pilotagePilotId;
    const navigate = useNavigate();

    const [showRejectionJustificationModal, setShowRejectionJustificationModal] = useState(false);
    const [showConvertToAvailableWorkTimeModal, setShowConvertToAvailableWorkTimeModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const sendAcceptPilotage = async () => {
        setShowAcceptModal(false);
        setShowConvertToAvailableWorkTimeModal(false);
        setIsBusy(true);
        var response = await PilotageApiRepository.acceptPilotage(assignmentId);
        var pilotStatus = {
            nameNor: 'Bekreftet',
            systemName: "CONFIRMED",
            value: 0
        };
        handleResponse(response, pilotStatus);
        if (response.status === 404)
            navigate(`/pilot/pilotages/assigned`);
    }

    const sendRejectPilotage = async (rejectionReason) => {
        setShowRejectionJustificationModal(false);
        setIsBusy(true);
        var response = await PilotageApiRepository.rejectPilotage(assignmentId, rejectionReason);
        var pilotStatus = {
            nameNor: 'Avvist',
            systemName: 'REJECTED',
            value: 1
        };
        handleResponse(response, pilotStatus);
        navigate(`/pilot/pilotages/assigned`);
    }

    const handleResponse = async (response, pilotStatus) => {
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Besvarelsen din ble lagret`);
            handleAssignmentStatus(pilotStatus);
        } else {
            var errorMessage = await response.text();
            publishWarningNotificationTopic(errorMessage);
        }
        setIsBusy(false);
    }

    const shouldShowRejectionJustificationModal = () => {
        setShowRejectionJustificationModal(showRejectionJustificationModal => !showRejectionJustificationModal);
    }

    const shouldShowConvertToAvailableWorkTimeModal = () => {
        if (!isNullOrEmpty(pilotagePilot.convertToAvailableWorkTimePeriod)) {
            setShowConvertToAvailableWorkTimeModal(showConvertToAvailableWorkTimeModal => !showConvertToAvailableWorkTimeModal);
        } else {
            setShowAcceptModal(showAcceptModal => !showAcceptModal);
        }
    }

    const shouldShowAcceptPilotageAssignmentModal = () => {
        setShowAcceptModal(showAcceptModal => !showAcceptModal);
    }

    const RejectionJustification = () => {
        const [rejectionReason, setRejectionReason] = useState('');
        return (
            showRejectionJustificationModal &&
                <Dialog properties={{
                    ...DialogProperties,
                    mode: "confirmation",
                    title: "Avvis oppdrag",
                    requiresDescription: true,
                    onClose: shouldShowRejectionJustificationModal,
                    onClick: () => sendRejectPilotage(rejectionReason),
                    onChange: setRejectionReason,
                    closeText: "Avbryt",
                    clickText: "Ok"
                }}/>
        )
    }

    const AcceptPilotageAssignment = () => {
        return (
            showAcceptModal &&
                <Dialog properties={{
                    ...DialogProperties,
                    mode: "confirmation",
                    title: "Bekreft oppdrag",
                    onClose: shouldShowAcceptPilotageAssignmentModal,
                    onClick: sendAcceptPilotage,
                    closeText: "Avbryt",
                    clickText: "Ok"
                }}>
                    <div className="confirm-pilotage-container">
                        <div className="covert-to-available-text">
                            {`Oppdrag for fartøy  ${pilotagePilot.pilotage.pilotageShip.shipName} med oppdragsnummer ${pilotagePilot.pilotage.pilotageNo}
                            vil bli bekreftet`}
                        </div>
                    </div>
                </Dialog>
        )
    }

    const ConvertToAvaibleWorkTimePeriod = () => {
        return (
            showConvertToAvailableWorkTimeModal &&
                <Dialog properties={{
                    ...DialogProperties,
                    mode: "confirmation",
                    title: "Bekreft oppdrag",
                    onClose: shouldShowConvertToAvailableWorkTimeModal,
                    onClick: sendAcceptPilotage,
                    closeText: "Avbryt",
                    clickText: "Ok"
                }}>
                    <div className="details-card-title">
                        Bekreft oppdrag
                    </div>
                    <div className="covert-to-available-text">
                        {`Oppdrag for fartøy  ${pilotagePilot.pilotage.pilotageShip.shipName} med oppdragsnummer ${pilotagePilot.pilotage.pilotageNo}
                        vil bli bekreftet`}
                    </div>
                    <Spacer />
                    <p className="covert-to-available-text">
                        {pilotagePilot.convertToAvailableWorkTimePeriod}
                    </p>
                </Dialog>
        )
    }

    return (
        <>
            <div className="confirm-pilotage">
                <Button
                    onClick={shouldShowRejectionJustificationModal} size="large"
                    >Avvis</Button>
                <Button 
                    themeColor="primary"
                    onClick={shouldShowConvertToAvailableWorkTimeModal}
                    size="large">Bekreft</Button>
                <AcceptPilotageAssignment />
                <RejectionJustification />
                <ConvertToAvaibleWorkTimePeriod />
            </div>
            <Overlay isBusy={isBusy}/>
        </>
    )
}

export default PilotageDetailConfirmPilotage;
