import React, { useEffect, useState } from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import parse from 'html-react-parser';
import { ApplicationRightSystemNames } from '../../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import { isArrayEmpty, isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { useGetApplicationRightsQuery } from '../../../reducers/slices/api.slice';
import { hasApplicationRights } from '../../../components/helpers/AuthHelpers';

export function PcsBottomMenu({ callback, index = 1 }) {

    const navigate = useNavigate();

    const [items, setItems] = useState([]);

    const {
        data: applicationRights,
        isSuccess,
    } = useGetApplicationRightsQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSuccess)) return;
        
        const result = [];
        let hasApplicationRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments]);
        if (hasApplicationRight) {
            result.push({
                text: parse("Sertifikatomr&#229;der"),
                icon: "mapSegments",
                action: 1
            });
        }

        hasApplicationRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation]);
        if (hasApplicationRight) {
            result.push({
                text: "Evalueringer",
                icon: "evaluation",
                action: 2
            });
        }

        hasApplicationRight = hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations]);
        if (hasApplicationRight) {
            result.push({
                text: "Lokasjoner",
                icon: "pin",
                action: 3
            });
        }

        setItems(result);

        callback(!isArrayEmpty(result));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        (!isArrayEmpty(items)) &&
            <BottomNavigation
            items={items}
            selectedAction={index}
            onCallback={onBottomMenuCallback }
            />
    );

    function onBottomMenuCallback(e) {
        let url = "";
        switch (e.action) {
            case 2:
                url = 'evaluations';
                break;
            case 3:
                url = 'locations';
                break;
            default:
                url = 'groups';
                break;
        }
        navigate(`/pcs/${url}`)
    }
}
