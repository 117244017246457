import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import DateTimePicker, { DateTimePickerModes } from '../../../../components/layout/DateTimePicker';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import Spacer from '../../../../components/layout/Spacer';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Label } from '../../../../components/layout/Label';
import { getFormattedDateTime } from '../../../../components/helpers/DateTimeHelpers';
import { addPilotDepartureTimeCommand, addPilotReturnTimeCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const TimeDepartureReturnCard = forwardRef((
    {
        pilotAssignmentId,
        initializeIsDirty,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            setHasDepartureTimeValidationError(codeStrings.includes(PilotAssignmentErrorCodes.PilotDepartureTimeIsMissing));
            setHasReturnTimeValidationError(codeStrings.includes(PilotAssignmentErrorCodes.PilotReturnTimeIsMissing));
        }
    }));

    const [showDepartureTime, setShowDepartureTime] = useState(false);
    const [showReturnTime, setShowReturnTime] = useState(false);
    const [departureTime, setDepartureTime] = useState(null);
    const [returnTime, setReturnTime] = useState(null);
    const [hasDepartureTimeValidationError, setHasDepartureTimeValidationError] = useState(false);
    const [hasReturnTimeValidationError, setHasReturnTimeValidationError] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic)
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, [])

    return (
        <>
            <Spacer height={10} />

            <FieldWrapper>
                <Label hasValidationError={hasDepartureTimeValidationError}>
                    Avreise:
                </Label>
                <div className="k-form-field-wrap">
                    <DateTimePicker
                        disabled={!isEditable}
                        hasValidationError={hasDepartureTimeValidationError}
                        onClick={() => setShowDepartureTime(true)}
                        title="Velg avreise tid"
                        value={departureTime}
                        show={showDepartureTime}
                        onClose={() => setShowDepartureTime(false)}
                        mode={DateTimePickerModes.DateTime}
                        onChange={onDepartureTimeChangeAsync} />
                </div>
            </FieldWrapper>

            <Spacer height={5} />

            <FieldWrapper>
                <Label hasValidationError={hasReturnTimeValidationError}>
                    Retur:
                </Label>
                <div className="k-form-field-wrap">
                    <DateTimePicker
                        disabled={!isEditable}
                        hasValidationError={hasReturnTimeValidationError}
                        onClick={() => setShowReturnTime(true)}
                        title="Velg returtid"
                        value={returnTime}
                        show={showReturnTime}
                        onClose={() => setShowReturnTime(false)}
                        mode={DateTimePickerModes.DateTime}
                        onChange={onReturnTimeChangeAsync} />
                </div>
            </FieldWrapper>
        </>
    )

    async function getPilotAssignmentAsync() {
        return await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
    }

    async function setAndPublishAsync(pilotAssignment) {
        await onUpdatePilotAssignmentAsync(pilotAssignment);
        initializeAsync();
    }

    async function initializeAsync() {
        const pilotAssignment = await getPilotAssignmentAsync();

        setDepartureTime(pilotAssignment.departureTime);
        setReturnTime(pilotAssignment.returnTime);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function onDepartureTimeChangeAsync(e) {
        setDepartureTime(e.value);

        let pilotAssignment = await getPilotAssignmentAsync();

        pilotAssignment.departureTime = getFormattedDateTime(e.value);
        pilotAssignment = addPilotDepartureTimeCommand(pilotAssignment, e.value);

        await setAndPublishAsync(pilotAssignment);
    }

    async function onReturnTimeChangeAsync(e) {
        setReturnTime(e.value);

        let pilotAssignment = await getPilotAssignmentAsync();

        pilotAssignment.returnTime = getFormattedDateTime(e.value);
        pilotAssignment = addPilotReturnTimeCommand(pilotAssignment, e.value);

        await setAndPublishAsync(pilotAssignment);

        initializeIsDirty();
    }
})
