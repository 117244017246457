import { useEffect } from 'react';
import {
    useMap,
} from '@vis.gl/react-google-maps';
import { isObjectNull } from '../../../helpers/ObjectHelpers';
import { FeaturePropertyTypes, mouseOverStrokeColor, pcsGroupFeatureType, setMapTitle, unselectFeatures } from '../GoogleMapHelpers';

export const MapEvents = ({
    mapId,
    featureMouseOver,
    featureSelected,
}) => {

    const map = useMap();

    useEffect(() => {
        if (!map) return;

        map.data.revertStyle();
        setMapTitle(mapId, "");

        if (isObjectNull(featureMouseOver)) return;

        map.data.forEach(function (feature) {
            const propertyValue = feature.getProperty(featureMouseOver.property);
            if (propertyValue === featureMouseOver.value) {
                map.data.overrideStyle(feature,
                    {
                        strokeColor: mouseOverStrokeColor,
                        fillColor: mouseOverStrokeColor,
                        strokeWeight: 4,
                        zIndex: 1000
                    }
                );
                const text = feature.getProperty("segmentName");
                setMapTitle(mapId, text);
            }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, featureMouseOver]);

    useEffect(() => {

        if (!map) return;

        unselectFeatures(map, pcsGroupFeatureType);

        map.data.revertStyle();

        if (isObjectNull(featureSelected)) return;

        const feature = map.data.getFeatureById(featureSelected.value);
        if (isObjectNull(feature)) return;

        const featurePropertyType = feature.getProperty("featurePropertyType");
        const featureGeometry = feature.getGeometry();

        if (featurePropertyType === FeaturePropertyTypes.Group) {
            let bounds = new window.google.maps.LatLngBounds();
            featureGeometry.forEachLatLng((latlng) => {
                bounds.extend(latlng);
            });
            map.fitBounds(bounds);
        }

        feature.setProperty("isSelected", true);
        
    }, [map, featureSelected]);
}
