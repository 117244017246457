/* eslint-disable react-hooks/exhaustive-deps */

import './searchresultitem.css'

import React from 'react';
import Moment from 'moment';
import { getPilotAssignmentStatusColor, getPilotAssignmentStatusColorRgba, isPilotAssignmentAssignedAndNotProcessed, isPilotAssignmentCorrectionNeeded } from '../../../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';

export const SearchResultItem = ({item, onClick }) => {
    return (
        <div className="search-result-item"
            onClick={onClick}
            style={{
                backgroundColor: getPilotAssignmentStatusColorRgba(item),
                border: `solid 2px ${getPilotAssignmentStatusColor(item)}`,
                borderLeft: `solid 10px ${getPilotAssignmentStatusColor(item)}`
            }}>
            <div
                >{item.pilotage.pilotageShip.shipName}
            </div>
            <div>{item.pilotage.pilotageNo}</div>
            <div>{`${Moment(item.pilotage.pilotageDetail.fromTime).format('DD.MM')} - ${Moment(item.pilotage.pilotageDetail.fromTime).format('HH:mm')}`}</div>
            <div>{item.pilotage.fromLocation.name} til {item.pilotage.toLocation.name}</div>
            {
                isPilotAssignmentAssignedAndNotProcessed(item) && <div className="search-result-item-reply"><span className="reply-link">Besvar foresp&oslash;rsel</span></div>
            }
            {
                isPilotAssignmentCorrectionNeeded(item) && <div className="search-result-item-reply"><span className="reply-link">Korriger skipperbevis</span></div>
            }
        </div>
    )

    
}
    
