import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const NoticeTimeApiRepository = {

    async getLocationSummaryAsync() {
        return await BaseApiRepository.getAsync(`notice-time/locations/summary`);
    },

    async getGeneralAsync() {
        return await BaseApiRepository.getAsync('notice-time/general');
    },

    async setGeneralAsync(obj) {
        return await BaseApiRepository.postAsync('notice-time/general', obj);
    },

    async setLocationAsync(obj) {
        return await BaseApiRepository.postAsync('notice-time/location', obj);
    },

    async deleteLocationAsync(id) {
        return await BaseApiRepository.deleteAsync(`notice-time/location/${id}`);
    },

    async setGroupAsync(obj) {
        return await BaseApiRepository.postAsync('notice-time/group', obj);
    },

    async deleteGroupAsync(id) {
        return await BaseApiRepository.deleteAsync(`notice-time/group/${id}`);
    },

    async deleteLocationsAsync(obj) {
        return await BaseApiRepository.deleteAsync(`notice-time/notice-times`, obj);
    },

    
};
wrapMethodsWithLogging(NoticeTimeApiRepository);

export default NoticeTimeApiRepository;