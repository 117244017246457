import './card.css'

import React from 'react';
import parse from 'html-react-parser';
import { NoContentMessage } from '../NoContentMessage';

export const CardListEmpty = (
    {
        text = ""
    }) => {
        

    return (
        <NoContentMessage message={parse(text)}/>
    );

};
