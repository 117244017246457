import "./quaymanual.css"

import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import { publishHeaderTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";

const QuayStations = () => {

    const [stations, setStations] = useState([]);
    const {department} = useParams();

    const initializeAsync = async () => {
        var response = await QuayManualApiRepository.getPilotStation(department);
        if(response.ok)
        {
            var result = await response.json();
            setStations(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        }
    };

    useEffect(() => {
        publishHeaderTopic("Kaibok");
        initializeAsync();
    }, []);

    return (
        <ContentGrid title="Kaibok" titleIcon="book">
            <div className="quaymanual">
                {stations.map((s) => (
                    <NavLink key={s.departmentID} to={`/pilot/quaymanual/${department}/${s.departmentID}`}><span className="link-text">{s.departmentName}</span></NavLink>
                ))}
            </div>
        </ContentGrid>
    );


};

export default QuayStations;
