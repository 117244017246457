import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";

function DropDownListFieldWrapper({
    title = "",
    adaptiveTitle = "",
    disabled = false,
    onChange = null,
    data = [],
    dataItemKey = "",
    textField = "",
    value = null,
    boldTitle = false,
    adaptive = false
}) {

    return (
        <FieldWrapper>
            <Label className="k-form-label" style={{
                fontWeight: boldTitle ? "500" : "normal"
            }}>
                {parse(title)}:
            </Label>
            <div className="k-form-field-wrap">
                <DropDownList
                    disabled={disabled}
                    data={data}
                    dataItemKey={dataItemKey}
                    textField={textField}
                    adaptive={adaptive}
                    adaptiveTitle={adaptiveTitle}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </FieldWrapper>
    );
}

export default DropDownListFieldWrapper; 