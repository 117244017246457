import React, { useEffect, useState } from 'react';

import {
    IconColors,
} from '../../../../../services/SystemNames';
import EvaluationService from '../../../services/EvaluationService';
import Spacer from '../../../../../components/layout/Spacer';
import "../../../styles/evaluation.css"
import Icons from '../../../../../components/layout/icons/Icons';
import Slide from '../../../../../components/layout/Slide';
import { TextArea } from "@progress/kendo-react-inputs";
import { isArrayEmpty, isNullOrEmpty } from '../../../../../components/helpers/ObjectHelpers';
import RadioButton from '../../../../../components/layout/RadioButton';
import { EvaluationAlternativeTypes, EvaluationAssesstmentStepStates } from '../../../services/EvaluationHelpers';

export default function AssessmentStep(
    {
        parent = "",
        child = "",
        showIncomplete = false,
        state = EvaluationAssesstmentStepStates.Default,
        step,
        onUpdateAlternative
    }
) {
    const [isIncomplete, setIsIncomplete] = useState(true);
    const [canComment, setCanComment] = useState(false);
    const [currentSelectedAlternative, setCurrentSelectedAlternative] = useState(0);
    const [currentComment, setCurrentComment] = useState(step.comment);
    const [alternatives, setAlternatives] = useState([]);

    useEffect(() => {

        setAlternatives(getAlternatives(step));

        const alternative = getSelectedAlternative(step);
        setCurrentSelectedAlternative(alternative);
        setIsIncomplete(alternative === 0 || alternative === null);
        setCanComment(isNullOrEmpty(step.comment) === false);

    }, [step]);

    return (
        <div className={`${(isIncomplete && showIncomplete) ? "evaluation-form-section-question-incomplete" : ""}`}>
            <Spacer height={20} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p>
                            {step.text}
                        </p>
                    </div>
                </div>
            </div>
            <Spacer height={10} />
            <div className="container">
                <div className="row g-2">
                    <AssessmentStepAlternatives
                        state={state}
                        onClick={onSelectAlternative}
                        alternatives={alternatives}
                        currentSelectedAlternative={currentSelectedAlternative}
                    />
                </div>
            </div>
            <Spacer height={30} />
            <div className="container">
                <div className="row">
                    <div className="col"/>
                    <div className="col-auto">
                        <div className="evaluation-listitem-toggle-area pointer" onClick={() => setCanComment(!canComment)}>
                            <div>
                                {canComment ? "LUKK" : "KOMMENTARER"}
                            </div>
                            <div>
                                <Icons color={IconColors.Active} iconName={canComment ? "chevronUp" : "chevronDown"} dimensions={24} />
                            </div>
                        </div>
                    </div>
                </div>
                <Slide show={canComment}>
                    <div className="row">
                        <div className="col">
                            <TextArea
                                cols={3}
                                value={currentComment}
                                onChange={onCommentChange}
                                autoSize={true}
                            />
                        </div>
                    </div>
                </Slide>
                <Spacer height={20} />
            </div>
            {
                (isIncomplete && showIncomplete) &&
                <div className="evaluation-form-section-question-incomplete-banner">
                    <div>
                        Ikke fullført
                    </div>
                </div>
            }
        </div>
    );

    function onSelectAlternative(alternative) {
        setIsIncomplete(false);
        setCurrentSelectedAlternative(alternative.systemName);
        onUpdateAlternative(parent, child, step, alternative);
    }

    function onCommentChange(e) {
        setCurrentComment(e.target.value);
        step.onUpdateComment(parent, child, e.target.value);
    }

    function getSelectedAlternative(step) {
        switch (step.alternativeType) {
            case EvaluationAlternativeTypes.Boolean:
                return EvaluationService.getYesNoAlternative(step.selectedAlternative);
            default:
                return step.selectedAlternative;
        }
    }

    function getAlternatives(step) {
        switch (step.alternativeType) {
            case EvaluationAlternativeTypes.Boolean:
                return EvaluationService.getAssesmentAlternativesYesNo();
            case EvaluationAlternativeTypes.Level:
                return EvaluationService.getAssesmentAlternativesLevel();
            case EvaluationAlternativeTypes.LevelWithNotApplicable:
                return EvaluationService.getAssesmentAlternativesLevelWithNotApplicable();
            case EvaluationAlternativeTypes.CompleteType:
                return EvaluationService.getAssesmentAlternativesCompleteType();
            case EvaluationAlternativeTypes.DayNight:
                return EvaluationService.getAssesmentAlternativesDayNight();
            case EvaluationAlternativeTypes.RecommendationType:
                return EvaluationService.getAssesmentAlternativesRecommendationType();
            default:
                break;
        }
    }
}

const AssessmentStepAlternatives = ({ state, alternatives, onClick, currentSelectedAlternative }) => {
    if (state === EvaluationAssesstmentStepStates.CommentsOnly || isArrayEmpty(alternatives)) return;
    return (
        alternatives.map((alternative, index) =>
            <div
                key={index}
                className={`col-6 col-sm-4 pointer evaluation-form-alternative-${(index + 1)}`}>
                <RadioButton
                    onClick={() => onClick(alternative)}
                    label={alternative.text}
                    checked={currentSelectedAlternative === alternative.systemName}
                />
            </div>
        )
    );
}
