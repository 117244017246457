import React from 'react';
import BottomNavigation from '../../../components/layout/BottomNavigation';
import { useNavigate } from 'react-router-dom';

export default function WorkTimeBottomNavigation({ id }) {

    const navigate = useNavigate();

    return (
        <BottomNavigation
            onCallback={onClick}
            selectedAction={`${id}`}
            items={getNavItems()}
        />
    )

    function onClick(e) {
        
        switch (e.action) {
            case "register":
                navigate('/worktime/register');
                break;
            case "future":
                navigate('/worktime/future');
                break;
            case "simulate":
                navigate('/worktime/simulate');
                break;
            case "timeline":
                navigate('/worktime/timeline');
                break;
            default:
                return;
        }
    }

    function getNavItems() {
        const result = [
            {
                text: "Registrering",
                icon: "clock",
                action: "register"
            },
            {
                text: "De neste timene",
                icon: "information",
                action: "future"
            },
            {
                text: "Simulering",
                icon: "hourglass",
                action: "simulate"
            },
            {
                text: "Tidslinje",
                icon: "pulse",
                action: "timeline"
            },
        ];

        return result;
    }
}
