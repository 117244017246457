import React from 'react'
import { enablePush } from '../../../firebase'
import { checkLocalStorageForDeviceID } from './PushHelpers'
import PilotApiRepository from '../../../repositories/api/PilotApiRepository'
import { isSupported } from 'firebase/messaging'
import { Dialog } from '../../../components/layout/dialogs/Dialog'
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { Button } from "@progress/kendo-react-buttons";
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers'

function PushPermissionModal() {
    const shownAlready = localStorage.getItem("shownAlready");
    const [show, setShow] = React.useState(false);
    const checkPermission = window?.Notification?.permission;

    React.useEffect(() => {
        isSupported().then((result) => {
            if (result) {
                if (checkPermission !== "granted" && !shownAlready) {
                    setShow(true);
                }
            }
        })
    }, [checkPermission, shownAlready])

    function closeModal() {
        localStorage.setItem("shownAlready", true);
        setShow(false);
    }

    async function allowPush() {
        const deviceId = checkLocalStorageForDeviceID();
        if (!isNullOrEmpty(deviceId)) {
            await PilotApiRepository.refreshPushSubscriptionsAsync(deviceId);
        } else {
            const response = await PilotApiRepository.registerDeviceAsync()
            if (response.ok) {
                const id = await response.json()
                localStorage.setItem("deviceId", Number(id))
            }
        }
        enablePush().then(() => closeModal())
    }

    const renderMessage = (type) => {

        switch (type) {
            case "denied": {
                return <>
                    <p>Du har takket nei til push-varsler i din nettleser.</p>
                    <p>For å motta push-varsler må dette aktiveres gjennom "innstillinger" i din nettleser</p>
                    <div>
                        <Button themeColor="primary" className={"lrg-btn grid-c-3"} onClick={() => closeModal()}>Abryvt</Button>
                    </div>
                </>
            }
            default: {
                return <>
                    <p>Du må tillate push varsler i din nettleser.</p>
                    <p>Etter godkjenning, må du via "innstillinger" velge hvilke varsler du vil abonnere på.</p>
                    <div className='btns-container'>
                        <Button themeColor="primary" className={"lrg-btn grid-c-2"} onClick={() => allowPush()}>Tillate Push</Button>
                        <Button themeColor="secondary" className={"lrg-btn grid-c-3"} onClick={() => closeModal()}>Ikke nå</Button>
                    </div>
                </>
            }
        }
    }

    return (
        <>
            {show && <Dialog
                properties={{
                    ...DialogProperties,
                    title: "Push Varsler Tillatelse",
                    className: "dialog-title",
                    onClose: closeModal,
                    size: "small"
                }}
            >
                {renderMessage(window?.Notification?.permission)}
            </Dialog>}
        </>
    )
}

export default PushPermissionModal
