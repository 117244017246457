import React, { useEffect, useState } from 'react';
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import { convertPilotAssignmentApiDtoToIdb, isPilotAssignmentAssignedAndNotProcessed } from '../pilot-assignment/helpers/PilotAssignmentHelpers';
import PilotageDetailConfirmPilotage from './PilotageDetailConfirmPilotage';

export default function PilotageDetailsAssignment({ pilotageId, updatePilotagePilot }) {

    const [data, setData] = useState(null);

    useEffect(() => {
        const getPilotagePilot = async () => {
            const result = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
            setData(() => ({
                pilotagePilot: result
            }));
        }
        getPilotagePilot();
    }, [updatePilotagePilot, pilotageId])

    return (
        <>
            {
                data && !isNullOrEmpty(data.pilotagePilot.convertToAvailableWorkTimePeriod) &&
                <Card properties={{
                    ...CardProperties,
                    title: "Arbeidstid"
                }}>
                    <div>
                        <p className="reject-pilotage-modal-text">
                            {data.pilotagePilot.convertToAvailableWorkTimePeriod}
                        </p>
                    </div>
                </Card>
            }
            {
                data && isPilotAssignmentAssignedAndNotProcessed(data.pilotagePilot) &&
                <PilotageDetailConfirmPilotage
                    state={data.pilotagePilot}
                    handleAssignmentStatus={handleUpdatePilotAssignmentAsync}
                />
            }
        </>
    )

    async function handleUpdatePilotAssignmentAsync(pilotStatus) {
        const response = await PilotApiRepository.getPilotageByIdAsync(data.pilotagePilot.pilotage.pilotageId);
        const dto = await response.json();
        const pilotagePilot = convertPilotAssignmentApiDtoToIdb(dto);

        updatePilotAssignmentColour(pilotStatus);
        updatePilotagePilot(pilotagePilot);
    }

    function updatePilotAssignmentColour(pilotStatus) {
        const index = data.pilotagePilot.pilotage.pilotagePilotsInformation.findIndex(pilot => pilot.pilotPersonId === data.pilotagePilot.pilotPersonId);
        data.pilotagePilot.pilotage.pilotagePilotsInformation[index].pilotagePilotStatus = pilotStatus;
    }
}