export const PageStates = {
    Add: 'Add',
    Boarding: 'Boarding',
    BoardingHelicopter: 'BoardingHelicopter',
    BoardingType: 'BoardingType',
    BoardingVessel: 'BoardingVessel',
    BridgeTime: 'BridgeTime',
    BridgeTimeDay: 'BridgeTimeDay',
    BridgeTimeNight: 'BridgeTimeNight',
    Busy: 'Busy',
    Confirm: 'Confirm',
    DateTime: 'DateTime',
    Default: 'Default',
    Delete: 'Delete',
    Deleting: 'Deleting',
    Deviation: 'Deviation',
    Edit: 'Edit',
    EditImage: 'EditImage',
    Error: 'Error',
    Executed: 'Executed',
    ExtraPay: 'ExtraPay',
    FetchFailed: 'FetchFailed',
    Harbours: 'Harbours',
    HourCompensation: 'HourCompensation',
    Invalid: 'Invalid',
    List: 'List',
    Location: 'Location',
    Map: 'Map',
    More: 'More',
    MissingData: 'MissingData',
    NoAccess: 'NoAccess',
    NotPilot: 'NotPilot',
    Observations: 'Observations',
    Offline: 'Offline',
    Overtime: 'Overtime',
    Pdf: 'Pdf',
    PilotAssignment: 'PilotAssignment',
    PilotType: 'PilotType',
    Receipt: 'Receipt',
    Reload: 'Reload',
    Remark: 'Remark',
    Responsibility: 'Responsibility',
    Retract: 'Retract',
    Search: 'Search',
    SearchLocations: 'SearchLocations',
    SelectNewImage: 'SelectNewImage',
    SelectType: 'SelectType',
    SelectTypeEdit: 'SelectTypeEdit',
    Ship: 'Ship',
    ValidateAccess: 'ValidateAccess',
    WaitingHoursReasonType: 'WaitingHoursReasonType',
    Welcome: 'Welcome',
    PilotStationSelector: 'PilotStationSelector',
    ExaminerPilotageHours: 'ExaminerPilotageHours',
}

export const EditStates = {
    Default: 'Default',
    Delete: 'Delete',
    Confirm: 'Confirm'
}

export const PersonTypes = {
    Pilot: 'PILOT',
    PilotMaster: 'PILOTMASTER'
}

export const LoginTypes = {
    Person: 'person'
}

export const Tokens = {
    Token: 'id_token'
}

export const ApplicationRightSystemNames = {
    NjordPilotWebLogin: 'NJORD_PILOT_WEB_LOGIN',
    EditPilotCoastalSegments: 'EDIT_PILOT_COASTAL_SEGMENTS',
    EditPilotCoastalSegmentsLocations: 'EDIT_PILOT_COASTAL_SEGMENTS_LOCATIONS',
    EditPilotCoastalSegmentsEvaluation: 'EDIT_PILOT_COASTAL_SEGMENTS_EVALUATION',
    PilotRotation: 'PILOT_ROTATION',
    EditShipObservations: 'EDIT_SHIP_OBSERVATIONS'
}

export const ApplicationRights = [
    ApplicationRightSystemNames.NjordPilotWebLogin,
    ApplicationRightSystemNames.EditPilotCoastalSegments,
    ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations,
    ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation,
    ApplicationRightSystemNames.PilotRotation,
    ApplicationRightSystemNames.EditShipObservations
]

export const MenuStates = {
    Maximized: 'Maximized',
    Minimized: 'Minimized'
}

export const NavItemTypes = {
    Default: 'DEFAULT',
    RefreshData: 'REFRESH_DATA',
    Login: 'LOGIN',
    Logout: 'LOGOUT',
    NewVersion: 'NEW_VERSION'
}

export const IconColors = {
    Primary: 'var(--icon-primary-fill)',
    Secondary: 'var(--icon-secondary-fill)',
    Active: 'var(--icon-active-fill)',
    InActive: 'var(--icon-inactive-fill)',
    Disabled: 'var(--icon-disabled-fill)',
    Error: 'var(--icon-error-fill)',
    Ship: 'var(--icon-ship-fill)',
    ShipDefault: 'var(--icon-ship-default-fill)',
    ShipPilotVessel: 'var(--icon-ship-pilot-vessel-fill)',
    ShipCargo: 'var(--icon-ship-cargo-fill)',
    ShipTanker: 'var(--icon-ship-tanker-fill)',
    ShipPassenger: 'var(--icon-ship-passenger-fill)',
    ShipFish: 'var(--icon-ship-fish-fill)',
    ShipTug: 'var(--icon-ship-tug-fill)',
    ShipAuxillary: 'var(--icon-ship-auxillary-fill)',
    Pin: 'var(--icon-pin-fill)',
    PinStart: 'var(--icon-pin-start-fill)',
    PinStop: 'var(--icon-pin-stop-fill)',
    PinLocation: 'var(--icon-pin-location-fill)',
    AssignmentConfirmed: 'var(--icon-assignment-confirmed-fill)',
    AssignmentAssigned: 'var(--icon-assignment-assigned-fill)'

}

export const LinkButtonTypes = {
    Danger: 'danger',
    Standard: 'standard'
}

export const Sizes = {
    Default: 'default',
    Small: 'small',
    Medium: 'medium',
    Large: 'large',
    XLarge: 'xlarge'
}

export const TabStates = {
    Default: 'Default',
    Advanced: 'Advanced',
    Reset: 'Reset'
}

export const Sections = {
    None: 'None',
    Default: 'Default',
    Search: 'Search'
}

export const ItemStates = {
    Default: 'Default',
    Edit: 'Edit',
    Delete: 'Delete'
}

export const MapStates = {
    Feature: 'Feature',
    Polygon: 'Polygon'
}

export const PilotRotationStates = {
    ReadOnly: 'READONLY',
    Edit: 'EDIT',
    Counter: 'COUNTER'
}

export const PilotRotationTypes = {
    Standard: 'STANDARD',
    Change: 'CHANGE'
}

export const WorkTimePeriodStates = {
    Default: 'Default',
    Edit: 'Edit',
    Delete: 'Delete'
}

export const WorkTimeStampTypes = {
    REALTIME: 'REALTIME',
    MANUAL_OVERRIDDEN: 'MANUAL_OVERRIDDEN',
    AUTO_GENERATED: 'AUTO_GENERATED'
}

export const HttpStatusCodes = {
    Ok: 200
}

export const PilotActualWorkTimeStateEnum = {
    SUCCESS: 'SUCCESS',
    NOT_PILOT: 'NOT_PILOT',
    MISSING_DATES: 'MISSING_DATES',
    MISSING_WORKTIME_PERIODS: 'MISSING_WORKTIME_PERIODS'
}

export const IndexedDbNames = {
    Database: 'PilotWebDB',
    MetaStore: 'MetaStore',
    LocationStore: 'LocationStore',
    PilotagePilotStore: 'PilotagePilotStore',
    PilotageStore: 'PilotageStore',
    DepartmentStore: 'DepartmentStore',
    EvaluationStore: 'EvaluationStore',
    PilotBoardingTypeStore: 'PilotBoardingTypeStore',
    PilotagePilotTypeStore: 'PilotagePilotTypeStore',
    ManeuverResponsibleTypeStore: 'ManeuverResponsibleTypeStore',
    HourCompensationTypeStore: 'HourCompensationTypeStore',
    VariableCompensationOverrideReasonTypeStore: 'VariableCompensationOverrideReasonTypeStore',
    PilotagePilotWaitingHoursReasonTypeStore: 'PilotagePilotWaitingHoursReasonTypeStore',
    SelectedPilotStationStore: 'SelectedPilotStationStore',
}

export const IndexedDbKeyNames = {
    Token: 'token',
    CacheLastExpireDate: 'CacheLastExpireDate',
    WhoAmI: 'WhoAmI',
    EvaluationForm: 'evaluation-form',
    EvaluationList: 'evaluation-list',
    DayTime: 'DayTime',
    Configuration: 'Configuration'
}

export const PilotCoastalSegmentGroupLocationTypes = {
    General: 'GENERAL',
    Special: 'SPECIAL'
}

export const SlideMenuTypes = {
    MainMenu: 'main-menu',
    Modal: 'modal'
}

export const HistoryTypes = {
    WorkTime: 'WORKING_TIME',
    RestTime: 'RESTING_TIME',
    AvailableTime: 'AVAILABLE',
    OvertimeTravelTime: 'ARRIVAL_OVERTIME_TRAVEL',
    Appointment: 'Appointment',
    Piloting: 'Piloting',
}

export const PilotAssignmentStatusEnums = {
    Assigned: 'ASSIGNED',
    Confirmed: 'CONFIRMED',
    Rejected: 'REJECTED',
    Not_Processed: 'NOT_PROCESSED',
    Completed: 'COMPLETED',
    CorrectionNeeded: 'CORRECTION_NEEDED'
}

export const PilotAssignmentTypeEnums = {
    SinglePiloting: "SINGLE_PILOTING",
    SequentialPiloting: "SEQUENTIAL_PILOTING",
    ParallelPiloting: "PARALLEL_PILOTING"
}

export const PilotBoardingTypeEnums = {
    None: "NONE",
    Helicopter: "HELICOPTER",
    PilotBoat: "PILOT_BOAT",
    NotApplicable: "NOT_APPLICABLE"
}

export const PilotagePilotVariableCompensationTypeEnums = {
    BoardingVessel:	"BOARDING_VESSEL",
    BoardingHelicopter:	"BOARDING_HELICOPTER",
    BridgeTimeDay: "BRIDGE_TIME_DAY",
    BridgeTimeNight: "BRIDGE_TIME_NIGHT",
    InconvenienceAllowance: "INCONVENIENCE_ALLOWANCE",
}

export const PilotagePilotVariableCompensationOverrideReasonTypeEnums = {
    Other: "OTHER",
    SequentialPiloting: "SEQUENTIAL_PILOTING",
    MultiplePilotagesWithinOneHour: "MULTIPLE_PILOTAGES_WITHIN_ONE_HOUR",
    SharingCabin: "SHARING_CABIN"
}

export const PilotAssignmentStatusColors = {
    Confirmed: "#EFCC65",
    Assigned: "#2d8696",
    Completed: "#33A271",
    Rejected: "#D34B5B",
    Tentative: "#889BAA",
    CorrectionNeeded: "#de6441",
    NotProcessed: ""
}
export const PilotageExemptionApplicationStatusEnum = {
    APPLIED: "APPLIED",
    DISMISSED: "DISMISSED",
    SENT_TO_PILOT_MASTER: "SENT_TO_PILOT_MASTER",
    CONSIDERED_EVIDENTLY_JUSTIFIABLE: "CONSIDERED_EVIDENTLY_JUSTIFIABLE",
    CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE: "CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE",
    GRANTED: "GRANTED",
    REJECTED: "REJECTED",
    VOYAGE_ENDED: "VOYAGE_ENDED"
}

export const PilotageExemptionApplicationStatusColors = {
    APPLIED: "#70808e",
    DISMISSED: "#d34b5b",
    SENT_TO_PILOT_MASTER: "#efcc65",
    CONSIDERED_EVIDENTLY_JUSTIFIABLE: "#efcc65",
    CONSIDERED_NOT_EVIDENTLY_JUSTIFIABLE: "#efcc65",
    GRANTED: "#33a271",
    REJECTED: "#d34b5b",
    VOYAGE_ENDED: "#d34b5b"
}

export const SearchResultSettings = {
    SearchDialogMaxWindowWidth: 670
}

export const RouteGuardStates = {
    Busy: 'Busy',
    Valid: 'Valid',
    NoAccess: 'NoAccess',
    Offline: 'Offline',
    Login: 'Login'
}

export const CompensationType = {
    HourCompensation: 'HourCompensation',
    Overtime: 'Overtime',
    PilotageHourCompensation: 'PilotageHourCompensation',
    PilotageOvertime: 'PilotageOvertime',
    VariableCompensation: 'VariableCompensation',
    PilotageCompensation: 'PilotageCompensation'
}
