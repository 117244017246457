import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VariableCompensationItem } from './VariableCompensationCard';
import { VariableCompensationBoardingDialog } from './dialogs/VariableCompensationBoardingDialog';
import { Card } from '../../../../components/layout/card/Card';
import { PilotagePilotVariableCompensationTypeEnums, PilotBoardingTypeEnums } from '../../../../services/SystemNames';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import { deepCopyObject, errorsContainsFromObject, isArrayEmpty, isNullOrEmpty, isNumeric, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { addUpdateVariableCompensationOverriddenNumberCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { getPilotAssignmentAsync, isPilotAssignmentCompensationEqual, isPilotAssignmentEditable } from '../helpers/PilotAssignmentHelpers';
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';

export const VariableCompensationBoardingCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }, 
        onLocationChanged() {
            initializeAsync();
        },
        onValidationChanged(codeStrings) {
            setBoardingVesselHasValidationError(errorsContainsFromObject(codeStrings, {
                BoardingVessel: PilotAssignmentErrorCodes.BoardingVessel,
            }));
            setHelicopterHasValidationError(errorsContainsFromObject(codeStrings, {
                BoardingHelicopter: PilotAssignmentErrorCodes.BoardingHelicopter,
            }));
        }
    }));

    const [hasBoardingVesselValidationError, setBoardingVesselHasValidationError] = useState(false);
    const [hasHelicopterValidationError, setHelicopterHasValidationError] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [boardingVessel, setBoardingVessel] = useState({});
    const [boardingHelicopter, setBoardingHelicopter] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [currentCompensation, setCurrentCompensation] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        initializeAsync();
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, initializeAsync);


        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Card
                properties={{
                    ...CardProperties,
                    title: "Bording / kvitting tillegg",
                    hasValidationError: (hasBoardingVesselValidationError || hasHelicopterValidationError),
                    isDirty: isDirty
                }}>
                <div className="row">
                    <VariableCompensationItem
                        title="Losb&aring;t"
                        hasValidationError={hasBoardingVesselValidationError}
                        isEditable={isEditable}
                        compensation={boardingVessel}
                        onEdit={onEditBoarding}
                        onDelete={onDeleteBoarding}
                        decimals={0}
                    />
                    <VariableCompensationItem
                        title="Helikopter"
                        hasValidationError={hasHelicopterValidationError}
                        isEditable={isEditable}
                        compensation={boardingHelicopter}
                        onEdit={onEditBoarding}
                        onDelete={onDeleteBoarding}
                        decimals={0}
                    />
                </div>
            </Card>

            {
                showDialog && 
                <VariableCompensationBoardingDialog
                    onClose={() => setShowDialog(false)}
                    compensation={currentCompensation}
                    callback={onBoardingCallback}
                />
            }
            
        </>
    )

    async function setAndPublishAsync(pilotAssignment) {
        await onUpdatePilotAssignmentAsync(pilotAssignment);
        initializeAsync();
    }

    async function initializeAsync() {

        const pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);
        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
        const calculated = getCalculatedBoardingTypes(pilotAssignment);
        
        setCompensation(pilotAssignment, setBoardingVessel, PilotagePilotVariableCompensationTypeEnums.BoardingVessel, calculated.calculatedPilotBoat);
        setCompensation(pilotAssignment, setBoardingHelicopter, PilotagePilotVariableCompensationTypeEnums.BoardingHelicopter, calculated.calculatedHelicopter);

        initializeIsDirty(pilotAssignment);
    }

    function initializeIsDirty(pilotAssignment) {
        const originalArr = pilotAssignment.original.variableCompensations;
        const updatedArr = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;

        let isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BOARDING_VESSEL");
        
        if (isEqual) {
            isEqual = isPilotAssignmentCompensationEqual(originalArr, updatedArr, "BOARDING_HELICOPTER");
        }

        setIsDirty(!isEqual);
    }

    function setCompensation(pilotAssignment, set, systemName, calculatedNumber) {
        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        if (isArrayEmpty(compensations)) return;

        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === systemName);

        if (isObjectNull(compensation)) return;

        compensation.calculatedNumber = calculatedNumber;
        
        set(compensation);
    }

    function onEditBoarding(compensation) {
        if (!isEditable) return;

        setCurrentCompensation(compensation);
        setShowDialog(true);
    }

    function onDeleteBoarding(compensation) {
        const compensationCopy = deepCopyObject(compensation);
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonRemark = null;
        compensationCopy.overridenNumber = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonTypeId = null;
        compensationCopy.pilotagePilotVariableCompensationOverrideReasonType = null;

        onBoardingCallback(compensationCopy);
    }

    function onBoardingCallback(compensation) {
        if (compensation.pilotagePilotVariableCompensationSystemName === PilotagePilotVariableCompensationTypeEnums.BoardingVessel) {
            setBoardingVessel(compensation);
        } else {
            setBoardingHelicopter(compensation);
        }

        onUpdateVariableCompensationOverriddenNumberAsync(compensation);
    }

    async function onUpdateVariableCompensationOverriddenNumberAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync(pilotAssignmentId);

        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
        const compensation = compensations.find(c => c.pilotagePilotVariableCompensationSystemName === obj.pilotagePilotVariableCompensationSystemName);
        const index = compensations.indexOf(compensation);

        compensations[index] = obj;

        pilotAssignment = addUpdateVariableCompensationOverriddenNumberCommand(pilotAssignment, obj);

        setAndPublishAsync(pilotAssignment);
    }

    function getCalculatedBoardingTypes(pilotAssignment) {

        const legs = [];
        for (let i = 0; i < pilotAssignment.locations.length - 1; i++) {
            legs.push({
                from: pilotAssignment.locations[i],
                to: pilotAssignment.locations[i + 1]
            });
        }

        return {
            calculatedPilotBoat: getCalculatedByBoardingType(PilotBoardingTypeEnums.PilotBoat, legs),
            calculatedHelicopter: getCalculatedByBoardingType(PilotBoardingTypeEnums.Helicopter, legs)
        }
    }

    function getCalculatedByBoardingType(pilotBoardingType, legs) {

        if (isArrayEmpty(legs)) return 0;

        const onBoarding = legs.filter(l =>
            !isNullOrEmpty(l.from.fromTime) &&
            !l.from.isPilotBoardingTypeOffBoarding &&
            !isObjectNull(l.from.pilotBoardingType) &&
            l.from.pilotBoardingType.systemName === pilotBoardingType).length;

        let offBoarding = legs.filter(l =>
            !isNullOrEmpty(l.to.toTime) &&
            l.to.isPilotBoardingTypeOffBoarding &&
            !isObjectNull(l.to.pilotBoardingType) &&
            l.to.pilotBoardingType.systemName === pilotBoardingType).length;

        if (isNumeric(onBoarding) && !isNumeric(offBoarding)) {
            return onBoarding;
        }
        if (!isNumeric(onBoarding) && isNumeric(offBoarding)) {
            return offBoarding;
        }
        if (!isNumeric(onBoarding) && !isNumeric(offBoarding)) {
            return 0;
        }

        return onBoarding + offBoarding;
        
    }
    
})
