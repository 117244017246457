import moment from 'moment';
import React, { useState } from 'react';
import { AppointmentTypeEnum } from '../services/SystemNames';
import TimelineViewService from '../services/TimelineViewService';
import { PilotAssignmentStatusEnums } from '../../../../services/SystemNames';
import { useTimelineViewContext } from './TimelineViewContext';
import parse from 'html-react-parser'
import { useEffect } from 'react';
import { PilotageInfoDialog } from '../../dialogs/PilotageInfoDialog';

export function TimelineViewAppointments() {

    const { timelineViewType } = useTimelineViewContext();
    const [showModal, setShowModal] = useState(false);
    const [appointment, setAppointment] = useState({});
    const [adjustedAppointments, setAdjustedAppointments] = useState([]);
    const [pilotPilotages, setPilotPilotages] = useState([]);   
    const isPilotagePilot = pilotPilotages?.some(p => p.id === appointment.pilotageId);

    useEffect(() => {
        setPilotPilotages(timelineViewType.pilotPilotages);
        setAdjustedAppointments(TimelineViewService.adjustAppointments(timelineViewType));
    }, [timelineViewType]);

    return (
        <>
            {
                adjustedAppointments.map((appointment, index) => {
                    return <TimelineViewAppointment
                        key={index}
                        appointment={appointment}
                        calcHeight={calcHeight}
                        calcTop={calcTop}
                        onClick={onClick}
                    />
                })
            }

            {
                showModal &&
                <PilotageInfoDialog
                    onClose={() => setShowModal(false)}
                    pilotage={appointment}
                    isPilotagePilot={isPilotagePilot}
                />
            }

        </>
    )

    function calcHeight(time) {
        return TimelineViewService.calcHeight(moment(time.start), moment(time.stop));
    }

    function calcTop(time) {
        return TimelineViewService.calcHeight(moment(timelineViewType.fromTime), moment(time.start));
    }

    function onClick(appointment) {
        setAppointment(appointment);
        setShowModal(true);
    }
}

export function TimelineViewAppointment({ appointment, calcHeight, calcTop, onClick }) {

    return (
        <div onClick={() => onClick(appointment)} style=
            {{
                height: `${calcHeight(appointment)}px`,
                top: `${calcTop(appointment)}px`,
                right: `${calcRight()}px`
            }}
            className={`appointment ${getClassName()}`}>
            <div style=
                {{
                    width: `${calcHeight(appointment)}px`,
                    top: `${calcHeight(appointment)}px`,
                    height: `30px`
                }}>
                <div style={{
                    paddingLeft: `${calcPadding()}px`,
                }}>
                    {appointment.exceeds && `${parse("&#9664;")} `}
                </div>
                <div>
                    {appointment.title}
                </div>
            </div>
        </div>
    )

    function calcRight() {

        const extraMarginIfSequence = appointment.sequenceNo * 5;
        const defaultMarginRight = 20;
        const appointmentWidth = 30;

        return defaultMarginRight + (appointment.sequenceNo * appointmentWidth) + extraMarginIfSequence;
    }

    function calcPadding() {
        if (appointment.type !== AppointmentTypeEnum.Pilotage) return 0;
        return 10;
    }

    function getClassName() {
        switch (appointment.type) {
            case AppointmentTypeEnum.Calendar:
                return getCalendarClassName();
            case AppointmentTypeEnum.Pilotage:
                return getPilotageClassName();
            default:
                return "appointment-unknown";
        }
    }

    function getCalendarClassName() {
        if (appointment.attributes.isTentative) return 'appointment-calendar-tentative';

        return 'appointment-calendar';
    }

    function getPilotageClassName() {

        switch (appointment.attributes.status) {
            case PilotAssignmentStatusEnums.Assigned:
                return 'appointment-pilotage-assigned';
            case PilotAssignmentStatusEnums.Confirmed:
                return 'appointment-pilotage-confirmed';
            case PilotAssignmentStatusEnums.Rejected:
                return 'appointment-pilotage-rejected';
            case PilotAssignmentStatusEnums.Completed:
                return 'appointment-pilotage-completed';
            default:
                return 'appointment-pilotage-tentative';
        }
    }
}
