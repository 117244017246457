import React, { useEffect } from 'react';
import { useState } from 'react';
import Moment from 'moment';
import { PilotAssignmentCommands } from '../../../services/DispatcherActions';
import uuid from 'react-uuid';
import Spacer from '../../../../../components/layout/Spacer';
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import TextBoxFieldWrapper from '../../../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import DateTimePickerFieldWrapper from '../../../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import SwitchFieldWrapper from '../../../../../components/layout/fieldWrappers/SwitchFieldWrapper';
import TextAreaFieldWrapper from '../../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import { DateTimePickerModes } from '../../../../../components/layout/DateTimePicker';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from '../../../../../components/helpers/ObjectHelpers';
import { formatMoment } from '../../../../../components/helpers/DateTimeHelpers';
import { DialogForm } from '../../../../../components/layout/DialogForm';
import { Error } from '../../../../../components/layout/labels/Error';

const initialState = {
    isEditable: false,
    fromTime: null,
    toTime: null,
    isMinimumTime: false,
    isTwoThirds: false,
    description: "",
    hours: "",
    warnings: [],
    isDirty: false
}

export const OvertimeDialog = ({ onClose, overtime = null, callback, isOvertimeAvailable }) => {

    const [{
        isEditable,
        fromTime,
        toTime,
        isMinimumTime,
        isTwoThirds,
        description,
        hours,
        warnings,
        isDirty

    }, setState] = useState(initialState);

    const setStateValue = (property, e, isDirty = false) => {
        setState((prev) => ({
            ...prev,
            [property]: e,
            isDirty: isDirty
        }));
    }

    const setDescription = (e, isDirty = false) => {
        setStateValue("description", e, isDirty);
    }

    const setIsMinimumTime = (e, isDirty = false) => {
        setStateValue("isMinimumTime", e, isDirty);
    }

    const setIsTwoThirds = (e, isDirty = false) => {
        setStateValue("isTwoThirds", e, isDirty);
    }

    const setHours = (e, isDirty = false) => {
        setStateValue("hours", e, isDirty);
    }

    const setFromTime = (e, isDirty = false) => {
        setStateValue("fromTime", e, isDirty);
    }

    const setToTime = (e, isDirty = false) => {
        setStateValue("toTime", e, isDirty);
    }

    const setWarnings = (e) => {
        setStateValue("warnings", e);
    }

    const setIsEditable = (e) => {
        setStateValue("isEditable", e);
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isDirty) return;
        calculateHours();
        validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromTime, toTime, isMinimumTime, isTwoThirds, description]);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Overtid",
                onClose: onClose,
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClick,
                        disabled: !isEditable,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <DialogForm>
                {
                    !isOvertimeAvailable &&
                    <>
                        <Error>
                            <p>
                                Merk at losformidler verken har krysset av for overtid, reise overtid eller &gt; 12t arb tid.
                                Er det riktig &aring; rapportere overtid relatert til losoppdraget?
                            </p>
                        </Error>
                    </>
                }

                <DateTimePickerFieldWrapper
                    title="Fra dato"
                    mode={DateTimePickerModes.DateTime}
                    value={fromTime}
                    onChange={onFromTimeChange}
                />

                <Spacer height={20} />

                <DateTimePickerFieldWrapper
                    title="Til dato"
                    mode={DateTimePickerModes.DateTime}
                    value={toTime}
                    onChange={onToTimeChange}
                    warnings={warnings}
                />

                <Spacer height={20} />


                <SwitchFieldWrapper
                    title="Minimumstid 2t"
                    checked={isMinimumTime}
                    onChange={onMinimumTimeChange}
                />

                <Spacer height={20} />

                <SwitchFieldWrapper
                    title="2/3 timer"
                    checked={isTwoThirds}
                    onChange={onTwoThirdshange}
                />

                <Spacer height={20} />

                <TextBoxFieldWrapper
                    title="Antall"
                    value={hours}
                    disabled={true}
                    readOnly={true}
                />

                <Spacer height={20} />

                <TextAreaFieldWrapper
                    title="Beskrivelse"
                    rows={3}
                    value={description}
                    onChange={onDescriptionChange}
                />
            </DialogForm>
        </Dialog>
    )

    function initialize() {
        if (isObjectNull(overtime)) return;
        
        setDescription(overtime.description);
        setIsMinimumTime(overtime.isMinimum);
        setIsTwoThirds(overtime.isTwoThird);
        setHours(overtime.number);
        setFromTime(Moment(overtime.fromTime).toDate());
        setToTime(Moment(overtime.toTime).toDate());
    }

    function onSaveClick() {
        if (!isEditable) return;

        let compensation = {
            guid: uuid(),
            pilotagePilotOvertimeId: 0,
            command: PilotAssignmentCommands.AddPilotAssignmentOvertime
        };

        if (!isObjectNull(overtime)) {
            compensation = deepCopyObject(overtime);
            compensation.command = PilotAssignmentCommands.AddPilotAssignmentOvertime;
            compensation.pilotagePilotOvertimeId = overtime.pilotagePilotOvertimeId;

            if (compensation.hasOwnProperty("pilotagePilotOvertimeId")) {
                if (compensation.pilotagePilotOvertimeId > 0) {
                    compensation.command = PilotAssignmentCommands.UpdatePilotAssignmentOvertime;
                }
            }

            if (isNullOrEmpty(compensation.guid)) {
                compensation.guid = uuid();
            }
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        compensation.fromTime = formatMoment(from);
        compensation.toTime = formatMoment(to);
        compensation.isMinimum = isMinimumTime;
        compensation.isTwoThird = isTwoThirds;
        compensation.number = Number(hours);
        compensation.description = description;
        
        callback(compensation);
        onClose();
    }

    function onDescriptionChange(e) {
        setDescription(e.value, true);
    }

    function onFromTimeChange(e) {
        setFromTime(e.value, true);
    }

    function onToTimeChange(e) {
        setToTime(e.value, true);
    }

    function onMinimumTimeChange(e) {
        setIsMinimumTime(e.target.value, true);
    }

    function onTwoThirdshange(e) {
        setIsTwoThirds(e.target.value, true);
    }

    function calculateHours() {

        const warnings = [];

        if (isObjectNull(fromTime) || isObjectNull(toTime)) {
            if (isNullOrEmpty(fromTime)) {
                warnings.push("Fra-dato kan ikke v&aelig;re tom.");
            }
            if (isNullOrEmpty(toTime)) {
                warnings.push("Til-dato kan ikke v&aelig;re tom.");
            }

            setHours("");
            setWarnings(warnings);
            return;
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        if (from >= to) {
            warnings.push("Til-dato kan ikke v&aelig;re f&oslash;r fra-dato.")
        }

        const diff = to.diff(from, 'minutes');
        let hours = (diff / 60);

        if (isMinimumTime) {
            hours = 2;
        }
        if (isTwoThirds) {
            hours = (hours / 3) * 2;
        }

        setHours(hours.toFixed(2));
        setWarnings(warnings);
    }

    function validate() {
        let editable = !isNullOrEmpty(description) && !isNullOrEmpty(fromTime) && !isNullOrEmpty(toTime);
        if (editable) {
            const fromMoment = Moment(fromTime);
            const toMoment = Moment(toTime);
            editable = fromMoment < toMoment;
        }
        setIsEditable(editable);
    }
}
