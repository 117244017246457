import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { PilotAssignmentCommands } from '../../services/DispatcherActions';
import PubSub from 'pubsub-js';
import PilotAssignmentIdbRepository from '../../../../repositories/idb/PilotAssignmentIdbRepository';
import { OvertimeDialog } from './dialogs/OvertimeDialog';
import uuid from 'react-uuid';
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { CardModes, CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import TitleContainer from '../../../../components/layout/TitleContainer';
import { CardList } from '../../../../components/layout/card/CardList';
import { formatStringToList, isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';
import { formatMomentToDefault } from '../../../../components/helpers/DateTimeHelpers';
import { addDeletePilotAssignmentOvertimeCommand, addPilotAssignmentOvertimeCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { getPilotAssignmentCompensationId, getPilotAssignmentFilteredCompensations, isPilotAssignmentEditable, toggleSelectedItem } from '../helpers/PilotAssignmentHelpers';

export const OvertimeCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        },
    }));

    const [isEditable, setIsEditable] = useState(false);
    const [compensation, setCompensation] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [isOvertimeAvailable, setIsOvertimeAvailable] = useState(false);
    const [cards, setCards] = useState([]);
    const [toggleActivateSelect, setToggleActivateSelect] = useState(false);
    const [selectedCompensations, setSelectedCompensations] = useState([]);
    
    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, handlePubSubTopic);

        initializeAsync();

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TitleContainer
                title="Overtid"
                error={toggleActivateSelect && selectedCompensations.some(c => c.selected)}
                actions={getTitleActions()}
            />

            <CardList
                mode={CardModes.Multible}
                cards={cards}
                toggleActivateSelect={setToggleActivateSelect}
                toggleSelectItem={toggleSelectItem}
                disabled={!isEditable}
            />

            {
                showDialog &&
                <OvertimeDialog
                    onClose={() => setShowDialog(false)}
                    overtime={compensation}
                    isOvertimeAvailable={isOvertimeAvailable}
                    callback={onCallbackAsync}
                />
            }
            
            {
                showConfirmationDialog && 
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Slett overtid",
                        text: "&Oslash;nsker du &aring; slette overtiden?",
                        onClose: () => setShowConfirmationDialog(false),
                        onClick: onConfirmCallbackAsync
                    }}
                     />
            }
            
        </>
    )

    function getTitleActions() {
        const actions = [];

        if (isEditable) {
            if (toggleActivateSelect) {
                actions.push(
                    {
                        icon: "trash",
                        onClick: onDeleteSelectedClick,
                        iconColor: "white"
                    });
            } else {
                actions.push(
                    {
                        icon: "plus",
                        onClick: () => {
                            setCompensation(null);
                            setShowDialog(true);
                        },
                        iconColor: "white"
                    });
            }
        }
        
        return actions;
    }

    async function getPilotAssignmentAsync() {
        return await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
    }

    async function setAndPublishAsync(pilotAssignment) {
        await onUpdatePilotAssignmentAsync(pilotAssignment);
        initializeAsync();
    }

    function handlePubSubTopic() {
        initializeAsync();
    }

    async function initializeAsync() {
        
        const pilotAssignment = await getPilotAssignmentAsync();
        const isEditable = isPilotAssignmentEditable(pilotAssignment);
        setIsEditable(isEditable);
        setIsOvertimeAvailable(pilotAssignment.isOvertimeAvailable);

        const result = [];

        if (!isObjectNull(pilotAssignment.pilotagePilotCompensation)) {
            if (!isObjectNull(pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes)) {
                pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes.map((compensation) =>
                    result.push({
                        guid: uuid(),
                        properties: {
                            ...CardProperties,
                            id: getPilotAssignmentCompensationId(compensation, "pilotagePilotOvertimeId"),
                            sourceId: getPilotAssignmentCompensationId(compensation, "pilotagePilotOvertimeId"),
                            isDirty: compensation.pilotagePilotOvertimeId === 0,
                            title: getCompensationTimeDescription(compensation),
                            titleClassName: "card-item-content-header-title-small",
                            isSwipeEnabled: isEditable,
                            mode: CardModes.Multible,
                            borderColor: "var(--card-swipe-border)",
                            backgroundColor: "var(--card-swipe-bg)",
                            onDeleteClick: isEditable ? () => onDeleteClick(compensation) : null,
                            onClick: isEditable ? () => {
                                setCompensation(compensation);
                                setShowDialog(true);
                            } : null
                        },
                        body: <OvertimeContent compensation={compensation} />
                    }));
            }
        }

        setCards(result);
    }

    function onDeleteClick(compensation) {
        setCompensation(compensation);
        setShowConfirmationDialog(true);
    }
    
    function toggleSelectItem(id, selected) {
        const result = toggleSelectedItem(selectedCompensations, id, selected);
        setSelectedCompensations(result);
    }

    function onDeleteSelectedClick() {
        setCompensation(null);
        setShowConfirmationDialog(true);
    }

    async function onCallbackAsync(obj) {

        switch (obj.command) {
            case PilotAssignmentCommands.AddPilotAssignmentOvertime:
            case PilotAssignmentCommands.UpdatePilotAssignmentOvertime:
                await onPilotAssignmentOvertimeAsync(obj);
                break;
            default:
                return;
        }
        setShowConfirmationDialog(false);
    }

    async function onConfirmCallbackAsync() {

        if (isNullOrEmpty(compensation)) {

            await onDeletePilotAssignmentOvertimesAsync();
        } else {
            await onDeletePilotAssignmentOvertimeAsync(compensation);
        }

        setSelectedCompensations([]);
        setToggleActivateSelect(false);
        setShowConfirmationDialog(false);        
    }

    async function onPilotAssignmentOvertimeAsync(obj) {

        let pilotAssignment = await getPilotAssignmentAsync();
        const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes;
        let pilotagePilotOvertimeId = 0

        switch (obj.command) {
            case PilotAssignmentCommands.AddPilotAssignmentOvertime:
                const addCompensation = compensations.find(c => c.guid === obj.guid);
                if (!isObjectNull(addCompensation)) {
                    const addIndex = compensations.indexOf(addCompensation);
                    compensations[addIndex] = obj;
                } else {
                    compensations.push(obj);
                }
                break;

            default:
                const updateCompensation = compensations.find(c => c.pilotagePilotOvertimeId === obj.pilotagePilotOvertimeId);
                const updateIndex = compensations.indexOf(updateCompensation);

                compensations[updateIndex] = obj;

                pilotagePilotOvertimeId = obj.pilotagePilotOvertimeId;

                break;
        }
        
        pilotAssignment = addPilotAssignmentOvertimeCommand(pilotAssignment, obj, pilotagePilotOvertimeId);

        await setAndPublishAsync(pilotAssignment);
    }

    async function onDeletePilotAssignmentOvertimeAsync(obj) {
        
        let pilotAssignment = await getPilotAssignmentAsync();
        const compensations = [...pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes];

        pilotAssignment = processDeleteOvertime(pilotAssignment, compensations, obj);

        await setAndPublishAsync(pilotAssignment);
    }

    async function onDeletePilotAssignmentOvertimesAsync() {

        let pilotAssignment = await getPilotAssignmentAsync();
                
        for (let i = 0; i < selectedCompensations.length; i++) {
            const selectedCompensation = selectedCompensations[i];
            if (selectedCompensation.selected) {

                const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes;
                let objs = compensations
                    .filter(c =>
                        c.pilotagePilotOvertimeId === selectedCompensation.id ||
                        c.guid === selectedCompensation.id);

                if (!isArrayEmpty(objs)) {
                    pilotAssignment = processDeleteOvertime(pilotAssignment, compensations, objs[0]);
                }
            }
        }
        await setAndPublishAsync(pilotAssignment);
    }

    function processDeleteOvertime(pilotAssignment, compensations, obj) {

        pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes = 
            getPilotAssignmentFilteredCompensations(compensations, obj, "pilotagePilotOvertimeId");

        return addDeletePilotAssignmentOvertimeCommand(pilotAssignment, obj);
    }

    function getCompensationTimeDescription(compensation) {
        return `${formatMomentToDefault(compensation.fromTime)} &rarr; ${formatMomentToDefault(compensation.toTime)}`
    }
})

const OvertimeContent = ({ compensation }) => {
    
    return (
        <>
            <div className="row align-items-center">
                <div className="col">
                    {compensation.number} timer
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <i>{formatStringToList(compensation.description)}</i>
                </div>
            </div>
        </>
    );
};
