import { isObjectNull } from "../../../components/helpers/ObjectHelpers";

export const NoticeTimeService = {
    noticeTypes: {
        group: "GROUP",
        location: "LOCATION",
        general: "GENERAL"
    },

    isOverridden(item) {
        if (isObjectNull(item)) return false;
        return item.type === this.noticeTypes.group || item.type === this.noticeTypes.location;
    }
}