import React from 'react';
import {
    Button
} from "@progress/kendo-react-buttons";
import parse from 'html-react-parser';
import Icons from '../../icons/Icons';
import { isBoolean, isNullOrEmpty } from '../../../helpers/ObjectHelpers';

export const DialogActions = ({ properties = {} }) => {
    return (
        properties.actions.map((action, index) =>
            <Button
                key={index}
                disabled={isBoolean(action.disabled) ? action.disabled : false }
                onClick={action.onClick}
                themeColor={isNullOrEmpty(action.themeColor) ? "base" : action.themeColor}>
                {
                    (!isNullOrEmpty(action.icon)) ?
                        <div className="dialog-action-button">
                            <div>
                                <Icons iconName={action.icon} dimensions={20} />
                            </div>
                            <div>
                                {parse(action.text)}
                            </div>
                        </div>
                        :
                        parse(action.text)
                }
            </Button>
        )
    )
}
