import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ValidateAccess from '../access/ValidateAccess';
import NoAccess from '../access/NoAccess';
import { isBoolean } from '../helpers/ObjectHelpers';
import { isBetaUser } from '../helpers/AuthHelpers';
import { RouteGuardStates } from '../../services/SystemNames';

export const RouteGuardDefault = ({ beta: Beta }) => {

    const location = useLocation();
    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    useEffect(() => {
        let flag = true
        if (isBoolean(Beta)) {
            if (Beta) {
                flag = isBetaUser();
            }
        }
        setGuardState(flag ? RouteGuardStates.Valid : RouteGuardStates.Login);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        (() => {
            switch (guardState)
            {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                default:
                    return (<ValidateAccess />);
            }
        })()
    )

};
