import React, { useEffect, useState } from 'react';
import Spacer from '../../../../../components/layout/Spacer';
import { Dialog } from '../../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../../components/layout/dialogs/DialogProperties';
import PilotApiRepository from '../../../../../repositories/api/PilotApiRepository';
import { isArrayEmpty, isNumeric } from '../../../../../components/helpers/ObjectHelpers';
import { formatMomentToDefault } from '../../../../../components/helpers/DateTimeHelpers';
import { Card } from '../../../../../components/layout/card/Card';
import { CardProperties } from '../../../../../components/layout/card/components/CardProperties';

export const ReceiptDialog = ({ onClose, onGoBack, pilotageId, showDescription = true, actionText=""}) => {

    const [variableCompensations, setVariableCompensations] = useState([]);
    const [overtimes, setOvertimes] = useState([]);
    const [hourCompensations, setHourCompensations] = useState([]);

    useEffect(() => {

        async function initializeAsync() {
            const response = await PilotApiRepository.getPilotageByIdAsync(pilotageId);
            if (response.ok) {
                const pilotAssignment = await response.json();

                const compensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotVariableCompensations;
                if (!isArrayEmpty(compensations)) {
                    setVariableCompensations(compensations);
                }

                const overtimes = pilotAssignment.pilotagePilotCompensation.pilotagePilotOvertimes;
                if (!isArrayEmpty(overtimes)) {
                    setOvertimes(overtimes);
                }

                const hourCompensations = pilotAssignment.pilotagePilotCompensation.pilotagePilotHourCompensations;
                if (!isArrayEmpty(hourCompensations)) {
                    setHourCompensations(hourCompensations);
                }
            }
        }

        initializeAsync();
    }, [])

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Skipperbevis kvittering",
                onClose: onClose,
                actions: [
                    {
                        onClick: onGoBack,
                        themeColor: "primary",
                        icon: "ship",
                        text: actionText
                    }
                ]
            }}>
            <div className="dialog-form">

                {
                    !isArrayEmpty(variableCompensations) &&
                    <>
                        <Card properties={{
                            ...CardProperties,
                            title: "Variable tillegg"
                        } }>
                                {
                                    variableCompensations
                                        .filter(c => c.pilotagePilotVariableCompensationSystemName !== "INCONVENIENCE_ALLOWANCE")
                                        .map((compensation, index) => 
                                        <div className="row" key={index}>
                                            <div className="col text-truncate">
                                                {compensation.pilotagePilotVariableCompensationName}
                                            </div>
                                            <div className="col-auto">
                                                {formatNumber(getCompensationNumber(compensation), compensation.pilotagePilotVariableCompensationSystemName)}
                                            </div>
                                        </div>
                                    )
                                }
                        </Card>

                        <Spacer height={15} />
                    </>
                }

                {
                    !isArrayEmpty(overtimes) && 
                    <>
                        <Card properties={{
                            ...CardProperties,
                            title: "Overtid 100%"
                        } }>
                            {
                                overtimes.map((overtime, index) => 
                                    <div className="row" key={index}>
                                        <div className="col">
                                            {formatMomentToDefault(overtime.fromTime)} - {formatMomentToDefault(overtime.toTime)}
                                        </div>
                                        <div className="col-auto">
                                            {overtime.number}
                                        </div>
                                    </div>
                                )
                            }
                        </Card>

                        <Spacer height={15} />
                    </>
                }
                
                {
                    !isArrayEmpty(hourCompensations) &&
                    <>
                        <Card properties={{
                            ...CardProperties,
                            title: "C-tillegg"
                        } }>
                            {
                                hourCompensations.map((compensation, index) =>
                                    <div className="row" key={index}>
                                        <div className="col">
                                            {formatMomentToDefault(compensation.fromTime)} - {formatMomentToDefault(compensation.toTime)}
                                        </div>
                                        <div className="col-auto">
                                            {compensation.number}
                                        </div>
                                    </div>    
                                )
                            }                                
                        </Card>

                        <Spacer height={15} />
                    </>
                }

                {showDescription && 
                    <div className="row">
                        <div className="col">
                            Korrigeringen kan gj&oslash;res innen de neste 15 minuttene.
                            For &aring; korrigere: Fjern "Utf&oslash;rt"-krysset og send inn skipperbeviset p&aring; nytt.
                        </div>
                    </div>
                }  
                <Spacer height={15} />
            </div>
        </Dialog>
    )

    function getCompensationNumber(compensation) {

        if (!isNumeric(compensation.overridenNumber)) return compensation.calculatedNumber;

        return compensation.overridenNumber;
    }

    function formatNumber(number, systemName = "") {
        if (!isNumeric(number)) return "0";
        const fixed = (systemName === "BOARDING_VESSEL" || systemName === "BOARDING_HELICOPTER") ? 0 : 2;
        return number.toFixed(fixed);
    }
}
