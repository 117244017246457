import { IndexedDbNames } from '../../services/SystemNames';
import EvaluationIdbRepository from './EvaluationIdbRepository';
import PilotAssignmentIdbRepository from './PilotAssignmentIdbRepository';

const BaseIdbRepository = {

    async isDatabaseValidAsync() {
        let result = true;
        let db = await this.promiseRequestAsync(this.getDatabase());
        
        for (const prop in IndexedDbNames) {
            if (IndexedDbNames[prop] === IndexedDbNames.Database) continue;
            if (!db.objectStoreNames.contains(IndexedDbNames[prop])) {
                result = false;
                break;
            }
        }

        db.close();

        return result;
    },

    getDatabase() {
        return window.indexedDB.open(IndexedDbNames.Database, 7);
    },

    async deleteDatabaseAsync() {
        await this.promiseRequestAsync(window.indexedDB.deleteDatabase(IndexedDbNames.Database));
    },

    promiseRequestAsync(request) {
        return new Promise((resolve, reject) => {
            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
        });
    },

    init() {

        let db = null;
        let dbPromise = this.getDatabase();
        let objectStore = null;

        dbPromise.onerror = ('error', (err) => {
            console.warn(err);
        });

        dbPromise.onsuccess = (ev) => {
            db = ev.target.result;
        };

        // Every time you add a new store, or make changed to an existing store,
        // you have to up the version number in the function getDatabase()
        dbPromise.onupgradeneeded = async (ev) => {
            db = ev.target.result;

            if (!db.objectStoreNames.contains(IndexedDbNames.MetaStore)) {
                db.createObjectStore(IndexedDbNames.MetaStore, {
                    keyPath: 'id'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.LocationStore)) {
                objectStore = db.createObjectStore(IndexedDbNames.LocationStore, {
                    keyPath: 'locationId'
                });

                objectStore.createIndex('nameIDX', 'name', { unique: false });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.PilotagePilotStore)) {
                db.createObjectStore(IndexedDbNames.PilotagePilotStore, {
                    keyPath: 'pilotagePilotId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.PilotageStore)) {
                db.createObjectStore(IndexedDbNames.PilotageStore, {
                    keyPath: 'pilotageId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.SelectedPilotStationStore)) {
                db.createObjectStore(IndexedDbNames.SelectedPilotStationStore, {
                    keyPath: 'id'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.DepartmentStore)) {
                objectStore = db.createObjectStore(IndexedDbNames.DepartmentStore, {
                    keyPath: 'departmentID'
                });

                objectStore.createIndex('displayNameIDX', 'displayName', { unique: false });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.EvaluationStore)) {
                db.createObjectStore(IndexedDbNames.EvaluationStore, {
                    keyPath: 'id'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.PilotBoardingTypeStore)) {
                db.createObjectStore(IndexedDbNames.PilotBoardingTypeStore, {
                    keyPath: 'pilotBoardingTypeId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.PilotagePilotTypeStore)) {
                db.createObjectStore(IndexedDbNames.PilotagePilotTypeStore, {
                    keyPath: 'pilotagePilotTypeId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.ManeuverResponsibleTypeStore)) {
                db.createObjectStore(IndexedDbNames.ManeuverResponsibleTypeStore, {
                    keyPath: 'maneuverResponsibleTypeId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.HourCompensationTypeStore)) {
                db.createObjectStore(IndexedDbNames.HourCompensationTypeStore, {
                    keyPath: 'pilotagePilotHourCompensationTypeId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.VariableCompensationOverrideReasonTypeStore)) {
                db.createObjectStore(IndexedDbNames.VariableCompensationOverrideReasonTypeStore, {
                    keyPath: 'pilotagePilotVariableCompensationOverrideReasonTypeId'
                });
            }

            if (!db.objectStoreNames.contains(IndexedDbNames.PilotagePilotWaitingHoursReasonTypeStore)) {
                db.createObjectStore(IndexedDbNames.PilotagePilotWaitingHoursReasonTypeStore, {
                    keyPath: 'pilotagePilotWaitingHoursReasonTypeId'
                });
            }

            await PilotAssignmentIdbRepository.deleteAllAsync();
            await EvaluationIdbRepository.deleteAllAsync();
        };
    },

    async setAsync(storeName, key, obj) {
        let result = await this.getAsync(storeName, key);
        
        if (result === undefined) {
            await this.addAsync(storeName, obj);
        } else {
            await this.putAsync(storeName, obj);
        }
    },

    async addAsync(storeName, obj) {

        let db = await this.promiseRequestAsync(this.getDatabase());

        if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            return;
        }

        let tx = this.makeTx(db, storeName, 'readwrite');
        let store = tx.objectStore(storeName);

        await this.promiseRequestAsync(store.add(obj))
            .catch((err) => { console.warn(err) });

        db.close();
    },

    async putAsync(storeName, obj) {
        let db = await this.promiseRequestAsync(this.getDatabase());

        if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            return;
        }

        let tx = this.makeTx(db, storeName, 'readwrite');
        let store = tx.objectStore(storeName);

        await this.promiseRequestAsync(store.put(obj))
            .catch((err) => { console.warn(err) });

        db.close();
    },

    async deleteAsync(storeName, key) {
        let db = await this.promiseRequestAsync(this.getDatabase());

        if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            return;
        }

        let tx = this.makeTx(db, storeName, 'readwrite');
        let store = tx.objectStore(storeName);

        await this.promiseRequestAsync(store.delete(key))
            .catch((err) => { console.warn(err) });

        db.close();
    },

    async getAllAsync(storeName) {
        let result = null;
        let db = await this.promiseRequestAsync(this.getDatabase());

        if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            return result;
        }

        let tx = this.makeTx(db, storeName, 'readonly');
        let store = tx.objectStore(storeName);

        await this.promiseRequestAsync(store.getAll())
            .then((res) => { result = res; })
            .catch((err) => { console.warn(err) });

        db.close();

        return result;
    },

    async getAsync(storeName, key) {

        let result = null;
        let db = await this.promiseRequestAsync(this.getDatabase());

        if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            return result;
        }

        let tx = this.makeTx(db, storeName, 'readonly');
        let store = tx.objectStore(storeName);

        await this.promiseRequestAsync(store.get(key))
            .then((res) => { result = res; })
            .catch((err) => { console.warn(err) });

        db.close();

        return result;
    },

    async clearAsync(storeName) {

        let result = null;
        let db = await this.promiseRequestAsync(this.getDatabase());

        if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            return result;
        }

        let tx = this.makeTx(db, storeName, 'readwrite');
        let store = tx.objectStore(storeName);

        await this.promiseRequestAsync(store.clear())
            .then((res) => { result = res; })
            .catch((err) => { console.warn(err) });

        db.close();

        return result;
    },

    makeTx(db, storeName, mode) {
        let tx = db.transaction(storeName, mode);
        tx.onerror = (err) => {
            console.warn(err);
        }

        return tx;
    }
};

export default BaseIdbRepository;