import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.HourCompensationTypeStore;

const HourCompensationTypeIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(obj) {
        await BaseIdbRepository.setAsync(Store, obj.pilotagePilotHourCompensationTypeId, obj);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },
    
};

export default HourCompensationTypeIdbRepository;