/*eslint unicode-bom: ["error", "always"]*/
import React, { useState, useEffect } from 'react';
import Spacer from '../../../../../components/layout/Spacer';
import EvaluationService from '../../../services/EvaluationService';
import AssessmentStep from './AssessmentStep';
import "../../../styles/evaluation.css"
import PanelBar from '../../../../../components/layout/PanelBar';
import { EvaluationAlternativeTypes } from '../../../services/EvaluationHelpers';

function Subject(
    {
        showIncomplete = false,
        subject = {
            isInitialized: 0,
            steps: []
        }
    }
) {

    const totalSteps = subject.steps.length;

    const [stepsCompleted, setStepsCompleted] = useState(0);

    useEffect(() => {
        setStepsCompleted(subject.initialStepsCompleted);
    }, [subject.initialStepsCompleted]);

    return (
        <>
            <PanelBar
                title={subject.title}
                subTitle={`${stepsCompleted}/${totalSteps}`}
                className="evaluation-panelbar"
                subClassName={`font-${(stepsCompleted === totalSteps) ? 'active' : showIncomplete ? 'error' : 'teritary'}`}
                expanded={showIncomplete}
            >
                <div>
                    {
                        subject.steps.map((step, index) =>
                            <AssessmentStep
                                key={index}
                                parent={step.parent}
                                child={step.child}
                                showIncomplete={showIncomplete}
                                step={step}
                                onUpdateAlternative={onUpdateAlternative}
                                onUpdateComment={step.onUpdateComment}
                                question={step.text}
                                alternatives={getAlternatives(step)}
                                selectedAlternative={getSelectedAlternative(step)}
                                comment={step.comment}
                            />
                        )
                    }
                </div>
            </PanelBar>
            <Spacer height={10}/>
        </>
    );

    function onUpdateAlternative(parent, child, step, alternative) {
        const newStepsCompleted = getStepsCompleted(step);
        
        setStepsCompleted(newStepsCompleted);
        
        step.selectedAlternative = alternative.systemName;
        step.onUpdateAlternative(parent, child, alternative, newStepsCompleted === totalSteps);
    }

    function getAlternatives(step) {
        switch (step.alternativeType) {
            case EvaluationAlternativeTypes.Boolean:
                return EvaluationService.getAssesmentAlternativesYesNo();
            case EvaluationAlternativeTypes.LevelWithNotApplicable:
                return EvaluationService.getAssesmentAlternativesLevelWithNotApplicable
            default:
                break;
        }
    }

    function getSelectedAlternative(step) {
        switch (step.alternativeType) {
            case EvaluationAlternativeTypes.Boolean:
                return EvaluationService.getYesNoAlternative(step.selectedAlternative)
            default:
                break;
        }
    }

    function getStepsCompleted(step) {
        if ((step.selectedAlternative !== null && step.selectedAlternative > 0) || stepsCompleted === totalSteps) return stepsCompleted;
        return stepsCompleted + 1;
    }
}

export default Subject;
