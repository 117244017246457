import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import Moment from 'moment';
import { Card } from '../../../../components/layout/card/Card';
import { CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { isNullOrEmpty } from '../../../../components/helpers/ObjectHelpers';
import { getPilotAssignmentFromAndToTime } from '../helpers/PilotAssignmentHelpers';

const dispatcherActions = {
    Duration: 'Duration'
}

export const DurationCard = forwardRef((
    {
        locations,
        canSave = false,
        borderColor = "",
        backgroundColor = ""
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onLocationsChanged() {
            initialize();
        }
    }));

    const initialState = {
        duration: ""
    }

    const reducer = (state = initialState, action) => {
        switch (action.type) {

            case dispatcherActions.Duration:
                return Object.assign({}, state, {
                    duration: action.payload
                });

            default:
                return state;
        }
    };

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    }

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations])
    
    return (
        <Card
            properties={{
                ...CardProperties,
                className: 'pp-card',
                borderColor: borderColor,
                isBorderLeftFat: true
            }}
            style={{
                opacity: !canSave ? 0.7 : 1
            }}>
            <div className="title p-t-10 p-b-10">Losingstid: {state.duration} timer</div>
        </Card>
    )
    
    function initialize() {
        const fromToTime = getPilotAssignmentFromAndToTime(locations);
        
        if (isNullOrEmpty(fromToTime.fromTime) || isNullOrEmpty(fromToTime.toTime)) {
            dispatch(dispatcherActions.Duration, "0");
        } else {
            const diff = Moment(fromToTime.toTime).diff(Moment(fromToTime.fromTime), 'minutes');
            dispatch(dispatcherActions.Duration, Math.ceil(diff/60));
        }
        
    }
})

