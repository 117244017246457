import React, { useState } from 'react';

import {
    TabStates
} from '../../services/SystemNames';
import { useEffectOnce } from '../../components/useEffectOnce';
import CalendarTabContent from './components/CalendarTabContent';
import MyPageApiRepository from '../../repositories/api/MyPageApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import SettingsBottomMenu from './components/SettingsBottomMenu';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';

function Calendar() {
    const absenceTabId = 0;

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [absenceUrl, setAbsenceUrl] = useState("");
    const [shiftScheduleUrl, setShiftScheduleUrl] = useState("");
    const [absenceTabState, setAbsenceTabState] = useState(TabStates.Default);
    const [shiftScheduleTabState, setShiftScheduleTabState] = useState(TabStates.Default);

    // Functions for processing data
    const initializeAsync = async () => {

        publishHeaderTopic("Innstillinger", "Kalender");

        const response = await MyPageApiRepository.getCalendarDetailsAsync();

        if (response.ok === true) {
            const data = await response.json();

            setAbsenceUrl(data[0]);
            setShiftScheduleUrl(data[1]);

            setIsBusy(false);
        } else {
            handleError(response);
        }
    }

    const resetUrlAsync = async (url, setUrl) => {
        setIsBusy(true);

        const response = await MyPageApiRepository.resetUrl(url);

        if (response.ok === true) {
            const data = await response.json();
            setUrl(data);
            setIsBusy(false);

            publishSuccessNotificationTopic(`Kalender url ble lagret.`);

        } else {
            handleError(response);
        }
    }

    // Initialize function
    useEffectOnce(() => {
        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    return (
        <>
            <ContentGrid
                title="Kalender"
                titleIcon="calendar"
                subTitle={`Abonner p&aring; ${(selected === absenceTabId) ? "Frav&aelig;r" : "Vaktplan"}`}>
                <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab title="Frav&aelig;r">
                        <CalendarTabContent
                            calendarUrl={absenceUrl}
                            onReset={resetAbsenceUrlAsync}
                            tabState={absenceTabState}
                            setTabState={setAbsenceTabState}
                            onOpenGoogleUrl={onOpenGoogleUrl}
                            onOpenWebCalUrl={onOpenWebCalUrl}
                        />
                    </TabStripTab>
                    <TabStripTab title="Vaktplan">
                        <CalendarTabContent
                            calendarUrl={shiftScheduleUrl}
                            onReset={resetShiftScheduleUrlAsync}
                            tabState={shiftScheduleTabState}
                            setTabState={setShiftScheduleTabState}
                            onOpenGoogleUrl={onOpenGoogleUrl}
                            onOpenWebCalUrl={onOpenWebCalUrl}
                        />
                    </TabStripTab>
                </TabStrip>
            </ContentGrid>

            <SettingsBottomMenu index={2}/>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    function onReloadClick() {
        setIsBusy(true);
        initializeAsync();
    }

    async function resetAbsenceUrlAsync() {
        await resetUrlAsync("appointments", setAbsenceUrl);
        setAbsenceTabState(TabStates.Advanced);
    }

    async function resetShiftScheduleUrlAsync() {
        await resetUrlAsync("shift-schedule", setShiftScheduleUrl);
        setShiftScheduleTabState(TabStates.Advanced);
    }

    function handleError(response) {
        publishWarningNotificationTopic("En feil oppstod ved behandling av kalender.", response.status);
        setHasErrors(true);
        setIsBusy(false);
    }

    function onOpenGoogleUrl() {
        const url = getUrl();
        window.open('https://calendar.google.com/calendar/render?cid=' + url.replace("https://", "webcal://"));
    }

    function onOpenWebCalUrl() {
        const url = getUrl();
        window.open('webcal://' + url.replace("https://", ""));
    }

    function getUrl() {
        return (selected === absenceTabId) ?
            absenceUrl
            :
            shiftScheduleUrl
    }
}

export default Calendar;