import { Card } from "../../../../components/layout/card/Card";
import { CardProperties } from "../../../../components/layout/card/components/CardProperties";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Spacer from "../../../../components/layout/Spacer";
import PilotAssignmentIdbRepository from "../../../../repositories/idb/PilotAssignmentIdbRepository";
import Slide from "../../../../components/layout/Slide";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import Switch from "../../../../components/layout/switch";
import { addBothPilotAndExaminerCommand, hasCommandType } from "../helpers/PilotAssignmentCommandHelpers";
import { hasPilotAssignmentExaminers, hasPilotAssignmentPecExams, isPilotAssignmentEditable, isPilotAssignmentTrainee } from "../helpers/PilotAssignmentHelpers";

export const BothPilotAndExaminerCard = forwardRef((
    {
        pilotAssignmentId,
        onUpdatePilotAssignmentAsync
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onPilotageChanged() {
            initializeAsync();
        }
    }));

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [description, setDescription] = useState("");
    const [couldBeExaminer, setCouldBeExaminer] = useState(false);

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Slide show={show}>
            <Spacer height={30} />
            <Card properties={{
                ...CardProperties,
                title: "Var du b&aring;de sensor og los på oppdraget?",
                backgroundColor: "var(--card-question-bg)"
            }}>
                <p>
                    {description}
                </p>
                {
                    !couldBeExaminer &&
                    <p>
                        <i>For å kunne registere deg som sensor på oppdraget, så må det være krysset av for "Sensor" under Grunnlagsdata Person.</i>
                    </p>
                }
                <Switch
                    onLabel="Ja"
                    offLabel="Nei"
                    onChange={onChange}
                    checked={isChecked}
                    disabled={!isEditable}
                />
            </Card>
        </Slide>
    )

    async function initializeAsync() {
        const pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
        const hasPecExams = hasPilotAssignmentPecExams(pilotAssignment);
        const hasCommand = hasCommandType(pilotAssignment, PilotAssignmentCommands.BothPilotAndExaminer);

        setShow(
            hasPecExams &&
            !pilotAssignment.pilotage.canConvertToPilotIncl &&
            !pilotAssignment.isExaminer &&
            !isPilotAssignmentTrainee(pilotAssignment) &&
            !hasPilotAssignmentExaminers(pilotAssignment)
        );

        setIsEditable(isPilotAssignmentEditable(pilotAssignment));
        setIsChecked(hasCommand);
        setCouldBeExaminer(pilotAssignment.couldBeExaminer);

        if (!hasPecExams) return;
        const pecExamsCount = pilotAssignment.pilotage.pecExams.length;

        setDescription(`Oppdraget inneholder ${pecExamsCount} ${pecExamsCount === 1 ? "farledsprøve" : "farledsprøver"}.`);
    }

    function onChange(e) {
        setIsChecked(e.value);
        onUpdateAsync(e.value);
    }

    async function onUpdateAsync(bothPilotAndExaminer) {
        let pilotAssignment = await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);

        pilotAssignment = addBothPilotAndExaminerCommand(pilotAssignment, bothPilotAndExaminer);

        await onUpdatePilotAssignmentAsync(pilotAssignment);
    }

})
