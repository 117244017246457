import './switch.css'

import React from 'react';
import { Asterisk } from "phosphor-react-sc";
import { Switch as KendoSwitch } from "@progress/kendo-react-inputs";
import { isNullOrEmpty, isObjectNull } from '../helpers/ObjectHelpers';
import { getDisabledStyle } from '../helpers/ElementHelpers';

function Switch({
    onLabel = "",
    offLabel = "",
    checked = false,
    disabled = false,
    isDirty = false,
    onChange,
    size = "large",
    bold = true,
    fontSize = 18
}) {

    return (
        <div className="switch-container">
            <div>
                <KendoSwitch
                    size={size}
                    onChange={handleOnChange}
                    checked={checked}
                    disabled={disabled}
                />
            </div>
            <div style={getStyle(disabled)}>
                {checked ? getOnLabel() : getOffLabel()}
            </div>
            <div>
                {
                    isDirty &&
                    <Asterisk color="var(--card-dirty-color)" />
                }
            </div>
        </div>
    );

    function handleOnChange(e) {
        if (disabled || isObjectNull(onChange)) return;

        onChange(e);
    }

    function getOnLabel() {
        if (!isNullOrEmpty(onLabel)) return onLabel;

        return "Ja";
    }

    function getOffLabel() {
        if (!isNullOrEmpty(offLabel)) return offLabel;
        if (!isNullOrEmpty(onLabel)) return onLabel;

        return "Nei";
    }

    function getStyle() {
        const style = getDisabledStyle(disabled);
        if (bold) {
            style.fontWeight = "700";
        }
        style.fontSize = `${fontSize}px`
        return style;
    }
}

export default Switch; 