
import React, { useState } from 'react';
import { Dialog } from '../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import TextAreaFieldWrapper from '../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import Spacer from '../../../components/layout/Spacer';
import CounterFieldWrapper from '../../../components/layout/fieldWrappers/CounterFieldWrapper';
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';

function CurrentEditDialog({
    item,
    onCancel,
    onSave
}) {

    const [remark, setRemark] = useState(isNullOrEmpty(item.remark) ? "" : item.remark);
    const [pilotageCount, setPilotageCount] = useState(item.pilotageCount);
    const [canSave, setCanSave] = useState(false);

    return (
        <Dialog properties={{
            ...DialogProperties,
            title: item.personFullName,
            onClose: onCancel,
            actions: [
                {
                    onClick: onCancel,
                    icon: "close",
                    text: "Avbryt"
                },
                {
                    icon: "ok",
                    disabled: !canSave,
                    onClick: handleOnSave,
                    themeColor: "primary",
                    text: "Lagre"
                }
            ]
        } }>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <CounterFieldWrapper
                            title="Antall oppdrag"
                            value={pilotageCount}
                            onDecrease={onDecreaseClick}
                            onIncrease={onIncreaseClick}
                        />
                    </div>
                </div>
                <Spacer height={20}/>
                <div className="row">
                    <div className="col">
                        <TextAreaFieldWrapper
                            title="Merknad"
                            value={remark}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );

    async function handleOnSave() {

        item.pilotageCount = pilotageCount;
        item.remark = remark;

        onSave(item);
    }

    function onChange(e) {
        setRemark(e.target.value);
    }

    function onDecreaseClick() {
        if (item.pilotageCount === 0) return;

        updatePilotageCount(pilotageCount - 1);
    }

    function onIncreaseClick() {
        updatePilotageCount(pilotageCount + 1);
    }

    function updatePilotageCount(count) {
        setPilotageCount(count);
        setCanSave(item.pilotageCount !== count);
    }
}

export default CurrentEditDialog;