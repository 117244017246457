import React from 'react';

export default function Spacer(
    {
        height = 10,
        classname = ""
    }
) {

    return (
        <React.Fragment>
            {
                classname.length > 0 ?
                <div className={classname} />
                :
                <div style={{ height: height }} />
            }
            
        </React.Fragment>
    );
}
 