import ai from "../../services/AppInsightsService";

function wrapMethodsWithLogging(object) {
    Object.keys(object).forEach((methodName) => {
        const originalMethod = object[methodName];
        object[methodName] = async function (...args) {
            const response = await originalMethod.apply(this, args);
            if (!response.ok) {
                ai.trackException(new Error(`An error occurred when executing ${methodName} with params ${JSON.stringify(args)}: HttpStatuscode:${response.status} ${response.statusText} for url ${response.url}`));
            }
            return response;
        };
    });
}

export {
    wrapMethodsWithLogging
}
