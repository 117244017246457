import { useState } from "react";
import Spacer from "../layout/Spacer"
import { Button } from "@progress/kendo-react-buttons";
import Overlay from "../layout/overlay/Overlay";
import BaseIdbRepository from "../../repositories/idb/BaseIdbRepository";

export const RestoreDatabase = () => {

    const [isBusy, setIsBusy] = useState(false);

    return (
        <>
            <div className="app-content-grid">
                <div>
                    <p>
                        En uventet feil har dessverre oppstått.
                    </p>
                    {
                        (navigator.onLine) ?
                            <>
                                <p>
                                    Problemet kan bli løst med å gjenopprette dataene dine.
                                </p>
                                <p>
                                    Klikk på knappen for å forsøke og gjenopprette dine data.
                                </p>
                            </>
                            :
                            <p>
                                Problemet kan bli løst med å gjenopprette dataene dine, men du må være på nett for å gjøre dette.
                            </p>
                            
                    }
                    <p>
                        Vennligst ta kontakt med Administrator hvis problemet vedvarer.
                    </p>
                </div>
                {
                    (navigator.onLine) &&
                    <>
                        <Spacer height={20} />
                        <div>
                            <center>
                                <Button themeColor="primary" onClick={onRestore}>
                                    Gjenopprett data
                                </Button>
                            </center>
                        </div>
                    </>
                }
            </div>
            <Overlay isBusy={isBusy}/>
        </>
    )

    async function onRestore() {
        setIsBusy(true);
        await BaseIdbRepository.deleteDatabaseAsync();
        window.location.href = "/";
    }
}
