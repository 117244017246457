import React from 'react';
import {
    AdvancedMarker,
    useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { MapClickTypes } from '../GoogleMapHelpers';

export const MapSelectedPositionMarker = ({ properties, position }) => {

    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
        (properties.canMapClick && properties.mapClickType === MapClickTypes.Marker) &&
        <>
            <AdvancedMarker
                ref={markerRef}
                position={position}
            />
        </>
    )
}
