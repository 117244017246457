import React, { useEffect } from 'react';
import SearchTextBoxFieldWrapper from '../../components/layout/fieldWrappers/SearchTextBoxFieldWrapper';
import SearchedQuayLocation from './SearchedQuayLocations';
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import { isEmptyOrSpaces, isNullOrEmpty } from '../../components/helpers/ObjectHelpers';

export const SearchQuayLocation = ({ setHasSearched, searchText, setSearchText, setSearchLocations }) => {
    
    useEffect(() => {
        const getData = setTimeout(() => {
            onSearchLocationsAsync(searchText);
        }, 500)

        return () => clearTimeout(getData)
    }, [searchText]) 
    return (
        <div className="quay-search-panel">
            <div>
                <h5>S&oslash;k etter kaibok</h5>
                <SearchTextBoxFieldWrapper
                    value={searchText}
                    placeholder="Lokasjon"
                    onChange={onSearchTextChange}
                />
            </div>
        </div>
    );

    function onSearchTextChange(e) {
        setSearchText(e.value);
    }

    async function onSearchLocationsAsync(searchText) {
        if (isEmptyOrSpaces(searchText)) {
            setSearchLocations(null);
            return;
        }

        const result = await QuayManualApiRepository.searchQuayManuals(searchText.toString());
        if (result.ok) {
            const quayHarbours = await result.json();
            const locations = [];

            for (var location of quayHarbours) {
                locations.push({
                    name: location.name,
                    locationId: location.locationId,
                    documents: location.documents
                });
            }
            setSearchLocations(locations);
            setHasSearched(!isNullOrEmpty(searchText));
        } else {
            setSearchLocations(null);
            setHasSearched(false);
            return;
        }
    } 
};

export const SearchQuayLocationResult = ({ searchLocations }) => {
    return (
        searchLocations &&
            searchLocations.map(item => (
                <SearchedQuayLocation key={item.locationId} searchedLocation={item} />
            ))
    );
};

