import './notifications.css'
import React, { useEffect } from 'react';
import { useState } from 'react';
import PubSub from 'pubsub-js';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import parse from 'html-react-parser';
import { isNullOrEmpty } from '../helpers/ObjectHelpers';
import { PubSubTopics } from '../helpers/PubSubHelpers';
import { addClassToElementByClassName } from '../helpers/ElementHelpers';

export default function Notifications(
    
) {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.Notification, handlePubSubTopic);
        return () => {
            PubSub.unsubscribe(PubSubTopics.Notification);
        }

    }, [])

    return (
        (!isNullOrEmpty(notifications)) &&
            <NotificationGroup className="k-notification-group" >
                {
                    notifications.map((notification) => (
                        <NotificationCard
                            key={notification.id}
                            notification={notification}
                            onClick={onClick}
                            handleFadeout={handleFadeout}
                            />
                    ))
                }
            </NotificationGroup>
    );

    function handlePubSubTopic(msg, data) {
        if (msg !== PubSubTopics.Notification) return;

        setNotifications(prev => [
            ...prev,
            data
        ]);
    }

    function onClick(notification) {
        setNotifications(prev => {
            return prev.filter(n => n.id !== notification.id);
        });
    }

    function handleFadeout(notification) {
        setTimeout(() => {
            onClick(notification);
        }, 2500);
    }
}

const NotificationCard = ({ notification, onClick, handleFadeout }) =>
{

    useEffect(() => {
        if (notification.fadeout) {
            onHandleFadeout();
        }
    }, [])

    return (
        <Notification
            className={`k-notification ${notification.id}`}
            type={{
                style: notification.type,
                icon: false,
            }}
            closable={true}
            onClose={() => onClick(notification)}>
            <span>{parse(notification.text)}</span>
        </Notification>
    );

    function onHandleFadeout() {
        setTimeout(() => {
            addClassToElementByClassName(notification.id, 'k-notification-fadeout');
        }, 100);
        handleFadeout(notification);
    }
}
