import './styles/simulate.css';

import React, { useReducer, useState } from 'react';
import { useEffectOnce } from '../../components/useEffectOnce';
import { PageStates } from '../../services/SystemNames';
import Spacer from '../../components/layout/Spacer';
import moment from 'moment';
import { useSwipeable } from 'react-swipeable';
import { Controls, Default } from './components/SimulateControls';
import TimelineViewService from '../../components/layout/timelineView/services/TimelineViewService';
import { TimelineView } from '../../components/layout/timelineView/TimelineView';
import { TimelineViewWorkTimes } from '../../components/layout/timelineView/components/TimelineViewWorkTimes';
import { TimelineViewAppointments } from '../../components/layout/timelineView/components/TimelineViewAppointments';
import { TimelineViewNow } from '../../components/layout/timelineView/components/TimelineViewNow';
import { TimelineViewType } from '../../components/layout/timelineView/services/Types';
import { DispatcherActions } from './services/DispatcherActions';
import PilotApiRepository from '../../repositories/api/PilotApiRepository';
import WorkTimeApiRepository from '../../repositories/api/WorkTimeApiRepository';
import { Dialog } from '../../components/layout/dialogs/Dialog';
import { checkIcon } from "@progress/kendo-svg-icons";
import Overlay from '../../components/layout/overlay/Overlay';
import { DialogProperties } from '../../components/layout/dialogs/DialogProperties';
import WorkTimeBottomNavigation from './components/WorkTimeBottomNavigation';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { isMobile } from '../../components/helpers/DeviceHelpers';
import { copyMoment, formatMoment, getFormatMoment } from '../../components/helpers/DateTimeHelpers';
import { useGetSourceConfigQuery } from '../../reducers/slices/api.slice';
import { useEffect } from 'react';
import { isNullOrEmpty } from '../../components/helpers/ObjectHelpers';

const MousePosition = {
    up: false,
    previousMouseYPosition: 0
};

function Simulate() {

    const defaultMinutesTick = 5;

    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogText, setDialogText] = useState("");

    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    const initialState = {
        isBusy: true,
        hasErrors: false,
        simulateState: PageStates.Default,
        timeoutId: 0,

        // Arrays
        timelineTimes: [],
        simulatedWorkTimes: [],
        appointments: [],

        // Times
        sourceNowTime: null,
        nowTime: null,
        toTime: null,
        fromTime: null,

        // Misc
        isCurrentlyWorking: false,
        isCurrentlyResting: false,
        isCurrentlyInMandatoryRest: false,
        pilotDetails: {}
    };

    const reducer = (state = initialState, action) => {
        switch (action.type) {

            case DispatcherActions.HasErrors:
                return Object.assign({}, state, {
                    hasErrors: true,
                    isBusy: false
                });

            case DispatcherActions.Initialized:
                return Object.assign({}, state, {
                    fromTime: action.payload.fromTime,
                    toTime: action.payload.toTime,
                    nowTime: action.payload.nowTime,
                    sourceNowTime: action.payload.sourceNowTime,
                    timelineTimes: action.payload.timelineTimes
                });

            case DispatcherActions.FetchedPilotageDetails:
                return Object.assign({}, state, {
                    appointments: action.payload.appointments,
                    pilotDetails: action.payload.pilotDetails
                });

            case DispatcherActions.FetchedSimulatedPeriods:
                return Object.assign({}, state, {
                    isCurrentlyInMandatoryRest: action.payload.isCurrentlyInMandatoryRest,
                    isCurrentlyWorking: action.payload.isCurrentlyWorking,
                    isCurrentlyResting: action.payload.isCurrentlyResting,
                    simulatedWorkTimes: action.payload.simulatedWorkTimes,
                    isBusy: false
                });

            case DispatcherActions.NowTime:
                return Object.assign({}, state, {
                    nowTime: action.payload
                });

            case DispatcherActions.TimeoutId:
                return Object.assign({}, state, {
                    timeoutId: action.payload
                });

            case DispatcherActions.Reset:
                return Object.assign({}, state, {
                    isBusy: true,
                    hasErrors: false,
                    simulateState: PageStates.Default
                });

            case DispatcherActions.Reload:
                return Object.assign({}, state, {
                    sourceNowTime: action.payload.sourceNowTime,
                    nowTime: action.payload.nowTime,
                    simulateState: PageStates.Reload,
                    isBusy: true
                });

            case DispatcherActions.Start:
                return Object.assign({}, state, {
                    isCurrentlyWorking: true,
                    isCurrentlyResting: false,
                    nowTime: action.payload
                });

            case DispatcherActions.Stop:
                return Object.assign({}, state, {
                    isCurrentlyWorking: false,
                    isCurrentlyResting: true,
                    nowTime: action.payload
                });

            default:
                return state;
        }
    };

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    }

    // Initialize function
    useEffectOnce(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("touchmove", handleTouchMove);

        document.body.classList.add("no-overscroll-behavior");

        publishHeaderTopic("Simuler arbeidstid");

        initialize();

        return function cleanupEventListeners() {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("touchmove", handleTouchMove);

            document.body.classList.remove("no-overscroll-behavior");
        }

    }, []);

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess)) return;
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess])

    const swipeHandlers = useSwipeable({
        onSwiping: onSwiping,
        preventScrollOnSwipe: true,
        trackMouse: true,
        onSwipedUp: afterSwipe,
        onSwipedDown: afterSwipe
    });

    return (
        <>
            <Default>
                <TimelineView
                    timelineViewType={{
                        ...TimelineViewType,
                        timelineTimes: state.timelineTimes,
                        nowTime: state.nowTime,
                        fromTime: state.fromTime,
                        toTime: state.toTime,
                        showHighlight: true,
                        workTimes: state.simulatedWorkTimes,
                        appointments: state.appointments,
                        pilotPilotages: state.pilotDetails.pilotages
                    }}>
                    <TimelineViewNow />
                    <TimelineViewWorkTimes />
                    <TimelineViewAppointments />
                    <Spacer />
                </TimelineView>
                <Controls
                    state={state}
                    swipeHandlers={swipeHandlers}
                    onClickUp={onClickUp}
                    onClickDown={onClickDown}
                    onWheel={onWheel}
                    onReset={onReset}
                    onReload={onReload}
                    onStart={onStart}
                    onStop={onStop}
                />
            </Default>

            <WorkTimeBottomNavigation id="simulate" />

            <Overlay isBusy={state.isBusy} onReloadClick={onReset} hasErrors={state.hasErrors} />

            {
                showDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        title: dialogTitle,
                        onClose: () => setShowDialog(false),
                        actions: [
                            {
                                onClick: () => setShowDialog(false),
                                icon: checkIcon,
                                themeColor: "primary",
                                text: "Ok"
                            }
                        ]
                    }}>
                    {dialogText}
                </Dialog>
            }
        </>
    );

    function initialize() {
        const now = getFormatMoment(moment());

        let sourceFromTime = getFromTime(sourceConfig);
        let fromTime = getFormatMoment(copyMoment(sourceFromTime));
        const toTime = copyMoment(now).add(sourceConfig.pilotWorkTimeSimulationHoursIntoFuture, 'h');

        let array = [];
        while (fromTime < toTime) {
            array.push(moment(fromTime.toDate()));
            fromTime.add(1, 'h');
        }
        
        if (array[array.length - 1].hour() % 2 === 0) {
            array.push(moment(fromTime.toDate()));
        }

        dispatch(DispatcherActions.Initialized, {
            fromTime: getFromTime(sourceConfig),
            toTime: array.pop(),
            nowTime: getFormatMoment(moment()),
            sourceNowTime: getFormatMoment(moment()),
            timelineTimes: array
        });

        getPilotDetailsAsync(now, sourceFromTime, moment().add(-3, 'd'), moment().add(3, 'd'));
    }

    function getFromTime(configuration) {
        const minutes = moment().minutes();
        return getFormatMoment(moment()).add(-configuration.pilotWorkTimeSimulationHoursBackInTime, 'h').add(-minutes, 'm');
    }

    async function getPilotDetailsAsync(now, sourceFromTime, fromTime, toTime) {
        const response = await PilotApiRepository.getPilotDetailsAsync(formatMoment(fromTime), formatMoment(toTime));

        if (response.ok === true) {
            const data = await response.json();

            dispatch(DispatcherActions.FetchedPilotageDetails, {
                appointments: TimelineViewService.createPilotageAppointments(data.pilotages),
                pilotDetails: data
            });

            getSimulatedPeriodsAsync(data, sourceFromTime, now);
        } else {
            handleError(response);
        }
    }

    async function getSimulatedPeriodsAsync(pilot, fromTime, now, reload = false) {
        const pilotWorkTime = pilot.workTime.filter((item) => {
            return moment(item.started) < now;
        });
        pilot.workTime = pilotWorkTime;

        const query = {
            pilot: pilot,
            fromTime: formatMoment(fromTime),
            now: formatMoment(now),
            reload: reload
        };
        const response = await WorkTimeApiRepository.searchSimulateAsync(query);
        if (response.ok === true) {
            const data = await response.json();

            dispatch(DispatcherActions.FetchedSimulatedPeriods, {
                isCurrentlyInMandatoryRest: data.isCurrentlyInMandatoryRest,
                isCurrentlyWorking: data.isCurrentlyWorking,
                isCurrentlyResting: !data.isCurrentlyWorking,
                simulatedWorkTimes: TimelineViewService.setSimulationPeriodsProperties(data.periods),
            });
            
            setTimeout(() => {
                TimelineViewService.scrollToNow();
            }, 250);

        } else {
            handleError(response);
        }
    }

    function handleError(response) {
        publishWarningNotificationTopic("Kunne ikke hente inn simulerings data", response.status);
        dispatch(DispatcherActions.HasErrors, response.status);
    }

    function onWheel(event) {
        if (event.nativeEvent.wheelDelta > 0) {
            onUpdateNow(-defaultMinutesTick);
        } else {
            onUpdateNow(defaultMinutesTick);
        }
    }


    function handleMouseMove(event) {
        if (isMobile() === false) return;
        MousePosition.previousMouseYPosition = event.clientY;
    }

    function handleTouchMove(event) {
        if (isMobile() === false) return;
        const obj = event.changedTouches[0];
        MousePosition.up = obj.pageY < MousePosition.previousMouseYPosition;
        MousePosition.previousMouseYPosition = obj.pageY;
    }

    function onSwiping(event) {
        if (isMobile() === false) return;
        
        if (MousePosition.up === true) {
            onUpdateNow(-defaultMinutesTick);
        } else {
            onUpdateNow(defaultMinutesTick);
        }
    }

    function onUpdateNow(minutes) {

        clearTimeout(state.timeoutId);

        const newNowTime = moment(state.nowTime.toDate()).add(minutes, 'm');
        if (newNowTime.toDate() < state.sourceNowTime.toDate() || newNowTime.toDate() > state.toTime.toDate()) return;

        dispatch(DispatcherActions.NowTime, newNowTime);

        const timeoutId = setTimeout(() => {
            const reload = state.simulateState === PageStates.Reload;
            getSimulatedPeriodsAsync(state.pilotDetails, state.fromTime, newNowTime, reload);
        }, 250);

        dispatch(DispatcherActions.TimeoutId, timeoutId);
    }

    function onClickUp() {
        onUpdateNow(-1);
    }

    function onClickDown() {
        onUpdateNow(1);
    }

    function onReset() {

        dispatch(DispatcherActions.Reset);

        initialize();
    }

    function onReload() {
        state.pilotDetails.workTime = [];

        dispatch(DispatcherActions.Reload, {
            sourceNowTime: state.fromTime,
            nowTime: state.fromTime
        });

        getSimulatedPeriodsAsync(state.pilotDetails, state.fromTime, state.fromTime, true);
    }

    function onStart() {
        if (state.isCurrentlyWorking) return;
        if (state.isCurrentlyInMandatoryRest) {
            setDialogTitle("P&aring;lagt hvile!");
            setDialogText("Arbeidstid kan ikke starte n\u00E5r loser er p\u00E5 p\u00E5lagt hvile.")
            setShowDialog(true);
            return;
        }

        insertItem(0); // Working time

        onStartStop(DispatcherActions.Start);
    }

    function onStop() {
        if (state.isCurrentlyResting) return;

        insertItem(1); // Resting time

        onStartStop(DispatcherActions.Stop);
    }

    function onStartStop(dispatcherAction) {
        const newNowTime = moment(state.nowTime.toDate()).add(5, 'm');
        dispatch(dispatcherAction, newNowTime);

        const reload = state.simulateState === PageStates.Reload;
        getSimulatedPeriodsAsync(state.pilotDetails, state.fromTime, newNowTime, reload);
    }

    function insertItem(type) {
        const obj = {
            type: type,
            started: formatMoment(state.nowTime),
            stopped: formatMoment(state.nowTime)
        };

        state.pilotDetails.workTime.push(obj);
    }

    function afterSwipe() {
        getSimulatedPeriodsAsync(state.pilotDetails, state.fromTime, state.nowTime);
    }
}

export default Simulate;
