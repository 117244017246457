import React from 'react';
import "../pilot-station.css"
import Timeline from './Timeline';

const Initials = ({children}) => {
    return (
        <div className="initials">
            <div>
                {children}
            </div>
        </div>
    )
};

const Pilot = ({pilot, from, to, loggedInPersonId}) => {
    return (
        <div className="pilot">
            <Initials>{pilot.initials}</Initials>
            <Timeline from={from} to={to} pilot={pilot} loggedInPersonId={loggedInPersonId} />
        </div>
    );
};

export default Pilot;