/*eslint unicode-bom: ["error", "always"]*/
import React, { useEffect, useState } from 'react';
import { Dialog } from '../../dialogs/Dialog';
import { DialogProperties } from '../../dialogs/DialogProperties';

export default function Error({ onClick, hasErrors = false, errorText = "", statusCode = 0 }) {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(hasErrors);
    }, [hasErrors]);

    return (
        show && 
        <Dialog
            properties={{
                ...DialogProperties,
                title: "En feil oppstod!",
                onClose: () => setShow(false),
                actions: [
                    {
                        themeColor: "primary",
                        text: "Last inn p&aring; ny",
                        icon: "reload",
                        onClick: onClick
                    }
                ]
            }}>
            <div className="container">
                <div className="row">
                    En feil oppstod ved innlasting av siden.
                </div>
            </div>
        </Dialog>
                
    );
}