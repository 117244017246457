import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
} from "@progress/kendo-react-layout";
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';

export default function ActualWorkContent(
    {
        title = "",
        description = "",
        totalDescription = "",
        data
    }
) { 

    return (
        (data !== undefined && data.length > 0) &&
            <Card>
                <CardHeader>{title} {getDescription()}</CardHeader>
                <CardBody>
                    <div className="actual-work-content">
                            <div className="details">
                                <div className="header">Fra</div>
                                <div className="header">Til</div>
                                <div className="header">Brotid</div>

                                {data.map((wt, index) => (
                                    <React.Fragment key={index}>
                                        <div>{wt.startedDescription}</div>
                                        <div>{wt.stoppedDescription}</div>
                                        <div>{wt.durationDescription}</div>
                                    </React.Fragment>
                                ))}
                                <div className="sum">Sum</div>
                                <div>{totalDescription}</div>

                        </div>
                
                    </div>
                </CardBody>
            </Card>
    );

    function getDescription() {
        if (isNullOrEmpty(description)) return "";

        return ` (${description})`
    }

}
