import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../components/helpers/TokenHelpers';

export const apiSliceService = {
    baseQuery() {
        return fetchBaseQuery({
            baseUrl: 'api'
        });
    },
    headers() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
    }
};
