import { ApplicationRightSystemNames } from '../../../services/SystemNames';
import { hasApplicationRights } from '../../../components/helpers/AuthHelpers';
import { useNavigate } from 'react-router-dom';
import { HomeContent, HomeOnlineCard } from './HomeComponents';

export const HomeCoastalSegmentsCard = ({ isPilot, applicationRights }) => {

    const navigate = useNavigate();

    return (
        <HomeCoastalSegmentsBaseCard
            onClick={onClick}
            className="sm-col-span-2"
            canShow={!isPilot && hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments])}
            text="Rediger sertifikatområder"/>
    )

    function onClick() {
        navigate('/pcs/groups');
    }
}

export const HomeCoastalSegmentsEvaluationsCard = ({ isPilot, applicationRights }) => {

    const navigate = useNavigate();

    return (
        <HomeCoastalSegmentsBaseCard
            onClick={onClick}
            className="sm-col-span-2"
            canShow={!isPilot && hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation])}
            text="Evaluer sertifikatområder"/>
    )

    function onClick() {
        navigate('/pcs/evaluations');
    }
}

export const HomeCoastalSegmentsLocationsCard = ({ isPilot, applicationRights }) => {

    const navigate = useNavigate();

    return (
        <HomeCoastalSegmentsBaseCard
            onClick={onClick}
            canShow={!isPilot && hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations])}
            text="Rediger sertifikatområders lokasjoner"
        />
    )

    function onClick() {
        navigate('/pcs/locations');
    }
}

export const HomeCoastalSegmentsBaseCard = ({ onClick, canShow, text = "" }) => {

    const navigate = useNavigate();

    return (
        <HomeOnlineCard
            onClick={onClick}
            className="sm-col-span-2"
            canShow={canShow}>
            <HomeContent icon="mapSegments">
                <div className="k-d-grid">
                    <div className="home-content-title">
                        Sertifikatområder
                    </div>
                    <div>
                        {text}
                    </div>
                </div>
            </HomeContent>
        </HomeOnlineCard>
    )
}

