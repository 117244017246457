import './login.css'

import React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import packageJson from '../../../package.json';
import { isNullOrEmpty } from '../helpers/ObjectHelpers';
import { useState } from 'react';
import { useEffect } from 'react';
import Overlay from '../layout/overlay/Overlay';
import AuthApiRepository from '../../repositories/api/AuthApiRepository';
import { Tokens } from '../../services/SystemNames';
import { useGetSourceConfigQuery } from '../../reducers/slices/api.slice';

export default function Login() {

    const [environment, setEnvironment] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess) || !isSourceConfigSuccess) return;
        if (navigator.onLine) {
            setEnvironment(sourceConfig.Environment);
            localStorage.removeItem(Tokens.Token);
        }
        setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess])

    return (
        <>
            <div className="app-login">
                <div className="app-login-box">
                    <div>
                        <img alt="Kystverket" src="/images/kyv-logo.svg" />
                    </div>
                    <div>
                        <Button
                            size="large"
                            themeColor="primary"
                            onClick={onClick}
                        >
                            Logg på PilotWeb
                        </Button>
                    </div>
                </div>
            </div>

            <div></div>

            <div className="app-login-version">
                Versjon {packageJson.version}
            </div>

            <div className="app-login-environment">
                {!isNullOrEmpty(environment) && <div>{environment}</div>}
            </div>

            <Overlay isBusy={isBusy} />
        </>
    );

    async function onClick() {
        setIsBusy(true);
        if (!navigator.onLine) {
            alert("Du kan kun logge inn n\u00E5r du er p\u00E5 nett");
            return;
        }


        if (!navigator.onLine) {
            this.history.push("/");
        }

        const response = await AuthApiRepository.getLoginUrlAsync(window.location.href);
        const data = await response.json();

        window.location.href = data.loginUrl;
    }
}
