/*eslint unicode-bom: ["error", "always"]*/
import React from 'react';

import {
    IconColors,
    PilotRotationStates,
} from '../../../services/SystemNames';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import Icons from '../../../components/layout/icons/Icons';
import Spacer from '../../../components/layout/Spacer';


function PilotRotationListItem({
    row = 1,
    pilotRotationItem,
    editClick
}) {
    
    return (
        <div style={{ gridRow: row }}>
            <Card properties={{
                ...CardProperties,
                title: pilotRotationItem.personFullName,
                subTitle: getSubTitle(),
                borderColor: getBorderColor(),
                backgroundColor: getBackgroundColor(),
                onClick: (pilotRotationItem.state === PilotRotationStates.Edit) ? onClick : null,
                actions: 
                    (pilotRotationItem.state === PilotRotationStates.Edit) ?
                        [
                            {
                                icon: "edit",
                                onClick: onClick
                            },
                        ]
                        :
                        []
                    
            } }>
                {
                    pilotRotationItem.hasOnOffDuty &&
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">{pilotRotationItem.onDutyDescription}</div>
                                <div className="col text-center"><Icons color={IconColors.Primary} iconName="next" /></div>
                                <div className="col">{pilotRotationItem.offDutyDescription}</div>
                            </div>
                        </div>
                }
            </Card>
            <Spacer height={10}/>
        </div>
    );

    function getBorderColor() {
        return `var(--pilot-rotation-${pilotRotationItem.isOnDuty ? 'on' : 'off'}-border)`;
    }

    function getBackgroundColor() {
        return `var(--pilot-rotation-${pilotRotationItem.isOnDuty ? 'on' : 'off'}-bg)`;
    }

    function getSubTitle() {
        if (pilotRotationItem.state === PilotRotationStates.ReadOnly) return "";
        return `${pilotRotationItem.pilotageCount}`;
    }

    function onClick() {
        editClick(pilotRotationItem);
    }
}

export default PilotRotationListItem;
