import React from 'react';
import {
    Dialog as KendoDialog,
    DialogActionsBar as KendoDialogActionsBar
} from "@progress/kendo-react-dialogs";
import parse from 'html-react-parser';
import { xIcon } from "@progress/kendo-svg-icons";
import {
    Button
} from "@progress/kendo-react-buttons";
import { DialogActions } from './DialogActions';
import { isArrayEmpty, isObjectNull } from '../../../helpers/ObjectHelpers';
import { getDialogSizeClassName } from '../DialogHelpers';

export const DialogDefault = ({ children, properties = {}}) => {
    return (
        <KendoDialog title={
            <div className='dialog-subtitle'>
                <div><strong>{parse(properties.title)}</strong></div>
                <div className='dialog-subtitle-text'>{properties.subTitle}&nbsp;</div>
            </div>}
            onClose={handleOnClose}
            className={`dialog ${getDialogSizeClassName(properties)}`}>
            {children}
            <KendoDialogActionsBar>
                {
                    !isArrayEmpty(properties.actions) ?
                        <DialogActions properties={properties} />
                        :
                            <Button
                                onClick={handleOnClose}
                                svgIcon={xIcon}>Avbryt</Button>
                    }
                </KendoDialogActionsBar>           
        </KendoDialog>
    )

    function handleOnClose() {
        if (isObjectNull(properties.onClose)) {
            alert("Mangler aksjon for å lukke dialog.");
        } else {
            properties.onClose();
        }
    }
}
