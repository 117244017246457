
function sniffPlatform() {
    const ua = navigator.userAgent.toLowerCase();
    if (/android/i.test(ua)) return "android";
    if (/ipad|iphone/i.test(ua) || (/mac/i.test(ua) && "ontouchstart" in window)) return "ios";
    return "unknown";
}

function isInStandaloneMode() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
}

function isMobile() {
    var isIPadPro = /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent) || isIPadPro) {
        return true;
    } else {
        return false;
    }
}

export {
    sniffPlatform,
    isInStandaloneMode,
    isMobile
}