import '../styles/pilot.css';
import React, { useEffect, useReducer, useState } from 'react';
import {
    PageStates
} from '../../../services/SystemNames';
import { useParams } from 'react-router-dom';
import PilotAssignment from '../pilot-assignment/PilotAssignment';
import PilotageDetailsShip from '../components/PilotageDetailsShip';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import { DispatcherActions } from '../services/DispatcherActions';
import PilotageDetailsBottomMenu from '../components/PilotageDetailsBottomMenu';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import PilotAssignmentMap from '../pilot-assignment/PilotAssignmentMap';
import { PilotAssignmentUpdatedDialog } from '../pilot-assignment/components/dialogs/PilotAssignmentUpdatedDialog';
import { useNavigate } from 'react-router-dom';
import Overlay from '../../../components/layout/overlay/Overlay';
import PilotageDetailsDefault from '../pilotage-details/PilotageDetailsDefault';
import PilotageDetailsObservations from '../components/PilotageDetailsObservations';
import { isNumeric, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { processPilotAssignment } from '../pilot-assignment/helpers/PilotAssignmentProcessHelpers';
import { convertPilotAssignmentApiDtoToIdb, createEmptyPilotAssignment, setPilotAssignmentSuggestions } from '../pilot-assignment/helpers/PilotAssignmentHelpers';

function Details() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [showUpdatedDialog, setShowUpdatedDialog] = useState(false);

    const initialState = {
        pageState: PageStates.Busy,
        hasErrors: false,
        isBusy: true,
        pilotAssignment: createEmptyPilotAssignment(),
        httpStatusCode: 0
    };

    const reducer = (state = initialState, action) => {
        switch (action.type) {

            case DispatcherActions.PageState:
                return Object.assign({}, state, {
                    pageState: action.payload
                });

            case DispatcherActions.IsBusy:
                return Object.assign({}, state, {
                    isBusy: true,
                    hasErrors: false
                });

            case DispatcherActions.NotBusy:
                return Object.assign({}, state, {
                    isBusy: false
                });

            case DispatcherActions.HasErrors:
                return Object.assign({}, state, {
                    isBusy: false,
                    hasErrors: true
                });

            case DispatcherActions.Initialized:
                return Object.assign({}, state, {
                    pilotAssignment: action.payload,
                    pageState: PageStates.Default,
                    hasErrors: false,
                    isBusy: false
                });

            case DispatcherActions.Update:
                return Object.assign({}, state, {
                    pilotAssignment: action.payload
                });

            default:
                return state;
        }
    };

    const [stateDetail, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    }

    useEffect(() => {
        initializeAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            {(() => {
                switch (stateDetail.pageState) {
                    case PageStates.PilotAssignment:
                        return (<PilotAssignment pilotageId={id} />);
                    case PageStates.Map:
                        return (
                            navigator.onLine &&
                            <PilotAssignmentMap
                                pilotageId={stateDetail.pilotAssignment.pilotage.pilotageId}
                                shipId={stateDetail.pilotAssignment.pilotage.pilotageShip.shipId}
                            />
                        )
                    case PageStates.Default:
                        return (
                            <PilotageDetailsDefault
                                onReload={onUpdatePilotagePilotAsync}
                                state={stateDetail.pilotAssignment.pilotage}
                                isReadOnly={false}
                            />
                        )
                    case PageStates.Ship:
                        return (
                            <PilotageDetailsShip
                                pilotage={stateDetail.pilotAssignment.pilotage}
                            />
                        )

                    case PageStates.Observations:
                        return (
                            <PilotageDetailsObservations
                                pilotage={stateDetail.pilotAssignment.pilotage}
                            />
                        )

                    default:
                        return null;
                }
            })()}

            <Overlay isBusy={stateDetail.isBusy} onReloadClick={onReloadClick} isActive={stateDetail.hasErrors} />

            {
                showUpdatedDialog && 
                <PilotAssignmentUpdatedDialog
                    onClose={onClosePilotAssignmentUpdatedDialogClickAsync}
                    onClick={onClosePilotAssignmentUpdatedDialogClickAsync} />
            }

            <PilotageDetailsBottomMenu
                state={stateDetail}
                onClick={onBottomMenuClick}
            />

        </>
    );
    

    async function initializeAsync() {

        if (!isNumeric(id)) {
            publishWarningNotificationTopic("Kunne ikke laste inn losoppdraget da oppgitt id ikke er gyldig.");
            navigate('/pilot/pilotages/assigned');
        } else {

            const pilotageId = Number(id);

            if (navigator.onLine) {
                await initializeOnlineAsync(pilotageId);
            } else {
                await initializeOfflineAsync(pilotageId);
            }
        }
    }

    async function initializeOnlineAsync(pilotageId) {

        const pilotAssignment = await fetchPilotageAsync(pilotageId);

        if (!isObjectNull(pilotAssignment)) {
            const idb = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
            
            if (!isObjectNull(idb)) {
                const processedPilotAssignment = processPilotAssignment(idb, pilotAssignment);

                await PilotAssignmentIdbRepository.setAsync(processedPilotAssignment);
                dispatch(DispatcherActions.Initialized, processedPilotAssignment);
            
                initializeHeader(processedPilotAssignment);

                if (processedPilotAssignment.wasServerUpdated) {
                    setShowUpdatedDialog(false);
                }

            } else {

                await PilotAssignmentIdbRepository.setAsync(pilotAssignment);
                dispatch(DispatcherActions.Initialized, pilotAssignment);
                initializeHeader(pilotAssignment);
            }
        } else {
            await initializeOfflineAsync(pilotageId);
        }

    }

    async function initializeOfflineAsync(pilotageId) {
        const data = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);
        if (!isObjectNull(data)) {
            dispatch(DispatcherActions.Initialized, data);
            initializeHeader(data);
        } else {
            publishWarningNotificationTopic("Kunne ikke laste inn losoppdraget.");
            navigate('/pilot/pilotages/assigned');
        }
    }

    async function initializeHeader(pilotAssignment) {
        publishHeaderTopic(
            pilotAssignment.pilotage.pilotageShip.shipName,
            `${pilotAssignment.pilotage.pilotageNo}`,
            true, false, true);
    }

    async function fetchPilotageAsync(pilotageId) {
        return await PilotApiRepository.getPilotageByIdAsync(pilotageId)
            .then((response) => response.json())
            .then((result) => {
                publishHeaderTopic(result.pilotage.pilotageShip.shipName, result.pilotage.pilotageNo.toString());
                const converted = convertPilotAssignmentApiDtoToIdb(result);
                return converted;
            })
            .catch((error) => {
                console.error(error);
                publishWarningNotificationTopic("Kunne ikke hente inn oppdraget fra serveren.");
            })
    }

    async function onBottomMenuClick(pageState) {
        if (stateDetail.pageState === pageState || stateDetail.pageState === PageStates.Error) return;

        if (pageState === PageStates.PilotAssignment) {
            const pilotageId = Number(id);
            await setPilotAssignmentSuggestions(pilotageId)
        }

        dispatch(DispatcherActions.PageState, pageState);
    }

    function onReloadClick() {
        initializeAsync();
    }

    async function onClosePilotAssignmentUpdatedDialogClickAsync() {
        const pilotageId = Number(id);
        const data = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);

        data.wasServerUpdated = false;

        await PilotAssignmentIdbRepository.setAsync(data);

        setShowUpdatedDialog(false);
    }

    async function onUpdatePilotagePilotAsync(pilotagePilot) {
        await PilotAssignmentIdbRepository.setAsync(pilotagePilot);
        initializeAsync();
    }
}

export default Details;