import React, { useEffect, useState } from 'react';
import Spacer from '../../../components/layout/Spacer';
import RadioButton from '../../../components/layout/RadioButton';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import "../styles/pcs.css"
import TextBoxFieldWrapper from '../../../components/layout/fieldWrappers/TextBoxFieldWrapper';
import { isArrayEmpty, isArraysEqual, isBoolean, isNullOrEmpty, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { loggedInId } from '../../../components/helpers/AuthHelpers';
import { DialogConfirm } from '../../../components/layout/dialogs/components/DialogConfirm';
import { DialogProperties } from '../../../components/layout/dialogs/DialogProperties';
import { Button } from "@progress/kendo-react-buttons";
import Switch from '../../../components/layout/switch';
import { Card } from '../../../components/layout/card/Card';
import Overlay from '../../../components/layout/overlay/Overlay';
import { publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

export const GroupCard = forwardRef((
    {
        groupId,
        mapChanges,
        onClose,
        onUpdatePcsTitle
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onDelete() {
            if (groupId === 0) return;
            setShowDeleteDialog(true);
        },
    }));

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [keepExistingEvaluations, setKeepExistingEvaluations] = useState(null);
    const [isSegmentsValid, setIsSegmentsValid] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);
    const [group, setGroup] = useState({});
    const [groupName, setGroupName] = useState("");
    const [groupTypeDisplayName, setGroupTypeDisplayName] = useState(""); 
    const [coastalSegmentIds, setCoastalSegmentIds] = useState([]);
    const [coastalSegmentIdsCopy, setCoastalSegmentIdsCopy] = useState([]);
    const [evaluationCount, setEvaluationCount] = useState(0);

    useEffect(() => {
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isObjectNull(mapChanges)) return;
        if (!mapChanges.hasOwnProperty("isSegmentsValid")) return;
        
        setCoastalSegmentIds(mapChanges.segmentIds);
        setIsSegmentsValid(mapChanges.isSegmentsValid);

        setCanSave(!isNullOrEmpty(groupName) && !isArrayEmpty(mapChanges.segmentIds) && mapChanges.isSegmentsValid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapChanges]);

    return (
        <>
            <div className="pcs-group-edit">
                <div className="container">
                    <div>
                        <div className="pcs-edit">
                            <Card>
                                <Spacer height={10} />
                                <div>
                                    <TextBoxFieldWrapper
                                        title="Navn"
                                        boldTitle={true}
                                        value={groupName}
                                        onChange={onTextChange}
                                    />
                                    <Spacer height={5} />
                                    <div className="pcs-edit-info">
                                        <div>
                                            Vanskelighetsgrad: {groupTypeDisplayName}
                                        </div>
                                        <div>
                                            Antall ruter: {coastalSegmentIds.length}
                                        </div>
                                    </div>
                                    <Spacer height={20} />
                                    {
                                        (evaluationCount > 0) &&
                                        <Switch
                                            size="medium"
                                            onLabel="Slett alle tilh&oslash;rende evalueringer"
                                            onChange={onDeleteAllChanged}
                                            checked={deleteAll}
                                        />
                                    }

                                </div>
                                <Spacer height={10} />
                            </Card>
                        </div>
                                        
                    </div>
                    <Spacer height={20} />
                </div>

                <div className="pcs-evaluation-form-controls">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">

                            </div>
                            <div className="col-auto">
                                <Button
                                    onClick={() => onClose(false)}>
                                    Avbryt
                                </Button>
                            </div>
                            <div className="col-auto">
                                <Button
                                    disabled={!canSave || hasErrors}
                                    onClick={handleSaveAsync}
                                    themeColor="primary">
                                    Lagre
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showConfirmDialog &&
                <GroupConfirmChanges
                    deleteAll={deleteAll}
                    keepExistingEvaluations={keepExistingEvaluations}
                    setKeepExistingEvaluations={setKeepExistingEvaluations}
                    onCancelClick={onCancelConfirmClick}
                    saveAsync={saveAsync} />
            }

            {
                showDeleteDialog &&
                <GroupDelete
                    onCancelClick={() => {
                        setShowDeleteDialog(false);
                    }}
                    onSaveClick={() => {
                        setShowDeleteDialog(false);
                        deleteAsync();
                    }
                    } />
            }

            <Overlay isBusy={isBusy}/>
        </>
    );

    async function initializeAsync() {
        if (groupId === 0) return;

        setIsBusy(true);

        const response = await PcsApiRepository.getGroupAsync(groupId);

        if (response.ok === true) {
            const data = await response.json();

            if (isNullOrEmpty(data.groupName)) {
                data.groupName = "";
            }

            setGroup(data);
            setGroupName(data.groupName);
            setGroupTypeDisplayName(data.groupTypeDisplayName);
            setEvaluationCount(data.evaluationCount);
            setCoastalSegmentIds(data.coastalSegmentIds);
            setCoastalSegmentIdsCopy(data.coastalSegmentIds);
            setIsSegmentsValid(true);

        } else {
            publishWarningNotificationTopic("Kunne ikke hente inn sertifikatomr&aring;de");
            setHasErrors(true);
        }

        setIsBusy(false);
    }


    function onCancelConfirmClick() {
        setShowConfirmDialog(false);
    }

    async function handleSaveAsync() {
        const personId = loggedInId();

        let evaluationsByOthers = []
        let canSave = true;

        if (groupId > 0 && evaluationCount > 0) {
            const response = await PcsApiRepository.getGroupEvaluationsAsync(groupId);
            if (response.ok === true) {
                const data = await response.json();
                evaluationsByOthers = data.filter(e => e.personId !== Number(personId) && e.isCompleted === true);
            } else {
                canSave = false;
            }
        }

        if (!canSave && !deleteAll) return;

        if ((!isArraysEqual(coastalSegmentIdsCopy, coastalSegmentIds) && evaluationsByOthers.length > 0) || deleteAll) {
            setShowConfirmDialog(true);
        } else {
            await saveAsync();
        }
    }

    async function saveAsync(resetEvaluations = false) {
        if (!canSave) return;

        setIsBusy();

        group.groupName = groupName;
        group.coastalSegmentIds = coastalSegmentIds;
        group.resetEvaluations = resetEvaluations;

        const response = await PcsApiRepository.setGroupAsync(group);
        if (response.ok === true) {
            publishSuccessNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble lagret.`);
            setIsBusy(false);
            onClose(true);
        } else {
            setIsBusy(false);
            publishWarningNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble ikke lagret.`);
        }
    }

    async function deleteAsync() {
        setIsBusy(true);
        const response = await PcsApiRepository.deleteGroupAsync(group);
        if (response.ok === true) {
            setIsBusy(false);
            publishSuccessNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble slettet.`);
            onClose(true);
        } else {
            setIsBusy(false);
            publishWarningNotificationTopic(`Sertifikatomr&aringdet ${group.groupName} ble ikke slettet.`);
        }
    }

    function onTextChange(e) {
        setGroupName(e.target.value);
        setCanSave(!isNullOrEmpty(e.target.value) && !isArrayEmpty(coastalSegmentIds) && isSegmentsValid);
        onUpdatePcsTitle(e.target.value);
    }

    function onDeleteAllChanged(e) {
        setDeleteAll(e.value);
        setCanSave(!isNullOrEmpty(groupName) && !isArrayEmpty(coastalSegmentIds) && isSegmentsValid);
    }
})

const GroupDelete = (
    {
        onCancelClick,
        onSaveClick
    }
) => {

    return (
        <DialogConfirm properties={{
            ...DialogProperties,
            title: "Slett sertifikatomr&aring;de",
            size: "small",
            text: "&Oslash;nsker du &aring; slette sertifikatomr&aring;det? Alle evalueringer knyttet til dette sertifikatomr&aring;det vil ogs&aring; bli slettet.",
            onClose: onCancelClick,
            onClick: onSaveClick
        }} />
    );
}

const GroupConfirmChanges = ({
    deleteAll,
    keepExistingEvaluations,
    setKeepExistingEvaluations,
    onCancelClick,
    saveAsync
}) => {
    
    return (
        <DialogConfirm properties={{
            ...DialogProperties,
            title: "Bekreft endringer",
            onClose: onCancelClick,
            onClick: onSaveClickAsync,
            isClickActive: isBoolean(keepExistingEvaluations)
        } }>
            <div className="container">
                <div className="pcs-edit">
                    {
                        deleteAll ?
                            <>
                                Vil du ta vare p&aring; eller slette eksisterende evalueringer?
                            </>
                            :
                            <>
                                Det er gjort endringer p&aring; sertifkatomr&aring;det og det finnes evalueringer gjort av andre loser.<br />
                                Vil du ta vare p&aring; eller slette eksisterende evalueringer?
                            </>
                    }
                    <Spacer height={30} />
                    <RadioButton
                        label="Ta vare p&aring; eksisterende"
                        onClick={() => setKeepExistingEvaluations(true)}
                        checked={keepExistingEvaluations === true} />
                    <Spacer height={15} />
                    <RadioButton
                        label="Slett eksisterende"
                        onClick={() => setKeepExistingEvaluations(false)}
                        checked={keepExistingEvaluations === false} />
                </div>
            </div>
        </DialogConfirm>
    );

    async function onSaveClickAsync() {
        await saveAsync(!keepExistingEvaluations);
    }
}