
export const EvaluationSearchStates = {
    None: 'None',
    Dates: 'Dates',
    PilotageNo: 'PilotageNo'
}

export const EvaluationResponsibilityReasonEnum = {
    NONE: "NONE",
    YES: null,
    NO_RESPONSIBILITY: "NO_RESPONSIBILITY",
    NO_PARTICIPATION: "NO_PARTICIPATION"
};

export const EvaluationAssessmentEnum = {
    Blank: "Blank",
    NoKnowledge: "NoKnowledge",
    WeakKnowledge: "WeakKnowledge",
    OkKnowledge: "OkKnowledge",
    GoodKnowledge: "GoodKnowledge",
    NotApplicable: "NotApplicable",
    Completed: "Completed",
    NotCompleted: "NotCompleted",
    Day: "Day",
    Night: "Night",
    DayNight: "DayNight",
    Recommended: "Recommended",
    NotRecommended: "NotRecommended",
    NoBasisForEvaluation: "NoBasisForEvaluation",
    NoSelection: "NoSelection",

    Yes: "Yes",
    No: "No"
};

export const EvaluationListItemStates = {
    Default: 'Default',
    Archived: 'Archived',
    Editing: 'Editing'
}

export const EvaluationAssesstmentStepStates = {
    Default: 'Default',
    CommentsOnly: 'CommentsOnly'
}

export const EvaluationAlternativeTypes = {
    Boolean: 'Boolean',
    Level: 'Level',
    LevelWithNotApplicable: 'LevelWithNotApplicable',
    CompleteType: 'CompleteType',
    DayNight: 'DayNight',
    RecommendationType: 'RecommendationType'
}

export function isBlank(assessment) {
    return assessment === EvaluationAssessmentEnum.Blank;
}

