import React, { useEffect } from 'react';
import { useState } from 'react';
import { PilotAssignmentCommands } from '../../../pilot/services/DispatcherActions';
import Moment from 'moment';
import Spacer from '../../../../components/layout/Spacer';
import { DateTimePickerModes } from '../../../../components/layout/DateTimePicker';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import {
    TextBox,
} from "@progress/kendo-react-inputs";
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import { deepCopyObject, isNullOrEmpty, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { formatMoment } from '../../../../components/helpers/DateTimeHelpers';
import TextAreaFieldWrapper from '../../../../components/layout/fieldWrappers/TextAreaFieldWrapper';
import DateTimePickerFieldWrapper from '../../../../components/layout/fieldWrappers/DateTimePickerFieldWrapper';
import Switch from '../../../../components/layout/switch';
import { DialogForm } from '../../../../components/layout/DialogForm';

export const PilotageOvertimeDialog = ({ onClose, overtime = null, canEdit = false, callback}) => {

    const [isEditable, setIsEditable] = useState(false);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [isMinimumTime, setIsMinimumTime] = useState(false);
    const [isTwoThirds, setIsTwoThirds] = useState(false);
    const [description, setDescription] = useState("");
    const [hours, setHours] = useState("");
    const [warnings, setWarnings] = useState([]);

    useEffect(() => {
        initializeAsync();
    }, []);

    return (
        <Dialog
            properties={{
                ...DialogProperties,
                title: "Overtid",
                onClose: onClose,
                className: "dialog-std",
                actions: [
                    {
                        onClick: onClose,
                        icon: "close",
                        text: "Avbryt"
                    },
                    {
                        onClick: onSaveClick,
                        disabled: !isEditable,
                        icon: "ok",
                        themeColor: "primary",
                        text: "Ok"
                    }
                ]
            }}>
            <DialogForm>
                <DateTimePickerFieldWrapper
                    title="Fra dato"
                    mode={DateTimePickerModes.DateTime}
                    value={fromTime}
                    onChange={onFromTimeChange}
                    disabled={canEdit}
                />
                <Spacer height={20} />
                <DateTimePickerFieldWrapper
                    title="Til dato"
                    mode={DateTimePickerModes.DateTime}
                    value={toTime}
                    onChange={onToTimeChange}
                    disabled={canEdit}
                    warnings={warnings}
                />
                <Spacer height={20} />
                <Switch
                    onLabel="Minimumstid 2t"
                    checked={isMinimumTime}
                    onChange={onMinimumTimeChange}
                    disabled={canEdit}
                />
                <Spacer height={20} />
                <Switch
                    onLabel="2/3 timer"
                    checked={isTwoThirds}
                    onChange={onTwoThirdshange}
                    disabled={canEdit}
                />
                <Spacer height={20} />

                <FieldWrapper>
                    <Label className="k-form-label">
                        Antall:
                    </Label>
                    <div className="k-form-field-wrap">
                        <TextBox value={hours} disabled={true} readOnly={true} />
                    </div>
                </FieldWrapper>

                <Spacer height={20} />
                <TextAreaFieldWrapper
                    title="Beskrivelse"
                    rows={3}
                    value={description}
                    onChange={onDescriptionChange}
                    disabled={canEdit}
                />
            </DialogForm>
        </Dialog>
    )

    function initializeAsync() {
        
        if (isObjectNull(overtime)) return;

        setDescription(overtime.description);
        setIsMinimumTime(overtime.isMinimumTime);
        setIsTwoThirds(overtime.isTwoThirds);
        setHours(overtime.number);
        setFromTime(Moment(overtime.fromTime).toDate());
        setToTime(Moment(overtime.toTime).toDate());
    }

    async function onSaveClick() {
        if (!isEditable) return;

        let compensation = { command: PilotAssignmentCommands.AddPilotAssignmentOvertime };
        if (!isObjectNull(overtime)) {
            compensation = deepCopyObject(overtime);
            compensation.command = PilotAssignmentCommands.UpdatePilotAssignmentOvertime;
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        compensation.fromTime = formatMoment(from);
        compensation.toTime = formatMoment(to);
        compensation.isMinimumTime = isMinimumTime;
        compensation.isTwoThirds = isTwoThirds;
        compensation.number = Number(hours);
        compensation.description = description;
        //compensation.id = overtime.id;
        compensation.type = {
            systemName: "OVERTIME_100_PERCENT",
            value: 1
        }

        callback(compensation);
        onClose();
    }

    function onDescriptionChange(e) {
        setDescription(e.value);
        setIsEditable(warnings.length === 0);
    }

    function onFromTimeChange(e) {
        setFromTime(e.value);
        calculateHours(e.value, getParsedMomentDate(toTime), isMinimumTime, isTwoThirds);
    }

    function onToTimeChange(e) {
        setToTime(e.value);
        calculateHours(getParsedMomentDate(fromTime), e.value, isMinimumTime, isTwoThirds);
    }

    function onMinimumTimeChange(e) {
        setIsMinimumTime(e.target.value);
        calculateHours(getParsedMomentDate(fromTime), getParsedMomentDate(toTime), e.target.value, isTwoThirds);
    }

    function onTwoThirdshange(e) {
        setIsTwoThirds(e.target.value);
        calculateHours(fromTime, toTime, isMinimumTime, e.target.value);
    }

    function calculateHours(fromTime, toTime, minimumTime, twoThirds) {

        const warnings = [];

        if (isObjectNull(fromTime) || isObjectNull(toTime)) {
            if (isNullOrEmpty(fromTime)) {
                warnings.push("Fra-dato kan ikke v&aelig;re tom.");
            }
            if (isNullOrEmpty(toTime)) {
                warnings.push("Til-dato kan ikke v&aelig;re tom.");
            }

            setHours("");
            setWarnings(warnings);
            return;
        }

        const from = Moment(fromTime);
        const to = Moment(toTime);

        if (from >= to) {
            warnings.push("Til-dato kan ikke v&aelig;re f&oslash;r fra-dato.")
        }

        const diff = to.diff(from, 'minutes');
        let hours = (diff / 60);

        if (minimumTime) {
            hours = 2;
        }
        if (twoThirds) {
            hours = (hours / 3) * 2;
        }

        setHours(hours.toFixed(2));
        setWarnings(warnings);
        setIsEditable(warnings.length === 0 && !isNullOrEmpty(description));
    }

    function getParsedMomentDate(val) {
        return isNullOrEmpty(val) ? null : val;
    }
}
