/* eslint-disable react-hooks/exhaustive-deps */
import './header.css'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import Icons from '../icons/Icons';
import { IconColors } from '../../../services/SystemNames';
import { BadgeContainer } from "@progress/kendo-react-indicators";
import { Popup } from '@progress/kendo-react-popup';
import {
    ListView,
} from '@progress/kendo-react-listview';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import { isArrayEmpty, isNullOrEmpty } from '../../helpers/ObjectHelpers';
import { PubSubTopics } from '../../helpers/PubSubHelpers';
import PubSub from 'pubsub-js';
import CompensationApiRepository from '../../../repositories/api/CompensationApiRepository';
export const HeaderAlerts = () => {

    
    const navigate = useNavigate();
    const [showAlerts, setShowAlerts] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [alertCount, setAlertCount] = useState(0);

    const headerInterval = React.useRef(null);
    const anchorAlerts = React.useRef(null);

    const alertRender = (props) => {
        const alert = props.dataItem;
        return (
            <div className="header-alert-item">
                <div className="header-alert-item-content">
                    <div className="text-truncate">
                        {parse(alert.title)}
                    </div>
                    <div>
                        {parse(alert.message)}
                    </div>
                    {
                        !isNullOrEmpty(alert.urlText) &&
                            <div>
                                <div className="header-alert-item-navigate">
                                    <span className="reply-link" onClick={() => alert.onClick(alert)}>
                                        {parse(alert.urlText)}
                                    </span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.ReloadAlerts, handleHeaderTopic);
        initializeAlertsAsync();

        headerInterval.current = setInterval(() => initializeAlertsAsync(), 300000);// Check for alerts every 5 minutes

        return () => {
            PubSub.unsubscribe(PubSubTopics.ReloadAlerts);

            if (!headerInterval.current) {
                clearInterval(headerInterval.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        document.body.addEventListener("click", handleBodyClick);

        return () => {
            document.body.removeEventListener("click", handleBodyClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleBodyClick]);

    return (
        <>
            <div>
                <button ref={anchorAlerts} onClick={onShowAlertsClick} className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base" style={{ border: "0px" }}>
                    <BadgeContainer>
                        {
                            alertCount > 0 ?
                                <Icons iconName="notification" className="header-notification-active" />
                                :
                                <Icons iconName="notificationInactive" className="header-notification"/>
                        }
                    </BadgeContainer>
                </button>
            </div>
            <Popup anchor={anchorAlerts.current} show={showAlerts} popupClass={'header-popup-content'} style={{left: "0px" }}>
                {
                    alertCount === 0 ?
                        <>
                            <div className="header-alert-header">
                                <div>
                                    <Icons iconName="notificationInactive" color={IconColors.Primary} dimensions={40} />
                                </div>
                                <div>
                                    Varsler
                                </div>
                                <div>
                                    <Icons iconName="close" color={IconColors.Primary} onClick={() => setShowAlerts(!showAlerts)} className="pointer" />
                                </div>
                            </div>
                            <div className="header-alert-content" style={{ padding: 15 }}>
                                Du har ingen nye varsler.
                            </div>
                        </>
                        :
                        <>
                            <div className="header-alert-header">
                                <div>
                                    <Icons iconName="notificationInactive" color={IconColors.Primary} dimensions={40} />
                                </div>
                                <div>
                                    {alertCount} {alertCount === 1 ? "nytt varsel" : "nye varsler"}
                                </div>
                                <div>
                                    <Icons iconName="close" color={IconColors.Primary} onClick={() => setShowAlerts(!showAlerts)} className="pointer" />
                                </div>
                            </div>
                            <div className="header-alert-content">
                                <ListView
                                    className="header-alert-list"
                                    data={alerts}
                                    item={alertRender}
                                />
                            </div>
                        </>
                }
                <div className="header-alert-footer"></div>
            </Popup>
        </>
    );

    function handleBodyClick() {
        setShowAlerts(false);
    }

    function handleHeaderTopic(data) {
        initializeAlertsAsync();
    }

    async function initializeAlertsAsync() {

        const pilotAssignmentAlerts = await getPilotHseAlertsAsync();
        const compensationCorrectionAlerts = await getCompensationCorrectionAsync();
        
        const alerts = pilotAssignmentAlerts.concat(compensationCorrectionAlerts);

        if (isNullOrEmpty(alerts)) {
            setAlertCount(0);
            setAlerts([]);
            return;
        };
        setAlerts(alerts);
        setAlertCount(alerts.length);
    }
    
    function onShowAlertsClick(e) {
        e.stopPropagation();
        setShowAlerts(!showAlerts);
    }

    function onAlertClickAsync(alert) {
        setShowAlerts(false);

        if (isNullOrEmpty(alert.url)) return;

        goToUrl(alert.url);
    }

    function goToUrl(url) {
        setShowAlerts(false);
        setTimeout(() => {
            navigate(url);
        }, 250);
    }

    async function getPilotHseAlertsAsync() {
        const pilotHseAlerts = [];
        let personDataValidities = [];

        if (navigator.onLine) {
            const response = await PilotApiRepository.getPilotDataValidity();
            if (response.ok === true) {
                let pilotDataValidity = await response.json();
                personDataValidities = pilotDataValidity;
            }
        }

        if (isNullOrEmpty(personDataValidities)) return [];

        personDataValidities.forEach((personDataValidity) => {
            pilotHseAlerts.push(createHseAlert(personDataValidity));
        })

        //pilotAssignments.forEach(async (pilotAssignment) => {
        //    if (isPilotAssignmentAssignedAndNotProcessed(pilotAssignment)) {
        //        pilotAssignmentAlerts.push(createPilotAssignmentAlert(pilotAssignment, isObjLocal));
        //    }
        //});

        return pilotHseAlerts;
    }

    async function getCompensationCorrectionAsync() {
        const result = [];
        const response = await CompensationApiRepository.getPilotCompensations();

        if (response.ok) {
            const data = await response.json();
            const compensations = data.filter((c) => c.isCorrectionRequired);
            if (!isArrayEmpty(compensations)) {
                result.push(
                    {
                        title: "Korrigering kreves",
                        message: `Det er ${compensations.length} tillegg som krever korrigering`,
                        url: `/pilot/compensation?correction=true`,
                        urlText: "G&aring; til Tillegg",
                        onClick: onAlertClickAsync,
                    });
            }
        }
        return result;
    }

    function createHseAlert(personDataValidity) {
        if (isNullOrEmpty(personDataValidity.expiredText)) {
            return {
                title: `${personDataValidity.name}`,
                message: `${personDataValidity.soonExpiredText}`,
                url: `hse/${personDataValidity.typeGroupSystemName}`,
                onClick: onAlertClickAsync,
                obj: personDataValidity,
                urlText: 'G&aring; til HMS-data',
            }
        }
        return {
            title: `${personDataValidity.name}`,
            message: `${personDataValidity.expiredText}`,
            url: `/hse/${personDataValidity.typeGroupSystemName}`,
            onClick: onAlertClickAsync,
            obj: personDataValidity,
            urlText: 'G&aring; til HMS-data',
        };
    }
    
    //function createPilotAssignmentAlert(pilotAssignment, isObjLocal) {
    //    return {
    //        title: pilotAssignment.pilotage.pilotageShip.shipName,
    //        message: `${pilotAssignment.pilotage.pilotageNo}<br/>`
    //            + `${Moment(pilotAssignment.pilotage.pilotageDetail.fromTime).format('DD.MM HH:mm')}<br/>`
    //            + `${parse(pilotAssignment.pilotage.fromLocation.name)} til ${parse(pilotAssignment.pilotage.toLocation.name)}`,
    //        icon: 'ship',
    //        url: getPilotAssignmentUrl(pilotAssignment),
    //        urlText: 'Besvar foresp&oslash;rsel',
    //        obj: pilotAssignment,
    //        onClick: onPilotHseAlertClickAsync,
    //        isObjLocal: isObjLocal
    //    };
    //}
}

