import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useState } from 'react';
import { getElementsByClassName, removeClassFromElementsByClassName } from '../../../components/helpers/ElementHelpers';
import { isArrayEmpty, isArraysEqual, isObjectNull } from '../../../components/helpers/ObjectHelpers';
import { publishWarningNotificationTopic } from '../../../components/helpers/PubSubHelpers';
import Overlay from '../../../components/layout/overlay/Overlay';
import Spacer from '../../../components/layout/Spacer';
import PcsApiRepository from '../../../repositories/api/PcsApiRepository';
import "../styles/pcs.css";
import {
    Grid,
    GridColumn
} from "@progress/kendo-react-grid";

export const GroupListCards = forwardRef((
    {
        onClick = null,
        mapChanges = null,
        onMouseOver,
        onMouseOut
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onReload() {
            initializeAsync();
        },
        onToggleGroupVisibility() {
            toggleGroupVisibility(mapChanges, groups);
        },
        onGroupMouseOver(id) {
            activateGroupItem(id);
        },
        onGroupMouseOut() {
            deactivateGroupItems();
        }
    }));

    const [groups, setGroups] = useState([]);
    const [isBusy, setIsBusy] = useState(true);
    const [filteredGroups, setFilteredGroups] = useState([]);

    useEffect(() => {
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isObjectNull(mapChanges)) return;
        toggleGroupVisibility(mapChanges, groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapChanges]);
    
    return (
        <>
            <div className="pcs-result">
                <div className="container">
                    {
                        (!isArrayEmpty(filteredGroups)) ?
                            <Grid
                                className="pcs-grid"
                                data={filteredGroups}
                                onRowClick={onRowClick}>
                                <GridColumn width="30px" cell={GroupListRowIndicator} />
                                <GridColumn
                                    title="Sertifikatomr&aring;de"
                                    field="groupName" />
                            </Grid>
                            :
                            <>
                                <Spacer height={60}/>
                                <div className="row pcs-no-result align-middle">
                                    Det finnes ikke noen sertifikatomr&aring;der i valgt kartutsnitt.
                                </div>
                            </>
                    }
                    
                    <Spacer height={40} />
                </div>
            </div>
            <Overlay isBusy={isBusy} />
        </>
    );

    async function initializeAsync() {

        setIsBusy(true);

        const response = await PcsApiRepository.getSummaryAsync();
        if (response.ok === true) {
            const data = await response.json();
            setGroups(data.groupsGeoJson.groups);
            toggleGroupVisibility(mapChanges, data.groupsGeoJson.groups);
        } else {
            publishWarningNotificationTopic("Kunne ikke hente inn sertifikatomr&aring;der");
        }

        setIsBusy(false);
    }

    function onRowClick(e) {
        if (onClick === null) return;
        onClick(e.dataItem.groupId);
    }

    function toggleGroupVisibility(changes, groups) {

        let result = []
        if(!isObjectNull(changes)) {
            if (changes.hasOwnProperty("groupIds")) {
                result = groups.filter(x => changes.groupIds.includes(x.groupId));
            }
        }

        if (isArraysEqual(result, filteredGroups)) return;

        setFilteredGroups(result);
        setTimeout(() => {
            const grid = getGrid();
            if (isObjectNull(grid)) return;

            const elems = grid.querySelectorAll(".k-table-row");
            for (const elem of elems) {
                const index = elem.getAttribute("data-grid-row-index");
                const group = result[index];

                elem.addEventListener("mouseover", () => onMouseOver(group.groupId));
                elem.addEventListener("mouseout", () => onMouseOut());
            }

        }, 125);
    }

    function deactivateGroupItems() {
        removeClassFromElementsByClassName('pcs-row-active', 'pcs-row-active');
    }

    function activateGroupItem(groupId) {
        const group = filteredGroups.find(x => x.groupId === groupId);
        if (isObjectNull(group)) return;

        const index = filteredGroups.indexOf(group);
        const grid = getGrid();
        if (isObjectNull(grid)) return;

        const row = grid.querySelector(`[data-grid-row-index="${index}"]`);
        if (isObjectNull(row)) return;

        row.classList.add("pcs-row-active");
    }

    function getGrid() {
        const elems = getElementsByClassName("pcs-grid");
        if (isArrayEmpty(elems)) return null;

        return elems[0];
    }
})

const GroupListRowIndicator = (props) => {
    return (
        <td style={{
            backgroundColor: getBackgroundColor(),
            color: getColor(),
            textAlign: "center",
            fontWeight: 700
        } }>{props.dataItem.evaluationCount}</td>
    )

    function getBackgroundColor() {
        switch (props.dataItem.groupType) {
            case "GROUP_TYPE_1":
                return "var(--active-dark)";
            case "GROUP_TYPE_2":
                return "var(--yellow)";
            case "GROUP_TYPE_3":
                return "var(--error)";

            default:
                return "";
        }
    }

    function getColor() {
        switch (props.dataItem.groupType) {
            case "GROUP_TYPE_1":
            case "GROUP_TYPE_3":
                return "var(--grey-lightest-shadow)";
            case "GROUP_TYPE_2":
                return "var(--font-secondary)";

            default:
                return "var(--font-primary)";
        }
    }
}

//export const GroupListRow = ({ group  }) => {
//    return (
//        <div className="pcs-row-group">
//            <div>
//                <div className={`circle type-${getCircleType()}`}>
//                    <div>{group.evaluationCount}</div>
//                </div>
//            </div>
//            <div className="elipsis">
//                {group.groupName}
//            </div>
//        </div>
//    )

//    function getCircleType() {
//        return group.groupType.charAt(group.groupType.length - 1);
//    }
//}
