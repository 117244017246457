import { wrapMethodsWithLogging } from "../../components/helpers/ErrorHandlingHelpers";
import BaseApiRepository from "./BaseApiRepository";

const PecAdminApiRepository = {


    async getPecFairwayAreasAsync(id, pilotagePecExamId) {
        return await BaseApiRepository.getAsync(`pec-admin/pec/${id}/fairway-areas/${pilotagePecExamId}`);
    },

    async getPecExamAsync(id) {
        return await BaseApiRepository.getAsync(`pec-admin/exam/${id}`);
    },
    
};
wrapMethodsWithLogging(PecAdminApiRepository);

export default PecAdminApiRepository;