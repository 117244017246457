import {
    IndexedDbNames
} from '../../services/SystemNames';
import BaseIdbRepository from './BaseIdbRepository';

const Store = IndexedDbNames.PilotagePilotTypeStore;

const PilotagePilotTypeIdbRepository = {

    async clearAsync() {
        await BaseIdbRepository.clearAsync(Store);
    },

    async setAsync(obj) {
        await BaseIdbRepository.setAsync(Store, obj.pilotagePilotTypeId, obj);
    },

    async getAllAsync() {
        return await BaseIdbRepository.getAllAsync(Store);
    },
    
    async getAsync(key) {
        return await BaseIdbRepository.getAsync(Store, key);
    },

    async getDefaultAsync() {
        return await this.getBySystemNameAsync('SINGLE_PILOTING');
    },

    async getBySystemNameAsync(systemName) {
        const objs = await BaseIdbRepository.getAllAsync(Store);
        return objs.find(o => o.systemName.toUpperCase() === systemName.toUpperCase());
    },
};

export default PilotagePilotTypeIdbRepository;