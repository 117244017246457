import './floatingbuttoncontainer.css'

import React from 'react';

export default function FloatingButtonContainer(
    {
        children,
        hasBottomNavigation = false,
        position = "right",
        className = ""
    }
) {

    return (
        <div className={`floating-button-container ${className}`} 
            style={getStyle()}>
            {children}
        </div>
    );

    function getStyle() {
        switch (position) {

            case "left":
                return {
                    bottom: hasBottomNavigation ? 70 : 16,
                    left: 16
                }

            default:
                return {
                    bottom: hasBottomNavigation ? 70 : 16,
                    right: 16
                }
        }
    }
}
